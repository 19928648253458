export const allowedMaxFileSizeMb = 4.5;
export const allowedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.heic', '.heif'];
export const allowedFileExtensions = ['.pdf', '.csv', '.doc', '.docx', '.xls', '.xlsx'];
export const allowedUploadExtensions = allowedFileExtensions
  .concat(allowedImageExtensions)
  .join(', ');
export const allowedFileParameters = [
  `Max file size: ${allowedMaxFileSizeMb} MB`,
  `Allowed file types: ${allowedUploadExtensions} `,
];

export const uploadEnum = {
  dlfront: {
    title: 'Drivers license (front)',
    subtitle: 'upload or take a picture of the front of your valid drivers license.',
    label: 'Drivers License',
  },
  dlback: {
    title: 'Drivers license (back)',
    subtitle: 'upload or take a picture of the back of your valid drivers license.',
    label: 'Drivers License',
  },
  insurance: {
    title: 'Proof of Insurance',
    subtitle: 'upload or take a picture of your valid auto insurance documentation.',
    label: 'Insurance',
  },
  income: {
    title: 'Proof of Income',
    subtitle:
      'upload last 3 pay stubs or paychecks, a statement from your employer, self-employment records, or last years tax return.',
    label: 'Income',
  },
  residence: {
    title: 'Proof of Residence',
    subtitle: 'Upload or take a picture of a valid document proving your address. ',
    label: 'Residence',
  },
  tradein: {
    title: 'Do you have photos of your vehicle?',
    subtitle: 'Upload them here',
    label: 'Trade in',
  },
  other: {
    title: 'Need to upload additional documents?',
    subtitle: 'Upload other documents here',
    label: 'Other',
  },
};
