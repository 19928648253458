import { useSessionContext } from 'src/components/context/SessionProvider';
import { Calculator } from './Calculator';
import { UnlockPaymentForm } from './UnlockPaymentForm';
import { UnauthorizedLeadForm } from 'src/lead-forms/UnauthorizedLeadForm';
import { useAuth } from 'src/components/context/AuthProvider';
import { useContext, useMemo } from 'react';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { BlockRoute, BlockStatus } from 'src/types/blocks';
import Success from 'src/components/form/Success';
import { useDeals } from 'src/components/context/DealsProvider';
import { WidgetForm } from 'src/widgets/SpaceWidget';
import { useConfig } from 'src/components/context/config';
import { PaymentType } from 'src/types/deal';
import { Verify } from 'src/components/authentication/Verify';
import { isSitePaymentHidden } from 'src/constants/price';

export function PaymentExplorer({ submitText }) {
  const config = useConfig()!;
  const { deal } = useDeals();
  const {
    sessionDesk,
    sessionCustomer,
    sessionCreditScore,
    sessionPaymentType,
    sessionZip,
    unauthorizedLockedDeals,
  } = useSessionContext();
  const { token } = useAuth();
  const { status } = useContext(NavigationContext);

  const unauthorizedWithVehicle = !token && deal?.vin;
  const vehicleIsLockedDeal = unauthorizedLockedDeals?.includes(deal?.vin || '') && !sessionDesk;

  const calculatorIsAvailable = useMemo(() => {
    if (!deal) return false;
    const price = deal.price ?? deal.msrp;
    return (
      typeof price === 'number' && !isNaN(price) && !isSitePaymentHidden(config) && sessionCustomer
    );
  }, [config, sessionCustomer, deal]);

  const morePaymentInfoNeeded = !sessionZip || !sessionPaymentType || !sessionCreditScore;

  if (!deal) return null;

  if (unauthorizedWithVehicle) {
    if (!vehicleIsLockedDeal) {
      if (!calculatorIsAvailable) {
        if (status[BlockRoute.PaymentOptions] !== BlockStatus.Done) {
          if (morePaymentInfoNeeded) {
            return (
              <UnlockPaymentForm
                zip={sessionZip}
                paymentMethod={sessionPaymentType || PaymentType.Financing}
                creditScore={sessionCreditScore}
              />
            );
          }

          return (
            <UnauthorizedLeadForm
              form={WidgetForm.PaymentOptions}
              vehicle={deal}
              title={'Get Your Personalized Quote'}
              body={
                "Dive into your payment options with a quick sign up. Just fill in a few details and you're on your way to tailored payment plans."
              }
              additionalFields={[]}
              additionalPayload={{
                paymentMethod: sessionPaymentType,
                creditScore: sessionCreditScore,
              }}
              vin={deal.vin || null}
              submitText={submitText}
              requireZip={false}
              customNavigationHandle={() => {}} // Stop navigation
            />
          );
        }

        return (
          <Success
            title="We Are Working On Your Quote!"
            subtitle="Thanks for reaching out! A team member is building your payment options and will reach out shortly. In the meantime, fill out forms here to save time at the dealership."
          />
        );
      }

      return <Calculator zip={sessionZip} />;
    }
    return (
      <Verify
        title="You saved a payment option on this vehicle"
        subtitle="For your security, this information is protected. Please login to view your saved deal."
      />
    );
  }

  return <Calculator zip={sessionZip} />;
}
