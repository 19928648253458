import { useConfig } from 'src/components/context/config';
import { FormBody } from 'src/components/form/Form';

export function PullConsentText() {
  const config = useConfig()!;

  const title = () => {
    if (config.forms?.creditApplication?.pullConsentTitle) {
      return config.forms.creditApplication.pullConsentTitle;
    }

    if (config.forms?.creditApplication?.pullConsentIsHidden) {
      return 'Confirm Your Details';
    }

    return 'Do you agree to a hard pull of your credit?';
  };

  const body = () => {
    if (config.forms?.creditApplication?.pullConsentDescription) {
      return config.forms.creditApplication.pullConsentDescription;
    }

    if (config.forms?.creditApplication?.pullConsentIsHidden) {
      return null;
    }
    return 'By agreeing to the terms, you consent to a hard pull of your credit for the purpose of a credit application.';
  };

  return <FormBody title={title()} body={body()} />;
}
