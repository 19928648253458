import { useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthProvider';
import Menu from './Menu';
import RightMenu from './RightMenu';
import { Banner } from './Banner';
import { NavigationProvider } from './context/NavigationProvider';
import { MenuProvider, useMenu } from './context/MenuProvider';
import { MenuOverlay } from './MenuOverlay';
import DealsProvider from './context/DealsProvider';

function Page({ handleLogout, dealershipLogoUrl }) {
  const { isMenuOpen, isVehicleSelectorOpen } = useMenu();

  const renderClass = useMemo(() => {
    const classes = ['page'];
    if (isMenuOpen || isVehicleSelectorOpen) classes.push('is-overlay-open');
    if (isMenuOpen) classes.push('is-menu-open');
    return classes.join(' ');
  }, [isMenuOpen, isVehicleSelectorOpen]);

  return (
    <div className={renderClass}>
      <Menu dealershipLogoUrl={dealershipLogoUrl} handleLogout={handleLogout} />
      <div className="page--right">
        <RightMenu handleLogout={handleLogout} />
        <Banner />
        <main className="main">
          <Outlet />
        </main>
      </div>
      <MenuOverlay />
    </div>
  );
}

export function Layout({ dealershipLogoUrl }) {
  const navigate = useNavigate();

  const { signout } = useAuth();
  // if end of location pathname is 'offer' or 'offer/' redirect to 'offer/1'

  const handleLogout = async () => {
    signout(() => {
      navigate('/login?message=loggedOut');
    });
  };

  return (
    <DealsProvider initialVehicle={undefined}>
      <NavigationProvider>
        <MenuProvider>
          <Page handleLogout={handleLogout} dealershipLogoUrl={dealershipLogoUrl} />
        </MenuProvider>
      </NavigationProvider>
    </DealsProvider>
  );
}
