import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { FullBlockRoute } from 'src/types/blocks';
import { LottieLoader } from '../LottieLoader';
import { Colors } from 'src/types/colors';
import PageLoader from '../PageLoader';

export function NavigatingMessage({ route }: { route: FullBlockRoute }) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    let timer;

    timer = setTimeout(() => {
      setIsReady(true);
    }, 3000);

    // Cleanup function to cancel the timer if the error is resolved before the timeout
    return () => clearTimeout(timer);
  }, []);

  if (!isReady) return <LottieLoader color={Colors.Black} height={28} width={24} />;

  return (
    <PageLoader>
      <Navigate to={`${route}new`} />
    </PageLoader>
  );
}
