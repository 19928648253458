import './ActionIcon.css';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ActionIconProps {
  icon: IconDefinition;
  onClick: () => void;
}

export default function ActionIcon({ icon, onClick }: ActionIconProps) {
  return (
    <button className="action-icon" onClick={onClick} type="button">
      <FontAwesomeIcon icon={icon} />
    </button>
  );
}
