export const SmsTerms = `By checking this “Agree to terms & conditions.” box, you consent to receive text messages and
      phone calls, which may be recorded and/or sent using automated dialing equipment or software
      from this Company and its affiliates in the future, unless you opt-out from such
      communications. You understand that this consent is not a condition of purchase or credit and
      that you can opt-out at any time. If applicable, you agree to pay your mobile service
      provider's text messaging rates. All information stored in our database is secure and strictly
      confidential.`;

export const CreditReportTerms = `By checking this “Agree to a credit pull”, you consent to the use of the information provided
      in this form to facilitate the financing of a vehicle. Your personal and credit information
      will only be used to fulfill your request and in accordance with our Privacy Notice. By
      clicking the “Agree to terms & conditions.” button and providing an electronic signature
      below, you certify that all of the statements in this application are true and made to obtain
      credit, and you are confirming you have read and understood the Privacy Policy and the
      Application Disclosure, and you are authorizing this company under all applicable federal and
      state laws, including the Fair Credit Reporting Act, to obtain information from your personal
      credit profile. This form you have filled out on our website is not a loan application but a
      request to obtain prequalification. Also, you confirm that you understand that any
      pre-approval is subject to review and acceptance of credit information.`;

export const EsignatureTerms = `By checking this “Agree to use of eSignature.”, you consent to the use of the text entered
      into the "eSignature" as a stand-in for your personal signature and thus carrying the same
      weight. The above eSignature facilitates the execution of electronic documents between you,
      the dealership and other lending services. All information stored in our database is secure
      and strictly confidential.`;

export const PricingDisclaimer =
  'Prices may vary. Final rates need lender okay. No site content is a fixed deal. Optional products above have more benefits and coverage limits.';
