import { useConfig } from '../context/config';
import { FormWarning, FormWarningType } from './FormWarning';

export function CreditApplicationActionBar() {
  const config = useConfig()!;

  if (config?.forms?.creditApplication?.pullConsentIsHidden) {
    return (
      <FormWarning
        type={FormWarningType.Activity}
        text={
          config.forms.creditApplication.pullConsentIsHiddenText ||
          'Submitting this application WILL NOT PULL YOUR CREDIT without additional consent. This application is for documentation purposes only.'
        }
      />
    );
  }
  return null;
}
