import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useConfig } from '../config';

export const useQuickFormsStatus = (
  documentsStatus: BlockStatus,
  creditApplicationsStatus: BlockStatus,
  tradeInsStatus: BlockStatus
) => {
  const config = useConfig()!;
  return useMemo(() => {
    if (config.isRetailing) {
      if (
        documentsStatus === BlockStatus.Done &&
        (creditApplicationsStatus === BlockStatus.Done ||
          creditApplicationsStatus === BlockStatus.Hidden) &&
        tradeInsStatus === BlockStatus.Done
      ) {
        return BlockStatus.Done;
      }

      if (
        documentsStatus === BlockStatus.Incomplete ||
        creditApplicationsStatus === BlockStatus.Incomplete ||
        tradeInsStatus === BlockStatus.Incomplete
      ) {
        return BlockStatus.Incomplete;
      }
    } else {
      // No auth status
      if (creditApplicationsStatus === BlockStatus.Done && tradeInsStatus === BlockStatus.Done) {
        return BlockStatus.Done;
      }

      if (
        creditApplicationsStatus === BlockStatus.Incomplete ||
        creditApplicationsStatus === BlockStatus.Done ||
        tradeInsStatus === BlockStatus.Incomplete ||
        tradeInsStatus === BlockStatus.Done
      ) {
        return BlockStatus.Incomplete;
      }
    }
    return BlockStatus.Default;
  }, [documentsStatus, creditApplicationsStatus, tradeInsStatus, config]);
};
