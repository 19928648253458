import { Select } from '../controls/Select';

export function AppointmentType({ form, selectOptions }) {
  // Hide if there are no options
  if (selectOptions.length === 0) return null;
  return (
    <Select
      control={form.control}
      name="type"
      label="Appointment Type"
      placeholder="Select Appointment Type"
      selectOptions={selectOptions}
    />
  );
}
