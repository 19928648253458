import { Timezone } from '../types/dealership';

export function returnDealerTimezone(timezone: Timezone | string) {
  switch (timezone) {
    case Timezone.PST:
      return 'US/Pacific';
    case Timezone.MST:
      return 'US/Mountain';
    case Timezone.ARIZONA:
      return 'US/Arizona';
    case Timezone.CST:
      return 'US/Central';
    case Timezone.EST:
      return 'US/Eastern';
    default: // in case of irregular timezone config
      return timezone;
  }
}
