import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { useConfig } from '../context/config';
import Button from '../form/Button';
import { WebsiteRoute } from 'src/external/WebsiteRoutes';

export function SelectorAccordionSearch() {
  const config = useConfig()!;

  return (
    <Button
      type="button"
      baseClass="btn mod-thin mod-text-icon"
      icon={true}
      iconName={faSearch}
      iconClass="u-icon"
      handleSubmit={e => {
        e?.preventDefault();
        window.open(`https://${config.websiteDomain}${WebsiteRoute.ExploreVehicle}`);
      }}
      text="Search Inventory"
    />
  );
}
