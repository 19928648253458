import { ApplicationMode } from 'src/types/creditapplication-block';

export const ApplicationModeInputs = [
  {
    label: 'Single',
    labelDesc: 'Submit one credit application',
    name: 'applicationMode',
    value: ApplicationMode.Single,
  },
  {
    label: 'Joint',
    labelDesc: 'Submit a Primary and Co-signer application',
    name: 'applicationMode',
    value: ApplicationMode.Joint,
  },
];
