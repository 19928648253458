import UserTask from './UserTask';
import { BlockRoute, FullBlockRoute } from 'src/types/blocks';
import { TestVehicleBrowser } from 'src/pages/vehicles/TestVehicleBrowser';
import { NavigatingMessage } from './NavigatingMessage';
import { ChatMatrix } from './ChatMatrix';

export interface ChatConfig {
  botName: string;
  widgets: { widgetName: string; widgetFunc: (props?: any) => JSX.Element; props?: any }[];
}

const chatConfig = {
  botName: 'LearningBot',
  widgets: [
    {
      widgetName: 'learningOptions',
      widgetFunc: () => <UserTask />, //props => <UserTask {...props} />,
    },
    {
      widgetName: BlockRoute.CreditApplication,
      widgetFunc: () => <NavigatingMessage route={FullBlockRoute.CreditApplication} />,
      helpText: "I can help with that! One second, I'll send you to the right place",
    },
    {
      widgetName: BlockRoute.TradeIn,
      widgetFunc: () => <NavigatingMessage route={FullBlockRoute.TradeIn} />,
      helpText: "I can help with that! One second, I'll send you to the right place",
    },
    {
      widgetName: BlockRoute.Appointments,
      widgetFunc: () => <NavigatingMessage route={FullBlockRoute.Appointments} />,
      helpText: "I can help with that! One second, I'll send you to the right place",
    },
    {
      widgetName: BlockRoute.PaymentOptions,
      widgetFunc: () => <TestVehicleBrowser />,
      helpText:
        'Ok, here are some great choices. Click one to explore payments, or let me know what else you are looking for',
    },
    {
      widgetName: 'payments',
      widgetFunc: () => <ChatMatrix />,
      helpText:
        "Great choice. Let's explore some payment options for this vehicle. Which of these works for you?",
    },
  ],
};

export default chatConfig;
