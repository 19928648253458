import { FormActions } from 'src/components/form/FormActions';
import { useDealershipLocations } from 'src/helpers/useDealershipLocations';
import { location, returnCustomerSchema, zip } from '../../../constants/schemas';
import { Comments } from 'src/components/inputs/Textarea';
import { AdditionalField } from 'src/widgets/AdditionalField';
import { returnCustomerBlockDefaultValues } from 'src/types/customer-block';
import { CustomerFormattedBlock } from 'src/types/lead';
import { Customer } from 'src/components/steps/CustomerGroup';
import { AdditionalFields } from 'src/components/steps/AdditionalFields';
import { VehicleSelection, VehicleSelectionMode } from 'src/components/steps/VehicleSelection';
import { AppointmentStatus } from 'src/components/steps/AppointmentStatus';
import { FormBody, FormContentWrapper, FormWrapper, useSpaceForm } from 'src/components/form/Form';
import { Zip } from 'src/components/inputs/Input';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { MultilocationRadioGroup } from 'src/components/steps/MultilocationRadioGroup';
import { useConfig } from 'src/components/context/config';

interface LeadFormProps {
  handleFormSubmit: (data: any) => Promise<void>;
  handleFormCancel: () => void;
  notes?: string | null;
  isReview?: boolean;
  additionalFields?: AdditionalField[];
  vehicle?: any;
  title: string | null;
  body: string | null;
  customer?: CustomerFormattedBlock;
  submitText: string;
  requireZip: boolean;
  requirePhone: boolean;
  requireLocation: boolean; // not needed on basic info form
  requireSms: boolean;
  hideComments?: boolean;
  hideBackButton?: boolean;
  location?: string;
  navigation?: any;
}

export function LeadFormBuilder({ configuration }: { configuration: LeadFormProps }) {
  const activeForm = 'general';
  const config = useConfig()!;
  const { sessionCustomer } = useSessionContext();
  const dealershipLocations = useDealershipLocations();
  const data = returnCustomerBlockDefaultValues(
    configuration.customer || sessionCustomer || undefined,
    config
  );

  // Add fixed location to the data. If location exists we should not show multilocation radio group
  if (config.location || config.location === null) {
    data.location = config.location;
  } else if (configuration.location) {
    data.location = configuration.location;
  }

  const baseSchema = returnCustomerSchema(
    configuration.requirePhone,
    false,
    configuration.requireSms
  );

  const schema = {
    ...baseSchema,
    ...(configuration.requireLocation && { location }),
    ...(configuration.requireZip && { zip }),
  };

  const form = useSpaceForm(schema, data);

  return (
    <FormWrapper
      handleFormSubmit={configuration.handleFormSubmit}
      handleFormCancel={configuration.handleFormCancel}
      steps={[activeForm]}
    >
      {configuration.navigation ?? null}
      <FormContentWrapper form={form} id={activeForm}>
        <FormBody title={configuration.title} body={configuration.body || null} />
        <MultilocationRadioGroup form={form} locations={dealershipLocations.dropdownList} />
        {/* always read only because it only shows for vehicle inquiry form */}
        <VehicleSelection
          form={form}
          initialVehicle={configuration.vehicle}
          mode={VehicleSelectionMode.Browse}
        />
        <Customer form={form}>
          {configuration.requireZip && <Zip control={form.control} />}
        </Customer>
        <AdditionalFields form={form} additionalFields={configuration.additionalFields} />
        <AppointmentStatus appointment={configuration.vehicle} form={form} />
        {!configuration.hideComments && <Comments control={form.control} />}
      </FormContentWrapper>
      <FormActions
        hideBackButton={configuration.hideBackButton}
        fullButton={configuration.hideBackButton}
        alternateButtonText={configuration.submitText}
        isSubmitting={
          !config.isRetailing && config.forms?.lead?.explicitOptIn && !form.formState.isValid
        }
      />
    </FormWrapper>
  );
}
