import { useConfig } from '../context/config';
import { FormSignIn } from './FormSignIn';
import { FormSecondaryNavigation } from './FormSecondaryNavigation';
import { useFormSubmit } from './Form';
import Button from './Button';

// Instead of submitting form, this action is to skip to next step
interface FormSkipProps {
  isSubmitting?: boolean;
  alternateButtonText?: string; // Used to override button text
  formNavigationClass?: string; // Used to add additional classes to the form actions
  handleSubmit: (event: any) => void;
}

export function FormSkip({
  isSubmitting,
  alternateButtonText,
  formNavigationClass,
  handleSubmit,
}: FormSkipProps) {
  const config = useConfig()!;
  const { isSubmitting: isFormSubmitting } = useFormSubmit();
  const className = config.isRetailing
    ? formNavigationClass
      ? formNavigationClass
      : 'block-container--fixed-nav'
    : '';

  return (
    <div className={className}>
      <FormSecondaryNavigation />
      <FormSignIn />
      <div className={'block-container--action'}>
        <Button
          type="button"
          text={alternateButtonText}
          isSubmitting={isSubmitting || isFormSubmitting}
          baseClass="btn mod-primary"
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}
