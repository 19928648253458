import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { DocumentTag } from './document-block';

export enum BlockStatus {
  Done = 'done',
  Incomplete = 'incomplete',
  Disabled = 'disabled', // Only a local prop
  Hidden = 'hidden', // Only a local prop
  Blocked = 'blocked', // Only a local prop
  Default = 'default',
}

// Used externally. Do not change existing values
export enum BlockRoute {
  Account = 'account', // support basic-info
  CreditApplication = 'credit-application',
  TradeIn = 'trade-in',
  Appointments = 'appointments',
  Documents = 'documents',
  PaymentOptions = 'payment-options',
  QuickForms = 'quick-forms',
  Offers = 'offers', // Deprecated
  Review = 'review',
  Vehicle = 'vehicle',
  Inventory = 'inventory',
  References = 'references',
  ThankYou = 'thank-you', // Widget Only
  Apply = 'apply', // Widget Only
  Chat = 'chat', // Widget Only
  Directions = 'directions', // Widget Only
}

export enum FullBlockRoute {
  Account = '/account', // support basic-info
  CreditApplication = `/credit-application/`,
  TradeIn = `/trade-in/`,
  Appointments = `/appointments/`,
  Documents = `/documents/`,
  PaymentOptions = `/payment-options/`,
  QuickForms = `/quick-forms/`,
  References = `/references/`,
  Offers = `/offers/`, // Deprecated
  Review = `/review/`,
  Vehicle = `/vehicles`,
  Apply = '/apply/', // Widget Only
  Chat = '/chat/', // Widget Only
}

export interface RouteItem {
  name: BlockRoute;
  title: string;
  subtitle?: string;
  subtitleDone?: string;
  widgetTitle?: string;
  route: FullBlockRoute;
  time: string;
  icon: IconDefinition;
  iconDone?: IconDefinition;
  websiteRoute?: string;
  websiteIcon?: IconDefinition;
  websiteTitle?: string;
  isDealDependent: boolean;
  tip?: string;
  shortTitle?: string;
  alternateTitle?: string;
  alternateIcon?: IconDefinition;
  parentBlock?: BlockRoute;
  index?: string;
  isIndexPage?: boolean;
  // isDone?: boolean;
}

export interface RouteItemWithStatus extends RouteItem {
  status: BlockStatus;
}

export interface DocumentItem {
  name: DocumentTag | string;
  title: string;
  titleSpecific?: string;
  route: string;
  parentBlock?: BlockRoute;
  isIndexPage?: boolean;
}

export interface DocumentItemWithStatus extends DocumentItem {
  status: BlockStatus;
  index?: string;
}
