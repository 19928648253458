import React, { useEffect, useState } from 'react';

function createArrayUpToX(X: number) {
  const array: number[] = [];
  for (let i = 0; i <= X; i++) {
    array.push(i);
  }
  return array;
}

function PriceCounterLottoItem({
  digit,
  height,
  className,
}: {
  digit: number;
  height: number;
  className: string;
}) {
  // make this an array beginning with 0 and going to the digit

  const column = createArrayUpToX(digit);
  const [isMoving, setIsMoving] = useState(false);

  useEffect(() => {
    setIsMoving(true);
  }, []);

  return (
    <div className={className} style={{ top: isMoving ? `-${height * (column.length - 1)}px` : 0 }}>
      {column.map((item, index) => (
        <span key={index}>{item}</span>
      ))}
    </div>
  );
}
export function PriceCounter({ price, className }: { price: number; className: string }) {
  const digitArray = Array.from(String(price), Number);
  const visiblePayment = price.toLocaleString('en-US');
  const visiblePaymentArray = Array.from(visiblePayment);

  return (
    <div className="price-counter">
      {digitArray.map((digit, index) => {
        const separator =
          visiblePaymentArray[index] !== String(digit) ? visiblePaymentArray[index] : '';
        const isSeparator = separator === '.' || separator === ',';
        return (
          <React.Fragment key={index}>
            {isSeparator && <span className="matrix-item--label--text--title">{separator}</span>}
            <PriceCounterLottoItem
              digit={digit}
              height={28}
              // setIsAnimating={setIsAnimating}
              className={className}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}
