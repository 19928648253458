import React, { useMemo } from 'react';
import { useConfig } from '../context/config';
import { SelectorAccordionSearch } from './SelectorAccordionSearch';
import { useAccordion } from '../context/AccordionProvider';
import { ComponentState } from 'src/types/component';

export function SelectorAccordion({ items, selectedItem, setSelectedItem, renderRow, itemKey }) {
  const config = useConfig()!;
  const { isMenuOpen: isOpen, toggleMenu: toggleOpen, height } = useAccordion();
  const isVehicleSearchEnabled = !config.isRetailing;

  const selectorAccordionHeight = useMemo(() => {
    return isVehicleSearchEnabled ? (isOpen ? height + 32 : height) : height;
  }, [height, isVehicleSearchEnabled, isOpen]);

  const selectedRow = useMemo(() => {
    const isButtonVisible = items.length > 0 || isVehicleSearchEnabled;
    return renderRow(
      selectedItem,
      () => {
        toggleOpen();
      },
      isOpen,
      isButtonVisible
    );
  }, [selectedItem, renderRow, toggleOpen, isOpen, items, isVehicleSearchEnabled]);

  return (
    <div
      className={`selector--accordion ${isOpen ? ComponentState.Active : ''}`}
      style={{ height: `${selectorAccordionHeight}px` }}
    >
      <div>
        <React.Fragment key={selectedRow.vin}>{selectedRow}</React.Fragment>
        {isOpen && (
          <>
            {items.map(item => (
              <React.Fragment key={itemKey(item)}>
                {renderRow(item, () => {
                  setSelectedItem(item);
                  toggleOpen();
                })}
              </React.Fragment>
            ))}
            <SelectorAccordionSearch />
          </>
        )}
      </div>
    </div>
  );
}
