import { Control } from 'react-hook-form';
import { Input } from '../controls/Input';
import { faMapMarkerAlt, faTachometer } from '@fortawesome/pro-regular-svg-icons';
import { useMobileContext } from '../context/MobileProvider';

export function FirstName({ control }: { control: Control<any> }) {
  return <Input control={control} name="firstName" label="First Name" placeholder="e.g. John" />;
}

export function MiddleName({ control }: { control: Control<any> }) {
  return (
    <Input control={control} name="middleName" label="Middle Name" placeholder="e.g. Michael" />
  );
}

export function LastName({ control }: { control: Control<any> }) {
  return <Input control={control} name="lastName" label="Last Name" placeholder="e.g. Doe" />;
}

export function Email({ control }: { control: Control<any> }) {
  return (
    <Input control={control} name="email" label="Email" placeholder="e.g. john.doe@example.com" />
  );
}

export function Phone({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="phone"
      type="phone"
      label="Phone"
      placeholder="e.g. 555-123-4567"
    />
  );
}

export function DateOfBirth({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="birthDate"
      label="Date of Birth"
      type="date"
      placeholder="mm/dd/yyyy"
    />
  );
}

export function SSN({ control, label }: { control: Control<any>; label: string }) {
  return <Input control={control} name="ssn" label={label} placeholder="e.g. 123-45-6789" />;
}

export function DLNumber({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="driversLicenseNumber"
      label="DL Number"
      placeholder="e.g. D1234567"
    />
  );
}

export function Notes({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="notes"
      label="Note"
      placeholder="Additional information (optional)"
    />
  );
}

export function AddressLine1({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="addressLine1"
      label="Address Line 1"
      placeholder="e.g. 123 Main St"
    />
  );
}

export function AddressLine2({ control }: { control: Control<any> }) {
  return (
    <Input control={control} name="addressLine2" label="Address Line 2" placeholder="e.g. Apt 4B" />
  );
}

export function City({ control }: { control: Control<any> }) {
  return <Input control={control} name="city" label="City" placeholder="e.g. Los Angeles" />;
}

export function Zip({
  control,
  badge,
  disabled,
  readOnly,
}: {
  control: Control<any>;
  badge?: string;
  disabled?: boolean;
  readOnly?: boolean;
}) {
  const isMobile = useMobileContext();

  return (
    <Input
      control={control}
      name="zip"
      label="Zip"
      type="zip"
      placeholder="e.g. 90001"
      icon={isMobile ? null : faMapMarkerAlt}
      badge={isMobile ? undefined : badge}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

export function PreviousAddressLine1({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="previousAddressLine1"
      label="Address Line 1"
      placeholder="e.g. 123 Main St"
    />
  );
}

export function PreviousAddressLine2({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="previousAddressLine2"
      label="Address Line 2"
      placeholder="e.g. Apt 4B"
    />
  );
}

export function PreviousCity({ control }: { control: Control<any> }) {
  return (
    <Input control={control} name="previousCity" label="City" placeholder="e.g. Los Angeles" />
  );
}

export function PreviousZip({ control }: { control: Control<any> }) {
  return (
    <Input control={control} name="previousZip" label="Zip" type="zip" placeholder="e.g. 90001" />
  );
}

export function PreviousHomeMonthlyPayment({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="previousHomeMonthlyPayment"
      label="Monthly Payment"
      placeholder="e.g. 1200"
      type="integer"
    />
  );
}

export function HomeMonthlyPayment({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="homeMonthlyPayment"
      label="Monthly Payment"
      placeholder="e.g. 1200"
      type="integer"
    />
  );
}

export function Employer({ control }: { control: Control<any> }) {
  return (
    <Input control={control} name="employer" label="Company Name" placeholder="e.g. ACME Corp" />
  );
}

export function EmployerPhone({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="phone"
      label="Employer Phone"
      type="phone"
      placeholder="e.g. 555-123-4567"
    />
  );
}

export function JobTitle({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="jobTitle"
      label="Job Title"
      placeholder="e.g. Software Engineer"
    />
  );
}

export function PreviousEmployer({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="previousEmployer"
      label="Employer"
      placeholder="e.g. ACME Corp"
    />
  );
}

export function PreviousJobTitle({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="previousJobTitle"
      label="Title"
      placeholder="e.g. Software Engineer"
    />
  );
}

export function PreviousMonthlyIncome({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="previousMonthlyIncome"
      label="Monthly Income"
      placeholder="e.g. 5000"
      type="integer"
    />
  );
}

export function MonthlyIncome({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="monthlyIncome"
      label="Monthly Income"
      placeholder="e.g. 5000"
      type="integer"
    />
  );
}

export function OtherMonthlyIncome({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="otherMonthlyIncome"
      label="Other Monthly Income"
      placeholder="e.g. 300"
      type="integer"
      validateOnSubmit={true}
    />
  );
}

export function OtherMonthlyIncomeSource({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="otherMonthlyIncomeSource"
      label="Other Monthly Income Source"
      placeholder="e.g. Freelance Work"
      validateOnSubmit={true}
    />
  );
}

export function Esignature({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="esignature"
      label="eSignature"
      placeholder="Type your name here"
    />
  );
}

export function Mileage({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="mileage"
      label="Mileage"
      placeholder="e.g. 45000"
      type="integer"
    />
  );
}

export function VIN({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="vin"
      type="text" //do we need?
      label="VIN"
      placeholder="e.g. 1HGBH41JXMN10918"
    />
  );
}

export function LoanLender({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="payoffLender"
      label="Lender"
      placeholder="e.g. Bank of America"
    />
  );
}

export function LoanAmount({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="payoffAmount"
      label="Estimated Remaining Balance"
      placeholder="e.g. 5000"
      type="number"
    />
  );
}

export function Relationship({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="relationship"
      label="Relationship"
      placeholder="e.g. Landlord"
      type="text"
    />
  );
}

export function CreditScore({
  control,
  badge,
  disabled,
  readOnly,
}: {
  control: Control<any>;
  badge?: string;
  disabled?: boolean;
  readOnly?: boolean;
}) {
  const isMobile = useMobileContext();

  return (
    <Input
      control={control}
      name="creditScore"
      label="Credit Score"
      placeholder="e.g. 650"
      type="creditScore"
      icon={isMobile ? null : faTachometer}
      badge={isMobile ? undefined : badge}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

export function MonthlyPaymentTarget({ control }: { control: Control<any> }) {
  return (
    <Input
      control={control}
      name="monthlyPaymentTarget"
      label="Monthly Payment"
      placeholder="$0"
      type="integer"
    />
  );
}
