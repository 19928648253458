/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Radio } from '../controls/Radio';
import { FormError } from '../controls/FormError';
import { ApplicationMode } from 'src/types/creditapplication-block';
import { ApplicationModeInputs } from 'src/constants/applicationMode';
import { useEffect } from 'react';
import { FormBody, useFormSubmit } from '../form/Form';

const validationSchema = Joi.object({
  applicationMode: Joi.string().allow(ApplicationMode).required().messages({
    'string.empty': `You must select an application option`,
  }),
});

interface PaymentApplicationModeGroupProps {
  setApplicationMode: (mode: ApplicationMode) => void;
}

export const PaymentApplicationModeGroup = ({
  setApplicationMode,
}: PaymentApplicationModeGroupProps) => {
  const { handleFormGroupSubmit, formValues: payment, activeForm } = useFormSubmit();
  const form = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      applicationMode: payment.applicationMode,
    },
  });

  const appMode = form.watch('applicationMode');

  // Watch application mode and set it in parent component for button text
  useEffect(() => {
    if (appMode) {
      setApplicationMode(appMode);
    }
  }, [appMode]);

  if (activeForm !== 'paymentApplicationModeForm') {
    return null;
  }

  return (
    <form id="paymentApplicationModeForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Select an application type"
        body="The co-applicant must be present and must indicate his or her acceptance of the Terms and Conditions at the end of this application before it is submitted."
      />
      {ApplicationModeInputs.map(item => (
        <Radio
          control={form.control}
          key={item.value}
          value={item.value}
          name="applicationMode"
          label={item.label}
          labelDesc={item.labelDesc}
        />
      ))}
      <FormError control={form.control} name="applicationMode" />
    </form>
  );
};
