import { State } from './address';

export interface Customer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  state: State;
  city: string;
  addressLine1: string;
  addressLine2: string;
  zip: string;
  optoutEmails: boolean;
  optoutSms: boolean;
  optoutCalls: boolean;
  dealerEmail: string | null;
  dealerFirstName: string | null;
  dealerLastName: string | null;
  dealerAvatar: string | null;
  dealerPhone: string | null;
  dealershipEmail: string | null;
  dealershipPhone: string | null;
  dealershipVirtualPhone: string | null;
  requireIncomeProof: boolean;
  requireReferenceCount: number;
  requireResidenceProof: boolean;
}

export interface CustomerDefaultValues {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  state: State | string;
  city: string;
  addressLine1: string;
  addressLine2: string;
  zip: string;
  optoutEmails: boolean;
  optoutSms: boolean;
  optoutCalls: boolean;
  dealerEmail: string | null;
  dealerFirstName: string | null;
  dealerLastName: string | null;
  dealerAvatar: string | null;
  dealerPhone: string | null;
  dealershipEmail: string | null;
  dealershipPhone: string | null;
  dealershipVirtualPhone: string | null;
  requireIncomeProof: boolean;
  requireReferenceCount: number;
  requireResidenceProof: boolean;
}

export function returnCustomerDefaultValues(data?: Customer): CustomerDefaultValues {
  return {
    id: data?.id ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    email: data?.email ?? '',
    phone: data?.phone?.replace(/(\+1)/g, '') || '',
    zip: data?.zip ?? '',
    state: data?.state ?? '',
    city: data?.city ?? '',
    addressLine1: data?.addressLine1 ?? '',
    addressLine2: data?.addressLine2 ?? '',
    optoutCalls: data?.optoutCalls ?? false,
    optoutSms: data?.optoutSms ?? false,
    optoutEmails: data?.optoutEmails ?? false,
    dealerEmail: data?.dealerEmail ?? null,
    dealerFirstName: data?.dealerFirstName ?? null,
    dealerLastName: data?.dealerLastName ?? null,
    dealerAvatar: data?.dealerAvatar ?? null,
    dealerPhone: data?.dealerPhone ?? null,
    dealershipEmail: data?.dealershipEmail ?? null,
    dealershipPhone: data?.dealershipPhone ?? null,
    dealershipVirtualPhone: data?.dealershipVirtualPhone ?? null,
    requireIncomeProof: data?.requireIncomeProof ?? false,
    requireReferenceCount: data?.requireReferenceCount ?? 0,
    requireResidenceProof: data?.requireResidenceProof ?? false,
  };
}
