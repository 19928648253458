import { useAppointments } from '../../fetches/useSWRFetch';
import ModularVehicleCard from '../../components/cards/vehicle/ModularVehicleCard';
import { useDeals } from '../../components/context/DealsProvider';
import { SplideSlide } from '@splidejs/react-splide';
import { CRMVehicle } from 'src/types/vehicle';
import { useVehicleSelectionData } from '../../helpers/useVehicleSelectionData';

export function VehiclesWithoutDeals() {
  const { createDeal } = useDeals();
  const { appointmentsIsLoading } = useAppointments();
  const { vehicleSelectionDataAppointments } = useVehicleSelectionData();

  if (appointmentsIsLoading || vehicleSelectionDataAppointments?.length === 0) {
    return null;
  }

  return (
    <>
      {vehicleSelectionDataAppointments?.map((slide: CRMVehicle) => (
        <SplideSlide key={slide.vin}>
          <ModularVehicleCard
            key={slide.vin}
            item={slide}
            handleSelect={(item: CRMVehicle) => createDeal({ vin: item.vin })}
          />
        </SplideSlide>
      ))}
    </>
  );
}
