import { useState } from 'react';

export function VehicleCardImage({
  imageSrc,
  className,
}: {
  imageSrc: string | undefined;
  className?: string;
}) {
  const [imgSrc, setImgSrc] = useState(imageSrc);
  const carSvg = '/car.svg';

  const handleImageError = () => {
    setImgSrc(carSvg);
  };

  return imageSrc && imageSrc === imgSrc ? (
    <img className={className} src={imageSrc} alt="Vehicle" onError={handleImageError} />
  ) : (
    <div className={`${className}--placeholder`}>
      <img className={`${className}--placeholder--img`} src={carSvg} alt="" />
    </div>
  );
}
