import { TradeinDeclarationGroup } from '../../../components/steps/TradeinDeclarationGroup';
import { FormActions } from '../../../components/form/FormActions';
import { FormContentWrapper, FormWrapper, useSpaceForm } from 'src/components/form/Form';
import { tradeinDeclaration } from 'src/constants/schemas';
import { FormJourneyTab } from 'src/components/navigation/JourneyTab';
import { useContext } from 'react';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { DeclarationType } from 'src/constants/tradeinDeclaration';

interface TradeDeclarationFormProps {
  declaration: string;
  handleFormSubmit: (requireTradeins: boolean) => Promise<void>;
  handleFormCancel: any;
}

export const TradeDeclarationForm = ({
  declaration,
  handleFormCancel,
  handleFormSubmit,
}: TradeDeclarationFormProps) => {
  const activeForm = 'tradeinDeclarationForm';
  const { nextBlockTitle } = useContext(NavigationContext);

  const form = useSpaceForm(
    { tradeinDeclaration },
    {
      tradeinDeclaration: declaration || '',
    }
  );

  const formTradeDeclaration = form.watch('tradeinDeclaration');

  return (
    <FormWrapper
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={handleFormCancel}
      steps={[activeForm]}
    >
      <FormJourneyTab />
      <FormContentWrapper form={form} id={activeForm}>
        <TradeinDeclarationGroup form={form} />
      </FormContentWrapper>

      <FormActions
        alternateButtonText={
          formTradeDeclaration === DeclarationType.NoTrades ? nextBlockTitle : 'Next: Trade In'
        }
        showSkipButton={true}
      />
    </FormWrapper>
  );
};
