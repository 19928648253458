import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAppointment, useAppointments } from '../../../fetches/useSWRFetch';
import PageLoader from '../../../components/PageLoader';
import PageError from '../../../components/PageError';
import 'react-day-picker/dist/style.css';
import { useDocTitle } from '../../../helpers/global-document-title';
import { AppointmentBlockSubmit, returnAppointmentBlock } from 'src/types/appointment';
import { useCallback, useContext } from 'react';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { BlockRoute } from 'src/types/blocks';
import { useDeals } from 'src/components/context/DealsProvider';
import { useAuth } from 'src/components/context/AuthProvider';
import { UnauthorizedAppointmentForm } from 'src/lead-forms/AppointmentForm';
import { AppointmentBuilder } from './AppointmentBuilder';
import AppointmentTypeDropdownArray from 'src/components/dropdowns/AppointmentTypeDropdownList';
import { WidgetForm } from 'src/widgets/SpaceWidget';
import { ScheduleProvider, useSchedule } from 'src/components/context/ScheduleProvider';
import { VehicleStatus } from 'src/types/vehicle';

export default function NewAppointment() {
  useDocTitle('Appointments');
  const { token } = useAuth();
  const { deal, deals, dealIsLoading, createDeal } = useDeals();
  const { dealId, blockId } = useParams();
  const navigate = useNavigate();
  const { navigatePrevBlock, navigateNextBlock } = useContext(NavigationContext);
  const { dealerTimezone } = useSchedule();
  // Get ISO date from server
  const { appointment, appointmentIsLoading, appointmentIsError, appointmentSubmit } =
    useAppointment(blockId);
  const { appointments, appointmentsMutate } = useAppointments();

  const handleAuthenticatedSubmit = async (data: any) => {
    const formattedData: AppointmentBlockSubmit = returnAppointmentBlock(data, dealerTimezone);
    try {
      const appointment = await appointmentSubmit(formattedData);
      await appointmentsMutate();

      if (
        appointment?.vin &&
        appointment?.vehicle?.status === VehicleStatus.Available &&
        !deals.find(deal => deal.vin === appointment?.vin)
      ) {
        try {
          await createDeal({ vin: formattedData.vin });
        } catch (error: any) {
          toast.error(error.message);
        }
      } else {
        navigateNextBlock();
      }
      toast.success('Appointment updated!');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleFormCancel = useCallback(() => {
    if (appointments.length === 0) {
      navigatePrevBlock(BlockRoute.Appointments);
    } else {
      navigate(`/deals/${dealId}/appointments`);
    }
  }, [appointments, dealId, navigate, navigatePrevBlock]);

  if (appointmentIsLoading || dealIsLoading) return <PageLoader />;
  if (appointmentIsError) return <PageError />;

  return (
    <ScheduleProvider location={appointment?.location}>
      {token ? (
        <AppointmentBuilder
          configuration={{
            name: null,
            appointment,
            requirePhone: true,
            requireSms: false,
            customer: undefined,
            additionalFields: undefined,
            handleFormSubmit: handleAuthenticatedSubmit,
            handleFormCancel,
            title: appointment?.id ? 'Edit Appointment' : 'Visit the Showroom',
            body: null,
            appointmentTypeOptions: AppointmentTypeDropdownArray,
            vehicle: appointment?.vehicle,
            submitText: appointment?.id ? 'Update Appointment' : 'Schedule Appointment',
          }}
        />
      ) : (
        <UnauthorizedAppointmentForm
          form={WidgetForm.TestDrive}
          vehicle={deal}
          additionalFields={[]}
          title="Visit the Showroom"
          body="Please fill out the form below to schedule a visit."
          assumeCustomer={true}
        />
      )}
    </ScheduleProvider>
  );
}
