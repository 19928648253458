/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Radio } from '../controls/Radio';
import { FormError } from '../controls/FormError';
import { FormBody, useFormSubmit } from '../form/Form';
import { AddressType } from 'src/types/address';
import { useEffect, useState } from 'react';
import PageLoader from '../PageLoader';
import Button from '../form/Button';
import { useApiFetch } from 'src/fetches/useApiFetch';

interface AddressGroupProps {
  index: number;
  type: AddressType;
}

const validationSchema = Joi.object({
  addressVerification: Joi.string().required().messages({
    'string.empty': `Verification is required`,
  }),
});

export enum AddressVerificationState {
  Unverified = 'unverified',
  Verified = 'verified',
}

export const AddressVerificationGroup = ({ index, type }: AddressGroupProps) => {
  const { activeForm } = useFormSubmit();

  if (activeForm !== `addressVerification${type}${index}`) {
    return null;
  }

  return <AddressVerification index={index} type={type} />;
};

export const AddressVerification = ({ index, type }: AddressGroupProps) => {
  const {
    activeForm,
    formValues: payment,
    handleFormGroupSubmit,
    handleFormGroupBack,
  } = useFormSubmit();
  const apiFetch = useApiFetch();
  const addresses = type === AddressType.Employer ? payment.employment : payment.addresses;
  const [isLoading, setIsLoading] = useState(true);
  const [unverifiedAddress] = useState<any>(addresses[index]);
  const [verifiedAddress, setVerifiedAddress] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>();

  const form = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      addressVerification: AddressVerificationState.Verified,
    },
  });

  const handleUSPSVerification = async () => {
    try {
      const path = `address/validate`;
      const data = addresses[index];
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          Address1: data.addressLine1, // For testing purpose, comment out to view Error message
          Address2: data.addressLine2,
          City: data.city,
          State: data.state,
          Zip5: data.zip,
        }),
      };

      const validatedAddress = await apiFetch(path, requestOptions);

      const updatedFormData = {
        addressLine1: validatedAddress.Address1,
        addressLine2: validatedAddress.Address2,
        city: validatedAddress.City,
        state: validatedAddress.State,
        zip: validatedAddress.Zip5,
        phone: data.phone, // Need to get phone from previous step entry
      };

      // copy data and add updatedFormData to it
      const saveData = { ...data, ...updatedFormData };
      setVerifiedAddress(saveData);
    } catch (error: any) {
      setErrorMessage(error.message);
      form.setValue('addressVerification', AddressVerificationState.Unverified);
    } finally {
      setIsLoading(false);
    }
  };

  const labelDesc1 = (
    <>
      <div>{unverifiedAddress.addressLine1}</div>
      <div>{unverifiedAddress.addressLine2}</div>
      <div>
        {unverifiedAddress.city}, {unverifiedAddress.state} {unverifiedAddress.zip}
      </div>
    </>
  );

  const labelDesc2 = verifiedAddress ? (
    <>
      <div>{verifiedAddress.addressLine1}</div>
      <div>{verifiedAddress.addressLine2}</div>
      <div>
        {verifiedAddress.city}, {verifiedAddress.state} {verifiedAddress.zip}
      </div>
    </>
  ) : null;

  const onSubmit = async (data: any) => {
    onAddressConfirm(data);
  };

  const onAddressConfirm = async (updatedFormData: any) => {
    // Copy Addresses
    const saveData = { ...addresses };
    // If state is verfied, use the verified address, otherwise use the unverified address
    saveData[index] =
      updatedFormData.addressVerification === 'verified' ? verifiedAddress : unverifiedAddress;
    const isEmployer = type === AddressType.Employer;
    const data = isEmployer ? { employment: saveData } : { addresses: saveData };

    handleFormGroupSubmit(data);
  };

  useEffect(() => {
    if (!isLoading) return;
    handleUSPSVerification();
  }, []);

  if (activeForm !== `addressVerification${type}${index}`) {
    return null;
  }

  return isLoading ? (
    <PageLoader />
  ) : (
    <form id={`addressVerification${type}${index}`} onSubmit={form.handleSubmit(onSubmit)}>
      <FormBody
        title={errorMessage ? "We couldn't find your address" : 'Did we find your address?'}
        body="USPS address verification"
      />
      {errorMessage ? (
        <>
          <div className="radio--label u-no-pointer-events">
            <div className="radio-label--text">
              <div className="radio-label--text--title">Does this look correct?</div>
              <div className="radio-label--text--desc">{labelDesc1}</div>
            </div>
          </div>
          <Button
            type="button"
            baseClass="btn mod-secondary"
            text="Re-enter address"
            progress={true}
            handleSubmit={handleFormGroupBack}
          />
        </>
      ) : (
        <>
          <Radio
            control={form.control}
            value={AddressVerificationState.Unverified}
            name="addressVerification"
            label={'Original'}
            labelDesc={labelDesc1}
            isComplex={true}
          />
          <Radio
            control={form.control}
            value={AddressVerificationState.Verified}
            name="addressVerification"
            label="USPS Verified"
            labelDesc={labelDesc2}
            isComplex={true}
            isRecommended={true}
          />
        </>
      )}
      <FormError control={form.control} name="addressVerification" />
    </form>
  );
};
