// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { location, returnCustomerSchema } from '../../constants/schemas';
import { DealershipLocations } from 'src/helpers/useDealershipLocations';
import { FormBody, useFormSubmit } from '../form/Form';
import { Customer } from './CustomerGroup';
import { MultilocationRadioGroup } from './MultilocationRadioGroup';
import { useConfig } from '../context/config';
import { useEffect } from 'react';

interface InfoCommunicationLocationGroupProps {
  requireEmail: boolean;
  requirePhone: boolean;
  locations: DealershipLocations;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const InfoCommunicationLocationGroup = ({
  locations,
  requireEmail,
  requirePhone,
  setIsSubmitting,
}: InfoCommunicationLocationGroupProps) => {
  const config = useConfig()!;
  const { handleFormGroupSubmit, formValues, activeForm, isSubmitting } = useFormSubmit();
  const schema = returnCustomerSchema(
    requirePhone,
    requireEmail,
    config.forms?.lead?.requireSms ?? false
  );

  const validationSchema =
    config.location === null ? Joi.object(schema) : Joi.object({ ...schema, location });

  const form = useForm({
    resolver: joiResolver(validationSchema, {
      allowUnknown: true,
    }),
    mode: 'onBlur',
    defaultValues: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
      optoutCalls: formValues.optoutCalls,
      optoutSms: formValues.optoutSms,
      optoutEmails: formValues.optoutEmails,
      location: typeof config.location === 'string' ? config.location : formValues.location,
    },
  });

  const optoutSms = form.watch('optoutSms');

  useEffect(() => {
    if (isSubmitting || !config?.forms?.lead?.requireSms || config.isRetailing) {
      return;
    }
    if (activeForm === 'infoAndCommunicationForm' && !form.formState.isValid) {
      setIsSubmitting(true);
    } else if (!isSubmitting) {
      setIsSubmitting(false);
    }
  }, [config, activeForm, setIsSubmitting, optoutSms, isSubmitting, form.formState]);

  if (activeForm !== 'infoCommunicationLocationForm') {
    return null;
  }

  return (
    <form id="infoCommunicationLocationForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Provide your basic information"
        body="The personal information you provide will be securely kept and will only be used to process your loan application."
      />
      <Customer form={form} />
      <MultilocationRadioGroup form={form} locations={locations.dropdownList} />
    </form>
  );
};
