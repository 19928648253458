import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VinHelpGraphic } from '../../../svg/vin-help-graphic';
import {
  PopoverContent,
  PopoverHeading,
  PopoverArrow,
  PopoverDescription,
  PopoverClose,
} from './Popover';
import { useRef } from 'react';
import { useConfig } from '../../context/config';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

export function VinTip() {
  const config = useConfig()!;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef}>
      <PopoverContent
        className="popover-media"
        containerRef={config.isRetailing ? null : containerRef}
      >
        <PopoverArrow className="popover-media--arrow" />
        <PopoverHeading className="popover-media--heading">Where’s my VIN?</PopoverHeading>
        <PopoverClose className="popover-media--close icon-btn">
          <FontAwesomeIcon icon={faTimes} />
        </PopoverClose>
        <PopoverDescription className="popover-media--description">
          <div className="popover-media--media">
            <a href="/" target="_blank" className="u-no-pointer-events">
              <VinHelpGraphic />
            </a>
          </div>
          <p className="popover-media--text">
            Your VIN Number is a 17 digit code that can be found on the your exterior windshield
            near the dashboard on the driver’s side. It can also be found on your driver’s side door
            jamb near the latch.
          </p>
        </PopoverDescription>
      </PopoverContent>
    </div>
  );
}
