import { useEffect, useMemo, useState } from 'react';
import { returnVehiclePhoto, returnVehicleYearMakeModel } from 'src/types/vehicle';
import { faAngleDown, faAngleRight, faCalendarStar } from '@fortawesome/pro-regular-svg-icons';
import { SelectorAccordion } from './SelectorAccordion';
import { useVehicleSelectionData } from '../../helpers/useVehicleSelectionData';
import { VehicleCardImage } from '../cards/vehicle/VehicleCardImage';
import { Colors } from 'src/types/colors';
import { Selector } from './Selector';
import { AccordionProvider } from '../context/AccordionProvider';

export function VehicleSelectorAccordion({ form, initialVehicle }) {
  const [selectedVehicle, setSelectedVehicle] = useState(initialVehicle);
  const { vehicleSelectionDataMinusActiveVehicle, vehicleSelectionDataIsLoading } =
    useVehicleSelectionData(selectedVehicle);

  useEffect(() => {
    if (selectedVehicle) {
      form.setValue('vin', selectedVehicle.vin);
      // If the vehicle has a vin, set the location
      if (selectedVehicle.dealershipName) {
        form.setValue('location', selectedVehicle.dealershipName);
      }
      // Location exists under different keys
      if (selectedVehicle.location) {
        form.setValue('location', selectedVehicle.location);
      }
    }
  }, [selectedVehicle, form]);

  const selectableDeals = useMemo(() => {
    // If no deals or user is creating new deal
    if (vehicleSelectionDataIsLoading) return [];

    if (selectedVehicle?.vin) {
      // Add a no vehicle option
      vehicleSelectionDataMinusActiveVehicle.unshift({
        vin: null,
      });
    }
    return vehicleSelectionDataMinusActiveVehicle;
  }, [vehicleSelectionDataIsLoading, selectedVehicle, vehicleSelectionDataMinusActiveVehicle]);

  const renderVehicleRow = (vehicle, onClick, isOpen, isButtonVisible) => {
    const btnIcon = isButtonVisible ? (isOpen ? faAngleDown : faAngleRight) : undefined;
    // If no vehicle, show general appointment
    if (!vehicle?.vin) {
      return (
        <Selector
          listType="row"
          type="button"
          thumbnail={{
            icon: faCalendarStar,
            iconBackground: Colors.Gray7,
            isWide: true,
          }}
          title="No Vehicle"
          subtitle="General Appointment"
          handleClick={onClick}
          btnIcon={btnIcon}
        />
      );
    } else {
      return (
        <Selector
          listType="row"
          type="button"
          thumbnail={{
            element: (
              <VehicleCardImage imageSrc={returnVehiclePhoto(vehicle)} className="selector--img" />
            ),
            isWide: true,
          }}
          title={returnVehicleYearMakeModel(vehicle)}
          subtitle={vehicle.trim}
          handleClick={onClick}
          btnIcon={btnIcon}
        />
      );
    }
  };

  const vehicleKeyExtractor = vehicle => vehicle.id;

  return (
    <AccordionProvider
      itemHeight={64}
      nestedItemHeight={64}
      nestedItemCount={selectableDeals.length}
    >
      <SelectorAccordion
        items={selectableDeals}
        selectedItem={selectedVehicle}
        setSelectedItem={setSelectedVehicle}
        renderRow={renderVehicleRow}
        itemKey={vehicleKeyExtractor}
      />
    </AccordionProvider>
  );
}
