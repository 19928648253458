import { FormBody } from 'src/components/form/Form';
import { FormBasicPage } from 'src/components/form/FormShell';
import { WebsiteVehicleBrowser } from './WebsiteVehicleBrowser';
import { useContext } from 'react';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { useParams } from 'react-router-dom';

export function VehicleSelectorPage() {
  const { block } = useParams();
  const { nextBlockTitle } = useContext(NavigationContext);

  if (!block) {
    return null;
  }
  return (
    <FormBasicPage
      configuration={{
        title: (
          <FormBody
            title="Pick Your Vehicle"
            body="Instant estimates and personalized payment options."
          />
        ),
        hideBackButton: true,
        alternateButtonText: nextBlockTitle,
      }}
    >
      <div className="selector--browse-accordion">
        <WebsiteVehicleBrowser />
      </div>
    </FormBasicPage>
  );
}
