import { Control, useController } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

interface NegativeToggleErrorProps {
  name: string;
  control: Control;
}

export const NegativeToggleError = ({ name, control }: NegativeToggleErrorProps) => {
  const {
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  return (
    <div>
      {isSubmitted || isTouched ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <div className="form--error">{message}</div>}
        />
      ) : null}
    </div>
  );
};
