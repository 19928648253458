import { PaymentType } from './deal';

export enum TermType {
  Monthly = 'monthly',
  BiWeekly = 'biWeekly',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export type PaymentOption = {
  term: number;
  payment: number;
  rate: number;
  isMoneyFactor: boolean;
  lender: string;
};

export type DeskingPayload = {
  paymentMethod: PaymentType;
  creditScore: number;
  zip: string;
  downPayment: number;
  currentCell: 0 | 1 | 2 | 3;
};

export type DeskingReponse = {
  isLocked: boolean;
  paymentRange: number;
  results: PaymentOption[];
  request: DeskingPayload | null;
  termType: TermType;
  updatedAt: string;
};
