import './VehicleCardLoading.css';

export function VehicleCardLoading() {
  return (
    <div className="modular-vehicle-card-loading">
      <div className="modular-vehicle-card-loading--top">
        <div className="modular-vehicle-card--top--labels">
          <div className="badge">Loading...</div>
        </div>
      </div>
      <div className="modular-vehicle-card-loading--bottom">
        <div className="modular-vehicle-card-loading--desc">
          <div className="modular-vehicle-card-loading--desc--top">
            <div className="modular-vehicle-card-loading--desc--title"></div>
            <div className="modular-vehicle-card-loading--desc--action"></div>
          </div>
          <div className="modular-vehicle-card-loading--desc--subtitle"></div>
        </div>
        <div className="modular-vehicle-card-loading--features u-mar-bottom-16"></div>
        <div className="u-display-grid grid--50 grid--gap-8 u-opacity-5">
          <div className="btn mod-tertiary">Loading...</div>
          <div className="btn mod-secondary">Loading...</div>
        </div>
      </div>
    </div>
  );
}
