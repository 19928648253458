import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTradein, useTradeins } from '../../../fetches/useSWRFetch';
import { useApiFetch } from '../../../fetches/useApiFetch';
import PageError from '../../../components/PageError';
import PageLoader from '../../../components/PageLoader';
import { TradeinForm } from './TradeinForm';
import { useDocTitle } from '../../../helpers/global-document-title';
import { FullBlockRoute } from '../../../types/blocks';
import { UnauthorizedTradeinBlockSubmit } from 'src/widgets/SpaceWidget';
import TradeinSuccessPage from './TradeinSuccessPage';
import { useAuth } from 'src/components/context/AuthProvider';
import { TradeinLeadForm } from 'src/lead-forms/TradeinLeadForm';
import { useConfig } from 'src/components/context/config';

export default function NewTradeIn({ hideBackButton = false }: { hideBackButton?: boolean }) {
  const { token } = useAuth();
  const config = useConfig()!;
  const apiFetch = useApiFetch();
  useDocTitle('Trade In');
  const { blockId } = useParams();
  const navigate = useNavigate();
  const { tradeinsMutate } = useTradeins();
  const { tradein, tradeinIsLoading, tradeinIsError, tradeinMutate } = useTradein(blockId || 'new');
  const backUrl = FullBlockRoute.TradeIn;
  const [isSucessState, setIsSucessState] = useState(false);

  const handleFormCancel = useCallback(() => {
    navigate(backUrl);
  }, [navigate, backUrl]);

  if (tradeinIsLoading) return <PageLoader />;
  if (tradeinIsError) return <PageError primaryText="That deal might not exist anymore" />;

  const handleAuthenticatedSubmit = async ({ blocks }: UnauthorizedTradeinBlockSubmit) => {
    const path =
      blockId && blockId !== 'new' ? `customers/tradeins/${blockId}` : `customers/tradeins`;
    const requestOptions = {
      method: blockId && blockId !== 'new' ? 'PUT' : 'POST',
      body: JSON.stringify(blocks[0]),
    };

    try {
      await apiFetch(path, requestOptions);
      await Promise.all([tradeinMutate(), tradeinsMutate()]);

      setIsSucessState(true);
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  if (isSucessState) {
    return <TradeinSuccessPage />;
  }

  return token && config.isRetailing ? (
    <TradeinForm
      tradein={tradein}
      handleFormCancel={handleFormCancel}
      handleFormSubmit={handleAuthenticatedSubmit}
      hideBackButton={hideBackButton}
    />
  ) : (
    <TradeinLeadForm vin={tradein.vin} hideBackButton={hideBackButton} />
  );
}
