import { useMemo } from 'react';
import { IconDefinition, faAngleRight, faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './Task.css';
import { Label } from '../text/Label';
import { BlockStatus } from 'src/types/blocks';
import { Colors } from 'src/types/colors';

interface RouterTaskProps {
  status: BlockStatus;
  label?: string;
  route: string;
  title: string;
}

export function RouterTask({ status, label, title, route }: RouterTaskProps) {
  const renderClass: string = useMemo(() => {
    const classes = ['task'];

    if (status === BlockStatus.Done) {
      classes.push('is-done');
    }

    if (status === BlockStatus.Incomplete) {
      classes.push('is-alert');
    }

    return classes.join(' ');
  }, [status]);

  const renderIcon: IconDefinition = useMemo(() => {
    if (status === BlockStatus.Done) {
      return faCheckCircle;
    }

    if (status === BlockStatus.Incomplete) {
      return faExclamationCircle;
    }

    return faCircle;
  }, [status]);

  return (
    <Link className={renderClass} to={route}>
      <div>
        <FontAwesomeIcon icon={renderIcon} className={`task--icon`} width="32px" />
        <span className="task--text">{title}</span>
      </div>
      <div className="task--right">
        {label && status !== BlockStatus.Done && (
          <Label color={Colors.Blue2} text={label} className="task--label" />
        )}
        <FontAwesomeIcon icon={faAngleRight} className="task--arrow" fixedWidth />
      </div>
    </Link>
  );
}
