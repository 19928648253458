import { UnauthorizedTradeinBlockSubmit } from 'src/widgets/SpaceWidget';
import { TradeInPayoffType, TradeinFormDefaultValues } from '../../../types/tradein-block';
import { TradeinFormGroup } from './TradeinFormGroup';
import { FormJourneyTab } from 'src/components/navigation/JourneyTab';

interface TradeinFormProps {
  tradein: TradeinFormDefaultValues;
  hideBackButton?: boolean;
  handleFormSubmit: (data: UnauthorizedTradeinBlockSubmit) => Promise<void>;
  handleFormCancel: () => void;
}

export const TradeinForm = ({
  tradein,
  hideBackButton,
  handleFormSubmit,
  handleFormCancel,
}: TradeinFormProps) => {
  return (
    <TradeinFormGroup
      hideBackButton={hideBackButton}
      tradein={tradein}
      navigation={<FormJourneyTab />}
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={handleFormCancel}
      conditionalFields={{
        tradeinPayoffLenderForm: (data: any) => {
          return data.payoffType === TradeInPayoffType.Neither;
        },
        infoCommunicationForm: () => true,
        infoCommunicationLocationForm: () => true,
      }}
    />
  );
};
