/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Select } from '../controls/Select';
import StateDropdownArray from '../dropdowns/StateDropdownArray';
import { AddressLine2, City, EmployerPhone, Zip } from '../inputs/Input';
import { Input } from '../controls/Input';
import { useEffect, useRef, useMemo } from 'react';
import useGooglePlaceAutoComplete from '../../services/GooglePlacesAutocomplete';
import { employerAddressSchema, addressSchema } from '../../constants/schemas';
import { AddressType } from 'src/types/address';
import { FormBody, useFormSubmit } from '../form/Form';
import { isZipInState } from 'src/constants/validation';

interface AddressGroupProps {
  index: number;
  type: AddressType;
}

export const AddressGroup = ({ index, type }: AddressGroupProps) => {
  const { activeForm } = useFormSubmit();

  if (activeForm !== `${type}${index}`) {
    return null;
  }

  return <AddressGroupFields index={index} type={type} />;
};

export const AddressGroupFields = ({ index, type }: AddressGroupProps) => {
  const { handleFormGroupSubmit, formValues: payment } = useFormSubmit();
  const addresses = type === AddressType.Employer ? payment.employment : payment.addresses;
  const address1Ref = useRef<HTMLInputElement>(null);
  const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
  let autoComplete = '';

  const form = useForm({
    resolver: joiResolver(
      Joi.object(
        type === AddressType.Customer || type === AddressType.Reference
          ? addressSchema
          : employerAddressSchema
      )
    ),
    mode: 'onBlur',
    defaultValues: {
      al1: addresses[index]?.addressLine1,
      addressLine2: addresses[index]?.addressLine2,
      city: addresses[index]?.city,
      state: addresses[index]?.state,
      zip: addresses[index]?.zip,
      phone: addresses[index]?.phone,
    },
  });

  const onSubmit = async data => {
    if (!isZipInState(data.zip, data.state)) {
      form.setError('zip', {
        type: 'manual',
        message: 'Zip code does not match selected state',
      });
      return;
    }
    const formData = {
      addressLine1: data.al1.trim(),
      addressLine2: data.addressLine2,
      city: data.city.trim(),
      state: data.state,
      zip: data.zip,
      phone: data.phone,
    };

    // Save unverified
    const saveData = { ...addresses };
    // copy saveData[index] and add saveData to it
    saveData[index] = { ...saveData[index], ...formData };
    const isEmployer = type === AddressType.Employer;
    const slotData = isEmployer ? { employment: saveData } : { addresses: saveData };

    handleFormGroupSubmit(slotData);
  };

  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);

    form.setValue('al1', `${addressObj.address1}`);
    form.setValue('addressLine2', `${addressObj.address2 || ''}`);
    form.setValue('city', `${addressObj.city || addressObj.state}`);
    form.setValue('state', addressObj.state);
    form.setValue('zip', addressObj.postalCode);
  };

  const FormBodyText = useMemo(() => {
    let title = '';

    if (type === AddressType.Customer) {
      if (index === 0) {
        title = 'What is your current address?';
      } else {
        title = 'What was your previous address?';
      }
    } else if (type === AddressType.Reference) {
      if (index === 0) {
        title = "What is your reference's address?";
      } else {
        title = "What was your reference's previous address?";
      }
    } else if (type === AddressType.Employer) {
      if (index === 0) {
        title = "What is your employer's address?";
      } else {
        title = "What was your previous employer's address?";
      }
    }
    return title;
  }, [type, index]);

  useEffect(() => {
    async function loadGoogleMaps() {
      // initialize the Google Place Autocomplete widget and bind it to an input element.
      // eslint-disable-next-line
      autoComplete = await googleAutoCompleteSvc.initAutoComplete(
        address1Ref.current,
        handleAddressSelect
      );
    }
    loadGoogleMaps();
  }, [address1Ref]);

  const label = (
    <>
      <span>A d d r e s s</span>
      <span className="u-mar-left-4"></span>
      <span>L i n e</span>
      <span className="u-mar-left-4"></span>
      <span>1</span>
    </>
  );

  return (
    <form id={`${type}${index}`} onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
      {type === AddressType.Employer ? (
        <>
          <div className="block-form--section">
            <FormBody
              title={
                index === 0
                  ? "What is your employer's phone number?"
                  : "What was your previous employer's phone number?"
              }
              body={addresses.employer}
            />
            <EmployerPhone control={form.control} />
          </div>
        </>
      ) : null}
      <div className="block-form--section">
        <h3 className="block-form--heading">{FormBodyText}</h3>

        <Input
          control={form.control}
          name="al1"
          inputRef={address1Ref}
          label={label}
          labelClassName="is-autocomplete-off"
          placeholder="e.g. 123 Main St"
          autoComplete="off"
        />
        <AddressLine2 control={form.control} />
        <div className="u-display-grid grid--50">
          <City control={form.control} />
          <Select
            control={form.control}
            name="state"
            label="State"
            selectOptions={StateDropdownArray}
          />
        </div>
        <div className="u-display-grid grid--50">
          <Zip control={form.control} />
        </div>
      </div>
    </form>
  );
};
