export enum Colors {
  Black = '#000000',
  Gray0 = '#333333',
  Gray1 = '#48484a',
  Gray2 = '#8e8e93',
  Gray3 = '#a4a4a8',
  Gray4 = '#bcbcc0',
  Gray5 = '#dfdfe0',
  Gray6 = '#f0f0f5',
  Gray7 = '#fbfbfe',
  White = '#ffffff',

  Blue1 = '#007aff',
  Blue2 = '#cce4ff',
  Blue3 = '#e5f2ff',
  Blue4 = '#004093',

  Green1 = '#34c759',
  Green2 = '#d6f4de',

  Red1 = '#ff3b30',
  Red2 = '#ffd8d6',

  Orange1 = '#ff9500',
  Orange2 = '#ffeacc',

  Yellow1 = `#ffcc00`,
  Yellow2 = `#fff5cc`,
}
