/* eslint-disable react-hooks/exhaustive-deps */
import Joi from 'joi';
import DealsProvider, { useDeals } from '../context/DealsProvider';
import { FormContentWrapper, FormWrapper, useSpaceForm } from '../form/Form';
import { useChatContext } from './ChatActionProvider';
import { useVehicleVinSearch } from 'src/fetches/useSWRFetch';

import { useProgramSummary } from 'src/fetches/useSWRFetch';
import { Callout } from '../form/Callout';
import { useFormSubmit } from '../form/Form';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { MatrixItem, MatrixLoadingItem } from '../desking/MatrixItem';
import { MatrixContactItem } from '../desking/MatrixContactItem';
import { TermType } from 'src/types/desking';

interface MatrixProps {
  form: UseFormReturn<any>;
}

export function Matrix({ form }: MatrixProps) {
  const { formValues, isSubmitting } = useFormSubmit();
  const { watch } = useFormContext();
  const { handleSubmit } = useChatContext();
  const { activeDealId, deal, dealIsLoading } = useDeals();
  const { programSummaryIsLoading } = useProgramSummary(activeDealId);

  const deskValue = watch('currentCell');

  const desk = useMemo(() => {
    return {
      results: [
        {
          term: 36,
          lender: 'ALLY',
          payment: 206.99,
          rate: 7.44,
          isMoneyFactor: false,
        },
        {
          term: 48,
          lender: 'ALLY',
          payment: 160.85,
          rate: 7.44,
          isMoneyFactor: false,
        },
        {
          term: 60,
          lender: 'ALLY',
          payment: 133.27,
          rate: 7.44,
          isMoneyFactor: false,
        },
        {
          term: 72,
          lender: 'VEL',
          payment: 116.26,
          rate: 7.84,
          isMoneyFactor: false,
        },
      ],
      request: {
        sellingPrice: 36480,
        currentCell: '',
        downPayment: 3700,
        paymentMethod: 'financing',
        creditScore: 720,
        zip: '78757',
      },
      updatedAt: '2024-08-07T21:56:49.466Z',
      paymentRange: 0,
      termType: 'monthly' as TermType,
      isLocked: false,
    };
  }, []);

  const deskIsLoading = false;

  const renderClass = useMemo(() => {
    const classes = ['matrix'];

    if (deal?.isDeskingLocked) classes.push('is-locked');

    return classes.join(' ');
  }, [deal]);

  useEffect(() => {
    if (deskValue !== '') {
      let result = desk.results[deskValue];
      handleSubmit(`My preferred payment option is ${result.term} months at $${result.payment}`);
    }
  }, [deskValue, desk]);

  if (desk?.results.length === 0)
    return (
      // In this case, user most likely does not meet qualifications for any payment options
      <Callout
        title={'Payment Options Are Not Available Right Now'}
        desc={'Please contact us directly to explore your payment options'}
      />
    );

  if (dealIsLoading || deskIsLoading || isSubmitting || programSummaryIsLoading) {
    return (
      <div>
        <div className={`matrix ${deal?.isDeskingLocked ? ' is-locked' : ''}`}>
          <div className="matrix-content">
            <MatrixLoadingItem />
            <MatrixLoadingItem />
            <MatrixLoadingItem />
            <MatrixLoadingItem />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={renderClass}>
        <div className="matrix-content">
          {desk.results.map((v, index) => {
            return v ? (
              <MatrixItem
                control={form.control}
                key={index}
                value={index}
                name="currentCell"
                paymentRange={desk.paymentRange}
                // For now, whole numbers only
                payment={Math.floor(v.payment)}
                termType={desk.termType}
                subtitle={v.term + ' months'}
                // Money factor is rate times 2400
                description={
                  v.isMoneyFactor
                    ? (v.rate * 2400).toLocaleString('en-US', { maximumFractionDigits: 2 }) +
                      '% APR'
                    : v.rate.toLocaleString('en-US', { maximumFractionDigits: 2 }) + '% APR'
                }
                isDeskingLocked={deal?.isDeskingLocked}
                paymentMethod={formValues.paymentMethod}
              />
            ) : (
              // Return Contact Us item if no term results
              <MatrixContactItem
                key={index}
                subtitle={12 * (index + 2) + ' months'}
                description={'No term results'}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export const validationSchema = {
  currentCell: Joi.number().required(),
};

export function ChatMatrix() {
  const activeForm = 'matrix';
  const { activeVehicle } = useChatContext();
  const form = useSpaceForm(validationSchema, {
    currentCell: '',
  });
  const { searchVehicle } = useVehicleVinSearch(activeVehicle);

  if (!searchVehicle) {
    return null;
  }
  return (
    <DealsProvider initialVehicle={{ vin: activeVehicle }}>
      <FormWrapper
        handleFormSubmit={async data => {
          console.log(data);
        }}
        handleFormCancel={() => {}}
        steps={[activeForm]}
        classNames="mod-overflow"
      >
        <FormContentWrapper form={form} id={activeForm}>
          <Matrix form={form} />
        </FormContentWrapper>
      </FormWrapper>
    </DealsProvider>
  );
}
