import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { useFavorites } from '../../fetches/useSWRFetch';
import ModularVehicleCard from '../../components/cards/vehicle/ModularVehicleCard';
import { FormHeader } from '../../components/form/FormHeader';
import { useDeals } from '../../components/context/DealsProvider';
import { useConfig } from '../../components/context/config';
import { Favorite } from 'src/types/favorite';
import { SplideSlide } from '@splidejs/react-splide';
import { Colors } from 'src/types/colors';
import { FormBody } from 'src/components/form/Form';

export function Favorites() {
  const { deals, createDeal } = useDeals();
  const config = useConfig()!;
  const { favorites, favoritesIsLoading } = useFavorites();

  const filteredFavorites = useMemo(() => {
    if (!favorites || !deals) {
      return [];
    }
    return favorites.filter(favorite => !deals.find(deal => deal.vin === favorite.vin));
  }, [favorites, deals]);

  if (favoritesIsLoading) {
    return (
      <div className="block-container--empty-state">
        <div className="block-container">
          <FormHeader title="No Favorites" />
          <div className="block-container--content">
            <div className="block-container--content--centered">
              <FontAwesomeIcon icon={faHeart} className="success--icon" color={Colors.Red1} />
              <FormBody
                title="Your Favorite List is Empty."
                body="Browse our inventory and add your favorite cars, trucks, and SUVs to keep them in one convenient place for easy reference."
              />
            </div>
          </div>

          <div className="block-container--action">
            <a
              href={`https://${config.websiteDomain}/cars`}
              target="_blank"
              rel="noreferrer"
              className="btn mod-primary mod-full"
            >
              Search Inventory
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (favorites?.length === 0) {
    return null;
  }

  return (
    <>
      {filteredFavorites.map((slide: Favorite) => (
        <SplideSlide key={slide.id}>
          <ModularVehicleCard
            key={slide.id}
            item={slide}
            handleSelect={(item: Favorite) => createDeal({ vin: item.vin })}
          />
        </SplideSlide>
      ))}
    </>
  );
}
