import { Link } from 'react-router-dom';
import { useDocTitle } from '../helpers/global-document-title';

// pages/404.js
export default function Custom404() {
  useDocTitle('Page Not Found');
  return (
    <div className="page--main">
      <div className="empty-state">
        <div className="empty-state--header">Well this is a little embarassing...</div>
        <p className="empty-state--text u-text-center">
          {"Something happened that shouldn't."}
          <br />
          {"Our development team is aware of the issue and will make sure it doesn't happen again."}
        </p>
        <Link to="/" className="btn-height-text u-color-accent">
          Go home
        </Link>
      </div>
    </div>
  );
}
