import { Navigate } from 'react-router-dom';
import { useConfig } from '../../../components/context/config';
import { useAppointments } from '../../../fetches/useSWRFetch';
import AppointmentCard from '../../../components/cards/AppointmentCard';
import PageLoader from '../../../components/PageLoader';
import PageError from '../../../components/PageError';
import { useDocTitle } from '../../../helpers/global-document-title';

import { FormListPage } from 'src/components/form/FormShell';
import { Selector } from '../../../components/selectors/Selector';
import { faCalendarStar, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FormJourneyTab } from 'src/components/navigation/JourneyTab';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useRecordPageVisit } from 'src/helpers/useRecordPageVisit';
import { ScheduleProvider } from 'src/components/context/ScheduleProvider';

export default function AppointmentsIndexPage() {
  useDocTitle('Appointments');
  const config = useConfig()!;
  const { appointments, appointmentsIsLoading, appointmentsIsError } = useAppointments();
  const { appointmentsVisited, setAppointmentsVisited } = useSessionContext();
  useRecordPageVisit(appointmentsVisited, setAppointmentsVisited);

  if (appointmentsIsLoading) return <PageLoader />;
  if (appointmentsIsError) return <PageError />;

  // skip index page if no appointments
  if (appointments?.length === 0) {
    return (
      <PageLoader>
        <Navigate to={`new`} />
      </PageLoader>
    );
  }

  return (
    <FormListPage
      configuration={{
        navigation: config.isRetailing ? <div></div> : <FormJourneyTab />, // Appointments are not part of the "quick forms"
        title: null,
        itemTitle: 'Schedule Appointment',
        itemSubtitle: 'Schedule a meeting time',
      }}
    >
      <Selector
        type="link"
        to="new"
        thumbnail={{ icon: faCalendarStar }}
        title="Schedule Appointment"
        subtitle="Schedule a meeting time"
        btnIcon={faPlus}
      />
      {appointments.length
        ? appointments.map(appointment => (
            <ScheduleProvider key={appointment.id} location={appointment.location}>
              <AppointmentCard key={appointment.id} appointment={appointment} />
            </ScheduleProvider>
          ))
        : null}
    </FormListPage>
  );
}
