export default function PageError({
  primaryText,
  secondaryText,
}: {
  primaryText?: string;
  secondaryText?: string;
}) {
  return (
    <div className="empty-state">
      <div className="empty-state--header">Nothing to see here.</div>
      <p className="empty-state--text u-text-center">
        {primaryText}
        <br />
        {secondaryText}
      </p>
    </div>
  );
}
