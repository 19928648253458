/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import spaceColors from '../../constants/colors';
import { Radio } from '../controls/Radio';

import './TradeinColorGroup.css';
import { FormError } from '../controls/FormError';
import { FormBody, useFormSubmit } from '../form/Form';

const validationSchema = Joi.object({
  color: Joi.string().required().messages({
    'string.empty': `Please select a color`,
  }),
});

export const TradeinColorGroup = () => {
  const { handleFormGroupSubmit, formValues: tradein, activeForm } = useFormSubmit();
  const form = useForm({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
    defaultValues: {
      color: tradein.color,
    },
  });

  if (activeForm !== 'tradeinColorForm') {
    return null;
  }

  return (
    <form id="tradeinColorForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="What color is your vehicle?"
        body={`${tradein.year} ${tradein.make} ${tradein.model}`}
      />
      <div className="colors-list">
        {Object.entries(spaceColors.inputs).map(([colorKey, colorConfig]) => (
          <Radio
            className="color-picker"
            key={colorKey}
            value={colorKey}
            control={form.control}
            name="color"
            color={colorConfig.hex}
            label={colorConfig.label}
            isComplex={true}
          />
        ))}

        <div className="colors-error">
          <FormError control={form.control} name="color" />
        </div>
      </div>
    </form>
  );
};
