import { useState } from 'react';
import { FormActions } from '../../../components/form/FormActions';
import { FormContentWrapper, FormWrapper, useSpaceForm } from 'src/components/form/Form';
import {
  UnlockCalculatorGroup,
  validationSchema,
} from 'src/components/steps/UnlockCalculatorGroup';
import { useSessionContext } from 'src/components/context/SessionProvider';

export const UnlockPaymentForm = ({ zip, paymentMethod, creditScore }) => {
  const activeForm = 'unlockPaymentForm';
  const { setSessionCreditScore, setSessionZip, setSessionPaymentType } = useSessionContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useSpaceForm(validationSchema, {
    paymentMethod: paymentMethod || '',
    zip: zip || '',
    creditScore: creditScore || '',
  });

  const handleFormGroupSubmit = async data => {
    setIsSubmitting(true);
    try {
      setSessionCreditScore(data.creditScore);
      setSessionPaymentType(data.paymentMethod);
      setSessionZip(data.zip);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return (
    <FormWrapper
      handleFormSubmit={handleFormGroupSubmit}
      handleFormCancel={() => {}}
      steps={[activeForm]}
    >
      <FormContentWrapper form={form} id={activeForm}>
        <UnlockCalculatorGroup form={form} />
      </FormContentWrapper>

      <FormActions isSubmitting={isSubmitting} hideBackButton={true} alternateButtonText="Next" />
    </FormWrapper>
  );
};
