import { UseFormReturn } from 'react-hook-form';
import { Email, FirstName, LastName, Phone } from '../inputs/Input';
import { TermsGroup } from './TermsGroup';

export function Customer({
  form,
  children,
}: {
  form: UseFormReturn<any>;
  children?: React.ReactNode;
}) {
  return (
    <>
      <div className="u-display-grid grid--50">
        <FirstName control={form.control} />
        <LastName control={form.control} />
      </div>
      <div className="u-display-grid grid--50">
        <Phone control={form.control} />
        <Email control={form.control} />
      </div>
      {children && <div className="u-display-grid grid--50">{children}</div>}
      <TermsGroup control={form.control} />
    </>
  );
}
