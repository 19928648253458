import { HomeOwnership } from './creditapplication-block';

export enum State {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum Country {
  US = 'US',
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: State | string;
  zip: string;
  phone: string | null;
  homeOwnership?: HomeOwnership | string;
  homeMonthlyPayment?: number | null;
  homeLivingMonths?: number | null;
  homeLivingYears?: number | null;
}

export enum AddressType {
  Employer = 'EmployerAddressForm',
  Customer = 'CustomerAddressForm',
  Reference = 'ReferenceAddressForm',
}
