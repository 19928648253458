import { convertPhoneForSubmit } from '../constants/validation';
import { CustomerBlock, CustomerBase } from './customer-block';
import { PaymentType } from './deal';

export interface GeneralLead extends CustomerBlock {
  location?: string;
  creditScore?: number | null;
  paymentMethod?: PaymentType | null;
}
export interface PreapprovalLead extends CustomerBlock {
  zip: string;
}
export interface VehicleLead extends CustomerBlock {}

export interface GeneralLeadFormattedBlock extends CustomerBase {
  comments: string | null;
  creditScore?: number | null;
  paymentMethod?: PaymentType | null;
}

// Server-expected format for customer add on
export interface CustomerFormattedBlock extends CustomerBase {}

export function formatGeneralLeadBlock(
  data: GeneralLead,
  comments?: string[]
): GeneralLeadFormattedBlock {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: convertPhoneForSubmit(data.phone),
    optoutCalls: data.optoutCalls,
    optoutSms: data.optoutSms,
    optoutEmails: data.optoutEmails,
    comments: comments?.length ? comments?.join(' ') : null,
    creditScore: data.creditScore,
    paymentMethod: data.paymentMethod,
  };
}

// for credit app customer block, trade in app block, appointmentblock block
export function formatCustomerAddonBlock(data: GeneralLead): CustomerFormattedBlock {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email || null,
    phone: convertPhoneForSubmit(data.phone) || null,
    optoutCalls: data.optoutCalls,
    optoutSms: data.optoutSms,
    optoutEmails: data.optoutEmails,
    // no comments allowed for customer as a separate block
  };
}
