import { FormBody, FormContentWrapper, FormWrapper, useSpaceForm } from './Form';
import { FormActions } from './FormActions';
import React, { useContext } from 'react';
import { faWrench } from '@fortawesome/pro-regular-svg-icons';
import { BlockRoute } from 'src/types/blocks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from 'src/types/colors';
import { NavigationContext } from '../context/NavigationProvider';
import { useParams } from 'react-router-dom';
import { FormJourneyTab } from '../navigation/JourneyTab';

interface FormListPageProps {
  configuration: {
    navigation: React.ReactNode | null;
    icon?: any;
    title: React.ReactNode | null;
    itemTitle?: string;
    itemSubtitle?: string;
  };

  children: any;
}

export function FormListPage({ configuration, children }: FormListPageProps) {
  const { block } = useParams();
  const activeForm = `${block}-index`;
  const { nextBlockTitle, navigateNextBlock, navigatePrevBlock } = useContext(NavigationContext);

  const form = useSpaceForm({}, {});

  return (
    <FormWrapper
      handleFormSubmit={async () => navigateNextBlock()}
      handleFormCancel={() => navigatePrevBlock()}
      steps={[activeForm]}
    >
      {configuration.navigation}
      <FormContentWrapper form={form} id={activeForm}>
        {configuration.title}
        {children}
      </FormContentWrapper>

      <FormActions isSubmitting={false} alternateButtonText={nextBlockTitle} />
    </FormWrapper>
  );
}

interface ErrorProps {
  configuration: {
    title?: string;
    subtitle?: string;
    alternateButtonText?: string;
  };

  children?: React.ReactNode;
}

export default function FormErrorPage({ configuration, children }: ErrorProps) {
  const { block } = useParams();
  const { navigateNextBlock, nextBlockTitle } = useContext(NavigationContext);
  const activeForm = `${block}-error`;
  const form = useSpaceForm({}, {});

  return (
    <FormWrapper
      handleFormSubmit={async () => navigateNextBlock()}
      handleFormCancel={() => {}}
      steps={[activeForm]}
    >
      <FormJourneyTab />
      <FormContentWrapper form={form} id={activeForm}>
        <div className="block-container--content--centered is-fade-in">
          <FontAwesomeIcon icon={faWrench} className="success--icon" color={Colors.Gray2} />
          <FormBody title={configuration.title || null} body={configuration.subtitle || null} />
          {children}
        </div>
      </FormContentWrapper>

      <FormActions
        isSubmitting={false}
        hideBackButton={true}
        alternateButtonText={nextBlockTitle}
      />
    </FormWrapper>
  );
}

interface FormBasicPageProps {
  configuration: {
    blockRoute?: BlockRoute;
    title?: React.ReactNode | null;
    isSubmitting?: boolean;
    alternateButtonText?: string;
    hideBackButton?: boolean;
    hidePrimaryButton?: boolean;
    formNavigationClass?: string;
    formWrapperClassNames?: string;
    showSkipButton?: boolean;
  };

  children: any;
}

export function FormBasicPage({ configuration, children }: FormBasicPageProps) {
  const { navigateNextBlock, navigatePrevBlock } = useContext(NavigationContext);
  const activeForm = `${configuration.blockRoute}-basic`;
  const form = useSpaceForm({}, {});

  return (
    <FormWrapper
      handleFormSubmit={async () => navigateNextBlock()}
      handleFormCancel={async () => navigatePrevBlock()}
      steps={[activeForm]}
      classNames={configuration.formWrapperClassNames}
    >
      <FormJourneyTab />
      <FormContentWrapper form={form} id={activeForm}>
        {configuration.title}
        {children}
      </FormContentWrapper>

      <FormActions
        isSubmitting={configuration.isSubmitting}
        hideBackButton={configuration.hideBackButton ?? false}
        hidePrimaryButton={configuration.hidePrimaryButton ?? false}
        alternateButtonText={configuration.alternateButtonText}
        formNavigationClass={configuration.formNavigationClass}
        showSkipButton={configuration.showSkipButton}
      />
    </FormWrapper>
  );
}
