export interface AdditionalFieldBase<T> {
  name: string;
  label: string;
  description?: string;
  defaultValue?: T;
  // Requred/Valiation rules?
}

export interface HiddenAdditionalField {
  type: 'hidden';
  name: string;
  label?: string;
  value: string;
}

interface TextAdditionalField extends AdditionalFieldBase<string> {
  type: 'text';
  name: string;
  label: string;
  placeholder?: string;
}

interface TextreaAdditionalField extends AdditionalFieldBase<string> {
  type: 'textarea';
  name: string;
  label: string;
  placeholder?: string;
}

interface NumberAdditionalField extends AdditionalFieldBase<number> {
  type: 'number';
  name: string;
  label: string;
  placeholder?: string;
}

interface SelectAdditionalField extends AdditionalFieldBase<string> {
  type: 'select';
  name: string;
  label: string;
  options: { value: string; label: string }[];
  placeholder?: string;
}

interface CheckboxAdditionalField extends AdditionalFieldBase<boolean> {
  type: 'checkbox';
  name: string;
  label: string;
}

export type AdditionalField =
  | TextAdditionalField
  | TextreaAdditionalField
  | NumberAdditionalField
  | SelectAdditionalField
  | CheckboxAdditionalField
  | HiddenAdditionalField;

export function returnAdditionalFieldValues(additionalFields: AdditionalField[], data: any) {
  const userNotes = data.comments || data.notes;
  const array = additionalFields.map(field => {
    if (field.type === 'hidden') {
      // Handle hidden fields
      const hiddenField = field as HiddenAdditionalField;
      return `${hiddenField.value};`;
    } else {
      // Handle other field types
      const baseField = field as AdditionalFieldBase<any>;
      return `${baseField.label}: ${data[baseField.name] || 'not specified'};`;
    }
  });

  if (userNotes) {
    array.push(userNotes);
  }
  return array;
}
