import { MultipleDealResponse } from '../../types/deal';
import { SplideSlide } from '@splidejs/react-splide';
import ModularVehicleCard from '../../components/cards/vehicle/ModularVehicleCard';
import { useDeals } from '../../components/context/DealsProvider';
import { useMenu } from 'src/components/context/MenuProvider';

function getDealsOrder(deals: MultipleDealResponse[], activeDealId: string) {
  // Move active deal to the front of the array
  const activeDeal = deals.findIndex(obj => obj.id === activeDealId);
  const activeObject = deals.splice(activeDeal, 1)[0];
  deals.unshift(activeObject);
  return deals;
}

export function Reservations() {
  const { toggleVehicleSelector } = useMenu();
  const { deals, activeDealId, selectDeal } = useDeals();

  const handleChangeDeal = async (vehicle: MultipleDealResponse) => {
    selectDeal(vehicle.id, true);
    toggleVehicleSelector();
  };

  if (deals.length === 0) {
    return null;
  }

  return (
    <>
      {getDealsOrder(deals, activeDealId)
        .filter(deal => deal.vin !== null) // For now, filter out deals without a VIN. To do: we will need these created by appt widget
        .map((slide: MultipleDealResponse) => (
          <SplideSlide key={slide.id}>
            <ModularVehicleCard
              key={slide.id}
              item={slide}
              status={slide.status}
              handleSelect={handleChangeDeal}
            />
          </SplideSlide>
        ))}
    </>
  );
}
