import { Control, useController, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import './ButtonSegmentedPicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ButtonSegmentedPickerProps {
  name: string;
  control: Control<any>;
  value: string;
  label: string;
  icon: any; // Replace 'any' with the appropriate type for 'icon'
  iconColor: string;
  className?: string;
}

export default function ButtonSegmentedPicker({
  name,
  control,
  value,
  label,
  icon,
  iconColor,
  className,
}: ButtonSegmentedPickerProps) {
  const {
    field: { value: fieldValue, ...field },
    fieldState: { invalid, isTouched, isDirty },
    formState: { isSubmitted },
  } = useController({
    name,
    control,
  });

  const selectedValue = useWatch({
    control,
    name,
  });

  const renderClass = useMemo(() => {
    const classes = className
      ? [className, ' button-segmented-picker--option']
      : [' button-segmented-picker--option'];
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [className, invalid, isDirty, isSubmitted, isTouched]);

  return (
    <div className={renderClass}>
      <label
        htmlFor={`${name}${value}`}
        className={
          selectedValue === value
            ? 'button-segmented-picker--label is-active'
            : 'button-segmented-picker--label'
        }
      >
        <input
          id={`${name}${value}`}
          type="radio"
          className="button-segmented-picker"
          value={value}
          {...field}
          checked={selectedValue === value}
        />
        <FontAwesomeIcon icon={icon} className={`button-segmented-picker--icon  ${iconColor}`} />
        <div className="button-segmented-picker--text">{label}</div>
      </label>
    </div>
  );
}
