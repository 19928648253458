import { State } from '../../types/address';

const StateDropdownArray: { label: string; value: State }[] = [
  { label: 'Alabama', value: State.AL },
  { label: 'Alaska', value: State.AK },
  { label: 'Arizona', value: State.AZ },
  { label: 'Arkansas', value: State.AR },
  { label: 'California', value: State.CA },
  { label: 'Colorado', value: State.CO },
  { label: 'Connecticut', value: State.CT },
  { label: 'Delaware', value: State.DE },
  { label: 'District Of Columbia', value: State.DC },
  { label: 'Florida', value: State.FL },
  { label: 'Georgia', value: State.GA },
  { label: 'Hawaii', value: State.HI },
  { label: 'Idaho', value: State.ID },
  { label: 'Illinois', value: State.IL },
  { label: 'Indiana', value: State.IN },
  { label: 'Iowa', value: State.IA },
  { label: 'Kansas', value: State.KS },
  { label: 'Kentucky', value: State.KY },
  { label: 'Louisiana', value: State.LA },
  { label: 'Maine', value: State.ME },
  { label: 'Maryland', value: State.MD },
  { label: 'Massachusetts', value: State.MA },
  { label: 'Michigan', value: State.MI },
  { label: 'Minnesota', value: State.MN },
  { label: 'Mississippi', value: State.MS },
  { label: 'Missouri', value: State.MO },
  { label: 'Montana', value: State.MT },
  { label: 'Nebraska', value: State.NE },
  { label: 'Nevada', value: State.NV },
  { label: 'New Hampshire', value: State.NH },
  { label: 'New Jersey', value: State.NJ },
  { label: 'New Mexico', value: State.NM },
  { label: 'New York', value: State.NY },
  { label: 'North Carolina', value: State.NC },
  { label: 'North Dakota', value: State.ND },
  { label: 'Ohio', value: State.OH },
  { label: 'Oklahoma', value: State.OK },
  { label: 'Oregon', value: State.OR },
  { label: 'Pennsylvania', value: State.PA },
  { label: 'Rhode Island', value: State.RI },
  { label: 'South Carolina', value: State.SC },
  { label: 'South Dakota', value: State.SD },
  { label: 'Tennessee', value: State.TN },
  { label: 'Texas', value: State.TX },
  { label: 'Utah', value: State.UT },
  { label: 'Vermont', value: State.VT },
  { label: 'Virginia', value: State.VA },
  { label: 'Washington', value: State.WA },
  { label: 'West Virginia', value: State.WV },
  { label: 'Wisconsin', value: State.WI },
  { label: 'Wyoming', value: State.WY },
];

export default StateDropdownArray;
