enum CreditScore {
  Excellent = 'Excellent',
  VeryGood = 'Very Good',
  Good = 'Good',
  Fair = 'Fair',
  Poor = 'Poor',
  VeryPoor = 'Very Poor',
}

export function returnCreditScore(creditScore: number): string {
  if (creditScore >= 800) {
    return CreditScore.Excellent;
  } else if (creditScore >= 740) {
    return CreditScore.VeryGood;
  } else if (creditScore >= 670) {
    return CreditScore.Good;
  } else if (creditScore >= 580) {
    return CreditScore.Fair;
  } else if (creditScore >= 300) {
    return CreditScore.Poor;
  } else if (creditScore > 0) {
    return '';
  }

  return '';
}
