/* eslint-disable react-hooks/exhaustive-deps */
import { Radio } from '../controls/Radio';
import { FormError } from '../controls/FormError';
import { TradeinDeclarationInputs } from 'src/constants/tradeinDeclaration';
import { FormBody } from '../form/Form';

export const TradeinDeclarationGroup = ({ form }) => {
  return (
    <>
      <FormBody
        title="Are you considering trading in your vehicle(s)?"
        body="Get a fast accurate trade value make your dollar go further."
      />
      <div className="u-display-grid grid--gap-16">
        {TradeinDeclarationInputs.map(item => (
          <Radio
            control={form.control}
            key={item.value}
            value={item.value}
            name="tradeinDeclaration"
            label={item.label}
            labelDesc={item.labelDesc}
          />
        ))}
        <div className="valuation-card--footnote">
          Estimates are not offers and are subject to change
        </div>
        <FormError control={form.control} name="tradeinDeclaration" />
      </div>
    </>
  );
};
