import { RouterTask } from '../../components/navigation/RouterTask';
import { BlockRoute, BlockStatus } from '../../types/blocks';
import { useContext } from 'react';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { FormBasicPage } from 'src/components/form/FormShell';
import { FormBody } from 'src/components/form/Form';
import { useParams } from 'react-router-dom';

export function StatusPage() {
  const { block } = useParams();
  const { navigationMap, nextBlockTitle, status } = useContext(NavigationContext);

  return (
    <FormBasicPage
      configuration={{
        alternateButtonText: nextBlockTitle,
      }}
    >
      <div className="u-mar-bottom-16 u-text-center u-mar-top-16">
        <FormBody
          title={
            status[block as BlockRoute] === BlockStatus.Done
              ? 'You’ve added everything you need!'
              : 'Almost there!'
          }
          body={
            status[block as BlockRoute] === BlockStatus.Done
              ? 'You can update your documents at any time.'
              : 'Just a few more documents and you will be all set!'
          }
        />
      </div>
      <div className="task--grid">
        {navigationMap
          ?.filter(item => item.parentBlock === block)
          .map((item, index) => {
            return (
              <RouterTask key={index} route={item.route} status={item.status} title={item.title} />
            );
          })}
      </div>
    </FormBasicPage>
  );
}
