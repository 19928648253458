import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { faCars, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTradeins } from '../../../fetches/useSWRFetch';
import PageLoader from '../../../components/PageLoader';
import PageError from '../../../components/PageError';
import { useDocTitle } from '../../../helpers/global-document-title';
import { TradeDeclarationForm } from './TradeDeclarationForm';
import VehicleTradeInCard from '../../../components/cards/VehicleTradeInCard';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { DeclarationType } from 'src/constants/tradeinDeclaration';
import { FormListPage } from 'src/components/form/FormShell';
import { FormBody } from 'src/components/form/Form';
import { Selector } from '../../../components/selectors/Selector';
import { FormJourneyTab } from 'src/components/navigation/JourneyTab';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useRecordPageVisit } from 'src/helpers/useRecordPageVisit';

export default function TradeInsIndexPage() {
  useDocTitle('Trade In');
  const navigate = useNavigate();
  const { navigateNextBlock, navigatePrevBlock } = useContext(NavigationContext);
  const { tradeins, tradeinsIsLoading, tradeinsIsError, tradeinsDelete } = useTradeins();
  const { tradeinsVisited, setTradeinsVisited, setRequireTradeIns, requireTradeIns } =
    useSessionContext();
  useRecordPageVisit(tradeinsVisited, setTradeinsVisited);

  if (tradeinsIsLoading) return <PageLoader />;
  if (tradeinsIsError) return <PageError />;

  const globalFetchSuccess = (requireTradeins: boolean) => {
    switch (requireTradeins) {
      case false:
        navigateNextBlock();
        break;
      case true:
        if (!tradeins.length) {
          navigate('new');
        } else {
          navigateNextBlock();
        }
        break;
      default:
        throw new Error(`Payment method - Unknown trade in scenario: ${requireTradeins}`);
    }
  };

  const handleTradeinDeclarationSubmit = async (data: any) => {
    let requireTradeins = data.tradeinDeclaration === DeclarationType.Trades;
    try {
      setRequireTradeIns(requireTradeins);
      globalFetchSuccess(requireTradeins);
    } catch (error) {}
  };

  // Show declaration form if any of these are the case
  const showForm = requireTradeIns === null || requireTradeIns === false || !tradeins?.length;

  // Three possible declaration Types
  const declarationType =
    requireTradeIns === true
      ? DeclarationType.Trades
      : requireTradeIns === false
      ? DeclarationType.NoTrades
      : '';

  if (showForm) {
    return (
      <TradeDeclarationForm
        declaration={declarationType}
        handleFormSubmit={handleTradeinDeclarationSubmit}
        handleFormCancel={() => navigatePrevBlock()}
      />
    );
  }

  return (
    <FormListPage
      configuration={{
        navigation: <FormJourneyTab />,
        title: (
          <FormBody
            title="Trade In"
            body="Get a fast accurate trade value and make your dollar go further."
          />
        ),
        itemTitle: 'Add Trade Vehicle',
        itemSubtitle: 'Trade Application',
      }}
    >
      <Selector
        type="link"
        to={'new'}
        thumbnail={{ icon: faCars }}
        title="Add Trade Vehicle"
        subtitle="Trade Application"
        btnIcon={faPlus}
      />
      {tradeins.length
        ? tradeins.map(tradeIn => (
            <VehicleTradeInCard
              key={tradeIn.id}
              tradeIn={tradeIn}
              handleDelete={async id => {
                try {
                  await tradeinsDelete(id);
                  toast.success('Trade in removed');
                } catch (e: any) {
                  toast.error(e.message);
                } finally {
                  console.log('Trade in removed');
                  return;
                }
              }}
            />
          ))
        : null}
    </FormListPage>
  );
}
