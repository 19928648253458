/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Radio } from '../controls/Radio';

import './TradeinConditionGroup.css';
import { FormError } from '../controls/FormError';
import { FormBody, useFormSubmit } from '../form/Form';

enum TradeinCondition {
  Excellent = 'excellent',
  Good = 'good',
  Fair = 'fair',
}

const TradeinConditionInputs = [
  {
    label: 'Excellent (uncommon)',
    labelDesc: 'Looks new and in perfect mechanical condition',
    value: TradeinCondition.Excellent,
  },
  {
    label: 'Good (most common)',
    labelDesc: 'Some repairable cosmetic defects and is free of major mechanical issues',
    value: TradeinCondition.Good,
  },
  {
    label: 'Fair (common)',
    labelDesc: 'Has mechanical or cosmetic issues that require repair and/or replacement',
    value: TradeinCondition.Fair,
  },
];

const validationSchema = Joi.object({
  condition: Joi.string().allow(TradeinCondition).messages({
    'string.empty': `Condition is required`,
  }),
});

export const TradeinConditionGroup = () => {
  const { handleFormGroupSubmit, formValues: tradein, activeForm } = useFormSubmit();
  const form = useForm({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
    defaultValues: {
      condition: tradein.condition,
    },
  });

  if (activeForm !== 'tradeinConditionForm') {
    return null;
  }

  return (
    <form id="tradeinConditionForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="What is the condition of your vehicle?"
        body={`${tradein.year} ${tradein.make} ${tradein.model}`}
      />
      {TradeinConditionInputs.map(item => (
        <Radio
          control={form.control}
          key={item.value}
          value={item.value}
          name="condition"
          label={item.label}
          labelDesc={item.labelDesc}
        />
      ))}
      <FormError control={form.control} name="condition" />
    </form>
  );
};
