import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faSms, faLink } from '@fortawesome/pro-regular-svg-icons';
import Button from '../form/Button';
import toast from 'react-hot-toast';

import './ContactCard.css';
import { useMe } from 'src/fetches/useSWRFetch';
import { useConfig } from '../context/config';

function ContactCardTop({ item, isAgent, dealership, hasCrm }) {
  if (isAgent && hasCrm) {
    return (
      <>
        {item.dealerAvatar && (
          <div className="contact-card--top--profile--img">
            <img
              src={item.dealerAvatar} // Route of the image file
              height={48} // Desired size with correct aspect ratio
              width={48} // Desired size with correct aspect ratio
              alt="Sales agent profile"
            />
          </div>
        )}
        <div className="contact-card--top--profile--text">
          <span className="contact-card--top--profile--name">
            {item.dealerFirstName ? item.dealerFirstName : ''}{' '}
            {item.dealerLastName ? item.dealerLastName : ''}
          </span>
          <span className="contact-card--top--profile--title">Sales Associate</span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="contact-card--top--profile--text">
        <span className="contact-card--top--profile--name">{dealership}</span>
        <span className="contact-card--top--profile--title">Contact Info</span>
      </div>
    </>
  );
}

export function ContactCard({ item }) {
  const config = useConfig()!;
  const { me, meIsLoading } = useMe();
  const hasCrm = config.packages?.crm === true;

  const email = useMemo(
    () => (hasCrm ? item?.dealershipEmail || item?.dealerEmail : item?.dealershipEmail),
    [hasCrm, item]
  );
  const phone: string = useMemo(() => {
    const phoneNumberToUse = hasCrm
      ? me?.dealerPhone || me?.dealershipPhone
      : config.locations[0]?.phone;
    return phoneNumberToUse || '';
  }, [hasCrm, me, config]);
  const formattedPhone = useMemo(() => phone.substring(2), [phone]);
  const userFriendlyPhone = useMemo(
    () =>
      phone.slice(0, 2) +
      ' (' +
      phone.slice(2, 5) +
      ') ' +
      phone.slice(5, 8) +
      '-' +
      phone.slice(8, 12),
    [phone]
  );
  const sms = item?.dealershipVirtualPhone;
  const userFriendlySms = useMemo(
    () =>
      sms.slice(0, 2) + ' (' + sms.slice(2, 5) + ') ' + sms.slice(5, 8) + '-' + sms.slice(8, 12),
    [sms]
  );

  const isAgent = useMemo(() => item.dealerFirstName, [item]);

  const handleCopyToClipboard = text => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success('Copied to your clipboard!');
      },
      () => {
        toast.error('Sorry, your browser does not support copying text to the clipboard');
      }
    );
  };

  if (meIsLoading || !me) {
    return null;
  }

  return (
    <div className="contact-card">
      <div className="contact-card--top">
        <span className="contact-card--top--profile">
          <ContactCardTop
            item={item}
            isAgent={isAgent}
            dealership={config.dealershipName}
            hasCrm={hasCrm}
          />
        </span>
      </div>
      <div className="contact-card--item">
        <a className="contact-card--item--link" href={`tel:${formattedPhone}`}>
          <FontAwesomeIcon icon={faPhone} className="contact-card--item--link--icon" />
          <span className="contact-card--item--text">{userFriendlyPhone}</span>
        </a>
        <Button
          type="button"
          text=""
          baseClass="contact-card--btn icon-btn mod-sm"
          icon={true}
          iconName={faLink}
          iconClass=""
          isSubmitting={false}
          progress={true}
          handleSubmit={() => handleCopyToClipboard(userFriendlyPhone)}
        />
      </div>
      <div className="contact-card--item">
        <a className="contact-card--item--link" href={`mailto:${email}`}>
          <FontAwesomeIcon icon={faEnvelope} className="contact-card--item--link--icon" />
          <span className="contact-card--item--text">{email}</span>
        </a>
        <Button
          type="button"
          text=""
          baseClass="contact-card--btn icon-btn mod-sm"
          icon={true}
          iconName={faLink}
          iconClass=""
          isSubmitting={false}
          progress={true}
          handleSubmit={() => handleCopyToClipboard(email)}
        />
      </div>
      <div className="contact-card--item">
        <a className="contact-card--item--link" href={`sms:${sms}`}>
          <FontAwesomeIcon icon={faSms} className="contact-card--item--link--icon" />
          <span className="contact-card--item--text">{userFriendlySms}</span>
        </a>
        <Button
          type="button"
          text=""
          baseClass="contact-card--btn icon-btn mod-sm"
          icon={true}
          iconName={faLink}
          iconClass=""
          isSubmitting={false}
          progress={true}
          handleSubmit={() => handleCopyToClipboard(userFriendlySms)}
        />
      </div>
    </div>
  );
}
