import React, { useEffect } from 'react';
import { Carousel } from '../../carousel/Carousel';
import { DialogContent, DialogDescription } from '../dialog/Dialog';

export const CarouselPopover = React.forwardRef<HTMLDivElement, {}>((_, forwardedRef) => {
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof forwardedRef === 'function') {
      forwardedRef(ref.current);
    } else if (forwardedRef) {
      forwardedRef.current = ref.current;
    }
  }, [forwardedRef, ref]);

  return (
    // Put initial focus on selected Vehicle, 2
    <DialogContent
      className="popover-menu"
      containerRef={ref}
      alignStart={true}
      isOverlayInvisible={true}
    >
      <DialogDescription className="popover-media--description">
        <div className="popover-carousel">
          <div className="carousel">
            <Carousel />
          </div>
        </div>
      </DialogDescription>
    </DialogContent>
  );
});
