import { useEffect, useMemo, useState } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import './Radio.css';
import { useFormSubmit } from '../form/Form';

interface RadioIconProps {
  name: string;
  color?: string;
  checked: boolean;
}

function RadioIcon({ name, color, checked }: RadioIconProps) {
  if (name === 'color') {
    return (
      <div style={{ background: color }} className="radio--color-box">
        {checked ? <FontAwesomeIcon icon={faCheck} fixedWidth /> : null}
      </div>
    );
  }
  // default radio circle
  return (
    <div className="radio--circle">
      <div className="radio--inner-circle"></div>
    </div>
  );
}

interface RadioProps {
  name: string;
  control: Control<any>;
  value: string;
  label: string;
  className?: string;
  labelDesc?: any;
  colSpan?: string;
  color?: string;
  disabled?: boolean;
  isReadOnly?: boolean;
  isComplex?: boolean;
  isRecommended?: boolean;
  includeDelay?: boolean;
}

export function Radio({
  name,
  control,
  value,
  label,
  className,
  labelDesc,
  colSpan,
  color,
  disabled,
  isReadOnly,
  isComplex,
  isRecommended,
  includeDelay = false,
}: RadioProps) {
  const { isSubmitting } = useFormSubmit();
  const [isChanged, setIsChanged] = useState(false);
  const {
    field: { value: fieldValue, ...field },
    fieldState: { invalid, isTouched, isDirty },
    formState: { isSubmitted },
  } = useController({
    name,
    control,
  });
  const selectedValue = useWatch({
    control,
    name,
  });

  const renderClass = useMemo(() => {
    const classes = className ? [className, 'radio--container'] : ['radio--container'];
    if (colSpan) classes.push(colSpan);
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if (disabled || isChanged) classes.push('is-disabled');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    if (isReadOnly) classes.push('is-read-only');
    return classes.join(' ');
  }, [
    className,
    colSpan,
    invalid,
    isDirty,
    isSubmitted,
    isTouched,
    disabled,
    isChanged,
    isReadOnly,
  ]);

  // Use effect to add a listener to the form to detect changes
  useEffect(() => {
    let timer;

    if (includeDelay) {
      setIsChanged(true);
      timer = setTimeout(() => {
        setIsChanged(false);
      }, 500);
    }

    // Cleanup function to cancel the timer if the error is resolved before the timeout
    return () => clearTimeout(timer);
  }, [selectedValue, includeDelay]);

  return (
    <div className={renderClass}>
      <input
        id={`${name}${value}`}
        type="radio"
        className="radio--input"
        value={value}
        {...field}
        checked={selectedValue === value}
        disabled={disabled || isSubmitting || isChanged}
      />
      <label htmlFor={`${name}${value}`} className="radio--label">
        {isComplex ? (
          <div style={{ width: '32px' }}>
            <RadioIcon name={name} color={color} checked={selectedValue === value} />
          </div>
        ) : null}
        <div className="radio-label--text">
          <div className="radio-label--text--title">{label}</div>
          {labelDesc ? <div className="radio-label--text--desc">{labelDesc}</div> : null}
        </div>
        {isComplex ? (
          isRecommended ? (
            <div className="label mod-success">Recommended</div>
          ) : null
        ) : null}
      </label>
    </div>
  );
}
