// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { HardPullGroup } from './HardPullGroup';
import { AdditionalField } from 'src/widgets/AdditionalField';
import { AdditionalFieldControl } from 'src/widgets/AdditionalFieldControl';
import { PullConsentText } from 'src/constants/pullConsentText';
import { useConfig } from '../context/config';
import { DealerDropdown } from '../controls/DealerDropdown';
import { useFormSubmit } from '../form/Form';
import { esignature, esignatureConsent, softPullConsent } from 'src/constants/schemas';
import Joi from 'joi';

const validationSchema = {
  softPullConsent,
  esignature,
  esignatureConsent,
};

interface CreditAppAdditionalFieldGroupProps {
  additionalFields?: AdditionalField[];
  isPrimarySigner: boolean;
}

export const PaymentFinalizeGroup = ({
  additionalFields,
  isPrimarySigner,
}: CreditAppAdditionalFieldGroupProps) => {
  const { handleFormGroupSubmit, formValues: payment, activeForm } = useFormSubmit();
  const config = useConfig()!;
  const requiredValidation = config.forms?.creditApplication?.pullConsentIsHidden
    ? {}
    : validationSchema;
  const form = useForm({
    resolver: joiResolver(Joi.object(requiredValidation), {
      allowUnknown: true,
    }),
    mode: 'onBlur',
    defaultValues: {
      softPullConsent: payment.softPullConsent,
      esignatureConsent: payment.esignatureConsent,
      esignature: payment.esignature,
    },
  });

  if (activeForm !== 'paymentFinalizeForm') {
    return null;
  }

  return (
    <form id="paymentFinalizeForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <PullConsentText />
      <HardPullGroup form={form} />
      {additionalFields?.map(field => (
        <AdditionalFieldControl key={field.name} control={form.control} fieldConfig={field} />
      ))}
      {config.forms?.creditApplication?.showDealerDropdown &&
        !config.isRetailing &&
        isPrimarySigner && <DealerDropdown form={form} />}
    </form>
  );
};
