/* eslint-disable react-hooks/exhaustive-deps */
import { CodeInput } from '../controls/CodeInput';
import { Input } from '../controls/Input';
import Button from '../form/Button';
import toast from 'react-hot-toast';
import { FormBody, useFormSubmit, useSpaceForm } from '../form/Form';
import { AuthService } from 'src/services/Auth';
import { useAuth } from '../context/AuthProvider';
import { email, inputCode } from 'src/constants/schemas';
import { useEffect, useRef, useState } from 'react';
import { FormWarning } from '../form/FormWarning';
import { Popover, PopoverTrigger } from '../floating/popover/Popover';
import { Tooltip } from '../floating/popover/Tooltip';

interface CodeGroupProps {
  handleEmailCallback: (email: string, callback: () => void) => void;
  initialEmail: string;
  codeSent: boolean;
}

export const CodeForm = ({ handleEmailCallback, codeSent, initialEmail }: CodeGroupProps) => {
  const { activeForm } = useFormSubmit();

  if (activeForm !== 'loginCodeForm') {
    return null;
  }

  return (
    <CodeGroup
      handleEmailCallback={handleEmailCallback}
      codeSent={codeSent}
      initialEmail={initialEmail}
    />
  );
};

export const CodeGroup = ({ handleEmailCallback, codeSent, initialEmail }: CodeGroupProps) => {
  const { handleFormGroupSubmit, formValues, isSubmitting: isFormSubmitting } = useFormSubmit();
  const { signin } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [countdown, setCountdown] = useState(codeSent ? 20 : 0);
  const [isResendDisabled, setIsResendDisabled] = useState(codeSent);
  // Need a local submission state tracker because the form submission is async
  const [isAutoSubmitting, setIsAutoSubmitting] = useState(false);
  const [displayEmail, setDisplayEmail] = useState(formValues.email);

  const onSubmit = async (data: any) => {
    if (isFormSubmitting || isAutoSubmitting) return;
    try {
      await AuthService.verifyCode(data.code);
      const user = await AuthService.getToken();

      signin(user, () => {
        handleFormGroupSubmit(data);
      });
    } catch (e: any) {
      setIsAutoSubmitting(false);
      toast.error(e.message);
    }
  };

  const form = useSpaceForm(
    { code: inputCode, email },
    {
      code: formValues.code || '',
      email: formValues.email || '',
    }
  );
  const code = form.watch('code');
  const formEmail = form.watch('email');

  const handleNewCode = async () => {
    setCountdown(20);
    setIsResendDisabled(true);
    await handleEmailCallback(formEmail, () => {
      toast.success('A new sign in code was sent to your email!');
      setDisplayEmail(formEmail);
    });
  };

  useEffect(() => {
    if (countdown === 0) return;

    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown]);

  useEffect(() => {
    if (countdown === 0) {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  useEffect(() => {
    let timer;

    if (!codeSent && initialEmail) {
      timer = setTimeout(() => {
        setIsOpen(true);
      }, 3000);
    } else {
      setIsOpen(false);
    }

    // Cleanup function to cancel the timer if the error is resolved before the timeout
    return () => clearTimeout(timer);
  }, [codeSent]);

  useEffect(() => {
    // when code is 6 characters long, submit the form automatically
    if (code.length === 6) {
      if (codeSent) {
        setIsAutoSubmitting(true);
        onSubmit(form.getValues());
      } else {
        toast.error('Please send a code to your email first.');
      }
    }
    // /* eslint-disable react-hooks/exhaustive-deps */
  }, [code]);

  const buttonText = () => {
    if (codeSent) {
      if (countdown > 0) {
        return `Resend Code Available in ${countdown}s`;
      } else {
        return 'Resend Code';
      }
    } else {
      if (isResendDisabled) {
        return `Sending...`;
      } else {
        return 'Send My Code';
      }
    }
  };

  return (
    <form id="loginCodeForm" onSubmit={form.handleSubmit(onSubmit)}>
      <FormBody
        title="Get Your Login Code to Get Started"
        body={
          codeSent
            ? `To continue check your ${displayEmail} inbox for an email containing your temporary
          sign in code. Copy and paste it here to submit.`
            : 'Click “Send Me A Code” and we will send a code to your email. Enter it here to get a head start on buying a car.'
        }
      />
      <div className="code-input--holder u-mar-top-8" ref={containerRef}>
        <div className="u-mar-top-8">
          <Input
            control={form.control}
            name="email"
            label={form.getValues('email') !== '' ? 'Email' : 'Verify Email'}
            placeholder="e.g. john.doe@example.com"
          />

          {initialEmail !== '' && initialEmail !== formEmail && (
            <div className="u-mar-top-8">
              <FormWarning text="Are you sure you want to change your email? Some information attached to this email may be lost" />
            </div>
          )}
        </div>
        <CodeInput name="code" control={form.control} />
        <div className="u-position-relative">
          <Button
            type="button"
            text={buttonText()}
            baseClass={codeSent ? 'btn mod-tertiary mod-full' : 'btn mod-primary mod-full'}
            isSubmitting={isFormSubmitting || countdown > 0}
            progress={!isResendDisabled}
            handleSubmit={handleNewCode}
          />
          {/* Div for tooltip spacing since padding prop isn't working in this context */}
          <div className="u-mar-top-8"></div>
          {isOpen && (
            <Popover initialOpen={isOpen} placement="bottom">
              <PopoverTrigger className="popover-tooltip--hidden-trigger"></PopoverTrigger>
              <Tooltip
                text="Click to get your login code!"
                containerRef={containerRef}
                textClassName="popover-tooltip--text mod-medium"
              />
            </Popover>
          )}
        </div>
      </div>
    </form>
  );
};
