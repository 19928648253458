import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useWatch } from 'react-hook-form';
import type { useForm } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

const useAutoSave = (context: ReturnType<typeof useForm>, onSubmit: (data: unknown) => void) => {
  const watch = useWatch({ control: context.control });
  const { dirtyFields } = context.formState;
  const hasDirtyFields = Object.keys(dirtyFields).length > 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(() => {
      context.handleSubmit(onSubmit)();
    }, 500),
    []
  );

  useDeepCompareEffect(() => {
    if (hasDirtyFields) {
      debouncedSave();
    }
  }, [watch]);
};

export { useAutoSave };
