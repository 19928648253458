import { PaymentMethodInputs } from 'src/constants/paymentType';
import { useConfig } from '../context/config';
import { PaymentType } from 'src/types/deal';
import { useDeals } from '../context/DealsProvider';
import { Radio } from '../controls/Radio';
import { useFormSubmit } from '../form/Form';
import { useFormContext } from 'react-hook-form';

export function PaymentMethod() {
  const { isSubmitting } = useFormSubmit();
  const { control, formState } = useFormContext();
  const config = useConfig()!;
  const { deal } = useDeals();

  return (
    <div className="radio--flex-scroll">
      {PaymentMethodInputs.filter(
        item =>
          !config.paymentOptionsConfig[item.isDisabledKey] &&
          !(item.value === PaymentType.Leasing && !deal?.isLeaseEnabled)
      ).map((item, index) => (
        <Radio
          className="radio--flex-scroll--item"
          control={control}
          key={index}
          value={item.value}
          name="paymentMethod"
          label={item.label}
          // Disable payment method selection if current form has errors
          disabled={isSubmitting || Object.keys(formState.errors).length > 0}
          isReadOnly={deal?.isDeskingLocked}
          includeDelay={true}
        />
      ))}
    </div>
  );
}
