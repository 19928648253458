import { useMemo } from 'react';
import { useTradeins } from 'src/fetches/useSWRFetch';
import { BlockStatus } from 'src/types/blocks';
import { useSessionContext } from '../SessionProvider';

export const useTradeInStatus = () => {
  const { tradeins } = useTradeins();
  const { tradeinsVisited, requireTradeIns } = useSessionContext();

  return useMemo(() => {
    // User has 1 or more trade apps, or has selected no trades
    if (tradeins?.length > 0 || requireTradeIns === false) {
      return BlockStatus.Done;
    }

    // User has been to trade ins page and has selected Yes for trades
    if (tradeinsVisited === true && requireTradeIns === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [tradeins, requireTradeIns, tradeinsVisited]);
};
