import { useState, useCallback, useEffect } from 'react';
import { EmailForm } from '../steps/EmailGroup';
import { CodeForm } from '../steps/CodeGroup';
import toast from 'react-hot-toast';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { AuthService } from '../../services/Auth';
import { useSessionContext } from '../context/SessionProvider';
import { FormWrapper } from '../form/Form';
import { FormActions } from '../form/FormActions';
import { useConfig } from '../context/config';
import { WidgetHostMessageType } from 'src/widgets/SpaceWidget';
import { useAuth } from '../context/AuthProvider';
import { useMinimize } from '../context/MinimizeProvider';

export enum LoginState {
  Verify = 'verify',
}

const StepMap = {
  Email: 'loginEmailForm',
  Code: 'loginCodeForm',
};

export function LoginForm({
  email,
  formNavigationClass,
}: {
  email?: string;
  formNavigationClass?: string;
}) {
  const config = useConfig()!;
  const { token } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { sessionCustomer } = useSessionContext();
  const paramEmail = searchParams.get('email');
  const location = useLocation();
  const navigate = useNavigate();
  // useDocTitle(`${config.dealershipName} | Easy Sign In`);
  const [codeSent, setCodeSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialEmail = sessionCustomer?.email || email || paramEmail || '';
  const { closeModal } = useMinimize();

  const handleEmailCallback = useCallback(
    async (email: string, onSuccessCallback: () => void) => {
      setIsSubmitting(true);
      try {
        await AuthService.requestCode(config.dealershipId, email);
        setCodeSent(true);
        onSuccessCallback();
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setIsSubmitting(false);
      }
    },
    [config.dealershipId]
  );

  const handleFormSubmit = async () => {
    try {
      const returnUrl = location.state?.from?.pathname || '/';
      const addParams = location.state?.from?.search;
      if (!config.isRetailing) {
        // Send authorized users to standalone portal
        window.open(config.redirectUrl, '_blank');
        config.hostMessage!({
          type: WidgetHostMessageType.Redirect,
          payload: {
            isLoggedIn: true,
            route: location.pathname,
          },
        });
        closeModal();
        config.reset();
      }
      navigate(addParams ? `${returnUrl}${addParams}` : returnUrl, { replace: true });
    } catch (e: any) {
      toast.error(e.message);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const message = searchParams.get('message');

    if (message === 'loggedOut') {
      toast.success('You were successfully logged out');
    }
    if (message === 'needsAuth') {
      toast.success('Please log in again');
    }
    if (message) {
      searchParams.delete('message');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (token && !isSubmitting) {
      navigate('/');
    }
  }, [token, navigate, isSubmitting]);

  if (token) {
    return null;
  }

  return (
    <FormWrapper
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={() => {
        //navigate to previous page in history stack
        navigate(-1);
      }}
      multistepFormValues={{
        email: initialEmail,
        code: '',
      }}
      steps={
        initialEmail !== '' && location.state !== LoginState.Verify
          ? [StepMap.Code]
          : [StepMap.Email, StepMap.Code]
      }
      classNames={config.isRetailing ? 'login-container mod-center' : 'mod-center'}
    >
      <EmailForm handleEmailCallback={handleEmailCallback} />
      <CodeForm
        handleEmailCallback={handleEmailCallback}
        codeSent={codeSent}
        initialEmail={initialEmail}
      />

      <FormActions
        hideBackButton={location.state !== LoginState.Verify}
        isSubmitting={isSubmitting}
        formNavigationClass={formNavigationClass}
        hidePrimaryButton={initialEmail !== '' && location.state !== LoginState.Verify && !codeSent}
      />
    </FormWrapper>
  );
}
