/* eslint-disable react-hooks/exhaustive-deps */
import { UseFormReturn } from 'react-hook-form';
import Joi from 'joi';
import { useConfig } from '../context/config';
import { FormError } from '../controls/FormError';
import { PaymentType, SingleDealResponse } from '../../types/deal';
import { CreditScore, Zip } from '../inputs/Input';
import { Matrix } from '../desking/Matrix';
import { useDeals } from '../context/DealsProvider';
import { Slider } from '../controls/Slider';
import { useMemo } from 'react';
import { getStateFromZip } from 'src/constants/validation';
import { returnCreditScore } from 'src/constants/creditScore';
import { Summary } from '../desking/Summary';
import { PricingDisclaimer } from 'src/constants/legal';
import { Callout } from '../form/Callout';
import './CalculatorGroup.css';
import { FormBody, useFormSubmit } from '../form/Form';
import { VehicleSelection, VehicleSelectionMode } from './VehicleSelection';
import { isSitePaymentHidden } from 'src/constants/price';
import { useAutoSave } from 'src/helpers/useAutosave';
import { PaymentMethod } from '../desking/PaymentMethod';

export const validationSchema = {
  paymentMethod: Joi.string()
    .allow(...Object.values(PaymentType))
    .required()
    .messages({
      'string.empty': `You need to select a payment type to continue`,
    }),
  creditScore: Joi.number().min(350).max(850).messages({
    'number.min': `Credit score should not be below 350`,
    'number.max': `Credit score should not exceed 850`,
    'number.base': `Credit score should be a number`,
    'string.base': `Credit score should be a number`,
    'string.empty': `This field is required`,
  }),
  currentCell: Joi.number().required(),
  zip: Joi.string()
    .pattern(/^\d{5}$/)
    .custom((value, helpers) => {
      if (!getStateFromZip(value)) {
        return helpers.message({ custom: 'Please enter a valid zip' });
      }
      return value;
    }, 'custom validation')
    .messages({
      'string.pattern.base': `Zip Code must be 5 digits`,
      'string.empty': `Zip is required for accurate pricing`,
    }),

  downPayment: Joi.array().items(Joi.number().min(0)).min(1).max(1).required().messages({
    'string.empty': `Zip is required for accurate pricing`,
  }),
  monthlyPaymentTarget: Joi.number().required().allow(null).allow('').messages({
    'number.base': `Monthly payment target should be a number`,
    'string.empty': `This field is required`,
  }),
};

interface CalculatorGroupProps {
  form: UseFormReturn<any>;
  sellingPrice: number;
}

export const CalculatorGroup = ({ form, sellingPrice }: CalculatorGroupProps) => {
  const { handleFormGroupSubmit } = useFormSubmit();
  useAutoSave(form, handleFormGroupSubmit);

  const config = useConfig()!;
  const { deal }: { deal?: SingleDealResponse; activeDealId: string } = useDeals();

  const formPaymentMethod = form.watch('paymentMethod');
  const formDownPayment = form.watch('downPayment');
  const formZip = form.watch('zip');
  const formCreditScore: number | string = form.watch('creditScore');

  const zipBadge = useMemo(() => {
    if (formZip.length < 5) return undefined;
    return getStateFromZip(formZip);
  }, [formZip]);

  const downPaymentBadge = useMemo(() => {
    if (formDownPayment[0] === 0 || formDownPayment[0] === null || !deal || sellingPrice === null)
      return undefined;
    return Math.floor((formDownPayment[0] / sellingPrice) * 100) + '%';
  }, [formZip, sellingPrice, formDownPayment]);

  const creditScoreBadge = useMemo(() => {
    return formCreditScore ? returnCreditScore(+formCreditScore) : undefined;
  }, [formCreditScore]);

  if (!deal) return null;

  return (
    <div className="calculator-group u-display-grid grid--gap-16">
      <div className="block-container--overflow--padding">
        <FormBody
          title="Pick Your Payment"
          body="Instant estimates and personalized payment options."
        />
      </div>
      {config.isRetailing ? null : (
        <div className="block-container--overflow--padding">
          <VehicleSelection initialVehicle={deal} mode={VehicleSelectionMode.Browse} />
        </div>
      )}
      <div className="block-container--overflow--padding">
        <PaymentMethod />
      </div>

      {!deal?.isLeaseEnabled && formPaymentMethod === PaymentType.Leasing ? (
        <Callout
          title="Leasing is not available for this vehicle."
          desc="Please select a different payment type above to continue"
        />
      ) : (
        <>
          {deal.paymentType === PaymentType.Cash ? (
            <div className="block-container--overflow--padding">
              <Callout
                title="Cash will make this super simple."
                desc="Just a few more things and you'll be all set!"
              />
              {!isSitePaymentHidden(config) && <Summary />}
              <div className="text--body-3">{PricingDisclaimer}</div>
            </div>
          ) : (
            <div className="block-container--overflow--padding">
              <div
                className={
                  config.isRetailing
                    ? 'calculator-group-full-grid'
                    : 'calculator-group-condensed-grid'
                }
              >
                <div style={{ gridArea: 'creditScore' }}>
                  <CreditScore
                    control={form.control}
                    badge={creditScoreBadge}
                    readOnly={deal?.isDeskingLocked}
                  />
                </div>
                <div style={{ gridArea: 'zipCode' }}>
                  <Zip control={form.control} badge={zipBadge} readOnly={deal?.isDeskingLocked} />
                </div>
                <div style={{ gridArea: 'downPayment' }}>
                  {/* We have a fallback slider we can show if we need to show in an iFrame */}
                  <Slider form={form} max={sellingPrice as number} badge={downPaymentBadge} />
                </div>
              </div>

              {/* Do not show pricing matrix if hideprices is true */}
              {isSitePaymentHidden(config) ? null : (
                //   <>
                //     <h3 className="block-form--heading u-mar-top-16">
                //       What is your target monthly payment budget?
                //     </h3>
                //     <MonthlyPaymentTarget control={form.control} />
                //   </>
                // ) : (
                <Matrix form={form} />
              )}
            </div>
          )}
          <FormError control={form.control} name="paymentMethod" />
        </>
      )}
    </div>
  );
};
