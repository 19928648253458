/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Radio } from '../controls/Radio';
import { FormError } from '../controls/FormError';
import { HomeOwnership } from 'src/types/creditapplication-block';
import { HomeOwnershipInputs } from 'src/constants/homeOwnership';
import { FormBody, useFormSubmit } from '../form/Form';

const validationSchema = Joi.object({
  homeOwnership: Joi.string().allow(HomeOwnership).required().messages({
    'string.empty': `Residential status is required`,
  }),
});

export const AddressOwnershipGroup = ({ index }: { index: number }) => {
  const { activeForm, formValues: payment, handleFormGroupSubmit } = useFormSubmit();
  const addresses = payment.addresses[index];
  const form = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      homeOwnership: addresses.homeOwnership,
    },
  });

  const onSubmit = async (updatedFormData: any) => {
    const saveData = payment.addresses;
    saveData[index] = { ...saveData[index], ...updatedFormData };

    handleFormGroupSubmit({ addresses: saveData });
  };

  if (activeForm !== `addressOwnershipForm${index}`) {
    return null;
  }

  return (
    <form id={`addressOwnershipForm${index}`} onSubmit={form.handleSubmit(onSubmit)}>
      <FormBody
        title={
          index === 0 ? 'How do you pay for your residence?' : 'How did you pay for your residence?'
        }
        body={addresses.addressLine1}
      />
      {HomeOwnershipInputs.map(item => (
        <Radio
          control={form.control}
          key={item.value}
          value={item.value}
          name="homeOwnership"
          label={item.label}
          labelDesc={item.labelDesc}
        />
      ))}
      <FormError control={form.control} name="homeOwnership" />
    </form>
  );
};
