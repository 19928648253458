import { useCallback } from 'react';
import { useMenu } from './context/MenuProvider';

export function MenuOverlay() {
  const { toggleMenu } = useMenu();

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        toggleMenu();
      }
    },
    [toggleMenu]
  );

  return (
    <div
      className="page--overlay"
      onClick={toggleMenu}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
    ></div>
  );
}
