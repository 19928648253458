import { returnVehicleYearMakeModel } from 'src/types/vehicle';
import {
  SingleDealResponse,
  MultipleDealResponse,
  UnauthorizedDealResponse,
} from '../../../types/deal';
import { Favorite } from 'src/types/favorite';

interface VehicleCardTitleProps {
  vehicle: MultipleDealResponse | SingleDealResponse | UnauthorizedDealResponse | Favorite;
  isAvailable: boolean;
}

export function VehicleCardTitle({ vehicle }: VehicleCardTitleProps) {
  const YMMT = returnVehicleYearMakeModel(vehicle);
  const v = vehicle.vin;

  if (v) {
    return <span className="modular-vehicle-card--title text--title-1">{YMMT}</span>;
  }
  return <div className="modular-vehicle-card--title text--title-1">No Vehicle Added</div>;
}
