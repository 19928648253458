import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './JourneyTab.css';
import './SmallJourneyTab.css';
import { BlockRoute, FullBlockRoute, RouteItem, BlockStatus } from '../../types/blocks';
import {
  WidgetJourneyTabItems,
  noAuthQuickFormsMenuItems,
  quickFormsMenuItems,
} from '../../constants/routes';
import { useDeals } from '../context/DealsProvider';
import { Popover, PopoverTrigger } from '../floating/popover/Popover';
import { Tooltip } from '../floating/popover/Tooltip';
import { Colors } from 'src/types/colors';
import { useConfig } from '../context/config';
import { useMobileContext } from '../context/MobileProvider';
import { useAuth } from '../context/AuthProvider';
import { useMenuStatus } from '../context/status/useMenuStatus';
import { useIconColor } from '../hooks/useIconColor';

interface JourneyTabItemProps {
  name: BlockRoute;
  icon: IconDefinition;
  iconDone?: IconDefinition;
  title: string;
  route: FullBlockRoute;
  isDealDependent: boolean;
  tip?: string;
  isLastItem: boolean;
  className?: string;
}

function JourneyTabItem({
  name,
  icon,
  iconDone,
  title,
  isDealDependent,
  route,
  tip,
  isLastItem,
  className = 'journey-tab',
}: JourneyTabItemProps) {
  const isMobile = useMobileContext();
  const { activeDealId } = useDeals();
  const navigate = useNavigate();
  const { isActive, state, status } = useMenuStatus(name);
  const color = useIconColor(state);

  const handleNavigate = (e: any) => {
    if (status === BlockStatus.Disabled) {
      e.preventDefault();
    } else {
      navigate(isDealDependent ? `/deals/${activeDealId}${route}` : route);
    }
  };

  if (status === BlockStatus.Hidden) {
    return null;
  }

  return (
    <>
      <div className={`${className}--item--tooltip-holder ${isActive ? 'is-active' : ''}`}>
        <button
          className={`${className}--item ${state.join(' ')}`}
          onClick={handleNavigate}
          type="button"
        >
          {className === 'journey-tab' && (
            <FontAwesomeIcon
              icon={status === BlockStatus.Done && iconDone ? iconDone : icon}
              size="1x"
              color={color}
            />
          )}
          <div className={`${className}--item--text`}>{title}</div>
        </button>
        {status === BlockStatus.Disabled && isMobile && tip && (
          <Popover initialOpen={false} placement="top">
            <PopoverTrigger className="popover-tooltip--absolute-trigger"></PopoverTrigger>
            <Tooltip text={tip} />
          </Popover>
        )}
      </div>
      {!isLastItem && (
        <div
          className={`${className}--path`}
          style={{
            borderColor: isActive || status === BlockStatus.Done ? Colors.Gray0 : Colors.Gray3,
          }}
        ></div>
      )}
    </>
  );
}

interface JourneyTabProps {
  items?: RouteItem[];
}

export function JourneyTab({ items }: JourneyTabProps) {
  return (
    <div className="journey-tab">
      {items?.map((item, index) => (
        <React.Fragment key={index}>
          <JourneyTabItem
            key={index}
            icon={item.icon}
            iconDone={item.iconDone}
            title={item.title}
            route={item.route}
            name={item.name}
            tip={item.tip}
            isDealDependent={item.isDealDependent}
            isLastItem={index === items.length - 1}
          />
        </React.Fragment>
      ))}
    </div>
  );
}

export function WidgetJourneyTab() {
  return (
    <div className="small-journey-tab">
      {WidgetJourneyTabItems.map((item, index) => (
        <React.Fragment key={index}>
          <JourneyTabItem
            key={index}
            icon={item.icon}
            iconDone={item.iconDone}
            title={item.shortTitle || item.title}
            route={item.route}
            name={item.name}
            tip={item.tip}
            isDealDependent={item.isDealDependent}
            isLastItem={index === WidgetJourneyTabItems.length - 1}
            className="small-journey-tab"
          />
        </React.Fragment>
      ))}
    </div>
  );
}

export function FormJourneyTab() {
  const { token } = useAuth();
  const { block } = useParams();
  const config = useConfig()!;
  const quickFormsItems = [
    BlockRoute.TradeIn,
    BlockRoute.CreditApplication,
    BlockRoute.Documents,
    BlockRoute.QuickForms,
    BlockRoute.References,
  ];

  if (!config.isRetailing) return null;
  if (quickFormsItems.includes(block as BlockRoute)) {
    return <JourneyTab items={token ? quickFormsMenuItems : noAuthQuickFormsMenuItems} />;
  }

  return <div></div>;
}
