// import { FormHeader } from 'src/components/form/FormHeader';
import { returnAppointmentDefaultValues } from 'src/types/appointment';
import { AppointmentBlock } from 'src/types/appointment-block';
import { useConfig } from 'src/components/context/config';
import { FormActions } from 'src/components/form/FormActions';
import { useDealershipLocations } from 'src/helpers/useDealershipLocations';
import { useDealershipSchedule } from 'src/helpers/useDealershipSchedule';
import { appointmentSchema, location, returnCustomerSchema } from '../../../constants/schemas';
import { Notes } from 'src/components/inputs/Textarea';
import { AdditionalField } from 'src/widgets/AdditionalField';
import { SelectOptions } from 'src/widgets/SpaceWidget';
import { returnCustomerBlockDefaultValues } from 'src/types/customer-block';
import { CustomerFormattedBlock } from 'src/types/lead';
import { Customer } from 'src/components/steps/CustomerGroup';
import { AdditionalFields } from 'src/components/steps/AdditionalFields';
import { VehicleSelection, VehicleSelectionMode } from 'src/components/steps/VehicleSelection';
import { AppointmentType } from 'src/components/steps/AppointmentType';
import { AppointmentStatus } from 'src/components/steps/AppointmentStatus';
import { AppointmentTime } from 'src/components/steps/AppointmentTime';
import { FormBody, FormContentWrapper, FormWrapper, useSpaceForm } from 'src/components/form/Form';
import { useAuth } from 'src/components/context/AuthProvider';
import { ReactNode } from 'react';
import { useDeals } from 'src/components/context/DealsProvider';
import ModularVehicleCard from 'src/components/cards/vehicle/ModularVehicleCard';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { FormJourneyTab } from 'src/components/navigation/JourneyTab';
import { MultilocationRadioGroup } from 'src/components/steps/MultilocationRadioGroup';
import { useSchedule } from 'src/components/context/ScheduleProvider';
import { useVehicleVdp } from 'src/fetches/useSWRFetch';

interface AppointmentFormProps {
  name: string | null;
  appointment: AppointmentBlock | undefined;
  handleFormSubmit: (data: any) => Promise<void>;
  handleFormCancel: () => void;
  hideBackButton?: boolean;
  notes?: string | null;
  isReview?: boolean;
  additionalFields?: AdditionalField[];
  vehicle?: any;
  title: string | null;
  body: string | null;
  appointmentTypeOptions: SelectOptions;
  customer?: CustomerFormattedBlock;
  requirePhone: boolean;
  requireSms: boolean;
  submitText: string;
  customerFieldsAssumed?: boolean;
  isVehicleReadOnly?: boolean;
  isVehiclePickup?: boolean; // Special case for vehicle pickup: locked deal
  location?: string;
}

export function AppointmentBuilder({
  configuration,
  children,
}: {
  configuration: AppointmentFormProps;
  children?: ReactNode;
}) {
  const { deal } = useDeals();
  const config = useConfig()!;
  const { token } = useAuth();
  const activeForm = 'appointment';
  const { sessionCustomer } = useSessionContext();
  const dealershipLocations = useDealershipLocations();
  const { dealerTimezone, schedule } = useSchedule();
  const { vehicleVdp } = useVehicleVdp(deal?.vin);

  const { initialAppointmentTime, initialDate, isSavedOutOfHours } = useDealershipSchedule(
    dealerTimezone,
    schedule,
    configuration.appointment
  );

  const requireLocation = config.location !== null;

  const data = {
    ...returnCustomerBlockDefaultValues(token ? undefined : sessionCustomer || undefined, config),
    ...returnAppointmentDefaultValues(
      configuration.appointment,
      initialDate,
      initialAppointmentTime,
      configuration.vehicle,
      configuration.appointmentTypeOptions
    ),
  };

  // Add fixed location to the data. If location exists we should not show multilocation radio group
  if (config.location || config.location === null) {
    data.location = config.location;
  } else if (configuration.location) {
    data.location = configuration.location;
  } else if (config.locations.length === 1) {
    data.location = config.locations[0].name;
  }

  const baseSchema = returnCustomerSchema(
    configuration.requirePhone,
    false,
    configuration.requireSms
  );

  const schema = token
    ? {
        ...appointmentSchema,
        ...(requireLocation && { location }),
      }
    : {
        ...baseSchema,
        ...appointmentSchema,
        ...(requireLocation && { location }),
      };

  const form = useSpaceForm(schema, data);

  return (
    <FormWrapper
      handleFormSubmit={configuration.handleFormSubmit}
      handleFormCancel={configuration.handleFormCancel}
      steps={[activeForm]}
    >
      <FormJourneyTab />
      <FormContentWrapper form={form} id={activeForm}>
        <FormBody title={configuration.title} body={configuration.body || null} />
        {children}

        <AppointmentType form={form} selectOptions={configuration.appointmentTypeOptions} />
        {/* No editing of vehicle selection in test drive widget */}
        {configuration.isVehiclePickup ? (
          <ModularVehicleCard
            item={deal}
            status={deal.status}
            handleSelect={async () => {
              window.open(vehicleVdp?.url);
            }}
          />
        ) : (
          <VehicleSelection
            form={form}
            initialVehicle={configuration.vehicle}
            mode={
              configuration.isVehicleReadOnly
                ? VehicleSelectionMode.Browse
                : VehicleSelectionMode.Select
            }
          />
        )}
        <AppointmentTime form={form} isOutOfHours={isSavedOutOfHours ? true : false} />
        <MultilocationRadioGroup form={form} locations={dealershipLocations.dropdownList} />
        {(config.isRetailing && token) || configuration.customerFieldsAssumed ? null : (
          <Customer form={form} />
        )}
        <AdditionalFields form={form} additionalFields={configuration.additionalFields} />

        <AppointmentStatus appointment={configuration.appointment} form={form} />

        <Notes control={form.control} />
      </FormContentWrapper>
      <FormActions
        fullButton={configuration.isReview}
        alternateButtonText={configuration.submitText}
        hideBackButton={configuration.hideBackButton}
        isSubmitting={
          !config.isRetailing && config.forms?.lead?.explicitOptIn && !form.formState.isValid
        }
      />
    </FormWrapper>
  );
}
