/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Mileage } from '../inputs/Input';
import { Notes } from '../inputs/Textarea';
import { FormBody, useFormSubmit } from '../form/Form';

const validationSchema = Joi.object({
  mileage: Joi.number().min(0).required().messages({
    'number.base': `Mileage must be a number`,
    'string.empty': `Mileage is required`,
  }),
  notes: Joi.string().allow(''),
});

export const TradeinMileageGroup = () => {
  const { handleFormGroupSubmit, formValues: tradein, activeForm } = useFormSubmit();
  const form = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      mileage: tradein.mileage,
      notes: tradein.notes,
    },
  });

  if (activeForm !== 'tradeinMileageForm') {
    return null;
  }

  return (
    <form id="tradeinMileageForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="We need a few more details about your specific vehicle."
        body={`${tradein.year} ${tradein.make} ${tradein.model}`}
      />
      <Mileage control={form.control} />
      <Notes control={form.control} />
    </form>
  );
};
