// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { TermsGroup } from './TermsGroup';
import { Email, FirstName, LastName, MiddleName, Phone } from '../inputs/Input';
import { middleName, returnCustomerSchema } from '../../constants/schemas';
import { FormBody, useFormSubmit } from '../form/Form';
import { CreditApplicationActionBar } from '../form/CreditApplicationActionBar';
import { useEffect } from 'react';
import { useConfig } from '../context/config';

export interface PaymentInfoAndCommunicationGroupProps {
  requirePhone: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const PaymentInfoAndCommunicationGroup = ({
  requirePhone,
  setIsSubmitting,
}: PaymentInfoAndCommunicationGroupProps) => {
  const config = useConfig()!;
  const { activeForm, handleFormGroupSubmit, formValues: payment, isSubmitting } = useFormSubmit();
  const schema = returnCustomerSchema(requirePhone, false, config.forms?.lead?.requireSms ?? false);

  const form = useForm({
    resolver: joiResolver(Joi.object({ ...schema, middleName })),
    mode: 'onBlur',
    defaultValues: {
      firstName: payment.firstName,
      lastName: payment.lastName,
      middleName: payment.middleName,
      email: payment.email,
      phone: payment.phone,
      optoutCalls: payment.optoutCalls,
      optoutSms: payment.optoutSms,
      optoutEmails: payment.optoutEmails,
    },
  });

  const optoutSms = form.watch('optoutSms');

  useEffect(() => {
    if (isSubmitting || !config?.forms?.lead?.requireSms || config.isRetailing) {
      return;
    }
    if (activeForm === 'paymentInfoAndCommunicationForm' && !form.formState.isValid) {
      setIsSubmitting(true);
    } else if (!isSubmitting) {
      setIsSubmitting(false);
    }
  }, [config, activeForm, setIsSubmitting, optoutSms, isSubmitting, form.formState]);

  if (activeForm !== 'paymentInfoAndCommunicationForm') {
    return null;
  }

  return (
    <form id="paymentInfoAndCommunicationForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Provide your basic information"
        body="The personal information you provide will be securely kept and will only be used to process your loan application."
      />
      <CreditApplicationActionBar />
      <div className="u-display-grid grid--50">
        <FirstName control={form.control} />
        <MiddleName control={form.control} />
      </div>
      <LastName control={form.control} />
      <Email control={form.control} />
      <Phone control={form.control} />
      <TermsGroup control={form.control} />
    </form>
  );
};
