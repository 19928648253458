import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

import './Select.css';
import { Colors } from 'src/types/colors';

export type SelectOption = { value: string; label: string; disabled?: boolean };

interface SelectProps {
  name: string;
  control: Control<any>;
  label?: string;
  className?: string;
  placeholder?: string;
  hidePlaceholder?: boolean;
  disabled?: boolean;
  selectOptions?: SelectOption[];
  icon?: any;
}

export const Select = ({
  control,
  name,
  label,
  disabled,
  selectOptions,
  className,
  placeholder,
  icon,
  hidePlaceholder = false,
}: SelectProps) => {
  const {
    field: { value: fieldValue, ...field },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  const renderClass = useMemo(() => {
    const classes = className ? [className, 'select--container'] : ['select--container'];
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [className, invalid, isDirty, isSubmitted, isTouched]);

  const selectClass = useMemo(() => {
    const classes = ['select'];

    if (fieldValue) {
      classes.push('filled');
    }
    return classes.join(' ');
  }, [fieldValue]);

  if (selectOptions?.length === 0 || !selectOptions) {
    console.warn(
      'Oops! We do not have any select options to render. There is probably a config issue'
    );
    return null;
  }

  return (
    <div className={renderClass}>
      <div className="form--input--holder">
        {label ? (
          <label className="form--label" htmlFor={name}>
            <span className="form--label--bg"></span>
            <span className="form--label--text">{label}</span>
          </label>
        ) : null}
        <div className="select--holder">
          {icon && <FontAwesomeIcon icon={icon} className="select--icon" color={Colors.Blue1} />}
          <select className={selectClass} {...field} value={fieldValue} disabled={disabled}>
            {/* Need to display none to hide on iOS Safari. Will be visible if no options are selected (out of hours apt) */}
            {!hidePlaceholder && (
              <option hidden value="" style={{ display: 'none' }}>
                {placeholder || 'Select'}
              </option>
            )}
            {selectOptions
              ? selectOptions.map(({ value, label, disabled }) => (
                  <option key={value} value={value} disabled={disabled}>
                    {label}
                  </option>
                ))
              : null}
          </select>
          <div className="select--chevron--holder">
            <FontAwesomeIcon icon={faAngleDown} className="select--chevron" />
          </div>
        </div>
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="form--error">{message}</div>}
      />
    </div>
  );
};
