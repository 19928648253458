import FormErrorPage from 'src/components/form/FormShell';

export function ReviewError() {
  return (
    <FormErrorPage
      configuration={{
        title: "Review and Confirm isn't available here yet.",
        subtitle: 'Love this vehicle? Contact us directly to discuss payment options',
      }}
    />
  );
}
