import { useCallback, useMemo, useState } from 'react';
import { PaymentInfoGroup } from '../../../components/steps/PaymentInfoGroup';
import { PaymentInfoAndCommunicationGroup } from '../../../components/steps/PaymentInfoAndCommunicationGroup';
import { PaymentSensitiveGroup } from '../../../components/steps/PaymentSensitiveGroup';
import { PaymentOtherIncomeGroup } from '../../../components/steps/PaymentOtherIncomeGroup';
import { PaymentFinalizeGroup } from '../../../components/steps/PaymentFinalizeGroup';
import { PaymentInfoAndTypeGroup } from '../../../components/steps/PaymentInfoAndTypeGroup';
import { useDealershipLocations } from '../../../helpers/useDealershipLocations';
import { useConfig } from '../../../components/context/config';
import { PaymentFinalizeAndLocationGroup } from '../../../components/steps/PaymentFinalizeAndLocationGroup';
import { PaymentApplicationModeGroup } from '../../../components/steps/PaymentApplicationModeGroup';
import {
  ApplicationMode,
  CreditApplicationFormDefaultValues,
  CreditApplicationSubmitBlock,
  Signer,
} from 'src/types/creditapplication-block';
import { FormActions } from 'src/components/form/FormActions';
import { useAuth } from 'src/components/context/AuthProvider';
import { FormWrapper } from 'src/components/form/Form';
import { FormJourneyTab } from 'src/components/navigation/JourneyTab';
import { useLocation } from 'react-router-dom';
import { AddressRequirement } from './AddressRequirement';
import { EmploymentRequirement } from './EmploymentRequirement';
import { AddressType } from 'src/types/address';
import { requiresEmploymentDetails } from 'src/types/employmentStatus';
import { ReferenceRequirement } from './ReferenceRequirement';
import { DocumentRequirement } from './DocumentRequirement';
import { DocumentTag } from 'src/types/document-block';
import { useReferencesCount } from 'src/components/context/status/referenceCount';

export interface ApplicantFormSubmitProps {
  block: CreditApplicationSubmitBlock;
  customer: any;
  location: any;
  mode: any;
}

interface ApplicantFormGroupProps {
  type: Signer;
  handleApplicantFormCancel: () => void;
  handleApplicantFormSubmit: (data: CreditApplicationFormDefaultValues) => Promise<void>;
  payment: CreditApplicationFormDefaultValues;
  savedApplication?: boolean;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
}

export function ApplicantFormGroup({
  type,
  handleApplicantFormCancel,
  handleApplicantFormSubmit,
  payment,
  savedApplication,
  conditionalFields,
}: ApplicantFormGroupProps) {
  const config = useConfig()!;
  const location = useLocation();
  const dealershipLocations = useDealershipLocations();
  const [applicationMode, setApplicationMode] = useState<ApplicationMode>(ApplicationMode.Single);
  const { token } = useAuth();
  const referencesCount = useReferencesCount();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addressRequirement = useCallback(
    (data: any, index) => {
      // True means the field should be hidden;

      // Always show first address
      if (index === 0) {
        return false;
      }

      // Previous residence is not required if previous residence should be hidden, unless an address is already recorded for this address index
      if (config.forms?.creditApplication?.hidePreviousResidence) {
        return data.addresses[index].homeOwnership === '';
      }

      return Number(data.addresses[index - 1].homeLivingYears) >= 2;
    },
    [config]
  );

  // Employment test cases:
  // 1. If user is unemployed or retired, hide the group
  // 2. If user is employed, selfemployed or other, show employer, employer address and employer verification
  // 3. If user is employed less than 2 years:
  //   a. Add previous employer, previous employer address and previous employer verification
  //   b. If hide previous employment requirement is enabled skip the previous employer

  const employmentStatusRequirement = useCallback(
    (data: any, index: number) => {
      // True means the field should be hidden;
      // Never hide the first employment status question for now
      if (index === 0) {
        return false;
      }

      // Employment status is not required if previous employment status should be hidden, unless an employer is already recorded for this employment index
      if (config.forms?.creditApplication?.hidePreviousEmployment) {
        return data.employment[index].employer === '';
      }

      // If the user is unemployed or retired, don't ask about previous employment
      if (!requiresEmploymentDetails(data.employment[index - 1]?.employmentStatus)) {
        return true;
      }

      return Number(data.employment[index - 1].employmentYears) >= 2;
    },
    [config.forms]
  );

  const employmentRequirement = useCallback((data: any, index: number) => {
    // True means the field should be hidden;
    // ['employed', 'selfemployed', 'other'].includes(employmentStatus);
    const requiresDetails = requiresEmploymentDetails(data.employment[index].employmentStatus);
    return !requiresDetails;
  }, []);

  const conditionalFieldsMemo = useMemo(() => {
    let fields = conditionalFields ?? {};

    for (let i = 0; i < 2; i++) {
      // add function based on the index of the address
      fields[`${AddressType.Customer}${i}`] = (data: any) => addressRequirement(data, i);
      fields[`addressVerification${AddressType.Customer}${i}`] = (data: any) =>
        addressRequirement(data, i);
      fields[`addressOwnershipForm${i}`] = (data: any) => addressRequirement(data, i);
      fields[`addressPaymentForm${i}`] = (data: any) => addressRequirement(data, i);
    }

    for (let i = 0; i < 2; i++) {
      // Always show first employment
      fields[`employmentStatusForm${i}`] = (data: any) => employmentStatusRequirement(data, i);
      fields[`employerForm${i}`] = (data: any) => employmentRequirement(data, i);
      fields[`${AddressType.Employer}${i}`] = (data: any) => employmentRequirement(data, i);
      fields[`addressVerification${AddressType.Employer}${i}`] = (data: any) =>
        employmentRequirement(data, i);
    }

    return fields;
  }, [conditionalFields, addressRequirement, employmentRequirement, employmentStatusRequirement]);

  const steps = useMemo(() => {
    let referenceCount = config.requireReferenceCount ?? 0;
    let steps: string[] = [
      'paymentInfoForm',
      'paymentInfoAndTypeForm',
      'paymentInfoAndCommunicationForm',
      'paymentSensitiveForm',
    ];

    for (let i = 0; i < 2; i++) {
      steps.push(`${AddressType.Customer}${i}`);
      steps.push(`addressVerification${AddressType.Customer}${i}`);
      steps.push(`addressOwnershipForm${i}`);
      steps.push(`addressPaymentForm${i}`);
    }

    for (let i = 0; i < 2; i++) {
      steps.push(`employmentStatusForm${i}`);
      steps.push(`employerForm${i}`);
      steps.push(`${AddressType.Employer}${i}`);
      steps.push(`addressVerification${AddressType.Employer}${i}`);
    }

    steps.push('paymentOtherIncomeForm');

    //Only add these extra qeustions for primary signer
    if (type === Signer.Primary && !token) {
      for (let i = 0; i < referenceCount; i++) {
        steps.push(`referenceInfoForm${i}`);
      }

      // For unauthorized app, add income proof step if required
      if (config.requireIncomeProof) {
        steps.push(`documentForm${DocumentTag.Income}`);
      }

      // For unauthorized app, add residence step if required
      if (config.requireResidenceProof) {
        steps.push(`documentForm${DocumentTag.Residence}`);
      }
    }

    // Last Steps
    steps.push('paymentFinalizeForm');
    steps.push('paymentFinalizeAndLocationForm');
    steps.push('paymentApplicationModeForm');

    return steps;
  }, [
    config.requireReferenceCount,
    config.requireIncomeProof,
    config.requireResidenceProof,
    token,
    type,
  ]);

  return (
    <FormWrapper
      handleFormSubmit={async (data: any) => {
        try {
          await handleApplicantFormSubmit(data);
        } catch (e) {
          console.log(e);
        }
      }}
      handleFormCancel={handleApplicantFormCancel}
      multistepFormValues={{ ...payment, applicationMode }}
      steps={steps}
      conditionalFields={conditionalFieldsMemo}
      savedForm={savedApplication}
    >
      {config.isRetailing ? (
        <>
          <FormJourneyTab />
        </>
      ) : null}
      <div className="block-container--content">
        {/* Three First step options: Info, Info and Communication, Info and Type */}
        <PaymentInfoAndCommunicationGroup
          setIsSubmitting={setIsSubmitting}
          requirePhone={!token ? config.forms?.lead?.requirePhone ?? true : false}
        />
        <PaymentInfoAndTypeGroup />
        <PaymentInfoGroup />
        <PaymentSensitiveGroup />
        <AddressRequirement numberOfAddresses={2} />
        <EmploymentRequirement numberOfEmployers={2} />
        <PaymentOtherIncomeGroup />
        {/* Reference count is only valid in unauthorized form bc otherwise they are handled in a separate form */}
        <ReferenceRequirement
          references={token || type === Signer.CoSigner ? 0 : referencesCount}
        />
        <DocumentRequirement />
        <PaymentFinalizeGroup isPrimarySigner={type === Signer.Primary} />
        <PaymentFinalizeAndLocationGroup locations={dealershipLocations} />
        <PaymentApplicationModeGroup setApplicationMode={setApplicationMode} />
      </div>
      <FormActions
        isSubmitting={isSubmitting}
        showSkipButton={location.pathname !== '/form/payment-method'} // to do: is this the most DRY way to do this?
        hideBackButton={location.pathname === '/form/payment-method' && type === Signer.Primary}
        // alternateButtonText={
        //   formSteps[activeStepIndex] === StepMap.ApplicationMode &&
        //   applicationMode === ApplicationMode.Joint
        //     ? 'Next'
        //     : undefined
        // }
      />
    </FormWrapper>
  );
}
