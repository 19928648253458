import { ReferenceInfoGroup } from '../../../components/steps/ReferenceInfoGroup';
import { AddressGroup } from '../../../components/steps/AddressGroup';
import { FormActions } from '../../../components/form/FormActions';
import { AddressType } from 'src/types/address';
import { FormWrapper } from 'src/components/form/Form';
import { AddressVerificationGroup } from 'src/components/steps/AddressVerificationGroup';
import { FormJourneyTab } from 'src/components/navigation/JourneyTab';

interface ReferenceFormProps {
  index: number;
  reference: any;
  handleFormSubmit: (data: any) => Promise<void>;
  handleFormCancel: () => void;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
}

export function ReferenceForm({
  index,
  reference,
  conditionalFields,
  handleFormCancel,
  handleFormSubmit,
}: ReferenceFormProps) {
  // References are always submitted one at a time
  const StepMap = {
    Info: `referenceInfoForm${index}`,
    Address: `${AddressType.Reference}${0}`,
    AddressVerification: `addressVerification${AddressType.Reference}${0}`,
  };
  const formSteps = [StepMap.Info, StepMap.Address, StepMap.AddressVerification];

  return (
    <FormWrapper
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={handleFormCancel}
      steps={formSteps}
      multistepFormValues={reference}
      conditionalFields={conditionalFields}
    >
      <FormJourneyTab />
      <div className="block-container--content">
        <ReferenceInfoGroup index={index} />
        <AddressGroup index={0} type={AddressType.Reference} />
        <AddressVerificationGroup index={0} type={AddressType.Reference} />
      </div>
      <FormActions showSkipButton={true} />
    </FormWrapper>
  );
}
