import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './Selector.css';
import React, { useMemo, useRef } from 'react';
import { Label } from '../text/Label';
import { Colors } from 'src/types/colors';
import { Thumbnail, ThumbnailProps } from './Thumbnail';
import { SelectorText } from './SelectorText';
import ActionMenu from '../floating/popover/ActionMenu';
import { useAuth } from '../context/AuthProvider';
import { useRecoilValue } from 'recoil';
import { loadingState } from 'src/state/loading';
import { ComponentState } from 'src/types/component';
import { BlockRoute } from 'src/types/blocks';
import { ComponentStateProvider } from '../context/ComponentStateProvider';
import { useSelectorBox } from '../hooks/useSelectorBox';
import { AbsoluteTooltip } from '../floating/popover/AbsoluteTooltip';
import { useMobileContext } from '../context/MobileProvider';
import { useSelectorContainer } from '../hooks/useSelectorContainer';

export type SelectorSize = 'small' | 'medium' | 'large';
export type SelectorType = 'button' | 'link' | 'div' | 'multi' | 'accordion' | 'menu';
export type SelectorListType = 'card' | 'row';

interface MultiuseSelectorProps {
  type: SelectorType;
  listType?: SelectorListType;
  size?: SelectorSize;
  to?: string;
  thumbnail?: ThumbnailProps;
  image?: string;
  alt?: string;
  title?: string;
  subtitle?: string | JSX.Element;
  label?: { label: string; labelColor: Colors }[];
  labelColor?: Colors;
  btnIcon?: any;
  handleClick?: () => void;
  children?: any;
  vehicleImage?: React.ReactNode;
  linkState?: any;
  menuItems?: any;
  state?: ComponentState[];
  height?: string;
  block?: BlockRoute;
  tooltip?: { initialOpen: boolean; text: string };
}

export function Selector({
  type,
  to = '/',
  listType = 'card',
  thumbnail,
  title,
  subtitle,
  label,
  labelColor,
  btnIcon,
  handleClick,
  children,
  linkState,
  menuItems,
  state = [],
  height = 'auto',
  block,
  size = 'large',
  tooltip = undefined,
}: MultiuseSelectorProps) {
  const { token } = useAuth();
  const isMobile = useMobileContext();
  const isLoading = useRecoilValue(loadingState);
  const containerRef = useRef<HTMLDivElement>(null);
  const hasAction = (menuItems && menuItems.length > 0) || (handleClick && btnIcon);
  const boxStyles = useSelectorBox(listType, size, state);
  const containerStyles = useSelectorContainer(size, type, height, children);

  const labels = useMemo(() => {
    let labels: { label: string; labelColor: Colors }[] = [];
    if (label && typeof label === 'string' && labelColor) {
      labels.push({ label, labelColor });
    }

    if (Array.isArray(label)) {
      labels = label;
    }
    return labels;
  }, [label, labelColor]);

  const renderContainerClass = useMemo(() => {
    const classes = ['selector'];

    for (const ComponentState of state) {
      classes.push(ComponentState);
    }

    classes.push(`is-${type}-selector`);
    classes.push(`is-${block}-selector`);
    classes.push(`is-${size}`);

    // All vehicle selectors have a wide thumbnail
    if (thumbnail?.isWide) classes.push('is-vehicle-selector');
    if (hasAction) classes.push('is-action-selector');
    if (children) classes.push('is-with-bottom');
    if (isLoading) classes.push('is-loading');
    return classes.join(' ');
  }, [isLoading, type, children, thumbnail, state, hasAction, block, size]);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (handleClick) {
      handleClick();
    }
  };

  const renderBottom = () => {
    if (children) {
      return <div className="selector--bottom">{children}</div>;
    }
    return null;
  };

  const renderContent = () => (
    <div className="selector--top">
      <Thumbnail thumbnail={thumbnail} listType={listType} />
      <SelectorText title={title} subtitle={subtitle} />

      {labels.map(item => (
        <Label key={item.label} color={item.labelColor} text={item.label} />
      ))}

      {type === 'menu' && children}

      {btnIcon && (
        <FontAwesomeIcon
          icon={btnIcon}
          className="selector--btn is-icon-only"
          size="1x"
          height={32}
          width={32}
          color={Colors.Black}
        />
      )}
    </div>
  );

  const renderTooltip = () => (
    <>
      {tooltip && state.includes(ComponentState.Disabled) && !isMobile && (
        <AbsoluteTooltip initialOpen={tooltip?.initialOpen} text={tooltip?.text} />
      )}
    </>
  );

  const renderActionMenu = () => (
    <>
      {token && menuItems && !state.includes(ComponentState.ReadOnly) && (
        <ActionMenu containerRef={containerRef} menuItems={menuItems} />
      )}
    </>
  );

  const renderElement = () => {
    // Div item is read only, no actions should be taken
    if (type === 'div') {
      return (
        <div className="selector--box" {...boxStyles}>
          {renderContent()}
        </div>
      );
    }

    if (type === 'button') {
      return (
        <>
          <button type="button" className="selector--box" onClick={onClick} {...boxStyles}>
            {renderContent()}
            {renderBottom()}
          </button>
        </>
      );
    }

    if (type === 'multi') {
      return (
        <>
          <Link to={to} className="selector--box" state={linkState} {...boxStyles}>
            {renderContent()}
            {renderBottom()}
          </Link>
          {renderActionMenu()}
        </>
      );
    }

    if (type === 'accordion') {
      return (
        <>
          <button type="button" className="selector--box" onClick={onClick} {...boxStyles}>
            {renderContent()}
            {renderBottom()}
          </button>
        </>
      );
    }

    //Menu / link
    return (
      <>
        <Link to={to} className="selector--box" state={linkState} {...boxStyles}>
          {renderContent()}
        </Link>
        {renderTooltip()}
      </>
    );
  };
  // Conditional rendering based on props

  return (
    <ComponentStateProvider state={state}>
      <div className={renderContainerClass} ref={containerRef} {...containerStyles}>
        {renderElement()}
      </div>
    </ComponentStateProvider>
  );
}
