import { AccordionProvider } from 'src/components/context/AccordionProvider';
import { NestedAccordion } from 'src/components/selectors/NestedAccordion';

export function SummaryItem({
  items,
  title,
  subtitle = undefined,
  label,
}: {
  items: { title: string; label?: string }[];
  title: string;
  subtitle?: string;
  label?: string;
}) {
  return (
    <AccordionProvider
      itemHeight={subtitle ? 64 : 40}
      nestedItemHeight={32}
      nestedItemCount={items.length}
    >
      <NestedAccordion items={items} title={title} subtitle={subtitle} label={label} />
    </AccordionProvider>
  );
}
