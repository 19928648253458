import { faAngleDown, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { useAccordion } from '../context/AccordionProvider';
import { Colors } from 'src/types/colors';
import { Subtask } from '../navigation/Subtask';
import { Selector } from './Selector';
import { ComponentState } from 'src/types/component';

export function NestedAccordion({
  items,
  title,
  subtitle = undefined,
  label,
}: {
  items: { title: string; label?: string }[];
  title: string;
  subtitle?: string;
  label?: string;
}) {
  const { isMenuOpen: isOpen, toggleMenu: toggleOpen, height } = useAccordion();

  return (
    <Selector
      listType="row"
      type={items?.length ? 'accordion' : 'div'}
      title={title}
      subtitle={subtitle}
      handleClick={toggleOpen}
      label={label ? [{ label, labelColor: Colors.Gray6 }] : undefined}
      labelColor={Colors.Gray6 as Colors}
      btnIcon={items.length ? (isOpen ? faAngleDown : faAngleRight) : undefined}
      state={isOpen ? [ComponentState.Open] : []}
      height={isOpen ? `${height}px` : `${height}px`}
    >
      {isOpen && (
        <div className="subtask-item--list">
          {items.map((item, index) => (
            <Subtask
              key={index}
              title={item.title}
              label={item.label}
              labelColor={Colors.Gray6}
              extender={index !== items.length - 1}
            />
          ))}
        </div>
      )}
    </Selector>
  );
}
