/* eslint-disable react-hooks/exhaustive-deps */
import { SelectOption } from '../controls/Select';
import { useConfig } from '../context/config';
import { UseFormReturn } from 'react-hook-form';
import { DealerDropdown } from '../controls/DealerDropdown';
import { useEffect } from 'react';
import { Radio } from '../controls/Radio';

export function MultilocationRadioGroup({
  form,
  locations,
  allowDealerDropdown,
  hideText,
}: {
  form: UseFormReturn<any>;
  locations: SelectOption[];
  allowDealerDropdown?: boolean;
  hideText?: boolean;
}) {
  const config = useConfig()!;
  // Should return a dealership location name: Patterson Chrysler Dodge Jeep - Marshall
  const { location } = form.watch();

  //Use Effect to reset dealerId when dealershipLocation changes
  useEffect(() => {
    if (location && allowDealerDropdown) {
      form.setValue('dealerId', '');
    }
  }, [location]);

  // Don't show this group if location is pre-determined
  if (locations.length === 1) {
    return null;
  }

  return (
    <>
      {config.location || config.location === null ? null : (
        <div className="form--multilocation">
          {hideText ? null : (
            <>
              <div className="location--subtitle">Which of our locations do you want to visit?</div>
              <div className="location--subtitle u-color-gray-2">
                Not sure?{' '}
                <a
                  href={`https://${config.websiteDomain}/location/`}
                  target="_blank"
                  className="location--subtitle--link"
                  rel="noreferrer"
                >
                  Check out our list of locations
                </a>
              </div>
            </>
          )}
          <div className="radio--horizontal-scroll u-mar-top-8">
            {locations.map(item => (
              <Radio
                className="radio--horizontal-scroll--item"
                control={form.control}
                key={item.value}
                value={item.value}
                name="location"
                label={item.label}
                // .... config check and...
                // every time user selects a vehicle, we update the location. if location differences are not allowed, we disable other locations
                disabled={
                  config.isAppointmentLocationLockedByVehicle &&
                  form.getValues('vin') &&
                  form.getValues('location') !== item.value
                }
              />
            ))}
          </div>
        </div>
      )}
      {/* Special dropdown to select a sales agent on credit app */}
      {config.forms?.creditApplication?.showDealerDropdown && allowDealerDropdown && (
        <DealerDropdown form={form} />
      )}
    </>
  );
}
