import { faCheckCircle, faQuestionCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { AppointmentBlock } from './appointment-block';
import { zonedTimeToUtc } from 'date-fns-tz';
import { CustomerBase } from './customer-block';
import { format } from 'date-fns';
import { siteDateFormat } from 'src/constants/validation';
import { SelectOptions } from 'src/widgets/SpaceWidget';

export enum AppointmentType {
  Sales = 'sales',
  Service = 'service',
  Delivery = 'delivery',
  Other = 'other',
}

export enum AppointmentStatus {
  Set = 'set',
  Confirmed = 'confirmed',
  Declined = 'declined',
  Completed = 'completed',
  Missed = 'missed',
}

export const AppointmentStatusList = [
  {
    value: AppointmentStatus.Confirmed,
    label: 'Yes',
    icon: faCheckCircle,
    iconColor: 'green',
  },
  { value: AppointmentStatus.Set, label: 'Maybe', icon: faQuestionCircle, iconColor: 'orange' },
  { value: AppointmentStatus.Declined, label: 'No', icon: faTimesCircle, iconColor: 'red' },
];

export function returnAppointmentDefaultValues(
  appointment: AppointmentBlock | undefined,
  initialDate: Date,
  initialAppointmentTime: string,
  vehicle: any,
  appointmentTypeOptions?: SelectOptions
): ModifiedAppointment {
  return {
    notes: appointment?.notes ?? '',
    id: appointment?.id ?? undefined,
    calendarAppointmentDate: initialDate,
    inputAppointmentDate: format(initialDate, siteDateFormat),
    time: initialAppointmentTime,
    type:
      appointment?.type ||
      appointmentTypeOptions?.find(item => item.isDefault)?.value ||
      AppointmentType.Sales,
    status: appointment?.status ?? AppointmentStatus.Confirmed,
    vin: appointment?.vin || vehicle?.vin || '',
    location: appointment?.location ?? '',
  };
}

// Appointment modified to fit form inputs
export interface ModifiedAppointment {
  id: string | undefined;
  notes: string;
  calendarAppointmentDate: Date;
  inputAppointmentDate: string;
  time: string;
  type: string;
  status: AppointmentStatus;
  vin: string | null;
  location: string;
}

export interface AppointmentAndCustomer extends CustomerBase, ModifiedAppointment {}

export interface AppointmentPayload {
  notes: string | null;
  type: AppointmentType;
  status: AppointmentStatus;
  appointmentAt: string;
}

export interface AppointmentBlockSubmit extends AppointmentPayload {
  vin: string | null;
  location: string;
}

// for appointment block
export function formatAppointmentBlock(
  data: ModifiedAppointment,
  appointmentAt: string,
  comments?: string[]
): AppointmentPayload {
  return {
    type: data.type === '' ? AppointmentType.Sales : (data.type as AppointmentType), //default to sales
    status: data.status,
    notes: comments?.length ? comments?.join(' ') : null,
    appointmentAt: appointmentAt,
  };
}

export function formatAppointmentTime(time: string, date: Date, dealerTimezone: any): string {
  const timeString = time;
  const [hours, mins] = timeString.split(':');

  const combinedDate = new Date(date.setHours(parseInt(hours), parseInt(mins)));
  const utcDate = zonedTimeToUtc(combinedDate, dealerTimezone).toISOString();

  return utcDate;
}

export function returnAppointmentBlock(data: ModifiedAppointment, dealerTimezone: any) {
  const utcDateString = formatAppointmentTime(
    data.time,
    data.calendarAppointmentDate,
    dealerTimezone
  );

  const block: AppointmentPayload = formatAppointmentBlock(data, utcDateString, [data.notes]);

  const appointmentBlock: AppointmentBlockSubmit = {
    ...block,
    vin: data.vin || null,
    location: data.location,
  };

  return appointmentBlock;
}
