import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumericFormat } from 'react-number-format';
import { Colors } from 'src/types/colors';
import { LottieLoader } from '../LottieLoader';
import { useMobileContext } from '../context/MobileProvider';

export function DownPaymentInput({
  value,
  isDeskingLocked,
  badge,
  handleChange,
  isDebounceLoading,
  max,
}: {
  value: number;
  isDeskingLocked: boolean;
  badge?: string;
  handleChange?: (e: any) => void;
  isDebounceLoading: boolean;
  max: number;
}) {
  const isMobile = useMobileContext();

  return (
    <div className="input--container">
      <div className="form--input--holder">
        <label className="form--label" htmlFor="downPayment">
          <span className="form--label--bg"></span>
          <span className="form--label--text">Down Payment</span>
        </label>
        <div className={`form--input--relative ${isDeskingLocked ? ' is-read-only' : ''}`}>
          <FontAwesomeIcon icon={faDollarSign} className="form--input--icon" />
          <NumericFormat
            className={`form--input--text ${isDeskingLocked ? ' is-read-only' : ''}`}
            value={value}
            placeholder="1200"
            name="downPayment"
            onChange={handleChange}
            disabled={isDeskingLocked}
            allowLeadingZeros={false}
            isAllowed={values => {
              const { floatValue } = values;
              return floatValue ? floatValue <= max : true;
            }}
          />
          {badge && !isMobile && (
            <div className="form--input--badge">
              {isDebounceLoading ? (
                <LottieLoader color={Colors.Blue1} height={24} width={24} />
              ) : (
                badge
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
