import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'src/components/context/AuthProvider';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useConfig } from 'src/components/context/config';
import { useApiFetch } from 'src/fetches/useApiFetch';
import { UnauthorizedSubmitCallback, useHandleSuccess } from 'src/handlers/useHandleFormSuccess';
import { useDealershipLocations } from 'src/helpers/useDealershipLocations';
import { TradeinFormGroup } from 'src/pages/deal/trade-in/TradeinFormGroup';
import {
  TradeInPayoffType,
  TradeInResponseBlock,
  returnTradeinFormDefaultValues,
} from 'src/types/tradein-block';
import { UnauthorizedTradeinBlockSubmit, WidgetForm } from 'src/widgets/SpaceWidget';

export function TradeinLeadForm({
  vin,
  hideBackButton,
}: {
  vin: string | null;
  hideBackButton?: boolean;
}) {
  const config = useConfig()!;
  const handleSuccess: UnauthorizedSubmitCallback = useHandleSuccess(
    config.hostMessage!,
    WidgetForm.Tradein
  );
  const apiFetch = useApiFetch();
  const {
    sessionCustomer,
    sessionTradeins,
    setSessionCustomer,
    setSessionVehicle,
    setSessionLocation,
    setSessionTradeins,
    setRequireTradeIns,
  } = useSessionContext();
  const { token } = useAuth();
  const dealershipLocations = useDealershipLocations();
  const data = returnTradeinFormDefaultValues(sessionCustomer, config);

  const handleFormSubmit = useCallback(
    async ({ blocks, customer, location }: UnauthorizedTradeinBlockSubmit) => {
      const tradeins = sessionTradeins?.length ? sessionTradeins : [];
      try {
        const {
          dealId,
          blocks: responseBlocks,
          customerId,
        }: {
          dealId: string;
          blocks: TradeInResponseBlock[];
          customerId: string;
        } = await apiFetch('unauthorized/tradein', {
          method: 'POST',
          body: JSON.stringify({
            blocks,
            customer,
            location,
          }),
        });
        setSessionCustomer(customer);
        setSessionVehicle({ vin });
        setSessionLocation(location);
        //copy sessionTradins and add the new trade in
        setSessionTradeins([
          ...tradeins,
          { ...responseBlocks[0], id: responseBlocks[0].customerId },
        ]);
        setRequireTradeIns(true);

        await handleSuccess(customer.email, dealId, customerId, responseBlocks[0]);
      } catch (error: any) {
        toast.error(error.message);
        throw error.message;
      }
    },
    [
      apiFetch,
      handleSuccess,
      vin,
      setSessionCustomer,
      setSessionVehicle,
      setSessionLocation,
      setSessionTradeins,
      setRequireTradeIns,
      sessionTradeins,
    ]
  );

  // Add fixed location to the data. If location exists we should not show multilocation radio group
  if (config.location || config.location === null) {
    data.location = config.location;
  }

  return (
    <TradeinFormGroup
      tradein={data}
      navigation={null}
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={() => {}}
      hideBackButton={hideBackButton}
      // conditions that should hide a specific field
      conditionalFields={{
        tradeinPayoffLenderForm: (data: any) => {
          return data.payoffType === TradeInPayoffType.Neither;
        },
        tradeinPhotoForm: () => !token || !config.isRetailing,
        infoCommunicationForm: () =>
          (token && config.isRetailing) || dealershipLocations.isMultipleLocations,
        infoCommunicationLocationForm: () => {
          return !dealershipLocations.isMultipleLocations;
        },
      }}
    />
  );
}
