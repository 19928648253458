import { Splide, SplideTrack } from '@splidejs/react-splide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@splidejs/react-splide/css/core';
import './Carousel.css';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { useDeals } from '../../components/context/DealsProvider';
import { Reservations } from 'src/pages/vehicles/Reservations';
import { Favorites } from 'src/pages/vehicles/Favorites';
import { AddVehicle } from '../cards/AddVehicle';
import { VehiclesWithoutDeals } from 'src/pages/vehicles/VehiclesWithoutDeals';

export function Carousel() {
  const { activeDealId } = useDeals();

  return (
    <Splide
      options={{
        type: 'loop',
        perPage: 1,
        fixedHeight: '270px',
        gap: '8px',
        pagination: false,
        breakpoints: {
          767: {
            destroy: true,
          },
        },
        autoWidth: true,
      }}
      key={activeDealId}
      hasTrack={false}
      aria-labelledby="vehicle-carousel"
    >
      <div style={{ position: 'relative' }}>
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev icon-btn">
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button className="splide__arrow splide__arrow--next icon-btn">
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
        <SplideTrack>
          <Reservations />
          <Favorites />
          <VehiclesWithoutDeals />
          <AddVehicle />
        </SplideTrack>
      </div>
    </Splide>
  );
}
