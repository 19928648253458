/* eslint-disable react-hooks/exhaustive-deps */
import { useSwrDeals, useFavorites } from '../../fetches/useSWRFetch';
import PageLoader from '../../components/PageLoader';
import PageError from '../../components/PageError';
import { useDocTitle } from '../../helpers/global-document-title';
import { useContext, useEffect } from 'react';
import { useDeals } from 'src/components/context/DealsProvider';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { useSearchParams } from 'react-router-dom';

export function VehiclesIndexPage() {
  useDocTitle('Vehicles');
  const { activeDealId } = useDeals();
  const { navigateNextUnfinishedBlock } = useContext(NavigationContext);
  const { deals, dealsIsLoading, dealsIsError } = useSwrDeals();
  const { favoritesIsLoading, favoritesIsError } = useFavorites();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (deals && activeDealId && !searchParams.size) {
      navigateNextUnfinishedBlock();
    }
  }, [deals, activeDealId]);

  if (favoritesIsLoading || dealsIsLoading) return <PageLoader />;
  if (favoritesIsError || dealsIsError) return <PageError />;

  // Return nothing but open the vehicles menu in the VehicleSelector component
  return <PageLoader />;
  // <VehiclePurchase
  //   config={config}
  //   deals={deals}
  //   dealsCreate={dealsCreate}
  //   blockId={params.blockId}
  //   favorites={favorites}
  //   initialTabState={initialTabState}
  // />
}
