import { Colors } from 'src/types/colors';
import { useConfig } from '../../context/config';
import { PopoverArrow, PopoverDescription, PopoverContent, PopoverHeading } from './Popover';

export function Tooltip({
  text,
  color = Colors.Blue1,
  title,
  containerRef,
  textClassName = 'popover-tooltip--text',
}: {
  text: string;
  color?: Colors;
  title?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
  textClassName?: string;
}) {
  const config = useConfig()!;

  return (
    <div ref={containerRef} className="u-hide-mobile-block">
      <PopoverContent
        className={`popover-tooltip ${color === Colors.Blue1 ? 'mod-blue' : ''}`}
        containerRef={config.isRetailing ? null : containerRef}
        initialFocus={2}
      >
        <PopoverArrow className="popover-tooltip--arrow" />
        {title && (
          <PopoverHeading className="popover-tooltip--heading" style={{ background: color }}>
            {title}
          </PopoverHeading>
        )}
        <PopoverDescription className="popover-tooltip--description">
          <div className={textClassName} style={{ background: color }}>
            {text}
          </div>
        </PopoverDescription>
      </PopoverContent>
    </div>
  );
}
