// /* eslint-disable react-hooks/exhaustive-deps */
import { UseFormReturn } from 'react-hook-form';
import { Email } from '../inputs/Input';
import { FormBody, useFormSubmit, useSpaceForm } from '../form/Form';
import toast from 'react-hot-toast';
import { email } from 'src/constants/schemas';
import { useLocation } from 'react-router-dom';
import { LoginState } from '../authentication/LoginForm';

interface EmailGroupProps {
  form: UseFormReturn<any>;
}

export const EmailGroup = ({ form }: EmailGroupProps) => {
  return <Email control={form.control} />;
};

export const EmailForm = ({ handleEmailCallback }) => {
  const { handleFormGroupSubmit, formValues, activeForm } = useFormSubmit();
  const location = useLocation();

  const onSubmit = async (data: any) => {
    try {
      await handleEmailCallback(data.email, () => {
        toast.success('A sign in code was sent to your email!');
        handleFormGroupSubmit(data);
      });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const form = useSpaceForm(
    { email },
    {
      email: formValues.email || '',
    }
  );

  if (activeForm !== 'loginEmailForm') {
    return null;
  }

  return (
    <form id="loginEmailForm" onSubmit={form.handleSubmit(onSubmit)}>
      <FormBody
        title={
          location.state === LoginState.Verify
            ? 'You need to verify your email'
            : 'Get Your Login Code to Get Started'
        }
        body={
          location.state === LoginState.Verify
            ? 'In order to protect your information, you will need to verify your email before you can access this saved information.'
            : 'Dealing with passwords is a pain. Enter your email and we will send you a temporary sign in code.'
        }
      />
      <div className="u-mar-top-16">
        <EmailGroup form={form} />
      </div>
    </form>
  );
};
