import { Control } from 'react-hook-form';
import { Textarea } from '../controls/Textarea';

export function Notes({ control }: { control: Control<any> }) {
  return (
    <Textarea
      control={control}
      name="notes"
      label="Note"
      placeholder="Additional information (optional)"
    />
  );
}

export function Comments({ control }: { control: Control<any> }) {
  return (
    <Textarea control={control} name="comments" label="Message" placeholder="Message (optional)" />
  );
}
