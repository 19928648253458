import {
  returnVehiclePhoto,
  returnVehiclePrice,
  returnVehicleYearMakeModel,
} from 'src/types/vehicle';
import { VehicleSelectorAccordion } from '../selectors/VehicleSelectorAccordion';
import { Colors } from 'src/types/colors';
import { UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfig } from '../context/config';
import { VehicleCardImage } from '../cards/vehicle/VehicleCardImage';
import { FullBlockRoute } from 'src/types/blocks';
import { useSessionContext } from '../context/SessionProvider';
import { SingleDealResponse, UnauthorizedDealResponse } from 'src/types/deal';
import { useVehicleVdp, useVehicleVinSearch } from 'src/fetches/useSWRFetch';
import { useAuth } from '../context/AuthProvider';
import { Selector } from '../selectors/Selector';
import { ComponentState } from 'src/types/component';
import { useDeals } from '../context/DealsProvider';

export enum VehicleSelectionMode {
  Add = 'add', // Add to unauthorized "deal"
  Browse = 'browse', // Browse VDP of vehicle
  Select = 'select', // Select for appointment
  Chat = 'chat', // Chat with dealer about vehicle
}

export function VehicleSelectionWithVinSearch({ vin }) {
  const { searchVehicle } = useVehicleVinSearch(vin);

  if (!searchVehicle) {
    return null;
  }

  return (
    <VehicleSelection
      initialVehicle={searchVehicle}
      mode={VehicleSelectionMode.Add}
      listType="row"
    />
  );
}

export function VehicleSelection({
  form,
  initialVehicle,
  mode,
  handleSubmit,
  isActive,
  listType = 'card',
}: {
  form?: UseFormReturn<any>;
  initialVehicle: SingleDealResponse | UnauthorizedDealResponse;
  mode: VehicleSelectionMode;
  handleSubmit?: any;
  isActive?: boolean;
  listType?: 'card' | 'row';
}) {
  const location = useLocation();
  const config = useConfig()!;
  const { setSessionVehicle } = useSessionContext();
  const navigate = useNavigate();
  const { token } = useAuth();
  const price = token
    ? initialVehicle?.sellingPrice ?? initialVehicle?.price
    : initialVehicle?.price ?? (initialVehicle as UnauthorizedDealResponse)?.msrp;
  const { vehicleVdp } = useVehicleVdp(initialVehicle?.vin);
  const { dealMutate } = useDeals();

  // If no vehicle is known and we aren't in retailing mode, return null
  if (initialVehicle === null && !config.isRetailing) {
    return null;
  }

  // If a vehicle is expected, wait for the search vehicle to return.
  if (initialVehicle && !initialVehicle?.vin) {
    return null;
  }

  if (
    (!initialVehicle || initialVehicle?.vin === null) &&
    !location.pathname.includes('appointments/new')
  ) {
    return null;
  }

  if (mode !== VehicleSelectionMode.Select && initialVehicle) {
    return (
      <Selector
        type="button"
        thumbnail={{
          element: (
            <VehicleCardImage
              imageSrc={returnVehiclePhoto(initialVehicle)} // Gets the vehicle image as provided by the website on launch
              className="selector--img"
            />
          ),
          isWide: true,
        }}
        title={returnVehicleYearMakeModel(initialVehicle)}
        subtitle={initialVehicle.trim}
        handleClick={async () => {
          switch (mode) {
            case VehicleSelectionMode.Chat:
              handleSubmit(initialVehicle.vin, 'payments');
              break;
            case VehicleSelectionMode.Browse:
              window.open(vehicleVdp?.url);
              break;
            case VehicleSelectionMode.Add:
              setSessionVehicle({ vin: initialVehicle.vin });
              await dealMutate();
              navigate(FullBlockRoute.PaymentOptions, { replace: true }); // refresh page so sessionStorage changes take effect
              break;
            default:
              return;
          }
        }}
        state={isActive ? [ComponentState.Active] : undefined}
        label={
          config.packages?.hideprices
            ? undefined
            : [
                {
                  label: returnVehiclePrice(price ?? null) || 'Price Not Available',
                  labelColor: Colors.White,
                },
              ]
        }
        labelColor={Colors.White}
        listType={listType}
      />
    );
  }

  // Editable multi-select vehicle selector
  return <VehicleSelectorAccordion form={form} initialVehicle={initialVehicle} />;
}
