import { EmploymentStatus } from 'src/types/employmentStatus';

export const EmploymentStatusInputs = [
  {
    label: 'None',
    name: 'employmentStatus',
    value: EmploymentStatus.Unemployed,
  },
  {
    label: 'Retired',
    name: 'employmentStatus',
    value: EmploymentStatus.Retired,
  },
  {
    label: 'Employed',
    name: 'employmentStatus',
    value: EmploymentStatus.Employed,
  },
  {
    label: 'Self-Employed',
    name: 'employmentStatus',
    value: EmploymentStatus.SelfEmployed,
  },
  {
    label: 'Other',
    name: 'employmentStatus',
    value: EmploymentStatus.Other,
  },
];
