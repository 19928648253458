import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import './Toggle.css';

interface ToggleProps {
  name: string;
  control: Control;
  label: string;
  className?: string;
}

export default function Toggle({ name, control, label, className }: ToggleProps) {
  const {
    field: { value, ...field },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  const renderClass = useMemo(() => {
    const classes = className ? [className, 'form--toggle'] : ['form--toggle'];
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [className, invalid, isDirty, isSubmitted, isTouched]);

  return (
    <>
      <div className="form--toggle--holder">
        <label className={renderClass} htmlFor={name}>
          <input
            id={`${name}`}
            type="checkbox"
            className="form--toggle--checkbox"
            value={value}
            {...field}
            checked={value}
          />
          <div className="form--toggle--box">
            <div className="form--toggle--box--circle"></div>
            <div className="form--toggle--box--border"></div>
          </div>

          <div className="form--toggle--text">{label}</div>
        </label>

        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <div className="form--error">{!value && message}</div>}
        />
      </div>
    </>
  );
}
