/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import { useChatContext } from 'src/components/chat/ChatActionProvider';
import { useConfig } from 'src/components/context/config';
import { Selector } from 'src/components/selectors/Selector';
import { SelectorAccordionSearch } from 'src/components/selectors/SelectorAccordionSearch';
import { VehicleSelection, VehicleSelectionMode } from 'src/components/steps/VehicleSelection';
import { WebsiteRoute } from 'src/external/WebsiteRoutes';

export function TestVehicleBrowser() {
  const config = useConfig()!;
  const { messages, handleSubmit, activeVehicle } = useChatContext();
  const [vehiclesLoaded, setVehiclesLoaded] = useState(false);
  const [vehicles, setVehicles] = useState<any>([]);
  const recentMessage = messages[messages.length - 2];
  const isUserSearch = recentMessage.type === 'user';

  useEffect(() => {
    fetch('https://search-api.space-auto.com/vehicles/search', {
      method: 'POST',
      headers: {
        dealership: config.dealershipId,
      },
      body: JSON.stringify({
        pagination: {
          skip: 0,
          limit: 3,
        },
        query: isUserSearch ? recentMessage?.text : undefined,
        sort: {
          field: 'views',
          order: 'desc',
        },
      }),
    })
      .then(res => res.json())
      .then(res => setVehicles(res.vehicles))
      .then(() => setVehiclesLoaded(true));
  }, []);

  if (!vehiclesLoaded) return null;

  if (vehiclesLoaded && !vehicles.length) {
    return (
      <Selector
        type="button"
        btnIcon={faPlus}
        title="Search Inventory"
        subtitle="Find a vehicle to explore payment options."
        handleClick={() =>
          window.open(`https://${config.websiteDomain}${WebsiteRoute.ExploreVehicle}`)
        }
      />
    );
  }

  return (
    <>
      {vehicles?.map(vehicle => {
        return (
          <VehicleSelection
            key={vehicle.vin}
            initialVehicle={vehicle}
            mode={VehicleSelectionMode.Chat}
            handleSubmit={handleSubmit}
            isActive={activeVehicle === vehicle.vin}
          />
        );
      })}
      <SelectorAccordionSearch />
    </>
  );
}
