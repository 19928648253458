import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useFormSubmit } from '../form/Form';
import { UploadGroup } from './UploadGroup';
import { DocumentTag } from 'src/types/document-block';
import { Customer } from 'src/types/customer';

export const DocumentGroup = ({ tag }: { tag: DocumentTag }) => {
  const { handleFormGroupSubmit, formValues, activeForm } = useFormSubmit();
  const validationSchema = Joi.object({
    [tag]: Joi.boolean().required().messages({
      'string.empty': `Document upload is required`,
    }),
  });

  const form = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      [tag]: false,
    },
  });

  if (activeForm !== `documentForm${tag}`) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <form id={`documentForm${tag}`} onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
        <UploadGroup tag={tag} user={formValues as Customer} />
      </form>
    </FormProvider>
  );
};
