import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Banner.css';
import { useDeals } from './context/DealsProvider';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { Colors } from 'src/types/colors';
import Button from './form/Button';
import { useState } from 'react';
import { useApiFetch } from 'src/fetches/useApiFetch';
import { useSwrDeals } from 'src/fetches/useSWRFetch';
import toast from 'react-hot-toast';
import { useMatch } from 'react-router-dom';
import { FullBlockRoute } from 'src/types/blocks';
import { useSessionContext } from './context/SessionProvider';

export function Banner() {
  const { deal, activeDealId, dealMutate } = useDeals();
  const { paymentOptionsVisited, setPaymentOptionsVisited } = useSessionContext();
  const { dealsMutate } = useSwrDeals();
  const apiFetch = useApiFetch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const showBanner = useMatch({
    path: `/deals/:dealId${FullBlockRoute.PaymentOptions}*`,
    end: true,
  });

  // Lock the deal
  const handleUnlock = async () => {
    const path = `/deals/${activeDealId}/desk-state-unlock`;
    const requestOptions = {
      method: 'POST',
    };
    setIsSubmitting(true);

    try {
      await apiFetch(path, requestOptions);
      await Promise.all([dealsMutate(), dealMutate()]);
      // Remove payment option completed
      // remove id of active deal from payment options visited
      const newVisited = paymentOptionsVisited?.filter((id: string) => id !== activeDealId);
      setPaymentOptionsVisited(newVisited ?? []);

      toast.success('Deal unlocked');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  if (!showBanner || !deal || deal?.isDeskingLocked === false) return null;

  return (
    <div className="banner mod-black">
      <div className="banner--content">
        <div className="banner--content--icon">
          <FontAwesomeIcon icon={faLock} color={Colors.White} />
        </div>
        <div className="banner--content--text">
          <div className="banner--content--title">Payment Locked</div>
          <div className="banner--content--description">Changes require undoing commitment.</div>
        </div>
        <div className="banner--content--action">
          <Button
            type="button"
            text="Unlock"
            baseClass="btn mod-primary mod-danger-light"
            isSubmitting={isSubmitting}
            progress={true}
            handleSubmit={handleUnlock}
          />
        </div>
      </div>
    </div>
  );
}
