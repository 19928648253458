// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { returnCustomerSchema } from '../../constants/schemas';
import { Customer } from './CustomerGroup';
import { FormBody, useFormSubmit } from '../form/Form';
import { useEffect } from 'react';
import { useConfig } from '../context/config';

interface InfoCommunicationGroupProps {
  requirePhone: boolean;
  requireEmail: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const InfoCommunicationGroup = ({
  requirePhone,
  requireEmail,
  setIsSubmitting,
}: InfoCommunicationGroupProps) => {
  const config = useConfig()!;
  const { handleFormGroupSubmit, formValues, activeForm, isSubmitting } = useFormSubmit();
  // If we are in retailing, do NOT require phone number

  const schema = returnCustomerSchema(
    requirePhone,
    requireEmail,
    config.forms?.lead?.requireSms ?? false
  );

  const form = useForm({
    resolver: joiResolver(Joi.object(schema)),
    mode: 'onBlur',
    defaultValues: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
      optoutCalls: formValues.optoutCalls,
      optoutSms: formValues.optoutSms,
      optoutEmails: formValues.optoutEmails,
    },
  });

  const optoutSms = form.watch('optoutSms');

  useEffect(() => {
    if (isSubmitting || !config?.forms?.lead?.requireSms || config.isRetailing) {
      return;
    }
    if (activeForm === 'infoCommunicationForm' && !form.formState.isValid) {
      setIsSubmitting(true);
    } else if (!isSubmitting) {
      setIsSubmitting(false);
    }
  }, [config, activeForm, setIsSubmitting, optoutSms, isSubmitting, form.formState]);

  if (activeForm !== 'infoCommunicationForm') {
    return null;
  }

  return (
    <form id="infoCommunicationForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Provide your basic information"
        body="The personal information you provide will be securely kept and will only be used to process your loan application."
      />
      <Customer form={form} />
    </form>
  );
};
