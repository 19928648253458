import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useSessionContext } from '../SessionProvider';
import { useAuth } from '../AuthProvider';
import { useConfig } from '../config';
import { returnDocumentsAreEnabled } from 'src/constants/documents';
import { useDocuments, useMe } from 'src/fetches/useSWRFetch';
import { DocumentTag } from 'src/types/document-block';

export const useIncomeProofStatus = () => {
  const config = useConfig()!;
  const { documents } = useDocuments();
  const { documentsVisited } = useSessionContext();
  const { token } = useAuth();
  const { me } = useMe();

  return useMemo(() => {
    if (
      !returnDocumentsAreEnabled(token, config) ||
      (token && !me?.requireIncomeProof) ||
      (!token && !config.requireIncomeProof)
    ) {
      return BlockStatus.Hidden;
    }

    if (documents?.some(item => item.tag === DocumentTag.Income)) {
      return BlockStatus.Done;
    }

    // User has been to documents page
    if (documentsVisited === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [documentsVisited, config, token, documents, me]);
};
