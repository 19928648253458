import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import './AddVehicle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfig } from '../context/config';
import { SplideSlide } from '@splidejs/react-splide';
import { Colors } from 'src/types/colors';
import { WebsiteRoute } from 'src/external/WebsiteRoutes';
import { useDialogContext } from '../floating/dialog/Dialog';

export function AddVehicleCard({
  handleNewVehicle,
  isAlert,
}: {
  handleNewVehicle: () => void;
  isAlert?: boolean;
}) {
  return (
    <div className="modular-vehicle-card--holder">
      <button
        className={'modular-vehicle-card--select'}
        type="button"
        onClick={() => handleNewVehicle()}
        disabled={false}
      ></button>
      <div className={`modular-vehicle-card is-add-vehicle ${isAlert ? 'is-alert' : ''}`}>
        <div className="modular-vehicle-card--top is-add-vehicle">
          <FontAwesomeIcon icon={faPlus} color={Colors.Gray4} />
        </div>

        <div className="modular-vehicle-card--bottom">
          <div className="modular-vehicle-card--text">
            <span className="modular-vehicle-card--title text--title-1">Add Vehicle</span>
            <div className="modular-vehicle-card--subtitle text--body-1">Browse our inventory</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AddVehicle() {
  const config = useConfig()!;
  const { setOpen } = useDialogContext();

  const handleNewVehicle = async () => {
    // Send user to main website to pick a vehicle
    window.location.href = `https://${config.websiteDomain}${WebsiteRoute.CreateDeal}`;
    setOpen(false);
  };

  return (
    <SplideSlide key="addVehicleSlide">
      <AddVehicleCard handleNewVehicle={handleNewVehicle} />
    </SplideSlide>
  );
}
