import { ApplicantType } from 'src/types/creditapplication-block';

const ApplicantTypeDropdownArray: { label: string; value: ApplicantType }[] = [
  { label: 'Self', value: ApplicantType.Self },
  { label: 'Spouse', value: ApplicantType.Spouse },
  { label: 'Relative', value: ApplicantType.Relative },
  { label: 'Registered domestic partner', value: ApplicantType.RegisteredDomesticPartner },
  { label: 'Civil Union', value: ApplicantType.CivilUnion },
  { label: 'Other', value: ApplicantType.Other },
];

export default ApplicantTypeDropdownArray;
