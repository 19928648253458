import { add } from 'date-fns';
import {
  getAppointmentDate,
  getEditFriendlyTime,
  returnTimeOptions,
  week,
} from '../constants/dates';
import { AppointmentBlock } from '../types/appointment-block';
import { WeekSchedule } from '../types/dealership';

export function useDealershipSchedule(
  dealerTimezone: string,
  schedule: WeekSchedule,
  appointment?: AppointmentBlock
) {
  const { timeOptions, closedDays } = returnTimeOptions(schedule);

  // Get the time 24 hours from now, rounding up to the nearest 15 minutes
  const getInitialTime = () => {
    const now = new Date();
    let hour = now.getHours();
    let minutes = Math.ceil(now.getMinutes() / 15) * 15;
    return `${convertHour(minutes < 60 ? hour : hour + 1, false)}:${
      minutes === 0 || minutes === 60 ? '00' : minutes
    }`;
  };

  const getSoonestAppointment = () => {
    const today = new Date().getDay();
    for (let i = 1; i <= 7; i++) {
      const day = week[(today + i) % 7];
      if (schedule && schedule[day]?.isOpen) {
        const date = add(new Date(), { days: i });
        return { date, day };
      }
    }
    return { date: new Date(), day: week[today] };
  };

  const convertHour = (hour: number, label: boolean) => {
    if (hour < 10 && !label) return `0${hour}`;
    if (hour > 12 && label) return hour - 12;
    return hour;
  };

  const getInitialAppointmentTime = (day: string) => {
    // If the time 24 hrs from now is available for tomorrow, use that
    if (timeOptions[day]?.some(time => time.value === tomorrowsInitialTime)) {
      return tomorrowsInitialTime;
    }
    return timeOptions[day]?.[0]?.value;
  };

  const getAppointmentDetails = (appointment: AppointmentBlock) => {
    const aptDate = getAppointmentDate(appointment.appointmentAt, dealerTimezone);

    const aptTime = aptDate ? getEditFriendlyTime(aptDate).substring(0, 5) : null;

    const dayOfAppointmentOptions = timeOptions[week[aptDate!.getDay()]];

    const isOutOfHours =
      //AptDate is specified...
      aptDate &&
      //...and either the day of the appointment is always closed...
      (closedDays.includes(aptDate.getDay()) ||
        //...or the time of the appointment is not in the list of available times for that day
        !dayOfAppointmentOptions?.some(time => time.value === aptTime));

    return { aptDate, aptTime, isOutOfHours };
  };

  const tomorrowsInitialTime = getInitialTime();
  const soonestAppointment = getSoonestAppointment();
  const soonestAppointmentTime = getInitialAppointmentTime(soonestAppointment.day);
  const { aptDate, aptTime, isOutOfHours } = appointment
    ? getAppointmentDetails(appointment)
    : { aptDate: null, aptTime: null, isOutOfHours: false };
  const initialDate = aptDate ?? soonestAppointment.date;
  const initialAppointmentTime = aptTime ?? soonestAppointmentTime;

  return {
    initialDate,
    initialAppointmentTime,
    aptDate,
    aptTime,
    isOutOfHours,
    isSavedOutOfHours: isOutOfHours && appointment?.id,
  };
}
