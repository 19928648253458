import { Colors } from 'src/types/colors';
import { Label } from '../text/Label';
import { FormJourneyTab } from '../navigation/JourneyTab';

export function FormHeader({ title, label }: { title?: string; label?: string }) {
  if (!title) return null;
  return (
    <div className="block-container--header">
      {title && <h2 className="block-container--heading">{title}</h2>}
      {label && <Label color={Colors.Blue2} text={label} />}
    </div>
  );
}

export function FormHeaderWrapper({
  title,
  label,
  children,
}: {
  title?: string;
  label?: string;
  children?: React.ReactNode;
}) {
  return (
    <>
      <FormJourneyTab />
      <FormHeader title={title} label={label} />
      {children}
    </>
  );
}
