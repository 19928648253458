import {
  faCalendarCheck,
  faCheckCircle,
  faQuestionCircle,
  faTimesCircle,
  faClock,
} from '@fortawesome/pro-regular-svg-icons';
import { AppointmentStatus } from 'src/types/appointment';
import { Colors } from 'src/types/colors';

function getLabelColor(status: AppointmentStatus) {
  switch (status) {
    case AppointmentStatus.Set:
      return Colors.Orange1;
    case AppointmentStatus.Confirmed:
      return Colors.Green1;
    case AppointmentStatus.Declined:
      return Colors.Red1;
    default:
      return Colors.Blue2;
  }
}

function getStatusIcon(status: AppointmentStatus) {
  switch (status) {
    case AppointmentStatus.Set:
      return faQuestionCircle;
    case AppointmentStatus.Confirmed:
      return faCheckCircle;
    case AppointmentStatus.Declined:
      return faTimesCircle;
    case AppointmentStatus.Completed:
    case AppointmentStatus.Missed:
      return faClock;
    default:
      return faCalendarCheck;
  }
}

export function useAppointmentStatus(status: AppointmentStatus) {
  const labelColor = getLabelColor(status);
  const statusIcon = getStatusIcon(status);

  return {
    labelColor,
    statusIcon,
  };
}
