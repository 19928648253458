/* eslint-disable react-hooks/exhaustive-deps */
import { UseFormReturn } from 'react-hook-form';
import Joi from 'joi';
import { useConfig } from '../context/config';
import { FormError } from '../controls/FormError';
import { PaymentType } from '../../types/deal';
import { CreditScore, Zip } from '../inputs/Input';
import { useMemo } from 'react';
import { getStateFromZip } from 'src/constants/validation';
import { PaymentMethodInputs } from 'src/constants/paymentType';

import { returnCreditScore } from 'src/constants/creditScore';
import './CalculatorGroup.css';
import { Radio } from '../controls/Radio';
import { FormBody } from '../form/Form';
import { VehicleSelection, VehicleSelectionMode } from './VehicleSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from 'src/types/colors';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { useDeals } from '../context/DealsProvider';

export const validationSchema = {
  paymentMethod: Joi.string()
    .allow(...Object.values(PaymentType))
    .required()
    .messages({
      'string.empty': `You need to select a payment type to continue`,
    }),
  creditScore: Joi.number().min(350).max(850).messages({
    'number.min': `Credit score should not be below 350`,
    'number.max': `Credit score should not exceed 850`,
    'number.base': `Credit score should be a number`,
    'string.base': `Credit score should be a number`,
    'string.empty': `This field is required`,
  }),
  zip: Joi.string()
    .pattern(/^\d{5}$/)
    .custom((value, helpers) => {
      if (!getStateFromZip(value)) {
        return helpers.message({ custom: 'Please enter a valid zip' });
      }
      return value;
    }, 'custom validation')
    .messages({
      'string.pattern.base': `Zip Code must be 5 digits`,
      'string.empty': `Zip is required for accurate pricing`,
    }),
};

interface UnlockPaymentGroupProps {
  form: UseFormReturn<any>;
}

export const UnlockCalculatorGroup = ({ form }: UnlockPaymentGroupProps) => {
  const config = useConfig()!;
  const { deal } = useDeals();
  const formZip = form.watch('zip');
  const formCreditScore = form.watch('creditScore');

  const zipBadge = useMemo(() => {
    if (formZip.length < 5) return undefined;
    return getStateFromZip(formZip);
  }, [formZip]);

  const creditScoreBadge = useMemo(() => {
    return formCreditScore ? returnCreditScore(+formCreditScore) : undefined;
  }, [formCreditScore]);

  if (!config) return null;

  return (
    <>
      <div className="block-container--overflow--padding">
        <FormBody
          title="Explore Payment Options"
          body="Get a personalized quote with your payment options."
        />
      </div>
      <div className="block-container--overflow--padding">
        <VehicleSelection initialVehicle={deal} mode={VehicleSelectionMode.Browse} />
      </div>
      <div className="block-container--overflow--padding">
        <div className="radio--flex-scroll">
          {PaymentMethodInputs.filter(item => !config.paymentOptionsConfig[item.isDisabledKey]).map(
            (item, index) => (
              <Radio
                className="radio--flex-scroll--item"
                control={form.control}
                key={index}
                value={item.value}
                name="paymentMethod"
                label={item.label}
                disabled={false}
                isReadOnly={false}
              />
            )
          )}
        </div>
        <FormError control={form.control} name="paymentMethod" />
      </div>

      <div className="block-container--overflow--padding">
        <div className="calculator-group-full-grid u-mar-bottom-16">
          <div style={{ gridArea: 'creditScore' }}>
            <CreditScore control={form.control} badge={creditScoreBadge} readOnly={false} />
          </div>
          <div style={{ gridArea: 'zipCode' }}>
            <Zip control={form.control} badge={zipBadge} readOnly={false} />
          </div>
        </div>

        <div className="block-container--content--centered u-position-relative">
          <div className="unlock--blur-state--message">
            <FontAwesomeIcon icon={faLockAlt} color={Colors.Blue1} className="success--icon" />
            <div className="block-form--heading">Unlock Your Payment</div>
            <div className="text--body-2">
              With this information, we can help you get the best possible deal.
            </div>
          </div>
          <div className="unlock--blur-state--grid">
            <div className="unlock--blur-state--cell">
              <div className="matrix-item--label--text--title">$2,199.08 / mo</div>
              <div className="matrix-item--label--text--subtitle">24 Months</div>
              <div className="matrix-item--label--text--subtitle">10% APR</div>
            </div>
            <div className="unlock--blur-state--cell">
              <div className="matrix-item--label--text--title">$2,106.77 / mo</div>
              <div className="matrix-item--label--text--subtitle">36 Months</div>
              <div className="matrix-item--label--text--subtitle">10% APR</div>
            </div>
            <div className="unlock--blur-state--cell">
              <div className="matrix-item--label--text--title">$1,209.08 / mo</div>
              <div className="matrix-item--label--text--subtitle">48 Months</div>
              <div className="matrix-item--label--text--subtitle">10% APR</div>
            </div>
            <div className="unlock--blur-state--cell">
              <div className="matrix-item--label--text--title">$1,538.45 / mo</div>
              <div className="matrix-item--label--text--subtitle">72 Months</div>
              <div className="matrix-item--label--text--subtitle">10% APR</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
