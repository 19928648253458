import toast from 'react-hot-toast';
import { useAuth } from 'src/components/context/AuthProvider';
import { useDeals } from 'src/components/context/DealsProvider';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useAppointment, useAppointments } from 'src/fetches/useSWRFetch';
import { AppointmentBlockSubmit, returnAppointmentBlock } from 'src/types/appointment';
import { AppointmentBlock } from 'src/types/appointment-block';
import { AppointmentBuilder } from '../appointments/AppointmentBuilder';
import { UnauthorizedAppointmentForm } from 'src/lead-forms/AppointmentForm';
import { WidgetForm } from 'src/widgets/SpaceWidget';
import AppointmentTypeDropdownArray from 'src/components/dropdowns/AppointmentTypeDropdownList';
import { ScheduleProvider, useSchedule } from 'src/components/context/ScheduleProvider';

export function ReviewPageAppointment({
  appointmentToUse,
}: {
  appointmentToUse: AppointmentBlock | null;
}) {
  const { deal } = useDeals();
  const { appointmentsMutate } = useAppointments();
  const { sessionCustomer } = useSessionContext();
  const { token } = useAuth();
  const { appointment, appointmentIsLoading, appointmentSubmit } = useAppointment(
    appointmentToUse?.id || 'new'
  ); // In this case we are always creating a new appointment
  const { dealerTimezone } = useSchedule();

  const handleAuthenticatedSubmit = async (data: any) => {
    const formattedData: AppointmentBlockSubmit = returnAppointmentBlock(data, dealerTimezone);

    try {
      await appointmentSubmit(formattedData);
      await appointmentsMutate();
      toast.success('Appointment scheduled');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  if (appointmentIsLoading) return null;
  return (
    <ScheduleProvider location={appointment?.location}>
      {token ? (
        <AppointmentBuilder
          configuration={{
            name: appointment?.id ? 'Edit Appointment' : 'Schedule a Visit',
            appointment,
            requirePhone: true,
            requireSms: false,
            customer: undefined,
            additionalFields: undefined,
            handleFormSubmit: handleAuthenticatedSubmit,
            handleFormCancel: () => {},
            title: 'Congratulations, you saved 3 hours at the dealership! 🎉',
            body: 'Next step: Visit our store to finalize your purchase and pick up your vehicle. We can’t wait to see you!',
            appointmentTypeOptions: appointment?.id ? AppointmentTypeDropdownArray : [],
            vehicle: appointment?.vehicle || deal,
            isVehicleReadOnly: true,
            submitText: appointment?.id ? 'Update Appointment' : 'Schedule Appointment',
            isVehiclePickup: true,
            hideBackButton: true,
            customerFieldsAssumed: sessionCustomer?.email ? true : false,
          }}
        />
      ) : (
        <UnauthorizedAppointmentForm
          form={WidgetForm.TestDrive}
          vehicle={deal}
          additionalFields={[]}
          title="Congratulations, you saved 3 hours at the dealership! 🎉"
          body="Next step: Visit our store to finalize your purchase and pick up your vehicle. We can’t wait to see you!"
          assumeCustomer={true}
          hideBackButton={true}
          isVehiclePickup={true}
        />
      )}
    </ScheduleProvider>
  );
}
