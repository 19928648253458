/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Select } from '../controls/Select';

import YearsDropdownArray from '../dropdowns/YearsDropdownArray';
import MonthsDropdownArray from '../dropdowns/MonthsDropdownArray';
import { HomeMonthlyPayment } from '../inputs/Input';
import { HomeOwnership, requiresMortgage } from 'src/types/creditapplication-block';
import { FormBody, useFormSubmit } from '../form/Form';

export const AddressPaymentGroup = ({ index }: { index: number }) => {
  const { formValues: payment, activeForm, handleFormGroupSubmit } = useFormSubmit();
  const addresses = payment.addresses;
  const showHomePayment = requiresMortgage(addresses[index].homeOwnership as HomeOwnership);
  const baseSchema = {
    //Force month selection if Years < 2
    homeLivingMonths: Joi.any().custom((value, helpers) => {
      const livingYears = parseInt(helpers.state.ancestors[0].homeLivingYears);
      if (value === '' && livingYears < 2) {
        return helpers.message({ custom: 'Please select a number for months' });
      }
      return value;
    }),
    homeLivingYears: Joi.number().required().messages({
      'string.empty': `A year selection is required`,
      'number.base': `Please make a year selection`,
    }),
  };

  const validationSchema = showHomePayment
    ? Joi.object({
        ...baseSchema,
        homeMonthlyPayment: Joi.number().required().messages({
          'number.base': `Monthly payment should be a number`,
          'string.empty': `Monthly Payment is required`,
          'any.required': 'Monthly Payment is required',
        }),
      })
    : Joi.object(baseSchema);

  const form = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: showHomePayment
      ? {
          homeMonthlyPayment: addresses[index].homeMonthlyPayment,
          homeLivingMonths: addresses[index].homeLivingMonths,
          homeLivingYears: addresses[index].homeLivingYears,
        }
      : {
          homeLivingMonths: addresses[index].homeLivingMonths,
          homeLivingYears: addresses[index].homeLivingYears,
        },
  });

  const onSubmit = async (updatedFormData: any) => {
    const saveData = payment.addresses;
    saveData[index] = { ...saveData[index], ...updatedFormData };

    handleFormGroupSubmit({ addresses: saveData });
  };

  if (activeForm !== `addressPaymentForm${index}`) {
    return null;
  }

  return (
    <form id={`addressPaymentForm${index}`} onSubmit={form.handleSubmit(onSubmit)}>
      {showHomePayment ? (
        <div className="block-form--section">
          <FormBody
            title={index === 0 ? 'What is your monthly payment?' : 'What was your monthly payment?'}
            body={addresses[index].addressLine1}
          />
          <HomeMonthlyPayment control={form.control} />
        </div>
      ) : null}
      <div className="block-form--section">
        <FormBody
          title={index === 0 ? 'How long have you lived here?' : 'How long did you live here?'}
          body={addresses[index].addressLine1}
        />
        <div className="u-display-grid grid--50">
          <Select
            control={form.control}
            name="homeLivingYears"
            label="Years"
            selectOptions={YearsDropdownArray}
          />
          <Select
            control={form.control}
            name="homeLivingMonths"
            label="Months"
            selectOptions={MonthsDropdownArray}
          />
        </div>
      </div>
    </form>
  );
};
