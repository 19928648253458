import { EsignatureTerms } from '../../../constants/legal';
import { useConfig } from '../../context/config';
import { useRef } from 'react';
import {
  PopoverArrow,
  PopoverDescription,
  PopoverHeading,
  PopoverContent,
} from '../popover/Popover';

export function EsignatureTip() {
  const config = useConfig()!;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef}>
      <PopoverContent
        className="popover-media"
        containerRef={config.isRetailing ? null : containerRef}
      >
        <PopoverArrow className="popover-media--arrow" />
        <PopoverHeading className="popover-media--heading">What does this mean?</PopoverHeading>
        <PopoverDescription className="popover-media--description">
          <div className="popover-media--dark-text">
            {EsignatureTerms}{' '}
            <a
              href={`https://${config.websiteDomain}/privacy-policy/`}
              target="_blank"
              className="u-blue-link"
              rel="noreferrer"
            >
              Privacy Notice
            </a>
          </div>
        </PopoverDescription>
      </PopoverContent>
    </div>
  );
}
