import { ReactNode, useState } from 'react';
import { TradeinVehicleGroup } from '../../../components/steps/TradeinVehicleGroup';
import { TradeinColorGroup } from '../../../components/steps/TradeinColorGroup';
import { TradeinMileageGroup } from '../../../components/steps/TradeinMileageGroup';
import { TradeinConditionGroup } from '../../../components/steps/TradeinConditionGroup';
import { TradeinPayoffTypeGroup } from '../../../components/steps/TradeinPayoffTypeGroup';
import { TradeinPayoffLenderGroup } from '../../../components/steps/TradeinPayoffLenderGroup';
import { TradeinPhotoGroup } from '../../../components/steps/TradeinPhotoGroup';
import { FormActions } from '../../../components/form/FormActions';
import { TradeinFormDefaultValues, returnTradeinSubmitBlock } from '../../../types/tradein-block';
import { useConfig } from 'src/components/context/config';
import { InfoCommunicationLocationGroup } from 'src/components/steps/InfoCommunicationLocationGroup';
import { InfoCommunicationGroup } from 'src/components/steps/InfoCommunicationGroup';
import { formatCustomerAddonBlock } from 'src/types/lead';
import { UnauthorizedTradeinBlockSubmit } from 'src/widgets/SpaceWidget';
import { FormWrapper } from 'src/components/form/Form';
import { useDealershipLocations } from 'src/helpers/useDealershipLocations';

const StepMap = [
  'tradeinVehicleForm',
  'tradeinColorForm',
  'tradeinMileageForm',
  'tradeinConditionForm',
  'tradeinPayoffTypeForm',
  'tradeinPayoffLenderForm',
  'infoCommunicationForm',
  'infoCommunicationLocationForm',
  'tradeinPhotoForm',
];

interface TradeinFormProps {
  navigation: ReactNode | null;
  tradein: TradeinFormDefaultValues;
  handleFormSubmit: (data: UnauthorizedTradeinBlockSubmit) => Promise<void>;
  handleFormCancel: () => void;
  hideBackButton?: boolean;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
}

export const TradeinFormGroup = ({
  tradein,
  handleFormSubmit,
  handleFormCancel,
  conditionalFields,
  hideBackButton,
  navigation,
}: TradeinFormProps) => {
  const config = useConfig()!;
  const dealershipLocations = useDealershipLocations();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <FormWrapper
      handleFormSubmit={async (data: any) => {
        try {
          setIsSubmitting(true);
          const block = returnTradeinSubmitBlock(data);
          // Format customer data
          const customer = formatCustomerAddonBlock(data);

          const location = data.location === '' ? config.locations[0].name : data.location;

          handleFormSubmit({
            blocks: [block],
            customer,
            location,
          } as UnauthorizedTradeinBlockSubmit).finally(() => setIsSubmitting(false));
        } catch (e) {
          console.log(e);
        }
      }}
      handleFormCancel={handleFormCancel}
      multistepFormValues={tradein}
      steps={StepMap}
      conditionalFields={conditionalFields}
    >
      {navigation ?? null}
      <div className="block-container--content">
        <TradeinVehicleGroup />
        <TradeinColorGroup />
        <TradeinMileageGroup />
        <TradeinConditionGroup />
        <TradeinPayoffTypeGroup />
        <TradeinPayoffLenderGroup />
        {/* Don't show trade in photo group if user isn't logged in */}
        <TradeinPhotoGroup />
        {/* Show info group if not multilocation and not logged in */}
        <InfoCommunicationGroup
          requirePhone={config.forms?.lead?.requirePhone ?? true}
          requireEmail={false}
          setIsSubmitting={setIsSubmitting}
        />
        {/* Show multilocation group if multiple locations and not logged in */}
        <InfoCommunicationLocationGroup
          requireEmail={false}
          requirePhone={config.forms?.lead?.requirePhone ?? true}
          locations={dealershipLocations}
          setIsSubmitting={setIsSubmitting}
        />
      </div>

      <FormActions isSubmitting={isSubmitting} hideBackButton={hideBackButton} />
    </FormWrapper>
  );
};
