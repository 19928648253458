import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import Button from '../form/Button';
import { useChatContext } from './ChatActionProvider';
import './UserTask.css';
import { BlockRoute } from 'src/types/blocks';

const UserTask = () => {
  const { handleSuggestion } = useChatContext();

  const options = [
    {
      text: 'I want to fill out a credit application',
      handler: () => handleSuggestion(BlockRoute.CreditApplication),
      id: 1,
    },
    {
      text: 'I want to submit a trade application',
      handler: () => handleSuggestion(BlockRoute.CreditApplication),
      id: 2,
    },
    {
      text: 'I want to book an appointment',
      handler: () => handleSuggestion(BlockRoute.Appointments),
      id: 3,
    },
    {
      text: 'I want to explore payment options on a vehicle',
      handler: () => handleSuggestion(BlockRoute.PaymentOptions),
      id: 4,
    },
  ];

  const optionsMarkup = options.map(option => (
    <Button
      type="button"
      baseClass="chat-btn"
      icon={true}
      iconClass="u-icon"
      iconName={faPlus}
      key={option.id}
      progress={true} // TODO: add state
      isSubmitting={false} // : add state
      handleSubmit={e => {
        e.preventDefault();
        option.handler();
      }}
      text={option.text}
    ></Button>
  ));

  return <div className="chat-questions">{optionsMarkup}</div>;
};

export default UserTask;
