/* eslint-disable react-hooks/exhaustive-deps */

import FileUpload from '../form/FileUpload';
import toast from 'react-hot-toast';

import { useDocuments, useMe } from '../../fetches/useSWRFetch';
import PageLoader from '../PageLoader';
import PageError from '../PageError';
import { uploadEnum } from '../../constants/files';
import { FormBody } from '../form/Form';
import { Customer } from 'src/types/customer';
import { DocumentBlock, DocumentTag } from 'src/types/document-block';
import { useFormContext } from 'react-hook-form';
import { useAuth } from '../context/AuthProvider';
import { convertPhoneForSubmit } from 'src/constants/validation';

export const UploadGroup = ({ tag, user }: { tag: DocumentTag; user?: any }) => {
  const { documents, documentsIsLoading, documentsIsError, documentsDelete, documentsSubmit } =
    useDocuments();
  const { token } = useAuth();
  const { me, meIsLoading, meIsError } = useMe();
  const { setValue } = useFormContext();

  if (documentsIsLoading || meIsLoading) return <PageLoader />;
  if (documentsIsError || meIsError) return <PageError />;

  const handleSubmit = async (blocks: DocumentBlock[]) => {
    if (token) {
      await Promise.all(blocks.map(block => documentsSubmit(block as DocumentBlock)));
    } else {
      let customer = (user as Customer) || (me as Customer);

      let customerBlock = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email || null,
        phone: convertPhoneForSubmit(customer.phone) || null,
        optoutCalls: customer.optoutCalls,
        optoutEmails: customer.optoutEmails,
        optoutSms: customer.optoutSms,
      };
      await documentsSubmit(blocks as DocumentBlock[], customerBlock);
    }
    setValue(tag, true);
  };

  const handleDelete = async (id: string) => {
    try {
      await documentsDelete({ id });
      setValue(tag, false);
      toast.success('File was successfully removed');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <FormBody title={uploadEnum[tag].title} body={uploadEnum[tag].subtitle} />
      <FileUpload
        files={documents.filter(item => item.tag === tag)}
        tag={tag}
        currentPhotos={[]}
        handleSubmit={handleSubmit}
        removeFile={handleDelete}
      />
    </>
  );
};
