import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormSubmit } from './Form';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { NavigationContext } from '../context/NavigationProvider';
import { useContext } from 'react';

interface FormActionsProps {
  isSubmitting?: boolean;
  hideBackButton?: boolean; // Useful for hiding back button
  alternateButtonText?: string; // Used to override button text
  fullButton?: boolean; // Used to make button full width
  hidePrimaryButton?: boolean; // Used to hide primary button
  showSkipButton?: boolean; // Used to show skip button
}

export function FormPrimaryNavigation({
  isSubmitting,
  hideBackButton,
  alternateButtonText,
  fullButton,
  hidePrimaryButton,
  showSkipButton,
}: FormActionsProps) {
  const {
    isSubmitting: isFormSubmitting,
    handleFormGroupBack,
    activeForm,
    activeStepIndex,
    formSteps,
  } = useFormSubmit();

  const { navigateNextBlock } = useContext(NavigationContext);
  const isFirstBackButtonHidden = hideBackButton && activeStepIndex === 0;
  const isSkipVisible = showSkipButton && activeStepIndex === 0;
  const isFinalStep = formSteps?.length - 1 === activeStepIndex;

  return (
    <div
      className={
        fullButton
          ? 'block-container--action'
          : `block-container--actions ${isSkipVisible ? 'mod-skip' : ''}`
      }
    >
      {isFirstBackButtonHidden ? null : (
        <button className="btn mod-text-icon" type="button" onClick={handleFormGroupBack}>
          <FontAwesomeIcon icon={faAngleLeft} className="u-icon" />
          Back
        </button>
      )}
      {hidePrimaryButton ? null : (
        <button
          disabled={isSubmitting || isFormSubmitting}
          className="btn mod-primary"
          type="submit"
          key={activeForm}
          form={activeForm}
        >
          {alternateButtonText ?? (isFinalStep ? 'Submit' : 'Next')}
        </button>
      )}
      {isSkipVisible ? (
        <button
          className="btn mod-text-icon"
          type="button"
          onClick={e => {
            e?.preventDefault();
            navigateNextBlock();
          }}
        >
          Skip
        </button>
      ) : null}
    </div>
  );
}
