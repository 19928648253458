import { WebsiteRoute } from 'src/external/WebsiteRoutes';
import { useConfig } from '../context/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';

export function MatrixContactItem({ subtitle, description }) {
  const config = useConfig()!;

  return (
    <div className="matrix-item">
      <button
        type="button"
        className="matrix-item--label"
        onClick={() => {
          window.open(`https://${config.websiteDomain}${WebsiteRoute.Contact}`);
        }}
      >
        <div className="matrix-item--label--text">
          <div className="matrix-item--label--text--title is-mobile-variant">Contact Us</div>
          <div className="matrix-item--label--text--subtitle">{subtitle}</div>
          <div className="matrix-item--label--text--desc text--body-1">{description}</div>
        </div>
        <FontAwesomeIcon
          icon={faPhone}
          transform={{ flipX: true }}
          style={{ alignSelf: 'center', marginRight: '8px' }}
        />
      </button>
    </div>
  );
}
