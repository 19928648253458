/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Employer, JobTitle, MonthlyIncome } from '../inputs/Input';
import { Select } from '../controls/Select';
import YearsDropdownArray from '../dropdowns/YearsDropdownArray';
import MonthsDropdownArray from '../dropdowns/MonthsDropdownArray';
import { FormBody, useFormSubmit } from '../form/Form';

const validationSchema = Joi.object({
  employer: Joi.string().required().messages({
    'string.empty': `Company name is required`,
  }),
  jobTitle: Joi.string().required().messages({
    'string.empty': `Job title is required`,
  }),
  monthlyIncome: Joi.number().required().messages({
    'number.base': `Monthly income should be a number`,
    'string.empty': `Monthly income is required`,
  }),
  employmentYears: Joi.number().required().messages({
    'number.base': `Employment years should be a number`,
    'string.empty': `Employment years is required`,
  }),
  employmentMonths: Joi.number().required().messages({
    'number.base': `Employment months should be a number`,
    'string.empty': `Employment months is required`,
  }),
});

export const EmploymentGroup = ({ index }) => {
  const { handleFormGroupSubmit, formValues: payment, activeForm } = useFormSubmit();
  const employment = payment.employment[index];
  const form = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      employer: employment.employer,
      jobTitle: employment.jobTitle,
      monthlyIncome: employment.monthlyIncome,
      employmentYears: employment.employmentYears,
      employmentMonths: employment.employmentMonths,
    },
  });

  const employer = form.watch('employer');

  const onSubmit = async (updatedFormData: any) => {
    const saveData = payment.employment;
    saveData[index] = { ...saveData[index], ...updatedFormData };

    handleFormGroupSubmit({ employment: saveData });
  };

  if (activeForm !== `employerForm${index}`) {
    return null;
  }

  return (
    <form id={`employerForm${index}`} onSubmit={form.handleSubmit(onSubmit)}>
      <div className="block-form--section">
        <FormBody
          title={
            index === 0 ? 'What is the name of your employer?' : 'What was your previous employer?'
          }
          body={null}
        />
        <Employer control={form.control} />
      </div>
      <div className="block-form--section">
        <FormBody
          title={
            index === 0 ? 'What is your job title and monthly income?' : 'What was your job title?'
          }
          body={employer}
        />
        <div className="u-display-grid grid--50">
          <JobTitle control={form.control} />
          <MonthlyIncome control={form.control} />
        </div>
      </div>
      <div className="block-form--section">
        <FormBody
          title={
            index === 0
              ? 'How long have you been employed here?'
              : 'How long were you employed here?'
          }
          body={payment?.employer}
        />
        <div className="u-display-grid grid--50">
          <Select
            control={form.control}
            name="employmentYears"
            label="Years"
            selectOptions={YearsDropdownArray}
          />
          <Select
            control={form.control}
            name="employmentMonths"
            label="Months"
            selectOptions={MonthsDropdownArray}
          />
        </div>
      </div>
    </form>
  );
};
