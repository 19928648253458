import React, { RefObject, useState } from 'react';

import { Dialog } from '../dialog/Dialog';
import ConfirmModal from '../dialog/ConfirmModal';
import Button from 'src/components/form/Button';

interface ButtonWithConfirmationProps {
  containerRef: RefObject<HTMLDivElement> | null;
  isSubmitting: boolean;
  title: string;
  body: string;
  text: string;
  baseClass: string;
  onSubmit: () => Promise<void>;
}

const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProps> = ({
  containerRef,
  title,
  body,
  baseClass,
  text,
  onSubmit,
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  return (
    <>
      <Button
        type="button"
        baseClass={baseClass}
        text={text}
        progress={true}
        handleSubmit={() => setConfirmModal(true)}
      />
      <Dialog open={confirmModal} onOpenChange={setConfirmModal}>
        <ConfirmModal
          headerText={title || ''}
          bodyText={body || ''}
          containerRef={containerRef}
          confirmAction={async () => {
            await onSubmit();
            setConfirmModal(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default ButtonWithConfirmation;
