import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';

import './Toggle.css';

interface NegativeToggleProps {
  name: string;
  control: Control;
  label: string;
}

export default function NegativeToggle({ name, control, label }: NegativeToggleProps) {
  const {
    field: { value, ...field },
    fieldState: { invalid, isTouched, isDirty },
    formState: { isSubmitted },
  } = useController({
    name,
    control,
  });

  const renderClass = useMemo(() => {
    const classes = ['is-negative', 'form--toggle'];
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [invalid, isDirty, isSubmitted, isTouched]);

  return (
    <>
      <div className="form--toggle--holder">
        <label className={renderClass} htmlFor={name}>
          <input
            id={`${name}`}
            type="checkbox"
            className="form--toggle--checkbox"
            value={value}
            {...field}
            checked={value}
          />
          <div className="form--toggle--box">
            <div className="form--toggle--box--circle"></div>
            <div className="form--toggle--box--border"></div>
          </div>

          <div className="form--toggle--text">{label}</div>
        </label>
      </div>
    </>
  );
}
