import './RadioToggle.css';

interface RadioToggleProps {
  label: string;
  labelDesc?: string;
  selected: boolean;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function RadioToggle({ label, labelDesc, selected, handleClick }: RadioToggleProps) {
  return (
    <button
      className={`radio-toggle--btn${selected ? ' is-active' : ''} `}
      type="button"
      onClick={handleClick}
    >
      <div className="radio-toggle--text">
        <div className="radio-toggle--text--title">{label}</div>
        {labelDesc ? <div className="radio-toggle--text--desc">{labelDesc}</div> : null}
      </div>
    </button>
  );
}
