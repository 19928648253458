import { faExternalLinkSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function VehicleWebsiteLink({ link }: { link: string }) {
  return (
    <a href={link} target="_blank" className="modular-vehicle-card--side-quest" rel="noreferrer">
      <FontAwesomeIcon icon={faExternalLinkSquare} className="" />
    </a>
  );
}
