import { CreditPullTip } from '../floating/popover/CreditPullTip';
import { Popover, PopoverTrigger } from '../floating/popover/Popover';
import Toggle from '../controls/Toggle';
import './HardPullGroup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { UseFormReturn } from 'react-hook-form';
import { Esignature } from '../inputs/Input';
import { EsignatureTip } from '../floating/popover/EsignatureTip';
import { useConfig } from '../context/config';
import { CreditApplicationActionBar } from '../form/CreditApplicationActionBar';

export function HardPullGroup({ form }: { form: UseFormReturn<any> }) {
  const config = useConfig()!;

  if (config.forms?.creditApplication?.pullConsentIsHidden) {
    return <CreditApplicationActionBar />;
  }

  return (
    <>
      <div className="u-display-flex">
        <Toggle control={form.control} name="softPullConsent" label="I agree to a credit pull." />
        <Popover>
          <PopoverTrigger className="form--tip-input--tip icon-btn">
            <FontAwesomeIcon icon={faInfoCircle} />
          </PopoverTrigger>
          <CreditPullTip />
        </Popover>
      </div>
      <div className="u-display-flex">
        <Toggle
          control={form.control}
          name="esignatureConsent"
          label="I agree to the use of an eSignature."
        />
        <Popover>
          <PopoverTrigger className="form--tip-input--tip icon-btn">
            <FontAwesomeIcon icon={faInfoCircle} />
          </PopoverTrigger>
          <EsignatureTip />
        </Popover>
      </div>
      <Esignature control={form.control} />
    </>
  );
}
