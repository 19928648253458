// MenuContext.tsx
import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';

interface AccordionContextProps {
  isMenuOpen: boolean;
  height: number;
  toggleMenu: () => void;
}

const AccordionContext = createContext<AccordionContextProps | undefined>(undefined);

export const useAccordion = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useAccordion must be used within an AccordionProvider');
  }
  return context;
};

export const AccordionProvider: React.FC<{
  children: React.ReactNode;
  itemHeight: number;
  nestedItemHeight: number;
  nestedItemCount: number;
}> = ({ children, itemHeight, nestedItemHeight, nestedItemCount }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const accordionHeight = useMemo(() => {
    let closedHeight = itemHeight; // Selector height
    let openHeight = closedHeight + nestedItemCount * nestedItemHeight; // Bottom padding
    return { openHeight, closedHeight };
  }, [nestedItemCount, itemHeight, nestedItemHeight]);

  const toggleMenu = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);

  return (
    <AccordionContext.Provider
      value={{
        isMenuOpen,
        toggleMenu,
        height: isMenuOpen ? accordionHeight.openHeight : accordionHeight.closedHeight,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};
