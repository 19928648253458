import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { useConfig } from '../context/config';

export function FormSignIn() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const config = useConfig()!;
  const location = useLocation();

  const handleClick = e => {
    e.preventDefault();
    navigate('/login');
  };

  // Don't show on login page
  if (token || location.pathname === '/login' || !config.packages?.retailing) {
    return null;
  }

  return (
    <div className="u-mar-bottom-16">
      <span className="text--body-2">Already did this? </span>
      <a className="text--body-1 u-blue-link" href="/login" onClick={handleClick}>
        Sign in
      </a>
    </div>
  );
}
