import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BlockRoute, BlockStatus } from 'src/types/blocks';
import { NavigationContext } from '../NavigationProvider';
import { ComponentState } from 'src/types/component';

export function useMenuStatus(name: BlockRoute) {
  const { status } = useContext(NavigationContext);
  const { block } = useParams();

  const isActive = useMemo(() => {
    return (
      name === block ||
      (name === BlockRoute.QuickForms &&
        (block === BlockRoute.CreditApplication ||
          block === BlockRoute.TradeIn ||
          block === BlockRoute.Documents ||
          block === BlockRoute.References)) ||
      (name === BlockRoute.Documents && block === BlockRoute.References)
    );
  }, [block, name]);

  const renderClass = useMemo(() => {
    const state: ComponentState[] = [];

    // match for deal dependent routes
    if (isActive) {
      state.push(ComponentState.Active);
    }

    if (status[name] === BlockStatus.Done) {
      state.push(ComponentState.Done);
    }

    if (status[name] === BlockStatus.Incomplete) {
      state.push(ComponentState.Incomplete);
    }

    if (status[name] === BlockStatus.Disabled) {
      // Note: Only disable link - these "disabled" items are still clickable for a tooltip
      state.push(ComponentState.Disabled);
    }

    return { state: state };
  }, [status, isActive, name]);

  return {
    isActive,
    status: status[name],
    state: renderClass.state,
  };
}
