import { RefObject } from 'react';
import Button from '../../form/Button';
import { DialogContent, DialogHeading, DialogDescription, DialogClose } from './Dialog';
import { useConfig } from 'src/components/context/config';
import { useRecoilState } from 'recoil';
import { loadingState } from 'src/state/loading';

interface ConfirmModalProps {
  headerText: string;
  bodyText: string | JSX.Element;
  confirmAction: () => Promise<void>;
  containerRef?: RefObject<HTMLDivElement> | null;
}

export default function ConfirmModal({
  headerText,
  bodyText,
  confirmAction,
  containerRef,
}: ConfirmModalProps) {
  const config = useConfig()!;
  const [isLoading, setIsLoading] = useRecoilState(loadingState);

  return (
    <DialogContent className="dialog" containerRef={config.isRetailing ? null : containerRef}>
      <DialogHeading className="dialog-heading">{headerText}</DialogHeading>
      <DialogDescription className="dialog-description">{bodyText}</DialogDescription>
      <div className="dialog-footer">
        {confirmAction ? (
          <Button
            type="button"
            text="Remove"
            baseClass="btn mod-danger"
            isSubmitting={isLoading}
            progress={true}
            handleSubmit={async () => {
              setIsLoading(true);
              await confirmAction();
              setIsLoading(false);
            }}
          />
        ) : null}
        <DialogClose className="btn mod-tertiary">Dismiss</DialogClose>
      </div>
    </DialogContent>
  );
}
