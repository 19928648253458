import { VehicleStatus } from 'src/types/vehicle';

export function VehicleCardLabels({ labels }: { labels: string[] }) {
  return (
    <div className="modular-vehicle-card--top--labels">
      {labels.map(label => (
        // Unavailable badges should be red
        <div
          key={label}
          className={`badge ${label === VehicleStatus.Unavailable ? ' mod-error' : ''}`}
        >
          {label}
        </div>
      ))}
    </div>
  );
}
