import PageLoader from '../../../components/PageLoader';
import PageError from '../../../components/PageError';
import { useCreditApplications, useMe } from '../../../fetches/useSWRFetch';
import { useDocTitle } from '../../../helpers/global-document-title';
import { useDeals } from '../../../components/context/DealsProvider';
import { Calculator } from './Calculator';
import { useContext, useEffect } from 'react';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { useAuth } from 'src/components/context/AuthProvider';
import { PaymentExplorer } from './PaymentExplorer';
import { VehicleSelectorPage } from 'src/pages/vehicles/VehicleSelectorPage';
import Success from 'src/components/form/Success';

export default function PaymentOptionsIndexPage() {
  useDocTitle('Credit Application');
  const { deal, dealIsLoading, dealIsError } = useDeals();
  const { navigateNextBlock } = useContext(NavigationContext);
  const { creditApplicationsIsLoading, creditApplicationsIsError } = useCreditApplications();
  const { me, meIsLoading, meIsError } = useMe();
  const { token } = useAuth();

  useEffect(() => {
    if (dealIsLoading) {
      return;
    }

    // If no blocks are started, navigate to the first block
    if (!dealIsLoading && deal === undefined && token) {
      navigateNextBlock();
      return;
    }
  }, [navigateNextBlock, deal, dealIsLoading, token]);

  // Unauthorized user with no vehicle
  if (!token && !dealIsLoading && !deal?.vin) {
    return <VehicleSelectorPage />;
  }

  if (!token) {
    return <PaymentExplorer submitText="Unlock Payment Options" />;
  }

  if (!deal || creditApplicationsIsLoading || dealIsLoading || meIsLoading) return <PageLoader />;
  // Don't show payment options page if no price on vehicle
  if (deal?.sellingPrice === null || deal?.sellingPrice === 0)
    return (
      <Success
        title="We Are Working On Your Quote!"
        subtitle="Thanks for reaching out! A team member is building your payment options and will reach out shortly. In the meantime, fill out forms here to save time at the dealership."
      />
    );
  if (creditApplicationsIsError || dealIsError || meIsError) return <PageError />;

  return <Calculator zip={me.zip} />;
}
