import React from 'react';
import { AddressGroup } from 'src/components/steps/AddressGroup';
import { AddressVerificationGroup } from 'src/components/steps/AddressVerificationGroup';
import { EmploymentGroup } from 'src/components/steps/EmploymentGroup';
import { EmploymentStatusGroup } from 'src/components/steps/EmploymentStatusGroup';
import { AddressType } from 'src/types/address';

export function EmploymentRequirement({ numberOfEmployers = 1 }: { numberOfEmployers: number }) {
  if (numberOfEmployers < 1) return null;

  const employerComponents: any[] = [];
  for (let i = 0; i < numberOfEmployers; i++) {
    employerComponents.push(
      <React.Fragment key={i}>
        <EmploymentStatusGroup index={i} />
        <EmploymentGroup index={i} />
        <AddressGroup index={i} type={AddressType.Employer} />
        <AddressVerificationGroup index={i} type={AddressType.Employer} />
      </React.Fragment>
    );
  }

  return <>{employerComponents}</>;
}
