import { useFavorites } from '../../fetches/useSWRFetch';
import { useDeals } from '../context/DealsProvider';
import VehicleSelector from '../selectors/VehicleSelector';
import { useMobileContext } from '../context/MobileProvider';
import VehicleSelectorMobile from '../selectors/VehicleSelectorMobile';

export default function VehicleDealSelector() {
  const { deal, dealIsLoading } = useDeals();
  const { favoritesIsLoading } = useFavorites();
  const isMobile = useMobileContext();

  if (dealIsLoading || favoritesIsLoading) return null;

  if (isMobile) {
    return <VehicleSelectorMobile vehicle={deal} />;
  }

  return <VehicleSelector vehicle={deal} />;
}
