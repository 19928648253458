import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

export enum FormWarningType {
  Warning = 'warning',
  Activity = 'activity',
}

export function FormWarning({
  text,
  type = FormWarningType.Warning,
}: {
  text: string;
  type?: FormWarningType;
}) {
  return (
    <div className={`form--action-bar mod-${type}`}>
      <FontAwesomeIcon
        icon={type === FormWarningType.Warning ? faExclamationTriangle : faInfoCircle}
        className="form--action-bar--icon u-icon"
      />
      <span>{text}</span>
    </div>
  );
}
