import { UseFormReturn } from 'react-hook-form';
import { Range, getTrackBackground } from 'react-range';
import './Slider.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useState } from 'react';
import { Colors } from 'src/types/colors';
import { useDebounce } from 'src/helpers/useDebounce';
import { DownPaymentInput } from './DownPaymentInput';
import { useConfig } from '../context/config';
import { useFormSubmit } from '../form/Form';
import { useDeals } from '../context/DealsProvider';

const STEP = 500;
const MIN = 0;

export function Slider({
  form,
  max,
  badge,
}: {
  form: UseFormReturn<any>;
  max: number;
  badge?: string;
}) {
  const { isSubmitting: isLoading } = useFormSubmit();
  const { deal } = useDeals();
  const isDeskingLocked = deal?.isDeskingLocked;

  const config = useConfig()!;
  const formDownPayment = form.watch('downPayment');

  const [localDownPayment, setLocalDownPayment] = useState<number[]>(formDownPayment);
  const [isDebounceLoading, setIsDebounceLoading] = useState(false);

  const debouncedRequest = useDebounce(() => {
    // Use debounced function with local state value
    handleDownPaymentRequest(localDownPayment);
    setIsDebounceLoading(false);
  });

  const handleDownPaymentRequest = useCallback(
    (values: number[]) => {
      form.setValue('downPayment', values, { shouldDirty: true });
    },
    [form]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(e.currentTarget.value);
    setIsDebounceLoading(true);

    if (!isNaN(val)) {
      // To do: what if max is not a multiple of the step?
      if (val < max && val > MIN) {
        setLocalDownPayment([val]);
        debouncedRequest();
      } else if (val >= max) {
        setLocalDownPayment([max]);
        debouncedRequest();
      } else if (val <= MIN) {
        setLocalDownPayment([MIN]);
        debouncedRequest();
      }
    } else if (isNaN(val)) {
      setLocalDownPayment([MIN]);
      debouncedRequest();
    }
  };

  const handleChange = (values: number[]) => {
    setLocalDownPayment(values);
  };

  const handleRequest = (values: number[]) => {
    handleDownPaymentRequest(values);
  };

  if (!config.isRetailing) {
    return (
      <DownPaymentInput
        handleChange={handleInputChange}
        value={localDownPayment[0]}
        isDeskingLocked={isDeskingLocked}
        badge={badge}
        isDebounceLoading={isDebounceLoading}
        max={max}
      />
    );
  }

  return (
    <div className={`slider--section u-mar-top-16 ${isLoading ? 'is-disabled ' : ''}`}>
      <div className="slider--section--top">
        <div className="slider--section--top--left">
          <div className="text--title-2">Down Payment</div>
        </div>
        <DownPaymentInput
          handleChange={handleInputChange}
          value={localDownPayment[0]}
          isDeskingLocked={isDeskingLocked}
          badge={badge}
          isDebounceLoading={isDebounceLoading}
          max={max}
        />
      </div>

      <div className="slider--range-holder">
        <Range
          disabled={isDeskingLocked}
          values={localDownPayment}
          step={STEP}
          min={MIN}
          max={max || 50000}
          onChange={handleChange}
          onFinalChange={handleRequest}
          renderTrack={({ props, children }) => {
            return (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                className="slider--track"
                style={{
                  ...props.style,
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: '24px',
                    width: '100%',
                    borderRadius: '7px',
                    background: getTrackBackground({
                      values: localDownPayment,
                      colors: [isDeskingLocked ? Colors.Gray3 : Colors.Blue1, 'transparent'],
                      min: MIN,
                      max: max,
                    }),
                    alignSelf: 'center',
                    boxSizing: 'border-box',
                  }}
                >
                  {children}
                </div>
              </div>
            );
          }}
          renderThumb={({ props, isDragged }) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              {...props}
              className={`slider--thumb ${isDragged ? 'is-active' : ''} ${
                isDeskingLocked ? 'is-locked' : ''
              }`}
              style={{
                ...props.style,
              }}
            >
              <div className={`slider--thumb--tooltip ${isDragged ? 'is-active' : ''}`}>
                {'$' + localDownPayment[0]?.toLocaleString('en-US')}
              </div>
              {isDeskingLocked ? (
                <FontAwesomeIcon
                  icon={faLock}
                  className="slider--thumb--lock"
                  color={Colors.Gray0}
                />
              ) : (
                <div className={`slider--thumb--inner ${isDragged ? 'is-active' : ''}`} />
              )}
            </div>
          )}
          renderMark={({ index }) => {
            // Instead of rendering every mark, we are showing 10 "decorative" marks
            if (index > 0) return null;
            return (
              <div key={`outer-mark-${index}`} className="slider--decorative-marks">
                {Array.from({ length: 10 }).map((_, index) => (
                  <div key={`mark-${index}`} className="slider--decorative-marks--mark"></div>
                ))}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
