import { TermType } from 'src/types/desking';

export function returnTermTypeString(termType: TermType | null) {
  switch (termType) {
    case TermType.Yearly:
      return '/year';
    case TermType.BiWeekly:
      return '/biweekly';
    case TermType.Weekly:
      return '/week';
    default:
      return '/mo';
  }
}
