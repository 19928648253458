import { useState } from 'react';
import { useChatContext } from './ChatActionProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { Colors } from 'src/types/colors';
import './ChatInput.css';

export function ChatInput() {
  const { handleSubmit } = useChatContext();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Prevent empty messages
    if (inputValue.trim() !== '') {
      handleSubmit(inputValue).then(() => {
        setInputValue('');
      });
    }
  };

  return (
    <div className="form--input--holder">
      <textarea
        className="chat-input--textarea"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Send us a message"
      />
      <button onClick={handleFormSubmit} className="chat-input--send">
        <FontAwesomeIcon icon={faPaperPlane} color={Colors.White} />
      </button>
    </div>
  );
}
