import { PaymentType } from 'src/types/deal';

export const PaymentTypeInputs = [
  {
    label: 'Financing',
    labelDesc: 'Monthly payments go towards ownership',
    name: 'paymentType',
    value: PaymentType.Financing,
    isDisabledKey: 'disableFinancing',
  },
  {
    label: 'Leasing',
    labelDesc: 'Rent a vehicle for a set period',
    name: 'paymentType',
    value: PaymentType.Leasing,
    isDisabledKey: 'disableLeasing',
  },
  {
    label: 'Cash',
    labelDesc: 'Pay for the vehicle upfront',
    name: 'paymentType',
    value: PaymentType.Cash,
    isDisabledKey: 'disableCash',
  },
];

export const PaymentMethodInputs = [
  {
    label: 'Financing',
    labelDesc: 'Monthly payments go towards ownership',
    name: 'paymentMethod',
    value: PaymentType.Financing,
    isDisabledKey: 'disableFinancing',
  },
  {
    label: 'Leasing',
    labelDesc: 'Rent a vehicle for a set period',
    name: 'paymentMethod',
    value: PaymentType.Leasing,
    isDisabledKey: 'disableLeasing',
  },
  {
    label: 'Cash',
    labelDesc: 'Pay for the vehicle upfront',
    name: 'paymentMethod',
    value: PaymentType.Cash,
    isDisabledKey: 'disableCash',
  },
];
