import { Address, State } from './address';
import { format } from 'date-fns';
import { siteDateFormat } from '../constants/validation';
import { convertPhoneForSubmit, convertBirthDateForSubmit } from '../constants/validation';
import { EmploymentStatus } from './employmentStatus';
import { Employer, nullEmployment, nullPreviousEmployment } from './employer';
import { ReferenceFormDefaultValues } from './reference-block';
import { Config } from 'src/components/context/config';

export enum ApplicantType {
  Self = 'self',
  Spouse = 'spouse',
  Relative = 'relative',
  RegisteredDomesticPartner = 'registeredDomesticPartner',
  CivilUnion = 'civilUnion',
  Other = 'other',
}

export enum ApplicationMode {
  Single = 'single',
  Joint = 'joint',
}

export enum Signer {
  Primary = 'primary',
  CoSigner = 'co-signer',
}

export enum HomeOwnership {
  Rent = 'rent',
  Mortgage = 'mortgage',
  Own = 'own',
  Other = 'other',
}

export interface CreditApplicationBlock {
  id: string;
  updatedAt: string;
  createdAt?: string;
  type: Signer;
  applicantType: ApplicantType | string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  email: string;
  phone: string | null;
  birthDate: string;
  ssn: string;
  ssnLastFour?: string;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: State | null;
  zip: string | null;
  driversLicenseNumber: string | null;
  driversLicenseState: State | null;
  homeOwnership: HomeOwnership;
  homeMonthlyPayment: number | null;
  homeLivingMonths: number | null;
  homeLivingYears: number | null;
  previousAddressLine1: string | null;
  previousAddressLine2: string | null;
  previousCity: string | null;
  previousState: string | null;
  previousZip: string | null;
  previousHomeOwnership: HomeOwnership;
  previousHomeMonthlyPayment: number | null;
  previousHomeLivingMonths: number | null;
  previousHomeLivingYears: number | null;
  employmentStatus: EmploymentStatus;
  employer: string | null;
  employerAddressLine1: string | null;
  employerAddressLine2: string | null;
  employerState: string | null;
  employerCity: string | null;
  employerZip: string | null;
  jobTitle: string | null;
  monthlyIncome: number | null;
  employerPhone: string | null;
  employmentMonths: number | null;
  employmentYears: number | null;
  previousEmploymentStatus: EmploymentStatus;
  previousEmployer: string | null;
  previousEmployerAddressLine1: string | null;
  previousEmployerAddressLine2: string | null;
  previousEmployerState: string | null;
  previousEmployerCity: string | null;
  previousEmployerZip: string | null;
  previousJobTitle: string | null;
  previousMonthlyIncome: number | null;
  previousEmployerPhone: string | null;
  previousEmploymentMonths: number | null;
  previousEmploymentYears: number | null;
  otherMonthlyIncome: number | null;
  otherMonthlyIncomeSource: string | null;
  softPullConsent: boolean | null;
  esignature: string | null;

  optoutCalls?: boolean;
  optoutSms?: boolean;
  optoutEmails?: boolean;

  location?: string;
  dealerId?: string;
}

// Create a new type for sending data by omitting updatedAt and createdAt
export type CreditApplicationSubmitBlock = Omit<
  CreditApplicationBlock,
  'id' | 'updatedAt' | 'createdAt'
>;

export interface CreditApplicationFormDefaultValues {
  id?: string;
  updatedAt?: string;
  createdAt?: string;
  type: Signer | string;
  applicantType: ApplicantType | string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
  birthDate: string;
  ssn: string;
  ssnLastFour: string;
  driversLicenseNumber: string;
  driversLicenseState: State | string;
  otherMonthlyIncome: number | string;
  otherMonthlyIncomeSource: string;
  softPullConsent: boolean;
  esignature: string;

  //Additional props
  applicationMode: ApplicationMode;
  employment: [Employer, Employer];
  addresses: [Address, Address];
  esignatureConsent: boolean;
  optoutCalls: boolean;
  optoutSms: boolean;
  optoutEmails: boolean;
  //Set Location to empty string by default
  location: string;
  dealerId: string;
  //
  references?: ReferenceFormDefaultValues[];
}

export function returnCreditApplicationDefaultValues(
  type?: Signer,
  data?: any,
  config?: Config
): CreditApplicationFormDefaultValues {
  return {
    id: data?.id ?? undefined,
    updatedAt: data?.updatedAt ?? undefined,
    createdAt: data?.createdAt ?? undefined,
    applicationMode: ApplicationMode.Single, // We have to default to this to avoid onSubmit errors
    type: type || data?.type || Signer.Primary, // Either signer is specified or it's in the data
    // We can specify applicantType, or we can use the data if it exists
    applicantType:
      type && type === Signer.Primary
        ? ApplicantType.Self
        : data?.applicantType ?? ApplicantType.Spouse,
    firstName: data?.firstName ?? '',
    middleName: data?.middleName ?? '',
    lastName: data?.lastName ?? '',
    email: data?.email ?? '',
    phone: data?.phone?.replace(/(\+1)/g, '') || '',
    birthDate: data?.birthDate ? format(new Date(data.birthDate), siteDateFormat) : '',
    ssnLastFour: data?.ssnLastFour ?? '',
    ssn: data?.ssnLastFour ? `***-**-${data.ssnLastFour}` : '',
    addresses: [
      {
        addressLine1: data?.addressLine1 ?? '',
        addressLine2: data?.addressLine2 ?? '',
        city: data?.city ?? '',
        state: data?.state ?? '',
        zip: data?.zip ?? '',
        //Always empty string for customer
        phone: '',
        homeOwnership: data?.homeOwnership ?? '',
        homeMonthlyPayment: data?.homeMonthlyPayment ?? '',
        homeLivingMonths: data?.homeLivingMonths ?? 0,
        homeLivingYears: data?.homeLivingYears ?? '',
      },
      {
        addressLine1: data?.previousAddressLine1 ?? '',
        addressLine2: data?.previousAddressLine2 ?? '',
        city: data?.previousCity ?? '',
        state: data?.previousState ?? '',
        zip: data?.previousZip ?? '',
        phone: '',
        homeOwnership: data?.previousHomeOwnership ?? '',
        homeMonthlyPayment: data?.previousHomeMonthlyPayment ?? '',
        homeLivingMonths: data?.previousHomeLivingMonths ?? 0,
        homeLivingYears: data?.previousHomeLivingYears ?? '',
      },
    ],
    employment: [
      {
        employer: data?.employer ?? '',
        employmentStatus: data?.employmentStatus ?? '',
        jobTitle: data?.jobTitle ?? '',
        monthlyIncome: data?.monthlyIncome ?? '',
        employmentMonths: data?.employmentMonths ?? 0,
        employmentYears: data?.employmentYears ?? '',
        addressLine1: data?.employerAddressLine1 ?? '',
        addressLine2: data?.employerAddressLine2 ?? '',
        city: data?.employerCity ?? '',
        state: data?.employerState ?? '',
        zip: data?.employerZip ?? '',
        // Employer phone is moved to address object
        phone: data?.employerPhone?.replace(/(\+1)/g, '') ?? '',
      },
      // Create the previous address object
      {
        employer: data?.previousEmployer ?? '',
        employmentStatus: data?.previousEmploymentStatus ?? '',
        jobTitle: data?.previousJobTitle ?? '',
        monthlyIncome: data?.previousMonthlyIncome ?? '',
        employmentMonths: data?.previousEmploymentMonths ?? 0,
        employmentYears: data?.previousEmploymentYears ?? '',
        addressLine1: data?.previousEmployerAddressLine1 ?? '',
        addressLine2: data?.previousEmployerAddressLine2 ?? '',
        city: data?.previousEmployerCity ?? '',
        state: data?.previousEmployerState ?? '',
        zip: data?.previousEmployerZip ?? '',
        // Employer phone is moved to address object
        phone: data?.previousEmployerPhone?.replace(/(\+1)/g, '') ?? '',
      },
    ],
    driversLicenseNumber: data?.driversLicenseNumber ?? '',
    driversLicenseState: data?.driversLicenseState ?? '',
    otherMonthlyIncome: data?.otherMonthlyIncome ?? '',
    otherMonthlyIncomeSource: data?.otherMonthlyIncomeSource ?? '',
    softPullConsent: data?.softPullConsent ?? false,
    esignatureConsent: data?.esignature ? true : false,
    esignature: data?.esignature ?? '',
    optoutCalls: data?.optoutCalls ?? config?.forms?.lead?.explicitOptIn ? true : false,
    optoutSms: data?.optoutSms ?? config?.forms?.lead?.explicitOptIn ? true : false,
    optoutEmails: data?.optoutEmails ?? config?.forms?.lead?.explicitOptIn ? true : false,
    location: data?.location ?? '',
    dealerId: data?.dealerId ?? '',
  };
}

function convertToNumberOrAssignNull(value: string | number): number | null {
  return value === '' ? null : Number(value) ?? null;
}

export function returnSubmissionBlockData(
  data: CreditApplicationFormDefaultValues
): CreditApplicationSubmitBlock {
  const ssn = data.ssn.replace(/[^0-9]/g, '');
  const isNewSsn = ssn.length === 9;
  const currentAddress = data.addresses[0];
  const previousAddress = data.addresses[1];
  const currentEmployer = data.employment[0];
  const previousEmployer = data.employment[1];

  const address = {
    addressLine1: currentAddress.addressLine1 || null,
    addressLine2: currentAddress.addressLine2 || null,
    city: currentAddress.city || null,
    state: (currentAddress.state as State) || null,
    zip: currentAddress.zip || null,
    homeOwnership: (currentAddress.homeOwnership as HomeOwnership) || null,
    homeMonthlyPayment: Number(currentAddress.homeMonthlyPayment) || null,
    homeLivingMonths:
      currentAddress.homeLivingYears && currentAddress.homeLivingMonths
        ? convertToNumberOrAssignNull(currentAddress.homeLivingMonths)
        : null, // If years is empty, months should be null
    homeLivingYears: currentAddress.homeLivingYears
      ? convertToNumberOrAssignNull(currentAddress.homeLivingYears)
      : null,
  };

  const prevAddress = {
    previousAddressLine1: previousAddress.addressLine1 || null,
    previousAddressLine2: previousAddress.addressLine2 || null,
    previousCity: previousAddress.city || null,
    previousState: previousAddress.state || null,
    previousZip: previousAddress.zip || null,
    previousHomeOwnership: (previousAddress.homeOwnership as HomeOwnership) || null,
    previousHomeMonthlyPayment: Number(previousAddress.homeMonthlyPayment) || null,
    previousHomeLivingYears: previousAddress.homeLivingYears
      ? convertToNumberOrAssignNull(previousAddress.homeLivingYears)
      : null,
    previousHomeLivingMonths:
      previousAddress.homeLivingYears && previousAddress.homeLivingMonths
        ? convertToNumberOrAssignNull(previousAddress.homeLivingMonths)
        : null, // If years is empty, months should be null
  };

  const employment =
    currentEmployer.employmentStatus === EmploymentStatus.Unemployed ||
    currentEmployer.employmentStatus === EmploymentStatus.Retired
      ? nullEmployment
      : {
          employer: currentEmployer.employer || null,
          employerAddressLine1: currentEmployer.addressLine1 || null,
          employerAddressLine2: currentEmployer.addressLine2 || null,
          employerState: currentEmployer.state || null,
          employerCity: currentEmployer.city || null,
          employerZip: currentEmployer.zip || null,
          jobTitle: currentEmployer.jobTitle || null,
          monthlyIncome: Number(currentEmployer.monthlyIncome) || null,
          employerPhone: convertPhoneForSubmit(currentEmployer.phone),
          employmentYears: convertToNumberOrAssignNull(currentEmployer.employmentYears),
          employmentMonths:
            currentEmployer.employmentYears && currentEmployer.employmentMonths
              ? convertToNumberOrAssignNull(currentEmployer.employmentMonths)
              : null, // If years is empty, months should be null
        };

  const previousEmployment =
    previousEmployer.employmentStatus === EmploymentStatus.Unemployed ||
    previousEmployer.employmentStatus === EmploymentStatus.Retired
      ? nullPreviousEmployment
      : {
          previousEmployerAddressLine1: previousEmployer.addressLine1 || null,
          previousEmployerAddressLine2: previousEmployer.addressLine2 || null,
          previousEmployerState: previousEmployer.state || null,
          previousEmployerCity: previousEmployer.city || null,
          previousEmployerZip: previousEmployer.zip || null,
          previousEmployer: previousEmployer.employer || null,
          previousJobTitle: previousEmployer.jobTitle || null,
          previousMonthlyIncome: Number(previousEmployer.monthlyIncome) || null,
          previousEmployerPhone: convertPhoneForSubmit(previousEmployer.phone),
          previousEmploymentYears: convertToNumberOrAssignNull(previousEmployer.employmentYears),
          previousEmploymentMonths:
            previousEmployer.employmentMonths && previousEmployer.employmentYears
              ? convertToNumberOrAssignNull(previousEmployer.employmentMonths)
              : null, // If years is empty, months should be null
        };
  return {
    ...employment,
    ...previousEmployment,
    ...address,
    ...prevAddress,
    type: data.type as Signer,
    firstName: data.firstName,
    lastName: data.lastName,
    middleName: data.middleName,
    email: data.email,
    phone: convertPhoneForSubmit(data.phone),
    ssn: isNewSsn ? data.ssn.replace(/[^0-9]/g, '') : '',
    driversLicenseNumber: (data.driversLicenseNumber as string) || null,
    driversLicenseState: (data.driversLicenseState as State) || null,
    employmentStatus: (currentEmployer.employmentStatus as EmploymentStatus) || null,
    previousEmploymentStatus: (previousEmployer.employmentStatus as EmploymentStatus) || null,
    birthDate: convertBirthDateForSubmit(data.birthDate),
    applicantType: (data.applicantType as ApplicantType) || ApplicantType.Self,
    otherMonthlyIncome: Number(data.otherMonthlyIncome) || null,
    otherMonthlyIncomeSource: data.otherMonthlyIncomeSource || null,
    softPullConsent: data.softPullConsent || null,
    esignature: data.esignature || null,
  };
}

export interface CommonCreditAppGroupProps {
  handleSubmit: (data: CreditApplicationFormDefaultValues) => void;
  payment: CreditApplicationFormDefaultValues;
}

export function requiresMortgage(ownershipType: HomeOwnership) {
  return [HomeOwnership.Mortgage, HomeOwnership.Rent, HomeOwnership.Other].includes(ownershipType);
}
