import { AppointmentStatusList } from 'src/types/appointment';
import ButtonSegmentedPicker from '../controls/ButtonSegmentedPicker';
import { useAuth } from '../context/AuthProvider';
import { useRef } from 'react';
import ButtonWithConfirmation from '../floating/popover/ButtonWithConfirmation';
import { useAppointments } from 'src/fetches/useSWRFetch';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { FullBlockRoute } from 'src/types/blocks';

export function AppointmentStatus({ appointment, form }) {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { appointmentsDelete } = useAppointments();
  const containerRef = useRef<HTMLDivElement>(null);

  // Only show status for existing appointments
  if (!appointment?.id) return null;

  return (
    <div>
      <div className="form--split-label">
        <div className="text--body-2 u-mar-top-8">Are you going?</div>
        {token ? (
          <ButtonWithConfirmation
            containerRef={containerRef}
            isSubmitting={false}
            title="Remove Appointment"
            body="Are you sure? This appointment will need to be rescheduled"
            text="Delete"
            baseClass="btn-link mod-danger"
            onSubmit={async () => {
              try {
                await appointmentsDelete(appointment?.id);
                toast.success('Appointment cancelled');
                navigate(FullBlockRoute.Appointments);
              } catch (error: any) {
                toast.error(error.message);
              }
            }}
          />
        ) : null}
      </div>
      <div className="button-segmented-picker--holder">
        {AppointmentStatusList.map(item => (
          <ButtonSegmentedPicker
            key={item.value}
            value={item.value}
            label={item.label}
            icon={item.icon}
            iconColor={item.iconColor}
            name="status"
            control={form.control}
          />
        ))}
      </div>
    </div>
  );
}
