import { useConfig } from 'src/components/context/config';
import {
  VehicleCondition,
  returnVehicleCondition,
  returnVehicleMileage,
} from '../../../types/vehicle';

interface VehicleCardTermsProps {
  condition: VehicleCondition | null;
  mileage: number | null;
  price: number | null;
}

export function VehicleCardTerms({ condition, mileage, price }: VehicleCardTermsProps) {
  const config = useConfig()!;
  const formattedCondition = returnVehicleCondition(condition);
  const formattedMileage = returnVehicleMileage(mileage);

  if (config.packages?.hideprices || !formattedMileage || !price) {
    return <>{formattedCondition}</>;
  }

  const conditionMileageLabel = `${formattedCondition} • ${formattedMileage}`;
  return <>{conditionMileageLabel}</>;
}
