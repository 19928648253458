import { useParams } from 'react-router-dom';
import { BlockRoute } from 'src/types/blocks';
import CreditApplicationSuccessPage from '../payment-method/CreditApplicationSuccessPage';
import TradeinSuccessPage from '../trade-in/TradeinSuccessPage';
import DocumentSuccessPage from '../documents/DocumentSuccessPage';
import ProfileSuccessPage from 'src/pages/basic-info/ProfileSuccessPage';

export function SuccessPage() {
  const { block } = useParams();

  // switch through possible block routes to return right markup
  switch (block) {
    case BlockRoute.Account:
    case 'basic-info': //deprecated
    case 'my-profile': //deprecated
    case 'profile': //deprecated
      return <ProfileSuccessPage />;
    case BlockRoute.CreditApplication:
    case 'payment-method': //deprecated
      return <CreditApplicationSuccessPage />;
    case BlockRoute.TradeIn:
      return <TradeinSuccessPage />;
    case BlockRoute.Documents:
      return <DocumentSuccessPage />;

    default:
      return null;
  }
}
