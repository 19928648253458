import PageLoader from '../components/PageLoader';
import PageError from '../components/PageError';
import { useMe } from '../fetches/useSWRFetch';
import { ContactCard } from '../components/cards/ContactCard';
import { Customer } from 'src/types/customer';

function ContactPage({ me }: { me: Customer }) {
  return (
    <div className="contact-card--container">
      <ContactCard item={me} />
    </div>
  );
}

export default function ContactIndexPage() {
  const { me, meIsLoading, meIsError } = useMe();
  if (meIsLoading) return <PageLoader />;
  if (meIsError) return <PageError />;

  return <ContactPage me={me} />;
}
