// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Email, FirstName, LastName, MiddleName, Phone } from '../inputs/Input';
import { paymentTypeSchema } from '../../constants/schemas';
import { FormBody, useFormSubmit } from '../form/Form';
import { CreditApplicationActionBar } from '../form/CreditApplicationActionBar';

export const PaymentInfoGroup = () => {
  const { handleFormGroupSubmit, formValues: payment, activeForm } = useFormSubmit();
  const form = useForm({
    resolver: joiResolver(Joi.object(paymentTypeSchema)),
    mode: 'onBlur',
    defaultValues: {
      firstName: payment.firstName,
      lastName: payment.lastName,
      middleName: payment.middleName,
      email: payment.email,
      phone: payment.phone,
    },
  });

  if (activeForm !== 'paymentInfoForm') {
    return null;
  }

  return (
    <form id="paymentInfoForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Provide your basic information"
        body="The personal information you provide will be securely kept and will only be used to process your loan application."
      />
      <CreditApplicationActionBar />
      <div className="u-display-grid grid--50">
        <FirstName control={form.control} />
        <MiddleName control={form.control} />
      </div>
      <LastName control={form.control} />
      <Email control={form.control} />
      <Phone control={form.control} />
    </form>
  );
};
