import { AddressGroup } from 'src/components/steps/AddressGroup';
import { AddressOwnershipGroup } from 'src/components/steps/AddressOwnershipGroup';
import { AddressPaymentGroup } from 'src/components/steps/AddressPaymentGroup';
import { AddressVerificationGroup } from 'src/components/steps/AddressVerificationGroup';
import { AddressType } from 'src/types/address';

export function AddressRequirement({ numberOfAddresses = 1 }: { numberOfAddresses: number }) {
  if (numberOfAddresses < 1) return null;

  const addressComponents: any[] = [];
  for (let i = 0; i < numberOfAddresses; i++) {
    addressComponents.push(
      <AddressGroup key={`AddressGroup-${i}`} index={i} type={AddressType.Customer} />,
      <AddressVerificationGroup
        key={`AddressVerificationGroup-${i}`}
        index={i}
        type={AddressType.Customer}
      />,
      <AddressOwnershipGroup key={`AddressOwnershipGroup-${i}`} index={i} />,
      <AddressPaymentGroup key={`AddressPaymentGroup-${i}`} index={i} />
    );
  }

  return <>{addressComponents}</>;
}
