import { useContext, useEffect } from 'react';
import { NavigationContext } from '../context/NavigationProvider';

export function NavigateNextDealBlock() {
  const { navigateNextUnfinishedBlock } = useContext(NavigationContext);

  useEffect(() => {
    if (navigateNextUnfinishedBlock) {
      navigateNextUnfinishedBlock();
    }
  }, [navigateNextUnfinishedBlock]);

  return null;
}
