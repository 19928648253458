import { MultipleDealResponse, SingleDealResponse, UnauthorizedDealResponse } from './deal';
import { Favorite } from './favorite';

export enum VehicleCondition {
  Used = 'used',
  New = 'new',
  Certified = 'certified',
}

export enum VehicleStatus {
  Available = 'available',
  Unavailable = 'unavailable',
  Hidden = 'hidden',
}

export interface CRMVehicle {
  vin: string;
  stock: string;
  make: string;
  model: string;
  trim: string;
  year: number;
  photos: string[];
  stockPhotos640: string[];
  price: number;
  mileage: number;
  condition: VehicleCondition;
  status: VehicleStatus;
  substatus: string;
  inventoryDate: string;
  dealershipName: string;
}

export interface WebsiteVehicle {
  vin: string;
  status: VehicleStatus;
  dealershipId: string;

  year: number;
  make: string;
  model: string;
  trim: string;

  condition: VehicleCondition;

  stock?: string | null;
  incentives?: string | null;
  totalCustomerIncentives?: number | null;

  photos?: string[];
  stockPhotos640?: string[];
  stockPhotos1280?: string[];
  photosCount?: number | null;
  video?: string | null;

  fuel?: string | null;
  engine?: string | null;
  highwayMPG?: number | null;
  cityMPG?: number | null;

  safetyFeatures: string[];
  safetyHighlights: string[];

  technologyFeatures: string[];
  technologyHighlights: string[];

  exteriorFeatures: string[];
  exteriorHighlights: string[];

  interiorFeatures: string[];
  interiorHighlights: string[];

  mechanicalFeatures: string[];
  mechanicalHighlights: string[];

  moreFeatures: string[];
  moreHighlights: string[];

  description?: string | null;
  inventoryDate: string;
  msrp?: number | null;
  price?: number | null;

  originalInteriorColor?: string | null;
  color?: string | null | null;
  colorHex?: string | null | null;
  originalColor?: string | null;

  transmissionDescription?: string | null;
  transmission?: string | null;
  engineDisplacement?: string | null;
  engineCylinderCount?: number | null;
  mileage?: number | null;
  doorsCount?: number | null;
  body?: string | null;
  driveline?: string | null;
  modelCode?: string | null;
  updatedAt: string;

  customTags: string[];
  manualCustomTags: string[];
}

export function returnVehicleCondition(condition: VehicleCondition | null) {
  switch (condition) {
    case VehicleCondition.Used:
      return 'Used';
    case VehicleCondition.New:
      return 'New';
    case VehicleCondition.Certified:
      return 'Certified';
    default:
      return null;
  }
}

export function returnVehicleMileage(mileage: number | null) {
  return mileage ? `${mileage.toLocaleString('en-US')} mi` : '0 mi';
}

export function returnVehiclePrice(price: number | null) {
  return price ? `$${price.toLocaleString('en-US')}` : null;
}

export function returnVehicleYearMakeModel(
  vehicle: Favorite | MultipleDealResponse | SingleDealResponse | UnauthorizedDealResponse
) {
  if (vehicle.vin) {
    return `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
  } else return 'No Vehicle Added';
}

export function returnVehiclePhoto(vehicle: any) {
  if (!vehicle) return null;
  return (
    vehicle?.img || vehicle?.photo || vehicle?.photos?.[0] || vehicle?.stockPhotos640?.[0] || null
  );
}
