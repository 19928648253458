import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { createContext, useContext, useMemo, useState } from 'react';
import { useConfig } from '../context/config';
import { fireAnalyticsEvents } from 'src/handlers/useHandleFormAnalyticsEvent';

interface FormSubmitContextProps {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  activeForm: string;
  formValues: Partial<any>;
  handleFormGroupSubmit: (data: any) => Promise<void>;
  handleFormGroupBack: () => void;
  handleFormGroupSkip: () => void;
  handleFormCancel: () => void;
  formSteps: string[];
  activeStepIndex: number;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
}

const FormSubmitContext = createContext<FormSubmitContextProps | undefined>(undefined);

export const useFormSubmit = () => {
  const context = useContext(FormSubmitContext);
  if (!context) {
    throw new Error('useFormSubmit must be used within a FormSubmitProvider');
  }
  return context;
};

interface FormSubmitProviderProps {
  children: React.ReactNode;
  handleFormSubmit: (data: any) => Promise<void>;
  handleFormCancel: any;
  multistepFormValues?: any;
  steps: string[];
  classNames?: string | null;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
  savedForm?: boolean;
}

export const FormSubmitProvider: React.FC<FormSubmitProviderProps> = ({
  children,
  handleFormSubmit,
  handleFormCancel,
  multistepFormValues = {},
  steps,
  conditionalFields = {},
  savedForm,
}) => {
  const config = useConfig()!;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<Partial<any>>(multistepFormValues);
  const formSteps = useMemo(() => {
    const array: string[] = steps;

    //filter steps based on conditional fields. if no conditional field prop with step exists, include it. if it does, check if it should be included
    return array.filter(step => {
      if (conditionalFields[step] === undefined) {
        return true;
      }
      return !conditionalFields[step](formValues);
    });
  }, [formValues, steps, conditionalFields]);
  const [activeStepIndex, setActiveStepIndex] = useState(savedForm ? formSteps.length - 1 : 0);

  const handleFormGroupSubmit = async (data: any) => {
    const updatedFormData = {
      ...formValues,
      ...data,
    };

    setIsSubmitting(true);
    setFormValues(updatedFormData);

    if (!config.isRetailing) {
      fireAnalyticsEvents(config.form || 'Space Widget', formSteps[activeStepIndex]);
    }

    if (activeStepIndex !== formSteps.length - 1) {
      setActiveStepIndex(prev => prev + 1);
      setIsSubmitting(false);
    } else {
      try {
        await handleFormSubmit(updatedFormData).finally(() => {
          setIsSubmitting(false);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleFormGroupBack = () => {
    if (activeStepIndex === 0) {
      handleFormCancel();
    } else {
      setActiveStepIndex(prev => prev - 1);
    }
  };

  const handleFormGroupSkip = () => {
    setActiveStepIndex(prev => prev + 1);
  };

  if (formSteps[activeStepIndex] === undefined) {
    // to do: add loading spinner
    return <div>Form Submitting</div>;
  }

  return (
    <FormSubmitContext.Provider
      value={{
        handleFormGroupSubmit,
        handleFormGroupBack,
        handleFormGroupSkip,
        activeForm: formSteps[activeStepIndex],
        activeStepIndex,
        handleFormCancel,
        isSubmitting,
        setIsSubmitting,
        formValues,
        conditionalFields,
        formSteps,
      }}
    >
      {children}
    </FormSubmitContext.Provider>
  );
};

export function useSpaceForm(resolver, defaultValues) {
  return useForm({
    resolver: joiResolver(Joi.object(resolver), {
      // Allows Additional Field inputs to exists
      allowUnknown: true,
    }),
    mode: 'onBlur',
    defaultValues,
  });
}

export function FormContentWrapper({
  form,
  id,
  children,
  autocomplete = undefined,
}: {
  form: UseFormReturn<any>;
  id: string;
  children: React.ReactNode;
  autocomplete?: string;
}) {
  const { handleFormGroupSubmit } = useFormSubmit();
  return (
    <div className="block-container--content">
      <FormProvider {...form}>
        <form
          id={id}
          className={'block-container--content--flex'}
          onSubmit={form.handleSubmit(handleFormGroupSubmit)}
          autoComplete={autocomplete}
        >
          {children}
        </form>
      </FormProvider>
    </div>
  );
}

export function FormContentMultiStepWrapper({
  children,
  centerContent,
}: {
  children: React.ReactNode;
  centerContent?: boolean;
}) {
  return (
    <div className="block-container--content">
      <div className={centerContent ? 'block-container--centered-centered-content' : ''}>
        {children}
      </div>
    </div>
  );
}

export function FormWrapper({
  children,
  handleFormSubmit,
  handleFormCancel,
  multistepFormValues = {},
  steps,
  classNames,
  conditionalFields,
  savedForm,
}: {
  children: React.ReactNode;
  handleFormSubmit: (data: any) => Promise<void>;
  handleFormCancel: any;
  multistepFormValues?: any;
  steps: string[];
  classNames?: string | null;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
  savedForm?: boolean;
}) {
  const config = useConfig()!;

  return (
    <FormSubmitProvider
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={handleFormCancel}
      multistepFormValues={multistepFormValues}
      steps={steps}
      conditionalFields={conditionalFields}
      savedForm={savedForm}
    >
      <div
        className={
          config.isRetailing
            ? `block-container ${classNames}`
            : `block-modal-container ${classNames}`
        }
      >
        {children}
      </div>
    </FormSubmitProvider>
  );
}

export function FormBody({ title, body }: { title: string | null; body: string | null }) {
  return (
    <div>
      {title && <div className="block-form--heading">{title}</div>}
      {body && <div className="block-form--desc">{body}</div>}
    </div>
  );
}
