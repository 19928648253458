// @ts-nocheck
import React, { Component } from 'react';
import './SwipeToConfirm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons';
import { Colors } from 'src/types/colors';

const slider = React.createRef();
const container = React.createRef();
const isTouchDevice = 'ontouchstart' in document.documentElement;

interface SwipeToConfirmProps {
  color: string;
  text: string;
  textConfirmed: string;
  onSuccess: () => void;
  onFailure?: () => void;
  isShadowRoot: boolean;
  title: string;
}

export default class SwipeToConfirm extends Component<SwipeToConfirmProps> {
  isDragging = false;
  sliderLeft = 0;
  startX = 0;

  state = {
    confirmed: false,
  };

  componentDidMount() {
    // Access the shadow root if it exists
    const root = this.rootNode().getRootNode();
    const eventTarget = this.props.isShadowRoot ? root : document;

    if (isTouchDevice) {
      eventTarget.addEventListener('touchmove', this.onDrag);
      eventTarget.addEventListener('touchend', this.stopDrag);
    } else {
      eventTarget.addEventListener('mousemove', this.onDrag);
      eventTarget.addEventListener('mouseup', this.stopDrag);
    }
    this.containerWidth = container.current.clientWidth - 56;
  }

  componentWillUnmount() {
    // Access the shadow root if it exists
    const root = this.rootNode().getRootNode();
    const eventTarget = this.props.isShadowRoo ? root : document;

    this.unmounted = true;
    if (isTouchDevice) {
      eventTarget.removeEventListener('touchmove', this.onDrag);
      eventTarget.removeEventListener('touchend', this.stopDrag);
    } else {
      eventTarget.removeEventListener('mousemove', this.onDrag);
      eventTarget.removeEventListener('mouseup', this.stopDrag);
    }
  }

  rootNode = () => {
    return container.current || document;
  };

  onDrag = e => {
    if (this.unmounted || this.state.confirmed) return;
    if (this.isDragging) {
      if (isTouchDevice) {
        this.sliderLeft = Math.min(
          Math.max(0, e.touches[0].clientX - this.startX),
          this.containerWidth
        );
      } else {
        this.sliderLeft = Math.min(Math.max(0, e.clientX - this.startX), this.containerWidth);
      }
      this.updateSliderStyle();
    }
  };

  updateSliderStyle = () => {
    if (this.unmounted || this.state.confirmed) return;
    slider.current.style.left = this.sliderLeft + 56 + 'px';
  };

  stopDrag = () => {
    if (this.unmounted || this.state.confirmed) return;
    if (this.isDragging) {
      this.isDragging = false;
      if (this.sliderLeft > this.containerWidth * 0.9) {
        this.sliderLeft = this.containerWidth;
        this.onSuccess();
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      } else {
        this.sliderLeft = 0;
        if (this.props.onFailure) {
          this.props.onFailure();
        }
      }
      this.updateSliderStyle();
    }
  };

  startDrag = e => {
    if (this.unmounted || this.state.confirmed) return;
    this.isDragging = true;
    if (isTouchDevice) {
      this.startX = e.touches[0].clientX;
    } else {
      this.startX = e.clientX;
    }
  };

  onSuccess = () => {
    container.current.style.width = container.current.clientWidth + 'px';
    this.setState({
      confirmed: true,
    });
  };

  getText = () => {
    return this.state.confirmed
      ? this.props.textConfirmed || 'Confirmed'
      : this.props.text || 'SLIDE';
  };

  reset = () => {
    if (this.unmounted) return;
    this.setState({ confirmed: false }, () => {
      this.sliderLeft = 0;
      this.updateSliderStyle();
    });
  };

  render() {
    return (
      <div className="swipe-button--holder">
        <div>
          <div className="text--title-2">Swipe to Confirm Your Submission</div>
        </div>
        <div className="text--body-1 u-mar-bottom-16">
          By swiping to confirm, I agree that this signature will be an electronic representation of
          my signature.
        </div>
        <div className="swipe-button--container">
          <div className="swipe-to-confirm">
            <div
              className={
                'swipe-to-confirm--container ' + (this.state.confirmed ? 'is-confirmed' : '')
              }
              ref={container}
            >
              <div
                className="swipe-to-confirm--slider"
                ref={slider}
                onMouseDown={this.startDrag}
                style={{
                  background: this.state.confirmed
                    ? Colors.Green1
                    : `linear-gradient(269.99deg, rgba(52, 199, 89, 0) 0.01%, rgba(52, 199, 89, 0.36) 16.53%, rgba(52, 199, 89, 0) 99.99%)`,
                }}
                onTouchStart={this.startDrag}
                role="button"
                tabIndex={0}
              >
                <span className="swipe-to-confirm--screen"></span>
                <span
                  className="swipe-to-confirm--circle"
                  style={{ background: this.state.confirmed ? Colors.White : Colors.Green1 }}
                >
                  <span className="swipe-to-confirm--arrow">
                    <FontAwesomeIcon
                      icon={this.state.confirmed ? faCheckCircle : faChevronDoubleRight}
                      color={this.state.confirmed ? Colors.Green1 : Colors.White}
                    />
                  </span>
                </span>
              </div>
              <div className="swipe-to-confirm--text is-confirmed">{this.getText()}</div>
              <div className="swipe-to-confirm--text">{this.getText()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
