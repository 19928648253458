import { BlockRoute } from 'src/types/blocks';
import { ChatMessage } from 'src/types/chat';

export function parseMessage(newMessages: ChatMessage[]) {
  const userMessage = newMessages[newMessages.length - 1].text.toLowerCase();
  let botResponse: Partial<ChatMessage> = {
    text: "Sorry, I didn't understand that. Try asking something else",
    type: 'bot' as 'bot',
  };

  if (userMessage.includes('hello')) {
    botResponse.text = 'Hi there! How can I help you?';
  } else if (userMessage.includes('how are you')) {
    botResponse.text = 'I am just a bot, but I am doing great!';
  } else if (userMessage.includes('bye')) {
    botResponse.text = 'Goodbye! Have a great day!';
  }

  if (
    userMessage.includes('credit application') ||
    userMessage.includes('credit') ||
    userMessage.includes('financ') ||
    userMessage.includes('payment method')
  ) {
    botResponse.task = BlockRoute.CreditApplication;
  }

  if (userMessage.includes('trade')) {
    botResponse.task = BlockRoute.TradeIn;
  }

  if (
    userMessage.includes('appointment') ||
    userMessage.includes('visit') ||
    userMessage.includes('test')
  ) {
    botResponse.task = BlockRoute.TradeIn;
  }

  if (
    userMessage.includes('looking') ||
    userMessage.includes('do you have') ||
    userMessage.includes('in stock')
  ) {
    botResponse.task = BlockRoute.PaymentOptions;
  }

  return botResponse;
}
