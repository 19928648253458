import { useMemo } from 'react';
import { useCreditApplications } from 'src/fetches/useSWRFetch';
import { BlockStatus } from 'src/types/blocks';
import { useDeals } from '../DealsProvider';
import { PaymentType } from 'src/types/deal';
import { Signer } from 'src/types/creditapplication-block';
import { useSessionContext } from '../SessionProvider';

export const useCreditApplicationsStatus = () => {
  const { deal } = useDeals();
  const { creditApplications } = useCreditApplications();
  const { creditAppVisited } = useSessionContext();

  return useMemo(() => {
    // Payment Type is Cash, let's hide from flow altogether
    if (deal?.paymentType === PaymentType.Cash) {
      return BlockStatus.Hidden;
    }

    // User has 1 or more credit apps, or
    if (creditApplications?.filter(item => item.type === Signer.Primary)?.length > 0) {
      return BlockStatus.Done;
    }

    // User has been to credit app page and has a Payment Type of Financing or Leasing
    if (creditAppVisited === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [creditApplications, deal, creditAppVisited]);
};
