import { Control, useController } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

interface FormErrorProps {
  name: string;
  control: Control<any>;
}
export const FormError = ({ name, control }: FormErrorProps) => {
  const {
    field: { value },
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  return (
    <div>
      {isSubmitted || isTouched ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <div className="form--error">{!value && message}</div>}
        />
      ) : null}
    </div>
  );
};
