// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { HardPullGroup } from './HardPullGroup';
import { DealershipLocations } from 'src/helpers/useDealershipLocations';
import { PullConsentText } from 'src/constants/pullConsentText';
import { useFormSubmit } from '../form/Form';
import { MultilocationRadioGroup } from './MultilocationRadioGroup';
import { useConfig } from '../context/config';
import { esignature, esignatureConsent, location, softPullConsent } from 'src/constants/schemas';

const validationSchema = {
  softPullConsent,
  esignature,
  esignatureConsent,
};

interface PaymentFinalizeAndLocationGroupProps {
  locations: DealershipLocations;
}

export const PaymentFinalizeAndLocationGroup = ({
  locations,
}: PaymentFinalizeAndLocationGroupProps) => {
  const config = useConfig()!;
  const { handleFormGroupSubmit, formValues: payment, activeForm } = useFormSubmit();

  const requiredValidation = config.forms?.creditApplication?.pullConsentIsHidden
    ? {}
    : validationSchema;

  const schema =
    config.location === null
      ? Joi.object(requiredValidation)
      : Joi.object({ ...requiredValidation, location });
  const form = useForm({
    resolver: joiResolver(schema, {
      allowUnknown: true,
    }),
    mode: 'onBlur',
    defaultValues: {
      softPullConsent: payment.softPullConsent,
      esignatureConsent: payment.esignatureConsent,
      esignature: payment.esignature,
      location: typeof config.location === 'string' ? config.location : payment.location,
    },
  });

  if (activeForm !== 'paymentFinalizeAndLocationForm') {
    return null;
  }

  return (
    <form id="paymentFinalizeAndLocationForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <PullConsentText />
      <HardPullGroup form={form} />
      <MultilocationRadioGroup
        form={form}
        locations={locations.dropdownList}
        allowDealerDropdown={true}
      />
    </form>
  );
};
