import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useAuth } from '../AuthProvider';
import { useConfig } from '../config';
import { returnDocumentsAreEnabled } from 'src/constants/documents';
import { useDocuments } from 'src/fetches/useSWRFetch';

export const useOtherDocumentStatus = () => {
  const { documents } = useDocuments();
  const config = useConfig()!;
  const { token } = useAuth();

  // filter down to documents that have tag of other
  const otherDocuments = documents?.filter(item => item.tag === 'other');

  return useMemo(() => {
    if (!returnDocumentsAreEnabled(token, config)) {
      return BlockStatus.Hidden;
    }

    if (otherDocuments?.length) {
      return BlockStatus.Done;
    }

    return BlockStatus.Default;
  }, [config, token, otherDocuments]);
};
