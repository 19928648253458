import { useConfig } from '../context/config';
import { FormSignIn } from './FormSignIn';
import { FormPrimaryNavigation } from './FormPrimaryNavigation';
import { FormSecondaryNavigation } from './FormSecondaryNavigation';

interface FormActionsProps {
  isSubmitting?: boolean;
  hideBackButton?: boolean; // Useful for hiding back button
  alternateButtonText?: string; // Used to override button text
  fullButton?: boolean; // Used to make button full width
  hidePrimaryButton?: boolean; // Used to hide primary button
  showSkipButton?: boolean; // Used to show skip button
  formNavigationClass?: string; // Used to add additional classes to the form actions
}

export function FormActions({
  isSubmitting,
  hideBackButton,
  alternateButtonText,
  fullButton,
  hidePrimaryButton,
  showSkipButton,
  formNavigationClass,
}: FormActionsProps) {
  const config = useConfig()!;

  const className = config.isRetailing
    ? formNavigationClass
      ? formNavigationClass
      : 'block-container--fixed-nav'
    : '';

  return (
    <div className={className}>
      <FormSecondaryNavigation />
      <FormSignIn />
      <FormPrimaryNavigation
        isSubmitting={isSubmitting}
        hideBackButton={hideBackButton}
        alternateButtonText={alternateButtonText}
        fullButton={fullButton}
        hidePrimaryButton={hidePrimaryButton}
        showSkipButton={showSkipButton}
      />
    </div>
  );
}
