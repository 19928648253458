import { useContext, useEffect, useMemo } from 'react';
import { useDeals } from 'src/components/context/DealsProvider';
import { useConfig } from 'src/components/context/config';
import { useAppointments, useDesk, useMe, useProgramSummary } from 'src/fetches/useSWRFetch';
import { BlockRoute } from 'src/types/blocks';
import { AppointmentType } from 'src/types/appointment';
import { MissingInfoForm } from 'src/pages/basic-info/MissingInfoForm';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { ReviewError } from './ReviewError';
import { useAuth } from 'src/components/context/AuthProvider';
import { ReviewAndConfirm } from './ReviewAndConfirm';
import Success from 'src/components/form/Success';
import { VehicleSelectorPage } from 'src/pages/vehicles/VehicleSelectorPage';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { ReviewPageAppointment } from './ReviewPageAppointment';
import { Directions } from './Directions';
import { Verify } from 'src/components/authentication/Verify';
import { isSitePaymentHidden } from 'src/constants/price';
import PageLoader from 'src/components/PageLoader';
enum ReviewPageStatus {
  Unset = 'unset', // Page is loading
  Incomplete = 'incomplete', // Customer is missing some personal info
  Ready = 'ready', // Everything is ready to be filled out
  Confirmed = 'confirmed', // Customer has confirmed offer
  Appointment = 'appointment', // Customer has scheduled appointment
  Error = 'error', // There was a desking or program summary error
  NoPrice = 'no-price', // Deal has no price
  NoVehicle = 'no-vehicle', // Deal has no vehicle
  UnauthorizedLocked = 'unauthorized-locked', // Deal is locked for unauthorized user
}

export default function ReviewIndexPage() {
  const {
    deal,
    dealIsLoading,
    activeDealId,
  }: {
    deal;
    dealMutate;
    activeDealId: string;
    dealIsLoading: boolean;
  } = useDeals();
  const { appointments, appointmentsIsLoading } = useAppointments();
  const { me, meIsLoading, meUpsert } = useMe();
  const { token } = useAuth();
  const { desk, deskIsError } = useDesk();
  const { programSummaryIsError } = useProgramSummary(activeDealId, !desk);
  const config = useConfig()!;
  const { navigatePrevBlock } = useContext(NavigationContext);
  const { sessionDesk, sessionAppointment, unauthorizedLockedDeals } = useSessionContext();

  const vehicleIsLockedDeal = unauthorizedLockedDeals?.includes(deal?.vin || '') && !sessionDesk;

  const summaryIsAvailable = useMemo(() => {
    if (!deal) return false;

    const price = deal.price ?? (deal.msrp as number);

    if (token) {
      return deal.sellingPrice && !isSitePaymentHidden(config);
    }

    return price && !isSitePaymentHidden(config);
  }, [deal, config, token]);

  // Find any appointment that has the vin of the deal
  const appointmentForDeal = useMemo(() => {
    return appointments?.find(appointment => appointment.vin === deal?.vin);
  }, [appointments, deal?.vin]);

  const soonestConsultationAppointment = useMemo(() => {
    // add sessionappointment to appointments
    if (sessionAppointment) {
      return sessionAppointment;
    }

    return appointments
      ?.filter(appointment => appointment.type === AppointmentType.Sales)
      .find(appointment => {
        return new Date(appointment.appointmentAt).getTime() > new Date().getTime();
      });
  }, [appointments, sessionAppointment]);

  const reviewStatus: ReviewPageStatus = useMemo(() => {
    if ((!deal && token) || !me || !appointments || meIsLoading) return ReviewPageStatus.Unset;
    if (
      (deskIsError && deskIsError.status === 409) ||
      (programSummaryIsError && programSummaryIsError.status !== 409)
    )
      return ReviewPageStatus.Error;
    if (!me.firstName || !me.lastName || !me.email || !me.phone) return ReviewPageStatus.Incomplete;
    if (deal?.isDeskingLocked && soonestConsultationAppointment !== undefined)
      return ReviewPageStatus.Appointment;
    //Desking locked not visible to no auth users
    if (deal?.isDeskingLocked) return ReviewPageStatus.Confirmed;
    if (!token && !dealIsLoading && !deal?.vin) return ReviewPageStatus.NoVehicle;
    // If we tried to fetch but deal is actually locked, handle that error with Verify component
    if (vehicleIsLockedDeal || deskIsError || programSummaryIsError)
      return ReviewPageStatus.UnauthorizedLocked;
    if (!summaryIsAvailable) return ReviewPageStatus.NoPrice;
    return ReviewPageStatus.Ready;
  }, [
    deal,
    appointments,
    me,
    meIsLoading,
    soonestConsultationAppointment,
    deskIsError,
    programSummaryIsError,
    token,
    summaryIsAvailable,
    dealIsLoading,
    vehicleIsLockedDeal,
  ]);

  useEffect(() => {
    if (dealIsLoading) {
      return;
    }

    // If no deal is started, navigate to the first block
    if (!dealIsLoading && deal === undefined && config.isRetailing) {
      navigatePrevBlock(BlockRoute.Review);
      return;
    }
  }, [navigatePrevBlock, deal, dealIsLoading, config]);

  if (meIsLoading || appointmentsIsLoading) return null;

  if (config.isRetailing && !deal) return null;

  const pageMarkup = () => {
    switch (reviewStatus) {
      case ReviewPageStatus.Unset:
        return <PageLoader />;
      case ReviewPageStatus.Ready:
        return <ReviewAndConfirm />;
      case ReviewPageStatus.Incomplete:
        return <MissingInfoForm me={me} meUpsert={meUpsert} handleNext={() => {}} />;
      case ReviewPageStatus.Confirmed:
        return (
          <ReviewPageAppointment
            appointmentToUse={appointmentForDeal || soonestConsultationAppointment || null}
          />
        );
      case ReviewPageStatus.Appointment:
        return (
          <Directions
            deal={deal}
            appointment={appointmentForDeal || soonestConsultationAppointment || appointments[0]}
          />
        );
      case ReviewPageStatus.NoVehicle:
        return <VehicleSelectorPage />;
      case ReviewPageStatus.NoPrice:
        return (
          <Success
            title="We Are Working On Your Quote!"
            subtitle="Thanks for reaching out! A team member is building your payment options and will reach out shortly. In the meantime, fill out forms here to save time at the dealership."
          />
        );
      case ReviewPageStatus.UnauthorizedLocked:
        return (
          <Verify
            title="You saved a payment option on this vehicle"
            subtitle="For your security, this information is protected. Please login to view your saved deal."
          />
        );
      case ReviewPageStatus.Error:
        return <ReviewError />;
      default:
        return null;
    }
  };

  return <>{pageMarkup()}</>;
}
