import React, { createContext, useContext } from 'react';
import { useLocalStorage, useSessionStorage } from 'react-storage-complete';
import { AppointmentBlock } from 'src/types/appointment-block';
import { CreditApplicationBlock } from 'src/types/creditapplication-block';
import { PaymentType } from 'src/types/deal';
import { DocumentBlock } from 'src/types/document-block';
import { CustomerFormattedBlock } from 'src/types/lead';
import { Reference } from 'src/types/reference-block';
import { TradeInResponseBlock } from 'src/types/tradein-block';
import { WidgetVehicle } from 'src/widgets/SpaceWidget';

interface SessionContextProps {
  sessionAppointment: AppointmentBlock | undefined;
  setSessionAppointment: (appointment: any) => void;
  sessionCustomer: CustomerFormattedBlock | undefined | null;
  setSessionCustomer: (customer: any) => void;
  sessionVehicle: WidgetVehicle | { vin: string | null } | undefined | null;
  sessionVehicleDealString: string;
  setSessionVehicle: (vehicle: any) => void;
  sessionZip: string | undefined | null;
  setSessionZip: (zip: any) => void;
  sessionLocation: string | undefined | null;
  setSessionLocation: (location: any) => void;
  sessionTradeins: TradeInResponseBlock[] | undefined | null;
  setSessionTradeins: (tradeins: any) => void;
  sessionDocuments: DocumentBlock[] | undefined | null;
  setSessionDocuments: (documents: any) => void;
  sessionPaymentType: PaymentType | undefined | null;
  setSessionPaymentType: (paymentType: any) => void;
  sessionCreditApplication: CreditApplicationBlock[] | undefined | null;
  setSessionCreditApplication: (creditApplication: any) => void;
  sessionDesk: any | undefined | null;
  setSessionDesk: (desk: any) => void;
  sessionCreditScore: string | undefined | null;
  setSessionCreditScore: (creditScore: any) => void;
  clearSessionVehicle: () => void;
  tradeinsVisited: boolean | undefined | null;
  setTradeinsVisited: (tradeinsVisited: boolean) => void;
  requireTradeIns: boolean | undefined | null;
  setRequireTradeIns: (requireTradeIns: boolean) => void;
  paymentOptionsVisited: string[] | undefined | null;
  setPaymentOptionsVisited: (paymentOptionsVisited: string[]) => void;
  creditAppVisited: boolean | undefined | null;
  setCreditAppVisited: (creditAppVisited: boolean) => void;
  appointmentsVisited: boolean | undefined | null;
  setAppointmentsVisited: (appointmentsVisited: boolean) => void;
  documentsVisited: boolean | undefined | null;
  setDocumentsVisited: (documentsVisited: boolean) => void;
  unauthorizedLockedDeals: string[] | undefined | null;
  setUnauthorizedLockedDeals: (unauthorizedLockedDeals: string[]) => void;
  vinsWithDeals: string[] | undefined | null;
  setVinsWithDeals: (vinsWithDeals: string[]) => void;
  sessionReferences: Reference[] | undefined | null;
  setSessionReferences: (references: any) => void;
}

const SessionContext = createContext<SessionContextProps | undefined>(undefined);

export const useSessionContext = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSessionContext must be used within a SessionProvider');
  }
  return context;
};

export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }: any) => {
  return (
    <SessionContext.Provider value={{ ...useSessionData() }}>{children}</SessionContext.Provider>
  );
};

export const useSessionData = () => {
  const [sessionAppointment, setSessionAppointment] = useSessionStorage<AppointmentBlock>(
    'appointment',
    undefined
  ); // Really this is the appointment response block
  const [sessionCustomer, setSessionCustomer] = useSessionStorage<CustomerFormattedBlock>(
    'customer',
    undefined
  );
  const [sessionVehicle, setSessionVehicle, , clearSessionVehicle] = useSessionStorage<
    WidgetVehicle | { vin: string | null }
  >('vehicle', undefined, {
    emitterDisabled: true,
  });

  const [sessionZip, setSessionZip] = useSessionStorage<string>('zip', undefined);
  const [sessionLocation, setSessionLocation] = useSessionStorage<string>('location', undefined, {
    emitterDisabled: true,
  });
  const [sessionTradeins, setSessionTradeins] = useSessionStorage<Array<TradeInResponseBlock>>(
    'tradeins',
    undefined,
    {
      emitterDisabled: true,
    }
  );
  const [sessionReferences, setSessionReferences] = useSessionStorage<Array<Reference>>(
    'references',
    undefined,
    {
      emitterDisabled: true,
    }
  );
  const [sessionDocuments, setSessionDocuments] = useSessionStorage<Array<any>>(
    'documents',
    undefined,
    {
      emitterDisabled: true,
    }
  );
  const [sessionPaymentType, setSessionPaymentType] = useSessionStorage<PaymentType>(
    'paymentType',
    undefined
  );
  const [sessionCreditApplication, setSessionCreditApplication] = useSessionStorage<
    CreditApplicationBlock[]
  >('creditApplication', undefined, {
    emitterDisabled: true,
  });
  const [sessionDesk, setSessionDesk] = useSessionStorage<any>('desk', undefined, {
    emitterDisabled: true,
  });

  const [sessionCreditScore, setSessionCreditScore] = useSessionStorage<string>(
    'creditScore',
    undefined
  );

  const [tradeinsVisited, setTradeinsVisited] = useLocalStorage<boolean>(
    'tradeinsVisited',
    undefined
  );

  const [requireTradeIns, setRequireTradeIns] = useLocalStorage<boolean>(
    'requireTradeIns',
    undefined
  );
  const [paymentOptionsVisited, setPaymentOptionsVisited] = useLocalStorage<string[]>(
    'paymentOptionsVisitedDeals',
    []
  );
  const [unauthorizedLockedDeals, setUnauthorizedLockedDeals] = useLocalStorage<string[]>(
    'unauthorizedLockedDeals',
    []
  );
  const [vinsWithDeals, setVinsWithDeals] = useLocalStorage<string[]>('vinsWithDeals', []);
  const [creditAppVisited, setCreditAppVisited] = useLocalStorage<boolean>(
    'creditAppVisited',
    false
  );
  const [appointmentsVisited, setAppointmentsVisited] = useLocalStorage<boolean>(
    'appointmentsVisited',
    false
  );
  const [documentsVisited, setDocumentsVisited] = useLocalStorage<boolean>(
    'documentsVisited',
    false
  );

  return {
    sessionAppointment: sessionAppointment ?? undefined, // To do: improve this
    setSessionAppointment,
    sessionCustomer,
    setSessionCustomer,
    sessionVehicle,
    sessionVehicleDealString: sessionVehicle?.vin ? `?dealId=create&vin=${sessionVehicle.vin}` : '',
    setSessionVehicle,
    sessionZip,
    setSessionZip,
    sessionLocation,
    setSessionLocation,
    sessionTradeins,
    setSessionTradeins,
    sessionDocuments,
    setSessionDocuments,
    sessionPaymentType,
    setSessionPaymentType,
    sessionCreditApplication,
    setSessionCreditApplication,
    sessionDesk,
    setSessionDesk,
    sessionCreditScore,
    setSessionCreditScore,
    clearSessionVehicle,
    sessionReferences,
    setSessionReferences,
    //Local storage
    tradeinsVisited,
    setTradeinsVisited,
    requireTradeIns,
    setRequireTradeIns,
    paymentOptionsVisited,
    setPaymentOptionsVisited,
    creditAppVisited,
    setCreditAppVisited,
    appointmentsVisited,
    setAppointmentsVisited,
    documentsVisited,
    setDocumentsVisited,
    unauthorizedLockedDeals,
    setUnauthorizedLockedDeals,
    vinsWithDeals,
    setVinsWithDeals,
  };
};
