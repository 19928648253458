export function LoadingBlock({
  height = '100%',
  width = '100%',
}: {
  height?: string;
  width?: string;
}) {
  return (
    <div
      className="loading-block"
      style={{ height, width, borderRadius: '3px', background: 'var(--loading-bg)' }}
    >
      <div className="loading-block--loading">
        <div className="loading-block--loading--text"></div>
      </div>
    </div>
  );
}
