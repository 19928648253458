import { useParams } from 'react-router-dom';
import { UploadGroup } from '../../../components/steps/UploadGroup';
import { BlockRoute } from '../../../types/blocks';
import { FormBasicPage } from 'src/components/form/FormShell';
import { DocumentTag } from 'src/types/document-block';

export default function Document() {
  const { block, blockId } = useParams();

  return (
    <FormBasicPage
      configuration={{
        alternateButtonText: 'Next',
        showSkipButton: true,
        blockRoute: block as BlockRoute,
      }}
    >
      <UploadGroup tag={blockId as DocumentTag} />
    </FormBasicPage>
  );
}
