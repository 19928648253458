/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useSwrDeal, useSwrDeals, useVehicleVinSearch } from 'src/fetches/useSWRFetch';
import SkeletonSidebar from '../SkeletonSidebar';
import PageLoader from '../PageLoader';
import { useApiFetch } from '../../fetches/useApiFetch';
import { useConfig } from './config';
import { useAuth } from './AuthProvider';
import { useSessionContext } from './SessionProvider';
import { ModalLoader } from '../floating/dialog/ModalLoader';

// Create a context
const DealsContext = createContext<any>(null);

// Custom hook to use the DealsContext
export const useDeals = () => {
  return useContext(DealsContext);
};

// DealsProvider component
const DealsProvider = ({ children, initialVehicle }) => {
  const { deals, dealsIsLoading, dealsIsError, dealsCreate, dealsMutate } = useSwrDeals();
  const config = useConfig()!;
  const apiFetch = useApiFetch();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { sessionVehicle } = useSessionContext();

  // State to store active deal ID
  const [activeDealId, setActiveDealId] = useState<any>(token ? null : 'new');
  // const [isDealSelected, setIsDealSelected] = useState<boolean>(false);
  const [isLocalStorageInitialRead, setIsLocalStorageInitialRead] = useState<boolean>(false);
  const { deal, dealIsLoading, dealIsError, dealMutate, dealUpsert, dealIsMutating } =
    useSwrDeal(activeDealId);
  // Use a vehicle specifically provided to the config
  const { searchVehicle } = useVehicleVinSearch(
    initialVehicle ? initialVehicle.vin : sessionVehicle?.vin
  );

  // Load activeDealId from localStorage on component mount
  useEffect(() => {
    const storedActiveDealId = localStorage.getItem('activeDealId');
    if (isLocalStorageInitialRead || !token) return; // Prevents infinite loop and keeps the active deal ID "new" for no auth users

    // First, let's start by reading local storage.
    if (!activeDealId) {
      if (storedActiveDealId) {
        // If there's a deal in local storage, let's store it in state'
        setActiveDealId(storedActiveDealId);
      } else {
        // Otherwise, let's tell our state that we don't know what the customer's active deal is
        // We don't need to modify local storage here because we'll always do that later
        setActiveDealId('none');
      }
      setIsLocalStorageInitialRead(true);
    }
  }, [activeDealId]);

  // Function to select a deal
  const selectDeal = (dealId: string, navigateToDeal?: boolean) => {
    setActiveDealId(dealId);
    // Persist activeDealId to localStorage
    localStorage.setItem('activeDealId', dealId);

    if (navigateToDeal) {
      navigate(`/deals/${dealId}/`);
    }
  };

  const updateDealWithVin = async ({ dealId, vin }: { dealId: string; vin: string }) => {
    const path = `deals/${dealId}`;

    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify({ vin }),
    };

    try {
      const deal = await apiFetch(path, requestOptions);
      await Promise.all([dealsMutate(), dealMutate()]);

      // Update URL to reflect the new deal ID
      navigate(`/deals/${deal.id}/`);
    } catch (error) {
      console.error('Error creating deal:', error);
    }
  };

  // Function to create a new deal
  const createDeal = async dealData => {
    try {
      // Perform API call to create a new deal
      const deal = await dealsCreate(dealData);
      await Promise.all([dealsMutate()]);
      // Update URL to reflect the new deal ID
      navigate(`/deals/${deal.id}/`);
    } catch (error) {
      console.error('Error creating deal:', error);
    }
  };

  useEffect(() => {
    // If there's an error fetching a deal (400 or 404)
    if (dealsIsError || dealIsError) {
      // Wait until we have deals, but not the one from our active dealId (because there's an error)
      if (!deal && deals) {
        selectDeal(deals[0]?.id, true);
      }
    }
  }, [dealsIsError, dealIsError, deal, deals]);

  // Check search params for dealId and vin
  useEffect(() => {
    // Local storage is ready, but we still don't have deal data
    if (dealsIsLoading || activeDealId === null) return;

    // By default, we should prioritize param operations, like a new deal.
    // Next, we should check the URL for a dealId.
    // If the param is null, we should check local storage.
    // If local storage is null, we should just pick the first deal in the list.

    // In order of specificity, select a deal by:
    // 1. Deal ID from query params
    // 2. Deal ID from URL
    // 3. Deal ID from local storage
    // 4. First Deal available

    const handleSearchParams = async () => {
      const dealIdParam = searchParams.get('dealId');
      const vin = searchParams.get('vin');
      // Now let's address params used to create or update deals
      // some scenarios:
      // User is on a deal page and wants to create a new deal
      // /deals/5866029f-a2fe-4d04-87e9-bed283c3dd40/payment-options/?dealId=create&vin=1G1YY2388M5100001
      // Or is being directed to the portal from the website
      // /vehicles/?dealId=create&vin=1N4BL4CV9PN306843
      // In any case, we want to look at the dealId param, select if it exists, or create a new deal if it doesn't
      if (!dealIdParam && !vin) return;

      // Entering deal creation mode
      if (dealIdParam === 'create' || dealIdParam === '0') {
        // Try to find a deal with the given vin
        const existingDeal = deals && deals.find(deal => deal.vin === vin);

        if (existingDeal) {
          // If a deal with the same vin already exists, select it
          selectDeal(existingDeal.id);
          // ex: /deals/5866029f-a2fe-4d04-87e9-bed283c3dd40/payment-options/?dealId=create&vin=1G1YY2388M5100001
          // Remove the used query parameters from the URL
          searchParams.delete('dealId');
          searchParams.delete('vin');
        } else if (vin) {
          // Otherwise, create a new deal with the given vin
          await createDeal({ vin }); // Pass the vin to the createDeal function
          // Remove the used query parameters from the URL
          searchParams.delete('dealId');
          searchParams.delete('vin');
        }
      } else if (dealIdParam && dealIdParam !== 'create' && dealIdParam !== '0') {
        // If dealIdParam is a GUID, try to select the deal
        const existingDeal = deals && deals.find(deal => deal.id === dealIdParam);

        if (existingDeal) {
          // If the deal exists, select it
          if (vin && existingDeal.vin !== vin) {
            // If there's a VIN provided and it's different from the deal's VIN, update the VIN
            await updateDealWithVin({ dealId: existingDeal.id, vin }); // Pass both id and vin to update the deal's VIN
            return;
          } else {
            // Otherwise, just select the deal
            selectDeal(existingDeal.id);
            navigate(`deals/${existingDeal.id}`);
          }
          // Remove the used query parameters from the URL
          searchParams.delete('dealId');
          searchParams.delete('vin');
        }
        // If there's a VIN and no dealId, we don't need to do anything
      }
      return;
    };

    const handleParams = async () => {
      // By this point we know any params, search params, deals, and active deal
      // activeDealId here can be either "none" (we don't have enough info to decide) or a GUID, or "new"
      // We can now check for the following scenarios:

      // User has 0 deals. In this case, whatever params we have are irrelevant
      if (deals.length === 0) {
        setActiveDealId('new');
        return;
      }

      // If we have a dealId in the URL, we should use that dealId
      if (
        params.dealId &&
        params.dealId !== activeDealId &&
        deals.some(deal => deal.id === params.dealId)
      ) {
        // We are navigating to a dealId in the URL that exists and is not the active deal
        selectDeal(params?.dealId || deals[0]?.id);
        return;
      }

      // Fresh login state: Active deal is "none" and we have deals
      if (!params?.dealId && activeDealId === 'none') {
        selectDeal(deals[0]?.id);
        return;
      }
      return;
    };

    const handleDataFetch = async () => {
      await handleSearchParams();
      await handleParams();
      searchParams.delete('dealId');
      searchParams.delete('vin');
    };
    handleDataFetch();
  }, [activeDealId, deals, navigate]);

  if (
    activeDealId === null ||
    dealsIsLoading ||
    (dealIsLoading && config.isRetailing) ||
    activeDealId === 'none'
  ) {
    if (config.isRetailing) {
      return (
        <>
          <div className="u-display-flex">
            <SkeletonSidebar />
          </div>
          <PageLoader />
        </>
      );
    }

    if (config.display?.isModal) {
      return <ModalLoader />;
    }
    return <PageLoader />;
  }

  return (
    <DealsContext.Provider
      value={{
        deals,
        activeDealId,
        deal: config.isRetailing ? deal : searchVehicle,
        selectDeal,
        createDeal,
        dealUpsert,
        dealMutate,
        dealIsMutating,
        dealIsLoading,
        dealIsError,
        initialVehicle, // Pass down to context to access widget-specified vehicle props
      }}
    >
      {children}
    </DealsContext.Provider>
  );
};

export default DealsProvider;
