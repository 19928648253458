/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Radio } from '../controls/Radio';
import { TradeInPayoffType } from '../../types/tradein-block';
import './TradeinPayoffTypeGroup.css';
import { FormError } from '../controls/FormError';
import { FormBody, useFormSubmit } from '../form/Form';

const validationSchema = Joi.object({
  payoffType: Joi.string().label('Payoff').allow(TradeInPayoffType).messages({
    'string.empty': `A selection is required`,
  }),
});

const payoffTypeInputs = [
  {
    label: 'Loan',
    labelDesc: 'Making payments to own this vehicle',
    type: TradeInPayoffType.Loan,
  },
  {
    label: 'Lease',
    labelDesc: 'Another party owns this vehicle',
    type: TradeInPayoffType.Lease,
  },
  {
    label: 'Neither',
    labelDesc: 'There are no financial obligations attached to this vehicle',
    type: TradeInPayoffType.Neither,
  },
];

export const TradeinPayoffTypeGroup = () => {
  const { handleFormGroupSubmit, formValues: tradein, activeForm } = useFormSubmit();
  const form = useForm({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
    defaultValues: {
      payoffType: tradein.payoffType,
    },
  });

  if (activeForm !== 'tradeinPayoffTypeForm') {
    return null;
  }

  return (
    <form id="tradeinPayoffTypeForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Do you have a loan or a lease associated with this vehicle?"
        body={`${tradein.year} ${tradein.make} ${tradein.model}`}
      />
      {payoffTypeInputs.map(item => (
        <Radio
          control={form.control}
          key={item.type}
          value={item.type}
          name="payoffType"
          label={item.label}
          labelDesc={item.labelDesc}
        />
      ))}
      <FormError control={form.control} name="payoffType" />
    </form>
  );
};
