import { useContext } from 'react';
import { NavigationContext } from '../context/NavigationProvider';
import { FormBasicPage } from './FormShell';
import { FormBody } from './Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from 'src/types/colors';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

interface SuccessProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export default function Success({ title, subtitle, children }: SuccessProps) {
  const { nextBlockTitle } = useContext(NavigationContext);

  return (
    <FormBasicPage
      configuration={{
        alternateButtonText: nextBlockTitle,
        hideBackButton: true,
      }}
    >
      <div className="block-container--content--centered">
        <FontAwesomeIcon icon={faCheckCircle} className="success--icon" color={Colors.Green1} />
        <FormBody title={title} body={subtitle || null} />
        {children}
      </div>
    </FormBasicPage>
  );
}
