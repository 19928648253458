import { SelectOption } from '../components/controls/Select';
import { useConfig } from '../components/context/config';

export interface DealershipLocations {
  isMultipleLocations: boolean;
  dropdownList: SelectOption[];
}

export function useDealershipLocations() {
  const config = useConfig()!;
  let dropdownList: SelectOption[] = [];

  config.locations.forEach(item => {
    if (config.hideLocations?.includes(item.name)) return;
    dropdownList.push({ value: item.name, label: item.name });
  });

  return {
    isMultipleLocations: config.locations.length > 1,
    dropdownList: dropdownList,
  } as DealershipLocations;
}
