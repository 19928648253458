import { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCreditApplication, useCreditApplications, useMe } from '../../../fetches/useSWRFetch';
import PageError from '../../../components/PageError';
import PageLoader from '../../../components/PageLoader';
import { useDocTitle } from '../../../helpers/global-document-title';
import { CreditApplication } from './CreditApplication';
import { useDeals } from '../../../components/context/DealsProvider';
import { BlockRoute, FullBlockRoute } from '../../../types/blocks';
import { PaymentType } from '../../../types/deal';
import CreditApplicationSuccessPage from './CreditApplicationSuccessPage';
import {
  CreditApplicationFormDefaultValues,
  Signer,
  returnSubmissionBlockData,
} from 'src/types/creditapplication-block';
import { CreditApplication as LeadCreditApplication } from '../../../lead-forms/CreditApplication';
import { useAuth } from 'src/components/context/AuthProvider';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { useConfig } from 'src/components/context/config';

export default function NewPaymentMethod() {
  const config = useConfig()!;
  useDocTitle('Payment Method');
  const { navigatePrevBlock } = useContext(NavigationContext);
  const { token } = useAuth();
  const { deal, dealUpsert } = useDeals();
  const { blockId } = useParams();
  const [searchParams] = useSearchParams();
  const paymentTypeParam = searchParams.get('paymentType');
  const typeParam = searchParams.get('type');
  const type = [Signer.Primary, Signer.CoSigner].includes(typeParam as Signer)
    ? (typeParam as Signer)
    : Signer.Primary;
  const navigate = useNavigate();
  const { me, meIsLoading } = useMe();
  const {
    creditApplication,
    creditApplicationIsLoading,
    creditApplicationIsError,
    creditApplicationSubmit,
  } = useCreditApplication(blockId, type);
  const { creditApplicationsIsLoading, creditApplicationsIsError } = useCreditApplications();
  const [isSucessState, setIsSucessState] = useState(false);

  const requiresPaymentTypeUpdate = useMemo(() => {
    if (!token || !deal) return false;

    return (
      deal.paymentType === PaymentType.Cash ||
      (paymentTypeParam && paymentTypeParam !== deal.paymentType)
    );
  }, [deal, paymentTypeParam, token]);

  const prefilledCreditApplication = useMemo(() => {
    if (!creditApplication || !me) return null;

    let prefilledCreditApp = { ...creditApplication };

    // Only prefill the primary signer's information
    if (creditApplication.type === Signer.Primary) {
      ['firstName', 'lastName', 'email', 'phone'].forEach(key => {
        if (prefilledCreditApp[key] === '' && me[key]) {
          prefilledCreditApp[key] = me[key];
        }
      });
    }

    return prefilledCreditApp;
  }, [creditApplication, me]);

  if (prefilledCreditApplication === null) return <PageLoader />;
  if (!me || meIsLoading) return <PageLoader />;
  if (creditApplicationIsLoading || creditApplicationsIsLoading) return <PageLoader />;
  if (creditApplicationIsError || creditApplicationsIsError) return <PageError />;

  const handleFormCancel = () => {
    navigate(FullBlockRoute.CreditApplication);
  };

  const handleAuthenticatedSubmit = async (formattedData: CreditApplicationFormDefaultValues) => {
    try {
      const block = returnSubmissionBlockData(formattedData);
      await creditApplicationSubmit(block);

      if (requiresPaymentTypeUpdate) {
        const fallbackPaymentType = config.paymentOptionsConfig.disableFinancing
          ? PaymentType.Leasing
          : PaymentType.Cash;
        await dealUpsert({ paymentType: paymentTypeParam || fallbackPaymentType });
      }

      setIsSucessState(true);
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  if (isSucessState) {
    return <CreditApplicationSuccessPage />;
  }

  return token && config.isRetailing ? (
    <CreditApplication
      key={creditApplication.id}
      payment={prefilledCreditApplication}
      handleFormCancel={handleFormCancel}
      handleFormSubmit={handleAuthenticatedSubmit}
    />
  ) : (
    <LeadCreditApplication
      handleFormCancel={() => navigatePrevBlock(BlockRoute.CreditApplication)}
      vin={null}
    />
  );
}
