/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { useConfig } from './config';

interface MobileProviderProps {
  children: ReactNode;
}

export const MobileContext = createContext<boolean>(false);

export const useMobileContext = () => {
  const context = useContext(MobileContext);

  if (context == null) {
    throw new Error('Dialog components must be wrapped in <Dialog />');
  }

  return context;
};

export const MobileProvider: React.FC<MobileProviderProps> = ({ children }) => {
  const config = useConfig()!;
  const windowToUse = config.isRetailing ? window : window.parent;
  const [isMobile, setIsMobile] = useState(windowToUse.matchMedia('(max-width: 1023px)').matches);

  useEffect(() => {
    const mediaQuery = windowToUse.matchMedia('(max-width: 1023px)');
    const handleResize = () => setIsMobile(mediaQuery.matches);

    mediaQuery.addListener(handleResize);
    return () => mediaQuery.removeListener(handleResize);
  }, []);

  return <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>;
};
