import { useLocation } from 'react-router-dom';
import { useConfig } from '../context/config';
import { JourneyTab } from '../navigation/JourneyTab';
import { menuItems } from 'src/constants/routes';

export function FormSecondaryNavigation() {
  const config = useConfig()!;
  const location = useLocation();

  //Don't show on login page
  if (location.pathname === '/login') {
    return null;
  }

  // Never show in the embedded experience
  if (!config.isRetailing) return null;

  return <JourneyTab items={menuItems} />;
}
