import { useMemo } from 'react';
import { useDeals } from 'src/components/context/DealsProvider';
import { useAppointments, useFavorites } from 'src/fetches/useSWRFetch';
import { AppointmentBlock } from 'src/types/appointment-block';

export function useVehicleSelectionData(filterVehicle?: any) {
  const { deals } = useDeals();
  const { appointments, appointmentsIsLoading } = useAppointments();
  const { favorites, favoritesIsLoading } = useFavorites();

  // filter out favorites that have already been converted to deals
  const filteredFavorites = useMemo(() => {
    if (!favorites || !deals) return [];
    return favorites.filter(favorite => !deals?.find(deal => deal.vin === favorite.vin));
  }, [favorites, deals]);

  // Filter out appointments that have already been converted to deals or are in favorites or have null vehicles
  const filteredAppointments = useMemo(() => {
    if (!appointments || !deals) return [];

    // Get all vehicles from appointments (filter out appointments with no vehicles)
    const appointmentVehicles = appointments
      .filter(apt => apt.vehicle !== null)
      .map((apt: AppointmentBlock) => apt.vehicle)
      //remove duplicate vehicles
      .filter((vehicle, index, self) => self.findIndex(v => v?.vin === vehicle?.vin) === index);

    return (
      appointmentVehicles
        // Filter out appointments that have already been converted to deals
        .filter(vehicle => !deals?.find(deal => deal.vin === vehicle?.vin))
        // Filter out appointments that are in favorites
        .filter(vehicle => !filteredFavorites.find(favorite => favorite.vin === vehicle?.vin))
    );
  }, [appointments, deals, filteredFavorites]);

  // // Filter out a specified active vehicle from the combined data
  const arrayMinusActiveVehicle = useMemo(() => {
    if (!appointments || !deals || !favorites) return [];
    const combinedData = [...deals, ...filteredFavorites, ...filteredAppointments];
    return combinedData.filter(vehicle => vehicle?.vin !== filterVehicle?.vin);
  }, [filteredAppointments, filteredFavorites, filterVehicle, appointments, deals, favorites]);

  const vehicleSelectionDataExists =
    filteredAppointments.length || deals?.length || filteredFavorites.length;

  if (appointmentsIsLoading || favoritesIsLoading) {
    return {
      vehicleSelectionData: [],
      vehicleSelectionDataMinusActiveVehicle: [] as any,
      carouselIsLoading: true,
      carouselExists: false,
    };
  }

  return {
    vehicleSelectionData: [...deals, ...filteredFavorites, ...filteredAppointments],
    vehicleSelectionDataMinusActiveVehicle: arrayMinusActiveVehicle,
    vehicleSelectionDataIsLoading: false,
    vehicleSelectionDataExists,
    vehicleSelectionDataAppointments: filteredAppointments,
  };
}
