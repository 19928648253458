import { useMemo } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import './MatrixItem.css';
import { PriceCounter } from './PriceCounter';
import { TermType } from 'src/types/desking';
import { Popover, PopoverTrigger } from '../floating/popover/Popover';
import { Tooltip } from '../floating/popover/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Colors } from 'src/types/colors';
import { PaymentType } from 'src/types/deal';
import { LoadingBlock } from '../loaders/LoadingBlock';
import { useConfig } from '../context/config';
import { useFormSubmit } from '../form/Form';

export function MatrixLoadingItem() {
  return (
    <div className="matrix-item is-loading">
      <LoadingBlock height="24px" />
      <LoadingBlock height="16px" width="83px" />
      <LoadingBlock height="16px" width="50px" />
    </div>
  );
}

interface MatrixItemProps {
  name: string;
  control: Control<any>;
  value: number;
  subtitle?: string;
  description?: string;
  payment?: number;
  isDeskingLocked: boolean;
  termType: TermType;
  paymentRange: number;
  paymentMethod: PaymentType;
}

export function MatrixItem({
  name,
  control,
  value,
  subtitle,
  description,
  payment,
  isDeskingLocked,
  termType,
  paymentRange,
  paymentMethod,
}: MatrixItemProps) {
  const { isSubmitting } = useFormSubmit();
  const {
    field: { value: fieldValue, ...field },
    fieldState: { invalid, isTouched, isDirty },
    formState: { isSubmitted },
  } = useController({
    name,
    control,
  });
  const selectedValue = useWatch({
    control,
    name,
  });
  const config = useConfig()!;

  const termTypeString = useMemo(() => {
    switch (termType) {
      case TermType.Yearly:
        return '/year';
      case TermType.BiWeekly:
        return '/biweekly';
      case TermType.Weekly:
        return '/week';
      default:
        return '/mo';
    }
  }, [termType]);

  const renderClass = useMemo(() => {
    const classes = ['matrix-item'];
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if (isSubmitting) classes.push('is-disabled');
    if (selectedValue.toString() === value.toString()) classes.push('is-active');
    if (selectedValue.toString() === value.toString() && isDeskingLocked) {
      classes.push('is-locked');
    }
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [
    invalid,
    isDirty,
    isSubmitted,
    isTouched,
    selectedValue,
    value,
    isDeskingLocked,
    isSubmitting,
  ]);

  //To do: money factor mode

  return (
    <div className={renderClass}>
      <input
        id={`${name}${value}`}
        type="radio"
        className="matrix-item--input"
        disabled={isDeskingLocked || isSubmitting}
        value={value}
        {...field}
        checked={selectedValue.toString() === value.toString()}
      />
      <label htmlFor={`${name}${value}`} className="matrix-item--label">
        <div className="matrix-item--label--text">
          <div className="matrix-item--lotto-column-row">
            <div className="matrix-item--label--text--title">$</div>
            <PriceCounter price={payment || 0} className="matrix-item--lotto-column" />
            {paymentRange > 0 && payment ? (
              <>
                <div
                  className="matrix-item--label--text--title"
                  style={{ paddingLeft: '4px', paddingRight: '4px' }}
                >
                  -
                </div>
                <PriceCounter
                  price={payment + paymentRange || 0}
                  className="matrix-item--lotto-column"
                />
              </>
            ) : null}
            <span className="matrix-item--label--text--title--monthly">{termTypeString}</span>
          </div>
          {subtitle ? (
            <div className="matrix-item--label--text--subtitle">
              {subtitle}{' '}
              {config.isRetailing ? (
                <Popover initialOpen={false} placement="right">
                  <PopoverTrigger className="popover-tooltip--inline-trigger">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color={
                        selectedValue.toString() === value.toString() ? Colors.White : Colors.Gray2
                      }
                    />
                  </PopoverTrigger>
                  <Tooltip
                    text={`${paymentMethod} estimated $${payment?.toLocaleString(
                      'en-us'
                    )} ${termTypeString} for a duration of ${subtitle} at an interest rate of ${description}`}
                    color={Colors.Black}
                    title="Payment Option"
                  />
                </Popover>
              ) : null}
            </div>
          ) : null}
          {description ? (
            <div className="matrix-item--label--text--desc text--body-1">{description}</div>
          ) : null}
        </div>
      </label>
    </div>
  );
}
