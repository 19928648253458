import SkeletonSidebar from './SkeletonSidebar';

export default function SkeletonLoader() {
  return (
    <>
      <header>
        <nav className="nav">
          <div className="nav--logo--loading"></div>
        </nav>
      </header>
      <section className="page">
        <SkeletonSidebar />
      </section>
      <footer></footer>
    </>
  );
}
