/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm, useWatch } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { faCarSide, faQuestionCircle, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { add } from 'date-fns';
import { LottieLoader } from '../LottieLoader';
import { Select, SelectOption } from '../controls/Select';
import { Popover, PopoverTrigger } from '../floating/popover/Popover';
import './TradeinVehicleGroup.css';
import { VIN } from '../inputs/Input';
import { VinTip } from '../floating/popover/VinTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RadioToggle } from '../controls/RadioToggle';
import { SearchBy } from 'src/types/tradein-block';
import { Colors } from 'src/types/colors';
import { FormBody, useFormSubmit } from '../form/Form';
import { Selector } from '../selectors/Selector';
import { useApiFetch } from 'src/fetches/useApiFetch';

const JDP_MIN_VALUATION_MODELYEAR = 2000;
const MAX_VALUATION_MODELYEAR = add(new Date(), { years: 1 }).getFullYear();

const yearOptions: SelectOption[] = [];
for (let year = MAX_VALUATION_MODELYEAR; year >= JDP_MIN_VALUATION_MODELYEAR; --year) {
  yearOptions.push({ value: year.toString(), label: year.toString() });
}

const validationSchema = Joi.object({
  vin: Joi.string().length(17).allow('').messages({
    'string.empty': `VIN is required`,
    'string.length': `VIN must be 17 characters long`,
  }),
  year: Joi.number().required().messages({
    'number.base': `Year is required`,
  }),
  make: Joi.string().required().messages({
    'string.empty': `Make is required`,
  }),
  model: Joi.string().required().messages({
    'string.empty': `Model is required`,
  }),
  ucgvehicleid: Joi.string().required().messages({
    'string.empty': `A trim selection is required`,
    'any.required': `A configuration selection is required`,
  }),
  body: Joi.string().required().messages({
    'string.empty': `A configuration selection is required`,
  }),
  searchBy: Joi.string().allow(SearchBy),
});

export const TradeinVehicleGroup = () => {
  const {
    isSubmitting: isSearching,
    setIsSubmitting: setIsSearching,
    handleFormGroupSubmit,
    formValues: tradein,
    activeForm,
  } = useFormSubmit();
  const apiFetch = useApiFetch();
  const editMode = tradein.year; // If there is a year, the user has filled out this step before
  const tradeinForm = useForm({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
    // Body is a hidden field that is set by the user selecting a trim
    defaultValues: {
      vin: tradein.vin,
      year: tradein.year,
      make: tradein.make,
      model: tradein.model,
      ucgvehicleid: tradein.ucgvehicleid,
      body: tradein.body,
      searchBy: tradein.searchBy,
    },
  });

  const handleVinClear = () => {
    tradeinForm.setValue('vin', '');
    tradeinForm.setValue('year', '');
    tradeinForm.setValue('make', '');
    tradeinForm.setValue('model', '');
    tradeinForm.setValue('ucgvehicleid', '');
    setBodyOptions(undefined);
  };

  const handleVinSearch = useCallback(async (vin: string) => {
    setIsSearchFailed(false);
    const isValidVin = await tradeinForm.trigger('vin');
    if (!vin || !isValidVin) return;

    try {
      setIsSearching(true);
      //todo: test
      const vehicles = await apiFetch(`decode/vin/${vin}`);
      const { year, make, model } = vehicles[0];
      const bodyOptions = vehicles.map(({ ucgvehicleid, body }) => ({
        value: ucgvehicleid,
        label: body,
      }));

      setTimeout(() => {
        tradeinForm.setValue('year', year);
        tradeinForm.setValue('make', make);
        tradeinForm.setValue('model', model);

        if (bodyOptions.length === 1) {
          tradeinForm.setValue('ucgvehicleid', bodyOptions[0].value);
          tradeinForm.setValue('body', bodyOptions[0].label);
          tradeinForm.clearErrors('ucgvehicleid');
        }
      });
      setMakeOptions([{ value: make, label: make }]);
      setModelOptions([{ value: model, label: model }]);
      setBodyOptions(bodyOptions);
    } catch (error: any) {
      tradeinForm.setError('vin', { type: 'custom', message: error.message });
      setIsSearchFailed(true);

      tradeinForm.resetField('year');
      tradeinForm.resetField('make');
      tradeinForm.resetField('model');

      toast.error(error.message);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const handleMakesSearch = useCallback(async (year: string) => {
    setIsSearching(true);

    const queryParams = new URLSearchParams({ year }).toString();
    const path = `decode/makes?${queryParams}`;

    try {
      const makes: string[] = await apiFetch(path);
      const options = makes.map(make => ({
        value: make,
        label: make,
      }));
      setMakeOptions(options);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const handleModelsSearch = useCallback(async (make: string) => {
    setIsSearching(true);

    const year = tradeinForm.getValues('year').toString();
    const queryParams = new URLSearchParams({ year, make }).toString();
    const path = `decode/models?${queryParams}`;

    try {
      const models: string[] = await apiFetch(path);
      const options = models.map(model => ({
        value: model,
        label: model,
      }));
      setModelOptions(options);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const handleBodiesSearch = useCallback(async model => {
    setIsSearching(true);

    const queryParams = new URLSearchParams({
      year: tradeinForm.getValues('year').toString(),
      make: tradeinForm.getValues('make').toString(),
      model,
    }).toString();
    const path = `decode/bodies?${queryParams}`;

    try {
      const bodies: { ucgvehicleid: string; body: string }[] = await apiFetch(path);
      const options = bodies.map(({ ucgvehicleid, body }) => ({
        value: ucgvehicleid,
        label: body,
      }));
      setBodyOptions(options);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const vin = useWatch({
    control: tradeinForm.control,
    name: 'vin',
  });
  const year = useWatch({
    control: tradeinForm.control,
    name: 'year',
  });
  const make = useWatch({
    control: tradeinForm.control,
    name: 'make',
  });
  const model = useWatch({
    control: tradeinForm.control,
    name: 'model',
  });
  const searchBy = useWatch({
    control: tradeinForm.control,
    name: 'searchBy',
  });

  const ucgvehicleid = useWatch({
    control: tradeinForm.control,
    name: 'ucgvehicleid',
  });

  useEffect(() => {
    handleVinSearch(vin);
  }, [handleVinSearch, vin]);

  useEffect(() => {
    if (year) {
      if (searchBy !== SearchBy.Vin) {
        if (editMode && tradein.year !== year) {
          tradeinForm.setValue('make', '');
          tradeinForm.setValue('model', '');
          tradeinForm.setValue('ucgvehicleid', '');
          tradeinForm.setValue('body', '');
          handleMakesSearch(year.toString());
        } else {
          tradeinForm.resetField('make');
          tradeinForm.resetField('model');
          tradeinForm.resetField('ucgvehicleid');
          tradeinForm.resetField('body');
          handleMakesSearch(year.toString());
        }
      }
    }
  }, [year]);

  useEffect(() => {
    if (make) {
      if (searchBy !== SearchBy.Vin) {
        if (editMode && tradein.make !== make) {
          tradeinForm.setValue('model', '');
          tradeinForm.setValue('ucgvehicleid', '');
          tradeinForm.setValue('body', '');
          handleModelsSearch(make);
        } else {
          tradeinForm.resetField('model');
          tradeinForm.resetField('body');
          handleModelsSearch(make);
        }
      }
    }
  }, [make]);

  useEffect(() => {
    if (model) {
      if (searchBy !== SearchBy.Vin) {
        if (editMode && tradein.model !== model) {
          tradeinForm.setValue('ucgvehicleid', '');
          tradeinForm.setValue('body', '');
          handleBodiesSearch(model);
        } else {
          tradeinForm.resetField('body');
          handleBodiesSearch(model);
        }
      }
    }
  }, [model]);

  const [makeOptions, setMakeOptions] = useState(
    tradein?.make ? [{ value: tradein.make, label: tradein.make }] : undefined
  );
  const [modelOptions, setModelOptions] = useState(
    tradein?.model ? [{ value: tradein.model, label: tradein.model }] : undefined
  );
  const [bodyOptions, setBodyOptions] = useState(
    tradein?.ucgvehicleid ? [{ value: tradein.ucgvehicleid, label: tradein.body }] : undefined
  );

  useEffect(() => {
    if (ucgvehicleid && ucgvehicleid !== '') {
      tradeinForm.setValue(
        'body',
        bodyOptions?.find(option => option.value === ucgvehicleid)?.label || ''
      );
    }
  }, [ucgvehicleid, bodyOptions]);

  const [isSearchFailed, setIsSearchFailed] = useState(false);

  const handleSearchByChange = useCallback(
    (newSearchBy: SearchBy) => {
      if (newSearchBy === searchBy) return;
      // Reset Form
      tradeinForm.setValue('searchBy', newSearchBy);
      tradeinForm.setValue('vin', '');
      tradeinForm.setValue('year', '');
      tradeinForm.setValue('make', '');
      tradeinForm.setValue('model', '');
      tradeinForm.setValue('ucgvehicleid', '');
      tradeinForm.setValue('body', '');
      tradeinForm.clearErrors();
    },
    [tradeinForm, searchBy]
  );

  if (activeForm !== 'tradeinVehicleForm') return null;

  return (
    <form id="tradeinVehicleForm" onSubmit={tradeinForm.handleSubmit(handleFormGroupSubmit)}>
      <FormBody title="What kind of vehicle do you want to trade in?" body={null} />
      <RadioToggle
        label="By VIN"
        selected={searchBy === SearchBy.Vin}
        labelDesc="Fast and automatic vehicle information"
        handleClick={() => handleSearchByChange(SearchBy.Vin)}
      />

      <RadioToggle
        label="By Make & Model"
        labelDesc="Manually entered vehicle information"
        selected={searchBy === SearchBy.YMMT}
        handleClick={() => handleSearchByChange(SearchBy.YMMT)}
      />

      {searchBy === SearchBy.Vin ? (
        <>
          <div className="input-with-tip">
            <VIN control={tradeinForm.control} />
            <Popover>
              <PopoverTrigger className="form--tip-input--tip icon-btn">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </PopoverTrigger>
              <VinTip />
            </Popover>
          </div>

          {vin && year && make && model && (
            <>
              <Selector
                type="button"
                title={year + ' ' + make + ' ' + model}
                subtitle={vin}
                thumbnail={{ icon: faCarSide }}
                btnIcon={faUndo}
                handleClick={handleVinClear}
              />
              <Select
                control={tradeinForm.control}
                name="ucgvehicleid"
                key="ucgvehicleid"
                selectOptions={bodyOptions}
                label="Configuration"
                disabled={!bodyOptions}
              />
            </>
          )}
        </>
      ) : (
        <div className="u-display-grid grid--100-50 grid--gap-16">
          <Select
            className="select__ymmt"
            control={tradeinForm.control}
            name="year"
            key="year"
            selectOptions={yearOptions}
            label="Year"
          />
          <Select
            className="select__ymmt"
            control={tradeinForm.control}
            name="make"
            key="make"
            selectOptions={makeOptions}
            label="Make"
            disabled={!makeOptions}
          />
          <Select
            className="select__ymmt"
            control={tradeinForm.control}
            name="model"
            key="model"
            selectOptions={modelOptions}
            label="Model"
            disabled={!modelOptions}
          />
          <Select
            className="select__ymmt"
            control={tradeinForm.control}
            name="ucgvehicleid"
            key="ucgvehicleid"
            selectOptions={bodyOptions}
            label="Configuration"
            disabled={!bodyOptions}
          />
        </div>
      )}

      {searchBy === SearchBy.Vin && isSearchFailed ? (
        <div className="form--action-bar mod-error">
          {"Your information didn't match any vehicles"}
        </div>
      ) : null}

      {isSearching ? (
        <div>
          <LottieLoader color={Colors.Black} height={28} width={24} />
        </div>
      ) : null}
    </form>
  );
};
