import { AppointmentType } from '../../types/appointment';

const AppointmentTypeDropdownArray = [
  { label: 'Consultation', value: AppointmentType.Sales },
  { label: 'Service', value: AppointmentType.Service },
  { label: 'Delivery', value: AppointmentType.Delivery },
  { label: 'Other', value: AppointmentType.Other },
];

export default AppointmentTypeDropdownArray;
