import './Chat.css';
import { useMe } from 'src/fetches/useSWRFetch';
import { ChatProvider, useChatContext } from './ChatActionProvider';
import { BlockRoute } from 'src/types/blocks';
import { useSessionContext } from '../context/SessionProvider';
import { Calculator } from 'src/pages/deal/payment-options/Calculator';
import { ChatInput } from './ChatInput';
import { ChatComponent } from './ChatComponent';

export function DealerAvatar() {
  const { me } = useMe();

  return <img className="chat-dealer-avatar" src={me?.dealerAvatar || ''} alt="" />;
}

function ChatBody() {
  const { messages } = useChatContext();
  const { sessionZip } = useSessionContext();

  const content = () => {
    switch (messages[messages.length - 1].block) {
      case BlockRoute.PaymentOptions:
        return <Calculator zip={sessionZip} />;
      default:
        return <ChatMessages />;
    }
  };

  return <div className="chat-body">{content()}</div>;
}

function ChatFooter({ children }: { children: any }) {
  return <div>{children}</div>;
}

function ChatSuggestions({ task }) {
  const { chatConfig } = useChatContext();
  const taskItem = chatConfig.widgets.find(w => w.widgetName === task);
  if (!task || !taskItem) return null;
  return <div className="chat--container">{taskItem.widgetFunc(taskItem.props)}</div>;
}

function ChatMessages() {
  const { messages } = useChatContext();
  return (
    <>
      {messages.map((props, index) => {
        if (props.type === 'user') {
          return (
            <div className="chat--container" key={index}>
              <div className="chat-section is-user">
                <ChatComponent {...props} />
              </div>
            </div>
          );
        }
        return (
          <div className="chat--container" key={index}>
            <div className="chat-section is-bot">
              <DealerAvatar />
              <div className="chat--message">{props.text}</div>
            </div>
            <ChatSuggestions task={props.task} />
          </div>
        );
      })}
    </>
  );
}

function Chat() {
  return (
    <div className="chat-container">
      <ChatProvider>
        <ChatBody />
        <ChatFooter>
          <ChatInput />
        </ChatFooter>
      </ChatProvider>
    </div>
  );
}

export default Chat;
