import { ButtonHTMLAttributes, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LottieLoader } from '../LottieLoader';
import { Colors } from 'src/types/colors';

interface ButtonProps {
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  text?: string;
  isSubmitting?: boolean;
  baseClass: string;
  icon?: boolean;
  iconName?: any;
  iconClass?: string;
  progress?: boolean;
  handleSubmit: (event: any) => void;
  handleErrorMessage?: () => void;
}

export default function Button({
  type,
  text,
  isSubmitting,
  baseClass,
  icon,
  iconName,
  iconClass,
  progress,
  handleSubmit,
  handleErrorMessage,
}: ButtonProps) {
  const onHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (handleErrorMessage && !progress) {
      handleErrorMessage();
    } else {
      handleSubmit(event);
    }
  };

  const isProcessing = isSubmitting && handleErrorMessage;
  const isDisabled = isSubmitting && !handleErrorMessage;

  const renderClass = () => {
    var className = baseClass;

    if (isProcessing) {
      className += ' is-processing';
    }

    return className;
  };

  const loaderColor = useMemo(
    () =>
      baseClass.includes('mod-text-icon') || baseClass.includes('mod-standard')
        ? Colors.Black
        : Colors.White,
    [baseClass]
  );

  return (
    <button
      className={renderClass()}
      type={type}
      onClick={type === 'submit' ? undefined : onHandleClick}
      disabled={isDisabled}
    >
      {icon && iconClass === 'u-icon' && !isProcessing ? (
        <FontAwesomeIcon icon={iconName} className={iconClass} />
      ) : null}
      {isProcessing ? <LottieLoader color={loaderColor} height={28} width={24} /> : text}
      {icon && iconClass !== 'u-icon' && !isProcessing ? (
        <FontAwesomeIcon icon={iconName} className={iconClass} />
      ) : null}
    </button>
  );
}
