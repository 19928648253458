import { Link } from 'react-router-dom';
import { LoginState } from './LoginForm';
import Success from '../form/Success';

export function Verify({ title, subtitle }) {
  return (
    <Success title={title} subtitle={subtitle}>
      <Link to={'/login'} className="btn-height-text u-mar-top-16" state={LoginState.Verify}>
        Login
      </Link>
    </Success>
  );
}
