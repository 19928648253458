import { useDealers } from 'src/fetches/useSWRFetch';
import { Select } from './Select';
import { LottieLoader } from '../LottieLoader';
import { Colors } from 'src/types/colors';
import { UseFormReturn } from 'react-hook-form';
import { useConfig } from '../context/config';

export function DealerDropdown({ form }: { form: UseFormReturn<any> }) {
  const config = useConfig()!;
  const { dealers, dealersIsLoading } = useDealers();

  // Should return a dealership location name: Patterson Chrysler Dodge Jeep - Marshall
  const { location } = form.watch();

  // If multiple locations and no location selected, don't show dropdown yet
  if (config.locations.length > 1 && location === '') {
    return null;
  }

  if (!dealers || dealersIsLoading) {
    return <LottieLoader color={Colors.Black} height={28} width={24} />;
  }

  return (
    <div className="u-mar-bottom-16">
      <Select
        control={form.control}
        name="dealerId"
        label={
          config.forms?.creditApplication?.dealerDropdownLabel ||
          'Optional: Name of staff member assisting you'
        }
        placeholder="Select a name"
        selectOptions={dealers
          .filter(dealer => {
            // If dealer.firstName is not null
            if (dealer.firstName !== null) {
              // If config.locations has more than one item
              if (config.locations?.length > 1) {
                // Check if dealer.locations includes any of the location.name from config.locations
                return dealer.locations?.includes(location);
              }
              // If config.locations does not have more than one item, return true
              return true;
            }
            // If dealer.firstName is null, return false
            return false;
          })
          .map(dealer => ({
            label: `${dealer.firstName}${' '}${dealer.lastName || ''}`,
            value: dealer.id,
          }))}
      />
    </div>
  );
}
