import React, { useMemo } from 'react';
import './Menu.css';
import Button from '../components/form/Button';
import {
  faBars,
  faPlus,
  faCars,
  faSearch,
  faPhone,
  faUserCircle,
  faSignOut,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import MenuSelector from './selectors/MenuSelector';
import { useConfig } from '../components/context/config';
import ComplexLabel from '../components/labels/ComplexLabel';
import NavSelector from './selectors/NavSelector';
import { BlockRoute, FullBlockRoute } from '../types/blocks';
import { menuItems } from '../constants/routes';
import VehicleDealSelector from '../components/cards/VehicleDealSelector';
import { useMe } from 'src/fetches/useSWRFetch';
import { useNavigate } from 'react-router-dom';
import { useDeals } from './context/DealsProvider';
import { WebsiteRoute } from 'src/external/WebsiteRoutes';
import { useMenu } from './context/MenuProvider';
import { isSitePaymentHidden } from 'src/constants/price';

interface MenuHeaderProps {
  toggleMenu: () => void;
  dealershipLogoUrl: string;
}

export function MenuHeader({ toggleMenu, dealershipLogoUrl }: MenuHeaderProps) {
  const config = useConfig()!;

  return (
    <header className="menu-header">
      <div className="menu-header--desktop">
        <div className="u-hide-mobile-and-ipad-block">
          <a href={`https://${config.websiteDomain}`} className="menu-header--logo">
            <img className="menu-header--logo--img" src={dealershipLogoUrl} alt="Header Logo" />
          </a>
        </div>
        <Button
          type="button"
          text=""
          baseClass="icon-btn mod-outline"
          icon={true}
          iconName={faBars}
          isSubmitting={false}
          progress={true}
          handleSubmit={toggleMenu}
        />
      </div>
      <VehicleDealSelector />
    </header>
  );
}

function RetailingNav() {
  const config = useConfig()!;

  const getChildren = (name: BlockRoute) => {
    if (name === BlockRoute.PaymentOptions && !isSitePaymentHidden(config)) {
      return <ComplexLabel />;
    }
    return null;
  };

  return (
    <nav className="retailing-nav">
      {menuItems.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <MenuSelector
              key={index}
              name={item.name}
              to={item.route}
              icon={item.icon}
              iconDone={item.iconDone}
              title={item.title}
              subtitle={item.subtitle}
              subtitleDone={item.subtitleDone}
              isDealDependent={item.isDealDependent}
              tip={item.tip}
            >
              {getChildren(item.name)}
            </MenuSelector>
          </React.Fragment>
        );
      })}
    </nav>
  );
}

interface DetailsMenuProps {
  dealershipLogoUrl: string;
  toggleMenu: () => void;
  handleLogout: () => void;
}

function DetailsMenu({ dealershipLogoUrl, toggleMenu, handleLogout }: DetailsMenuProps) {
  const config = useConfig()!;
  const navigate = useNavigate();
  const { deal, dealIsLoading, activeDealId } = useDeals();
  const { me, meIsLoading } = useMe();

  const navigateVehicles = async () => {
    await toggleMenu();
    window.location.href = `https://${config.websiteDomain}/cars?dealId=${activeDealId}`;
  };

  const navigateToAccount = async () => {
    await toggleMenu();
    navigate(FullBlockRoute.Account);
  };

  if (meIsLoading || !me || dealIsLoading) {
    return null;
  }

  return (
    <>
      <header className="details-menu-header">
        <a href={`https://${config.websiteDomain}`} className="menu-header--logo">
          <img className="menu-header--logo--img" src={dealershipLogoUrl} alt="Header Logo" />
        </a>
        <Button
          type="button"
          text=""
          baseClass="icon-btn mod-outline"
          icon={true}
          iconName={faTimes}
          isSubmitting={false}
          progress={true}
          handleSubmit={toggleMenu}
        />
      </header>
      <nav className="nav-selector--list">
        <NavSelector
          title="New Reservation"
          btnIcon={faPlus}
          onBtnClick={() => {
            window.location.href = `https://${config.websiteDomain}${WebsiteRoute.CreateDeal}`;
          }}
        />
        {deal && !deal.isDeskingLocked && (
          <NavSelector
            title="Change Vehicles"
            btnIcon={faCars}
            onBtnClick={() => navigateVehicles()}
          />
        )}
        <NavSelector
          title="Search Inventory"
          btnIcon={faSearch}
          onBtnClick={() => {
            window.location.href = `https://${config.websiteDomain}${WebsiteRoute.CreateDeal}`;
          }} // Same Action as New Reservation for now
        />
        <NavSelector
          title="Contact Dealer"
          btnIcon={faPhone}
          onBtnClick={() => {
            window.open(`https://${config.websiteDomain}${WebsiteRoute.Contact}`);
          }}
        />
        <NavSelector
          title="Account"
          btnIcon={faUserCircle}
          onBtnClick={() => navigateToAccount()}
        />
        <NavSelector title="Sign Out" btnIcon={faSignOut} onBtnClick={() => handleLogout()} />
      </nav>
    </>
  );
}

interface MenuProps {
  dealershipLogoUrl: string;
  handleLogout: () => Promise<void>;
}

export default function Menu({ dealershipLogoUrl, handleLogout }: MenuProps) {
  const { isMenuOpen, toggleMenu } = useMenu();

  const renderMainMenuClass = useMemo(() => {
    const classes = ['menu'];
    if (isMenuOpen) classes.push('is-menu-open');
    return classes.join(' ');
  }, [isMenuOpen]);

  const renderDetailsMenuClass = useMemo(() => {
    const classes = ['details-menu'];
    if (isMenuOpen) classes.push('is-menu-open');
    return classes.join(' ');
  }, [isMenuOpen]);

  return (
    <>
      <div className={renderMainMenuClass}>
        <MenuHeader toggleMenu={toggleMenu} dealershipLogoUrl={dealershipLogoUrl}></MenuHeader>
        <RetailingNav />
      </div>
      <div className={renderDetailsMenuClass}>
        <DetailsMenu
          toggleMenu={toggleMenu}
          dealershipLogoUrl={dealershipLogoUrl}
          handleLogout={handleLogout}
        ></DetailsMenu>
      </div>
    </>
  );
}
