import React, { forwardRef, useMemo, Ref, FunctionComponent } from 'react';
import { useController, Control } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  PatternFormat,
  NumericFormat,
  PatternFormatProps,
  NumericFormatProps,
} from 'react-number-format';

import './Input.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InputProps {
  name: string;
  control?: Control<any>; // Replace 'any' with the appropriate type for 'control'
  type?: 'text' | 'date' | 'phone' | 'number' | 'integer' | 'creditScore' | 'zip';
  label: string | React.ReactNode;
  labelClassName?: string;
  placeholder?: string;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  autoComplete?: string;
  icon?: any;
  badge?: string;
  disabled?: boolean;
  readOnly?: boolean;
  validateOnSubmit?: boolean;
}

type NumericFormatPropsWithExtra = NumericFormatProps & {
  badge?: string;
  icon?: any;
};

type PatternFormatPropsWithExtra = PatternFormatProps & {
  badge?: string;
  icon?: any;
};

const TextInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <>
    {props.icon && <FontAwesomeIcon icon={props.icon} className="form--input--icon" />}
    <input className="form--input--text" {...props} ref={ref} />
    {props.badge && <div className="form--input--badge">{props.badge}</div>}
  </>
));
const PatternInput = forwardRef<HTMLInputElement, PatternFormatProps>((props, ref) => (
  <PatternFormat
    className="form--input--text"
    {...props}
    getInputRef={ref as Ref<HTMLInputElement>}
  />
));
const NumberInput = forwardRef<HTMLInputElement, NumericFormatPropsWithExtra>((props, ref) => (
  <>
    {props.icon && <FontAwesomeIcon icon={props.icon} className="form--input--icon" />}
    <NumericFormat
      className="form--input--text"
      {...props}
      getInputRef={ref as Ref<HTMLInputElement>}
    />
    {props.badge && <div className="form--input--badge">{props.badge}</div>}
  </>
));

const IntegerInput = forwardRef((props, ref) => (
  <NumberInput {...props} decimalScale={0} ref={ref as Ref<HTMLInputElement>} />
));
const PhoneInput = forwardRef((props, ref) => (
  <PatternInput
    format="###-###-####"
    mask={['_', '_', '_', '_', '_', '_', '_', '_', '_', '_']}
    {...props}
    ref={ref as Ref<HTMLInputElement>}
  />
));
const DateInput = forwardRef((props, ref) => (
  <PatternInput
    format="##/##/####"
    mask={['_', '_', '_', '_', '_', '_', '_', '_']}
    {...props}
    ref={ref as Ref<HTMLInputElement>}
  />
));

const ComplexPatternInput = forwardRef<HTMLInputElement, PatternFormatPropsWithExtra>(
  (props, ref) => (
    <>
      {props.icon && <FontAwesomeIcon icon={props.icon} className="form--input--icon" />}
      <PatternInput
        {...props}
        format={props.name === 'creditScore' ? '###' : '#####'} // Credit score and zip formats
        mask={['', '', '']}
        ref={ref as Ref<HTMLInputElement>}
      />
      {props.badge && <div className="form--input--badge">{props.badge}</div>}
    </>
  )
);

export const Input: FunctionComponent<InputProps> = ({
  name,
  control,
  type,
  inputRef,
  validateOnSubmit = false,
  ...props
}) => {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });
  const { labelClassName, ...restProps } = props;

  const renderClass = useMemo(() => {
    const classes = props.className ? [props.className, 'input--container'] : ['input--container'];
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if (props.disabled) classes.push('is-disabled');
    if (props.readOnly) classes.push('is-read-only');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [props.className, props.disabled, invalid, isDirty, isSubmitted, isTouched, props.readOnly]);

  const TypedInput = useMemo(() => {
    switch (type) {
      case 'date':
        return DateInput;
      case 'phone':
        return PhoneInput;
      case 'number':
        return NumberInput;
      case 'integer':
        return IntegerInput;
      case 'creditScore':
      case 'zip':
        return ComplexPatternInput;
      default:
        return TextInput;
    }
  }, [type]);

  return (
    <div className={renderClass}>
      <div className="form--input--holder">
        <label className={`form--label ${labelClassName || ''}`}>
          <span className="form--label--bg"></span>
          <span className="form--label--text">{props.label}</span>
        </label>
        <TypedInput {...field} {...restProps} ref={inputRef} />
      </div>
      {isSubmitted || (isTouched && !validateOnSubmit) ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <div className="form--error">{message}</div>}
        />
      ) : null}
    </div>
  );
};
