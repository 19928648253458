import { HomeOwnership } from 'src/types/creditapplication-block';

export const HomeOwnershipInputs = [
  {
    label: 'Rent',
    name: 'homeOwnership',
    labelDesc: 'I rent the property from a landlord',
    value: HomeOwnership.Rent,
  },
  {
    label: 'Mortgage',
    name: 'homeOwnership',
    labelDesc: 'I own the property and have a mortgage',
    value: HomeOwnership.Mortgage,
  },
  {
    label: 'Own',
    name: 'homeOwnership',
    labelDesc: 'I own the property and it is paid off',
    value: HomeOwnership.Own,
  },
  {
    label: 'Other',
    name: 'homeOwnership',
    labelDesc: 'My residence is provided unconventionally',
    value: HomeOwnership.Other,
  },
];
