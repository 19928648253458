/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { FormWarning } from '../form/FormWarning';
import { OtherMonthlyIncome, OtherMonthlyIncomeSource } from '../inputs/Input';
import { FormBody, useFormSubmit } from '../form/Form';
import { requiresEmploymentDetails } from 'src/types/employmentStatus';

export const PaymentOtherIncomeGroup = () => {
  const { handleFormGroupSubmit, formValues: payment, activeForm } = useFormSubmit();
  const emp = payment.employment[0];
  const needsOtherIncome =
    !requiresEmploymentDetails(emp.employmentStatus) ||
    Number(payment.monthlyIncome) + Number(payment.previousMonthlyIncome) < 1;

  const validationSchema = needsOtherIncome
    ? Joi.object({
        otherMonthlyIncome: Joi.number().required().messages({
          'number.base': `Monthly income should be a number`,
          'string.empty': `Monthly income is required`,
        }),
        otherMonthlyIncomeSource: Joi.string().required().messages({
          'string.empty': `Monthly income source is required`,
        }),
      })
    : Joi.object({
        otherMonthlyIncome: Joi.custom((value, helpers) => {
          if (
            (value && !helpers.state.ancestors[0].otherMonthlyIncomeSource) ||
            (!value && helpers.state.ancestors[0].otherMonthlyIncomeSource)
          ) {
            return helpers.message({
              custom:
                'If you list other monthly income, you must also list the source of that income',
            });
          }
          return value;
        }),
        otherMonthlyIncomeSource: Joi.custom((value, helpers) => {
          if (
            (value && !helpers.state.ancestors[0].otherMonthlyIncome) ||
            (!value && helpers.state.ancestors[0].otherMonthlyIncome)
          ) {
            return helpers.message({
              custom:
                'If you list other monthly income, you must also list the source of that income',
            });
          }
          return value;
        }),
      });
  const form = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      otherMonthlyIncome: payment.otherMonthlyIncome,
      otherMonthlyIncomeSource: payment.otherMonthlyIncomeSource,
    },
  });

  if (activeForm !== 'paymentOtherIncomeForm') {
    return null;
  }

  return (
    <form id="paymentOtherIncomeForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <div>
        {needsOtherIncome ? (
          <FormWarning text="Banks require some form of income. Please list another source of income" />
        ) : null}
        <FormBody title="Do you have any other monthly income?" body={null} />
      </div>
      <OtherMonthlyIncome control={form.control} />
      <OtherMonthlyIncomeSource control={form.control} />
    </form>
  );
};
