/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Radio } from '../controls/Radio';
import { FormError } from '../controls/FormError';
import { EmploymentStatusInputs } from 'src/constants/employmentStatus';
import { FormBody, useFormSubmit } from '../form/Form';
import { EmploymentStatus } from 'src/types/employmentStatus';

const validationSchema = Joi.object({
  employmentStatus: Joi.string().allow(EmploymentStatus).required().messages({
    'string.empty': `Employment status is required`,
  }),
});

export const EmploymentStatusGroup = ({ index }) => {
  const { handleFormGroupSubmit, formValues: payment, activeForm } = useFormSubmit();
  const employment = payment.employment[index];
  const form = useForm({
    resolver: joiResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      employmentStatus: employment.employmentStatus,
    },
  });

  const onSubmit = async (updatedFormData: any) => {
    const saveData = payment.employment;
    saveData[index] = { ...saveData[index], ...updatedFormData };

    handleFormGroupSubmit({ employment: saveData });
  };

  if (activeForm !== `employmentStatusForm${index}`) {
    return null;
  }

  return (
    <form id={`employmentStatusForm${index}`} onSubmit={form.handleSubmit(onSubmit)}>
      <FormBody
        title={index === 0 ? 'What is your employment status?' : 'What was your employment status?'}
        body={null}
      />
      {EmploymentStatusInputs.map(item => (
        <Radio
          control={form.control}
          key={item.value}
          value={item.value}
          name="employmentStatus"
          label={item.label}
        />
      ))}
      <FormError control={form.control} name="employmentStatus" />
    </form>
  );
};
