import { ReferenceInfoGroup, ReferenceType } from 'src/components/steps/ReferenceInfoGroup';

export function ReferenceRequirement({ references = 0 }: { references: number }) {
  if (references < 1) return null;

  const employerComponents: any[] = [];
  for (let i = 0; i < references; i++) {
    employerComponents.push(<ReferenceInfoGroup key={i} index={i} type={ReferenceType.Partial} />);
  }

  return <>{employerComponents}</>;
}
