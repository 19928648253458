import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import './Textarea.css';

interface TextareaProps {
  name: string;
  control: Control;
  label: string;
  className?: string;
  placeholder?: string;
}

export function Textarea({ control, name, ...props }: TextareaProps) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  const renderClass = useMemo(() => {
    const classes = ['textarea--container'];
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [invalid, isDirty, isSubmitted, isTouched]);

  return (
    <div className={renderClass}>
      <div className="form--input--holder">
        <label className="form--label">
          <span className="form--label--bg"></span>
          <span className="form--label--text">{props.label}</span>
        </label>
        <textarea className="textarea" {...field} placeholder={props.placeholder} />
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="form--error">{message}</div>}
      />
    </div>
  );
}
