import { utcToZonedTime } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { useConfig } from 'src/components/context/config';
import { useSchedule } from 'src/components/context/ScheduleProvider';
import { getReadableDate, getReadableTime } from 'src/constants/dates';
import { useAppointments } from 'src/fetches/useSWRFetch';
import { AppointmentStatus } from 'src/types/appointment';

export function NextAppointmentString(): JSX.Element | null {
  const config = useConfig()!;
  const { appointments, appointmentsIsLoading } = useAppointments();
  const [nextAppointment, setNextAppointment] = useState<string | null>(null);
  const { dealerTimezone } = useSchedule();

  useEffect(() => {
    if (!appointments || !appointments.length) return;

    const currentDate = new Date(utcToZonedTime(new Date(), dealerTimezone));

    // Get the next appointment that meets the following criteria:
    // 1. Status is either confirmed or set
    // 2. Appointment date is in the future
    // 3. Sort by appointment date

    const apppointmentSorted = appointments
      .filter(x => x.status === AppointmentStatus.Confirmed || x.status === AppointmentStatus.Set)
      .filter(x => new Date(x.appointmentAt) >= currentDate) // filter out past appointments
      .sort((a, b) => new Date(a.appointmentAt).getTime() - new Date(b.appointmentAt).getTime());
    const appointment = apppointmentSorted[0];

    if (appointment) {
      const zonedAptDate = utcToZonedTime(appointment.appointmentAt, dealerTimezone);

      setNextAppointment(getReadableDate(zonedAptDate) + ' ' + getReadableTime(zonedAptDate));
    } else {
      setNextAppointment('Please review your appointment');
    }
  }, [appointments, config, dealerTimezone]);

  if (!appointments || appointmentsIsLoading) {
    return null;
  }

  return <>{nextAppointment}</>;
}
