import { FileError } from 'react-dropzone';
import { allowedFileParameters } from '../../constants/files';

export default function FileUploadError({ file, errors }: { file: File; errors: FileError[] }) {
  return (
    <div>
      <div>
        <div>
          {file.name} - ({(file.size / 2 ** 20).toFixed(1)} MB) failed to upload because of the
          following error:
        </div>
      </div>
      <div style={{ marginTop: '4px', marginBottom: '4px' }}>
        {errors
          ? errors.map(e =>
              e.code === 'file-too-large' ? (
                <span className="label mod-error mod-wrap" key={e.code}>
                  File is larger than 4.5MB
                </span>
              ) : (
                <span className="label mod-error mod-wrap" key={e.code}>
                  {e.code}
                  {e.message}
                </span>
              )
            )
          : null}
      </div>
      <div>Requirements</div>
      <ul style={{ textAlign: 'left' }}>
        {allowedFileParameters.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
}
