// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { partialReferenceSchema, referenceSchema } from '../../constants/schemas';
import { Email, FirstName, LastName, Phone, Relationship } from '../inputs/Input';
import { FormBody, useFormSubmit } from '../form/Form';
import { useAuth } from '../context/AuthProvider';
import { useReferencesCount } from '../context/status/referenceCount';

export enum ReferenceType {
  Full = 'full',
  Partial = 'partial',
}

export const ReferenceInfoGroup = ({
  index,
  type = ReferenceType.Full,
}: {
  index: number;
  type?: ReferenceType;
}) => {
  const { handleFormGroupSubmit, formValues, activeForm } = useFormSubmit();
  const reference = type === ReferenceType.Partial ? formValues.references[index] : formValues;
  const { token } = useAuth();
  const referenceCount = useReferencesCount();

  const form = useForm({
    resolver: joiResolver(
      Joi.object(type === ReferenceType.Full ? referenceSchema : partialReferenceSchema)
    ),
    mode: 'onBlur',
    defaultValues: {
      firstName: reference.firstName,
      lastName: reference.lastName,
      email: reference.email,
      phone: reference.phone,
      relationship: reference.relationship,
    },
  });

  const onSubmit = async (updatedFormData: any) => {
    if (token || type === ReferenceType.Full) {
      handleFormGroupSubmit(updatedFormData);
    } else {
      const saveData = formValues.references;
      saveData[index] = { ...saveData[index], ...updatedFormData };

      handleFormGroupSubmit({ references: saveData });
    }
  };

  if (activeForm !== `referenceInfoForm${index}`) {
    return null;
  }

  return (
    <form id={`referenceInfoForm${index}`} onSubmit={form.handleSubmit(onSubmit)}>
      <FormBody
        title={`${reference.id === 'edit' ? 'Edit ' : 'Add '} Reference ${index + 1}`}
        body={`This institution requires ${referenceCount} references.`}
      />
      <div className="u-display-grid grid--50">
        <FirstName control={form.control} />
        <LastName control={form.control} />
      </div>
      <div className="u-display-grid grid--50">
        <Phone control={form.control} />
        <Email control={form.control} />
      </div>
      <div className="u-display-grid grid--100">
        <Relationship control={form.control} />
      </div>
    </form>
  );
};
