import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useDeals } from '../DealsProvider';
import { PaymentType } from 'src/types/deal';
import { VehicleStatus } from 'src/types/vehicle';
import { useConfig } from '../config';
import { isSitePaymentHidden } from 'src/constants/price';
import { useSessionContext } from '../SessionProvider';
import { useAuth } from '../AuthProvider';

export const useReviewStatus = () => {
  const { deal } = useDeals();
  const { token } = useAuth();
  const config = useConfig()!;
  const { unauthorizedLockedDeals } = useSessionContext();

  const isVehicleDisabled = useMemo(() => {
    return deal?.vin === null;
  }, [deal]);

  return useMemo(() => {
    // Do we need a done AND disabled status?

    if (isSitePaymentHidden(config)) {
      return BlockStatus.Hidden;
    }

    if (config.isRetailing) {
      if (
        !deal ||
        deal?.vin === null ||
        deal?.vehicleStatus === VehicleStatus.Hidden ||
        deal?.vehicleStatus === VehicleStatus.Unavailable ||
        (deal?.paymentType === PaymentType.Leasing && !deal?.isLeaseEnabled)
      ) {
        return BlockStatus.Disabled;
      }

      if (deal?.isDeskingLocked) {
        return BlockStatus.Done;
      }
    }

    // Do we need a done AND disabled status?
    if (!config.isRetailing && (isVehicleDisabled || (!deal && token))) {
      return BlockStatus.Disabled;
    }

    if (deal?.isDeskingLocked || unauthorizedLockedDeals?.includes(deal?.vin)) {
      return BlockStatus.Done;
    }
    return BlockStatus.Default;
  }, [deal, config, unauthorizedLockedDeals, isVehicleDisabled, token]);
};
