import { useConfig } from 'src/components/context/config';
import { returnVehiclePrice, returnVehicleMileage } from 'src/types/vehicle';

interface VehicleCardPriceProps {
  vin?: string | null;
  isAvailable: boolean;
  price: number | null;
  mileage: number;
}

export function VehicleCardPrice({ vin, isAvailable, mileage, price }: VehicleCardPriceProps) {
  const config = useConfig()!;
  const formattedPrice = returnVehiclePrice(price);
  const formattedMileage = returnVehicleMileage(mileage);

  if (config.packages?.hideprices) {
    return <>{formattedMileage}</>;
  }

  if (vin && isAvailable && formattedPrice) {
    return <>{formattedPrice}</>;
  }

  if (vin) {
    return <>{formattedMileage}</>;
  }

  return null;
}
