/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
export const useRecordPageVisit = (
  visitedStatus: boolean | undefined | null,
  setVisitedStatus: (visited: boolean) => void
) => {
  useEffect(() => {
    if (!visitedStatus) {
      setVisitedStatus(true);
    }
  }, [visitedStatus, setVisitedStatus]);
};
