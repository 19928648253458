import { useParams } from 'react-router-dom';
import { BlockRoute } from 'src/types/blocks';
import NewAppointment from '../appointments/[blockId]';
import NewPaymentMethod from '../payment-method/[blockId]';
import NewTradeIn from '../trade-in/[blockId]';
import Document from '../documents/[tag]';
import Reference from '../references/[blockId]';

export function ItemPage() {
  const { block } = useParams();

  switch (block) {
    case BlockRoute.TradeIn:
      return <NewTradeIn />;
    case BlockRoute.CreditApplication:
    case 'payment-method': //deprecated
      return <NewPaymentMethod />;
    case BlockRoute.Appointments:
      return <NewAppointment />;
    case BlockRoute.Documents:
      return <Document />;
    case BlockRoute.References:
      return <Reference />;
    default:
      return null;
  }
}
