import React, { createContext, ReactNode, useCallback, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  BlockRoute,
  BlockStatus,
  DocumentItemWithStatus,
  RouteItemWithStatus,
} from 'src/types/blocks';
import {
  CreditApplicationRoute,
  TradeInRoute,
  AppointmentsRoute,
  DocumentsRoute,
  PaymentOptionsRoute,
  ReviewRoute,
  // QuickFormsRoute,
} from 'src/constants/routes';
import { usePaymentOptionsStatus } from './status/paymentOptions';
import { useTradeInStatus } from './status/tradeIn';
import { useCreditApplicationsStatus } from './status/creditApplication';
import { useDocumentStatus } from './status/useDocumentStatus';
import { useAppointmentsStatus } from './status/useAppointmentsStatus';
import { useReviewStatus } from './status/useReviewStatus';
import { useQuickFormsStatus } from './status/useQuickFormsStatus';
import { useAccountStatus } from './status/useAccountStatus';
import { useReferencesStatus } from './status/useReferencesStatus';
import { useUploadSteps } from 'src/helpers/useUploadSteps';

interface NavigationContextProps {
  status: {
    [BlockRoute.TradeIn]: BlockStatus;
    [BlockRoute.Appointments]: BlockStatus;
    [BlockRoute.Account]: BlockStatus;
    [BlockRoute.QuickForms]: BlockStatus;
    [BlockRoute.CreditApplication]: BlockStatus;
    [BlockRoute.Documents]: BlockStatus;
    [BlockRoute.References]: BlockStatus;
    [BlockRoute.PaymentOptions]: BlockStatus;
    [BlockRoute.Review]: BlockStatus;
  };
  nextBlockTitle: string;
  navigationMap: (RouteItemWithStatus | DocumentItemWithStatus)[];
  navigateNextUnfinishedBlock: (currentBlock?: string) => void;
  navigatePrevBlock: (currentBlock?: BlockRoute) => void;
  navigateNextBlock: (currentBlock?: string) => void;
}
type RouteGroup = (RouteItemWithStatus | DocumentItemWithStatus)[];

export const NavigationContext = createContext<NavigationContextProps>({
  status: {
    [BlockRoute.TradeIn]: BlockStatus.Default,
    [BlockRoute.Appointments]: BlockStatus.Default,
    [BlockRoute.Account]: BlockStatus.Default,
    [BlockRoute.QuickForms]: BlockStatus.Default,
    [BlockRoute.CreditApplication]: BlockStatus.Default,
    [BlockRoute.Documents]: BlockStatus.Default,
    [BlockRoute.References]: BlockStatus.Default,
    [BlockRoute.PaymentOptions]: BlockStatus.Default,
    [BlockRoute.Review]: BlockStatus.Default,
  },
  nextBlockTitle: '',
  navigationMap: [],
  navigateNextUnfinishedBlock: () => {},
  navigatePrevBlock: () => {},
  navigateNextBlock: () => {},
});

interface NavigationProviderProps {
  children: ReactNode;
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  const { block, blockId } = useParams();
  const navigate = useNavigate();
  // Get the upload steps from the context
  const { blockOrder: uploadBlockOrder } = useUploadSteps();
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index') || undefined;

  const paymentOptionsStatus = usePaymentOptionsStatus();
  const tradeInsStatus = useTradeInStatus();
  const creditApplicationsStatus = useCreditApplicationsStatus();
  const referencesStatus = useReferencesStatus();

  const documentsStatus = useDocumentStatus(uploadBlockOrder);
  const appointmentsStatus = useAppointmentsStatus();
  const reviewStatus = useReviewStatus();
  const accountStatus = useAccountStatus();
  const quickFormsStatus = useQuickFormsStatus(
    documentsStatus,
    creditApplicationsStatus,
    tradeInsStatus
  );

  const blockOrder: RouteGroup = useMemo(() => {
    let array: (RouteItemWithStatus | DocumentItemWithStatus)[] = [];

    array.push({
      ...PaymentOptionsRoute,
      status: paymentOptionsStatus,
    });
    // array.push({ ...QuickFormsRoute, status: quickFormsStatus });
    array.push({ ...TradeInRoute, status: tradeInsStatus });
    array.push({
      ...CreditApplicationRoute,
      status: creditApplicationsStatus,
    });
    array.push({ ...DocumentsRoute, status: documentsStatus });

    for (let i = 0; i < uploadBlockOrder.length; i++) {
      // Add type to show it depends on documents
      array.push({ ...uploadBlockOrder[i] });
    }

    array.push({ ...AppointmentsRoute, status: appointmentsStatus });
    array.push({ ...ReviewRoute, status: reviewStatus });

    return array;
  }, [
    // quickFormsStatus,
    tradeInsStatus,
    uploadBlockOrder,
    creditApplicationsStatus,
    appointmentsStatus,
    paymentOptionsStatus,
    documentsStatus,
    reviewStatus,
  ]);

  const navigationBlockOrder = useMemo(() => {
    let blocks = blockOrder.filter(
      block => ![BlockStatus.Disabled, BlockStatus.Hidden].includes(block.status)
    );

    // Search blocks for the index of the current block
    let currentBlockIndex = blocks.findIndex(el => el.name === blockId);

    // For base url - no block
    if (block === undefined) {
      currentBlockIndex = 0;
    }

    if (currentBlockIndex === -1) {
      // Search by index (ie References)
      if (index) {
        currentBlockIndex = blocks.findIndex(el => el.name === block && el.index === index);
      } else {
        currentBlockIndex = blocks.findIndex(el => el.name === block);
      }
    }

    if (currentBlockIndex === -1) {
      // Find the index of the parent block. Since it isn't in order, move back one
      currentBlockIndex = blocks.findIndex(el => el.parentBlock === block) - 1;
    }

    const newOrder = blocks.slice(currentBlockIndex).concat(blocks.slice(0, currentBlockIndex));

    return newOrder;
  }, [blockOrder, block, blockId, index]);

  const nextBlockTitle = useMemo(() => {
    const block = navigationBlockOrder[1];

    if (block.parentBlock === BlockRoute.Documents) {
      return 'Next: Documents';
    }
    return `Next: ${block.title}`;
  }, [navigationBlockOrder]);

  const navigateNextUnfinishedBlock = useCallback(() => {
    // Navigate to the next block where the status is not done, disabled, or hidden
    navigate(
      navigationBlockOrder.find(
        block =>
          block.status !== BlockStatus.Done &&
          block.status !== BlockStatus.Disabled &&
          block.status !== BlockStatus.Hidden
      )!.route
    );
  }, [navigate, navigationBlockOrder]);

  const navigateNextBlock = useCallback(() => {
    const nextBlock = navigationBlockOrder[1];
    if (nextBlock.isIndexPage) {
      navigate(navigationBlockOrder[2].route);
    } else {
      navigate(nextBlock.route);
    }
  }, [navigate, navigationBlockOrder]);

  const navigatePrevBlock = useCallback(() => {
    const prevBlock = navigationBlockOrder[navigationBlockOrder.length - 1];
    if (prevBlock.isIndexPage) {
      navigate(navigationBlockOrder[navigationBlockOrder.length - 2].route);
    } else {
      navigate(prevBlock.route);
    }
  }, [navigate, navigationBlockOrder]);

  return (
    <NavigationContext.Provider
      value={{
        status: {
          [BlockRoute.TradeIn]: tradeInsStatus,
          [BlockRoute.Appointments]: appointmentsStatus,
          [BlockRoute.Account]: accountStatus,
          [BlockRoute.QuickForms]: quickFormsStatus,
          [BlockRoute.CreditApplication]: creditApplicationsStatus,
          [BlockRoute.Documents]: documentsStatus,
          [BlockRoute.References]: referencesStatus,
          [BlockRoute.PaymentOptions]: paymentOptionsStatus,
          [BlockRoute.Review]: reviewStatus,
        },
        navigationMap: navigationBlockOrder,
        navigateNextUnfinishedBlock,
        nextBlockTitle,
        navigatePrevBlock,
        navigateNextBlock,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
