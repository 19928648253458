import Success from '../../../components/form/Success';

export default function DocumentSuccessPage() {
  return (
    <Success
      title="Reference Submitted"
      subtitle="Just a few more documents and you'll be all set"
    />
  );
}
