export function formatPhone(phone: string): string | null {
  if (phone === '') return null;

  return `+1${phone.replace(/[^0-9]/g, '')}`;
}

export function returnUserFriendlyPhone(phone: string): string {
  //Phone with prefix example: +14342348768
  return phone.slice(2, 5) + '.' + phone.slice(5, 8) + '.' + phone.slice(8, 13);
}
