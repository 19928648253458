import { useAuth } from 'src/components/context/AuthProvider';
import { useConfig } from 'src/components/context/config';
import { DocumentGroup } from 'src/components/steps/DocumentGroup';
import { DocumentTag } from 'src/types/document-block';

export function DocumentRequirement() {
  const config = useConfig()!;
  const { token } = useAuth();

  if (!config.requireIncomeProof && !config.requireResidenceProof) return null;

  const documentComponents: any[] = [];

  if (!token && config.requireIncomeProof) {
    documentComponents.push(<DocumentGroup tag={DocumentTag.Income} key={DocumentTag.Income} />);
  }

  if (!token && config.requireResidenceProof) {
    documentComponents.push(
      <DocumentGroup tag={DocumentTag.Residence} key={DocumentTag.Residence} />
    );
  }

  return <>{documentComponents}</>;
}
