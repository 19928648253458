/* eslint-disable react-hooks/exhaustive-deps */
import { useDocTitle } from '../../../helpers/global-document-title';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useRecordPageVisit } from 'src/helpers/useRecordPageVisit';
import { useAuth } from 'src/components/context/AuthProvider';
import { UnauthorizedLeadForm } from 'src/lead-forms/UnauthorizedLeadForm';
import { WidgetForm } from 'src/widgets/SpaceWidget';
import Reference from './[blockId]';

export default function ReferencesIndexPage() {
  // Still considered a "Document" fo the user
  useDocTitle('Documents');
  const { documentsVisited, setDocumentsVisited, sessionCustomer } = useSessionContext();
  useRecordPageVisit(documentsVisited, setDocumentsVisited);
  const { token } = useAuth();

  // All unauthorized users need full customer info to proceed
  if (!token && !sessionCustomer) {
    return (
      <UnauthorizedLeadForm
        form={WidgetForm.General}
        vehicle={null}
        title={'Upload your documents'}
        body={'Let us know a few quick things so we can securely store your documents'}
        additionalFields={[]}
        vin={null}
        requireZip={false}
        customNavigationHandle={() => {}} // Stop navigation
        submitText="Continue"
      />
    );
  }

  return <Reference />;
}
