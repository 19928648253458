import './Selector.css';
import { BlockRoute, BlockStatus, FullBlockRoute } from '../../types/blocks';
import { useDeals } from '../context/DealsProvider';
import { NextAppointmentString } from 'src/pages/deal/appointments/NextAppointmentString';
import { useMenuStatus } from '../context/status/useMenuStatus';
import { Selector } from './Selector';
import { useMemo } from 'react';
import { Lines } from './Lines';

interface MenuSelectorProps {
  name: BlockRoute;
  to: FullBlockRoute;
  isDealDependent?: boolean;
  icon?: any;
  iconDone?: any;
  title: string;
  subtitle?: string;
  subtitleDone?: string;
  children?: any;
  tip?: string;
}

export default function MenuSelector({
  name,
  to,
  isDealDependent,
  icon,
  iconDone,
  title,
  subtitle,
  subtitleDone,
  children,
  tip,
}: MenuSelectorProps) {
  const { deal, activeDealId } = useDeals();
  const { isActive, status, state } = useMenuStatus(name);

  const menuSubtitle = useMemo(() => {
    if (status === BlockStatus.Done) {
      if (name === BlockRoute.Appointments) {
        return <NextAppointmentString />;
      }
      return subtitleDone || subtitle;
    }
    return subtitle;
  }, [status, subtitle, subtitleDone, name]);

  if (status === BlockStatus.Hidden) {
    return null;
  }

  return (
    <>
      <Selector
        type="menu"
        to={isDealDependent ? `/deals/${activeDealId}${to}` : to}
        state={state}
        thumbnail={{
          icon: status === BlockStatus.Done && iconDone ? iconDone : icon,
          hasIconRing: true,
        }}
        title={title}
        subtitle={menuSubtitle}
        tooltip={
          tip
            ? {
                initialOpen: !deal?.vin && name === BlockRoute.PaymentOptions,
                text: deal?.vin ? 'Please select another vehicle to finalize this offer' : tip,
              }
            : undefined
        }
      >
        {children}
      </Selector>
      <Lines isActive={status === BlockStatus.Done || isActive} />
    </>
  );
}
