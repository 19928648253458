import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { PaymentType } from 'src/types/deal';
import { useDeals } from '../DealsProvider';
import { useAuth } from '../AuthProvider';
import { useSessionContext } from '../SessionProvider';

export const usePaymentOptionsStatus = () => {
  const { token } = useAuth();
  const { deal, activeDealId } = useDeals();
  const { paymentOptionsVisited } = useSessionContext();
  const vehicleId = useMemo(() => (token ? activeDealId : deal?.vin), [activeDealId, token, deal]);

  const isVehicleDisabled = useMemo(() => {
    return deal?.vin === null;
  }, [deal]);

  return useMemo(() => {
    // Do we need a done AND disabled status?
    if (isVehicleDisabled || (!deal && token)) {
      return BlockStatus.Disabled;
    }

    if (!deal?.vin) {
      return BlockStatus.Blocked;
    }

    if (deal?.paymentType === PaymentType.Leasing && !deal?.isLeaseEnabled) {
      return BlockStatus.Incomplete;
    }

    if (deal?.isDeskingLocked || paymentOptionsVisited?.includes(vehicleId)) {
      return BlockStatus.Done;
    }

    //No incomplete status for this block
    return BlockStatus.Default;
  }, [deal, isVehicleDisabled, paymentOptionsVisited, token, vehicleId]);
};
