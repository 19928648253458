import { useMemo, useRef, FunctionComponent } from 'react';

import { Control, useController } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import AuthCode from 'react-auth-code-input';
import './CodeInput.css';

interface CodeInputProps {
  name: string;
  control: Control<any>; // Replace 'any' with the appropriate type for 'control'
}

export const CodeInput: FunctionComponent<CodeInputProps> = ({ name, control }) => {
  const {
    field: { onChange, value, ...field },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  const AuthInputRef = useRef(null);

  const renderClass = useMemo(() => {
    const classes = ['input--container'];
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [invalid, isDirty, isSubmitted, isTouched]);

  return (
    <div className={renderClass}>
      <div className="form--input--holder">
        {/* <label className="form--label">
          <span className="form--label--bg"></span>
          <span className="form--label--text"></span>
        </label> */}
        <AuthCode
          {...field}
          onChange={val => onChange(val?.toUpperCase())}
          ref={AuthInputRef}
          placeholder="0"
          containerClassName="code-input"
        />
      </div>
      {isSubmitted || isTouched ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <div className="form--error">{message}</div>}
        />
      ) : null}
    </div>
  );
};
