import { Colors } from 'src/types/colors';
import { Label } from '../text/Label';
import './Subtask.css';

function SubtaskExtenderIcon({ dark }) {
  return (
    <svg
      className={`subtask--extender-icon ${dark ? 'is-active' : ''}`}
      width="2"
      height="32"
      viewBox="0 0 2 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0V32" stroke={dark ? '#A4A4A8' : '#C7C7C7'} strokeWidth="2" />
    </svg>
  );
}

function SubtaskCurvedIcon({ dark }) {
  return (
    <svg
      className="subtask--active-icon"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 17C13.5523 17 14 16.5523 14 16C14 15.4477 13.5523 15 13 15V17ZM0 0V8H2V0H0ZM9 17H13V15H9V17ZM0 8C0 12.9706 4.02944 17 9 17V15C5.13401 15 2 11.866 2 8H0Z"
        fill={dark ? '#A4A4A8' : '#C7C7C7'}
      />
    </svg>
  );
}

interface SubtaskProps {
  title: string;
  extender?: boolean;
  label?: string;
  labelColor?: Colors;
}

export function Subtask({ title, extender, label, labelColor = Colors.Gray6 }: SubtaskProps) {
  return (
    <div className="subtask-item">
      <div className="subtask--icon-grid">
        {extender && <SubtaskExtenderIcon dark={false} />}
        <SubtaskCurvedIcon dark={false} />
      </div>

      <div className="subtask">
        <span className="subtask--text">{title}</span>
        {label && <Label color={labelColor} text={label ?? 'Loading'} />}
      </div>
    </div>
  );
}
