import { CreditApplicationFormDefaultValues, Signer } from '../../../types/creditapplication-block';
import { ApplicantFormGroup } from './ApplicantFormGroup';

interface CreditApplicationProps {
  payment: CreditApplicationFormDefaultValues;
  handleFormSubmit: (data: CreditApplicationFormDefaultValues) => Promise<void>;
  handleFormCancel: () => void;
}
export const CreditApplication = ({
  payment,
  handleFormSubmit,
  handleFormCancel,
}: CreditApplicationProps) => {
  return (
    <ApplicantFormGroup
      handleApplicantFormCancel={handleFormCancel}
      handleApplicantFormSubmit={handleFormSubmit}
      type={payment.type as Signer}
      payment={payment}
      conditionalFields={{
        paymentInfoForm: () => payment.type !== Signer.Primary,
        paymentInfoAndTypeForm: () => payment.type === Signer.Primary,
        paymentInfoAndCommunicationForm: () => true,
        paymentFinalizeAndLocationForm: () => true,
        paymentApplicationModeForm: () => true,
      }}
    />
  );
};
