const spaceColors = {
  required: true,
  inputs: {
    Black: {
      label: 'Black',
      name: 'color',
      hex: '#333333',
    },
    Gray: {
      label: 'Gray',
      name: 'color',
      hex: '#BCBCC0',
    },
    LightGray: {
      label: 'Light Gray',
      name: 'color',
      hex: 'F0F0F5',
    },
    White: {
      label: 'White',
      name: 'color',
      hex: '#FFFFFF',
    },
    Maroon: {
      label: 'Maroon',
      name: 'color',
      hex: '#B60037',
    },
    Red: {
      label: 'Red',
      name: 'color',
      hex: '#FF3B30',
    },
    Pink: {
      label: 'Pink',
      name: 'color',
      hex: '#FFD5DD',
    },
    Copper: {
      label: 'Copper',
      name: 'color',
      hex: '#BA643A',
    },
    Brown: {
      label: 'Brown',
      name: 'color',
      hex: '#874F00',
    },
    Orange: {
      label: 'Orange',
      name: 'color',
      hex: '#FF9500',
    },
    Yellow: {
      label: 'Yellow',
      name: 'color',
      hex: '#FFCC00',
    },
    Gold: {
      label: 'Gold',
      name: 'color',
      hex: '#FFD700',
    },
    Green: {
      label: 'Green',
      name: 'color',
      hex: '#34C759',
    },
    Turquoise: {
      label: 'Turquoise',
      name: 'color',
      hex: '#00F0FF',
    },
    Blue: {
      label: 'Blue',
      name: 'color',
      hex: '#007AFF',
    },
    Purple: {
      label: 'Purple',
      name: 'color',
      hex: '#AF52DE',
    },
    Tan: {
      label: 'Tan',
      name: 'color',
      hex: '#874F00',
    },
    Beige: {
      label: 'Beige',
      name: 'color',
      hex: '#FFF2D0',
    },
    OffWhite: {
      label: 'Off White',
      name: 'color',
      hex: '#FFFDF8',
    },
  },
};

export default spaceColors;
