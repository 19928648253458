import { useMemo } from 'react';
import { useMe } from 'src/fetches/useSWRFetch';
import { BlockStatus } from 'src/types/blocks';

export const useAccountStatus = () => {
  const { me } = useMe();
  return useMemo(() => {
    // User has added first name
    if (me?.firstName?.length > 0) {
      return BlockStatus.Done;
    }
    // To do: add incomplete status?
    return BlockStatus.Default;
  }, [me]);
};
