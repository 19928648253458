// /* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Select } from '../controls/Select';
import ApplicantTypeDropdownArray from '../dropdowns/ApplicantTypeDropdownArray';
import { Email, FirstName, LastName, MiddleName, Phone } from '../inputs/Input';
import { paymentTypeSchema, applicantType } from '../../constants/schemas';
import { FormBody, useFormSubmit } from '../form/Form';
import { CreditApplicationActionBar } from '../form/CreditApplicationActionBar';

export const PaymentInfoAndTypeGroup = () => {
  const { activeForm, handleFormGroupSubmit, formValues: payment } = useFormSubmit();
  const form = useForm({
    resolver: joiResolver(Joi.object({ ...paymentTypeSchema, applicantType })),
    mode: 'onBlur',
    defaultValues: {
      firstName: payment.firstName,
      lastName: payment.lastName,
      middleName: payment.middleName,
      email: payment.email,
      phone: payment.phone,
      applicantType: payment.applicantType,
    },
  });

  if (activeForm !== 'paymentInfoAndTypeForm') {
    return null;
  }

  return (
    <form id="paymentInfoAndTypeForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Provide your basic information"
        body="The personal information you provide will be securely kept and will only be used to process your loan application."
      />
      <CreditApplicationActionBar />
      <div className="u-display-grid grid--50">
        <FirstName control={form.control} />
        <MiddleName control={form.control} />
      </div>
      <LastName control={form.control} />
      <Email control={form.control} />
      <Phone control={form.control} />
      <Select
        control={form.control}
        name="applicantType"
        label="Applicant Type"
        selectOptions={ApplicantTypeDropdownArray}
      />
    </form>
  );
};
