import './VehicleSelector.css';
import { useRef, useMemo } from 'react';
import ActionIcon from '../floating/popover/ActionIcon';
import { faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons';
import { CarouselPopover } from '../floating/popover/Carousel';
import { SingleDealResponse } from 'src/types/deal';
import { useConfig } from '../context/config';
import ModularVehicleCard from '../cards/vehicle/ModularVehicleCard';
import { Dialog } from '../floating/dialog/Dialog';
import { useMenu } from '../context/MenuProvider';
import { AddVehicleCard } from '../cards/AddVehicle';

import { useDeals } from '../context/DealsProvider';
import { useVehicleSelectionData } from 'src/helpers/useVehicleSelectionData';
import { useVehicleVdp } from 'src/fetches/useSWRFetch';
// To do: address vehicle unavailable state

interface VehicleSelectorProps {
  vehicle?: SingleDealResponse;
}

export function toggleMenu(setIsOpen: (isOpen: boolean) => void, isOpen: boolean) {
  setIsOpen(!isOpen);
}

export default function VehicleSelector({ vehicle }: VehicleSelectorProps) {
  const { activeDealId } = useDeals();
  const config = useConfig()!;
  const { isVehicleSelectorOpen, toggleVehicleSelector } = useMenu();
  const containerRef = useRef<HTMLDivElement>(null);
  const { vehicleSelectionDataIsLoading, vehicleSelectionDataExists } = useVehicleSelectionData();
  const { vehicleVdp } = useVehicleVdp(vehicle?.vin || '');

  const renderClass = useMemo(() => {
    const classes = ['vehicle-selector--link'];
    if (isVehicleSelectorOpen) classes.push('is-overlay-open');
    return classes.join(' ');
  }, [isVehicleSelectorOpen]);

  const handleNewVehicle = async () => {
    // Send user to main website to pick a vehicle
    window.location.href = `https://${config.websiteDomain}/cars?dealId=${
      activeDealId === 'new' ? 'create' : activeDealId
    }`;
  };

  const openVehicleVdp = async () => {
    // Send user to main website to pick a vehicle
    window.open(vehicleVdp?.url);
  };

  if (vehicleSelectionDataIsLoading) return null;

  return (
    <div className={renderClass} ref={containerRef}>
      {vehicle?.vin ? (
        <ModularVehicleCard item={vehicle} status={vehicle.status} handleSelect={openVehicleVdp} />
      ) : (
        <AddVehicleCard handleNewVehicle={handleNewVehicle} isAlert={true} />
      )}

      {vehicleSelectionDataExists ? (
        <ActionIcon icon={faAngleDoubleRight} onClick={toggleVehicleSelector} />
      ) : null}

      <Dialog open={isVehicleSelectorOpen} onOpenChange={toggleVehicleSelector}>
        <CarouselPopover ref={containerRef} />
      </Dialog>
    </div>
  );
}
