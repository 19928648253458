/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import './TradeinPayoffLenderGroup.css';
import { LoanAmount, LoanLender } from '../inputs/Input';
import { FormBody, useFormSubmit } from '../form/Form';

const validationSchema = Joi.object({
  payoffLender: Joi.string().label('Lender').required().default(null).messages({
    'string.empty': `This field is required`,
  }),
  payoffAmount: Joi.number()
    .label('Remaining Balance')
    .min(1)
    .max(999_999)
    .required()
    .default(null)
    .messages({
      'string.empty': `This amount is required`,
      'number.base': `This must be a number`,
    }),
});

export const TradeinPayoffLenderGroup = () => {
  const { handleFormGroupSubmit, formValues: tradein, activeForm } = useFormSubmit();
  const form = useForm({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema),
    defaultValues: {
      payoffLender: tradein.payoffLender,
      payoffAmount: tradein.payoffAmount,
    },
  });

  if (activeForm !== 'tradeinPayoffLenderForm') return null;

  return (
    <form id="tradeinPayoffLenderForm" onSubmit={form.handleSubmit(handleFormGroupSubmit)}>
      <FormBody
        title="Tell us more about your loan."
        body={`${tradein.year} ${tradein.make} ${tradein.model}`}
      />
      <LoanLender control={form.control} />
      <LoanAmount control={form.control} />
    </form>
  );
};
