/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import chatConfig, { ChatConfig } from './Config';
import { ChatMessage } from 'src/types/chat';
import { useChat } from 'src/fetches/useSWRFetch';
import { parseMessage } from './MessageParser';
// import { useHandleChatSubmit } from 'src/handlers/useHandleChatSubmit';

interface ChatProviderProps {
  children: ReactNode;
}

interface ChatContextProps {
  messages: ChatMessage[];
  chatConfig: ChatConfig;
  activeVehicle: any; // Vin
  setMessages: Dispatch<SetStateAction<ChatMessage[]>>;
  handleSuggestion: (task: string) => void;
  handleSubmit: (data: string, task?: string) => Promise<void>;
}

export const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const useChatContext = () => {
  const context = useContext(ChatContext);

  if (context == null) {
    throw new Error('Dialog components must be wrapped in <Dialog />');
  }

  return context;
};

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const { chat, chatIsLoading } = useChat();
  const [messages, setMessages] = useState<ChatMessage[]>(chat);
  const [vehicle, setVehicle] = useState<any>(null); // a vin
  // const handleFormSubmit = useHandleChatSubmit();

  const createChatBotMessage = (task?: string): ChatMessage => {
    return {
      type: 'bot',
      text:
        chatConfig.widgets.find(w => w.widgetName === task)?.helpText || 'Ok, I can help with that',
      task,
    } as ChatMessage;
  };

  const handleSendMessage = async (data: string, task?: string) => {
    const newMessages = [...messages, { text: data, type: 'user' as 'user', task }];
    setMessages(newMessages);

    if (task === 'payments') {
      setVehicle(data);
    }
    handleBotResponse(newMessages);
  };

  const handleBotResponse = (newMessages: ChatMessage[]) => {
    if (newMessages[newMessages.length - 1].task) {
      let message = createChatBotMessage(newMessages[newMessages.length - 1].task);

      setTimeout(() => {
        setMessages(prevMessages => [...prevMessages, message as ChatMessage]);
      }, 500);
    } else {
      let botResponse = parseMessage(newMessages);

      if (botResponse.task) {
        botResponse = createChatBotMessage(botResponse.task);
      }

      setTimeout(() => {
        setMessages(prevMessages => [...prevMessages, botResponse as ChatMessage]);
      }, 500);
    }
  };

  const handleSuggestion = (task: string) => {
    const message = createChatBotMessage(task);

    setMessages([...messages, message]);
  };

  if (chatIsLoading) return null;

  return (
    <ChatContext.Provider
      value={{
        chatConfig: chatConfig,
        messages,
        activeVehicle: vehicle,
        setMessages,
        handleSuggestion,
        handleSubmit: async (data, task) => handleSendMessage(data, task),
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
