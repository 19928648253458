import { Navigate } from 'react-router-dom';
import { useState, useMemo } from 'react';
import CreditApplicationCard from '../../../components/cards/CreditApplicationCard';
import PageLoader from '../../../components/PageLoader';
import PageError from '../../../components/PageError';
import { useCreditApplications, useMe } from '../../../fetches/useSWRFetch';
import { faUser, faPlus, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import toast from 'react-hot-toast';
import { useDocTitle } from '../../../helpers/global-document-title';
import { Selector } from '../../../components/selectors/Selector';
import { useDeals } from '../../../components/context/DealsProvider';
import { PaymentType } from '../../../types/deal';
import { CreditApplicationBlock, Signer } from 'src/types/creditapplication-block';
import { useAuth } from 'src/components/context/AuthProvider';
import { FormListPage } from 'src/components/form/FormShell';
import { FormJourneyTab } from 'src/components/navigation/JourneyTab';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useRecordPageVisit } from 'src/helpers/useRecordPageVisit';
interface CreditRequirementProps {
  type: Signer;
  paymentType: string;
  payment: CreditApplicationBlock | null;
  creditApplicationsDelete: (id: string) => Promise<void>;
  creditApplicationsSwap: () => Promise<void>;
  hasApplication: boolean;
}

function CreditRequirement({
  type,
  paymentType,
  payment,
  creditApplicationsDelete,
  creditApplicationsSwap,
  hasApplication,
}: CreditRequirementProps) {
  return (
    <>
      {!hasApplication && (
        <Selector
          type="link"
          to={`new?paymentType=${paymentType}&type=${type}`}
          thumbnail={{ icon: type === Signer.Primary ? faUser : faUserFriends }}
          title={`Add ${type === Signer.Primary ? 'Primary' : 'Co-Signer'}`}
          subtitle={`${type === Signer.Primary ? 'Primary' : 'Secondary'} credit application`}
          btnIcon={faPlus}
        />
      )}
      {payment && (
        <CreditApplicationCard
          key={payment.id}
          application={payment}
          handleErrorMessage={toast.error}
          handleSuccessMessage={toast.success}
          creditApplicationsDelete={creditApplicationsDelete}
          creditApplicationsSwap={creditApplicationsSwap}
        />
      )}
    </>
  );
}

interface IndexPageProps {
  paymentType: PaymentType;
  creditApplications: CreditApplicationBlock[];
  creditApplicationsDelete: (id: string) => Promise<void>;
  creditApplicationsSwap: () => Promise<void>;
}

function IndexPage({
  creditApplications,
  paymentType,
  creditApplicationsDelete,
  creditApplicationsSwap,
}: IndexPageProps) {
  const { token } = useAuth();
  const [formValues] = useState({
    paymentType: paymentType === null ? '' : paymentType,
  });

  const hasPrimaryApplication: boolean = useMemo(
    () => creditApplications?.some(item => item.type === Signer.Primary),
    [creditApplications]
  );

  const hasCosignerApplication: boolean = useMemo(
    () => creditApplications?.some(item => item.type === Signer.CoSigner),
    [creditApplications]
  );

  const mostRecentPrimaryPayment: CreditApplicationBlock | null = useMemo(() => {
    const sortedPayment = creditApplications
      .filter(x => x.type === Signer.Primary)
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    return sortedPayment.length ? sortedPayment[0] : null;
  }, [creditApplications]);

  const mostRecentCosignerPayment: CreditApplicationBlock | null = useMemo(() => {
    const sortedPayment = creditApplications
      .filter(x => x.type === Signer.CoSigner)
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    return sortedPayment.length ? sortedPayment[0] : null;
  }, [creditApplications]);

  return (
    <FormListPage
      configuration={{
        navigation: <FormJourneyTab />,
        title: null,
      }}
    >
      <CreditRequirement
        type={Signer.Primary}
        paymentType={formValues.paymentType}
        payment={mostRecentPrimaryPayment}
        creditApplicationsDelete={creditApplicationsDelete}
        creditApplicationsSwap={creditApplicationsSwap}
        hasApplication={hasPrimaryApplication}
      />
      {token ? (
        <CreditRequirement
          type={Signer.CoSigner}
          paymentType={formValues.paymentType}
          payment={mostRecentCosignerPayment}
          creditApplicationsDelete={creditApplicationsDelete}
          creditApplicationsSwap={creditApplicationsSwap}
          hasApplication={hasCosignerApplication}
        />
      ) : null}
    </FormListPage>
  );
}

export default function CreditApplicationIndexPage() {
  useDocTitle('Credit Application');
  const { token } = useAuth();
  const { deal, dealIsLoading, dealIsError } = useDeals();
  const {
    creditApplications,
    creditApplicationsIsLoading,
    creditApplicationsIsError,
    creditApplicationsDelete,
    creditApplicationsSwap,
  } = useCreditApplications();
  const { meIsLoading, meIsError } = useMe();
  const { creditAppVisited, setCreditAppVisited } = useSessionContext();
  useRecordPageVisit(creditAppVisited, setCreditAppVisited);

  if (creditApplicationsIsLoading || dealIsLoading || meIsLoading) return <PageLoader />;
  if (creditApplicationsIsError || dealIsError || meIsError) return <PageError />;

  // Confirm this route will be correct in all cases
  if (
    // Always skip index page and force user to create a new credit application if not token
    creditApplications &&
    creditApplications.length === 0 &&
    deal?.paymentType !== PaymentType.Cash
  ) {
    return (
      <PageLoader>
        <Navigate to={`new?paymentType=${deal?.paymentType}&type=primary`} replace={token} />
      </PageLoader>
    );
  }

  return (
    <>
      <IndexPage
        paymentType={deal?.paymentType ? deal.paymentType : null}
        creditApplications={creditApplications}
        creditApplicationsDelete={creditApplicationsDelete}
        creditApplicationsSwap={creditApplicationsSwap}
      />
    </>
  );
}
