import { useCallback, useState } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useApiFetch, useSearchVinApiFetch, useApiPostFetch, useWebsiteFetch } from './useApiFetch';
import { validate as uuidValidate } from 'uuid';
import { MultipleDealResponse, SingleDealResponse, UnauthorizedDealResponse } from '../types/deal';
import { Customer, returnCustomerDefaultValues } from '../types/customer';
import {
  CreditApplicationBlock,
  Signer,
  returnCreditApplicationDefaultValues,
} from '../types/creditapplication-block';
import { AppointmentBlock } from '../types/appointment-block';
import {
  TradeinBlock,
  TradeinFormDefaultValues,
  returnTradeinFormDefaultValues,
} from '../types/tradein-block';
import { Favorite } from '../types/favorite';
import { DocumentBlock } from '../types/document-block';
import {
  Reference,
  ReferenceSubmitBlock,
  returnReferenceFormDefaultValues,
} from '../types/reference-block';
import { DeskingReponse } from 'src/types/desking';
import { ProgramSummary } from 'src/types/program-summary';
import { DisplayDealer } from 'src/types/dealer';
import { useAuth } from 'src/components/context/AuthProvider';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useDeals } from 'src/components/context/DealsProvider';
import { ChatMessage } from 'src/types/chat';
import { CustomerFormattedBlock } from 'src/types/lead';
import { useConfig } from 'src/components/context/config';

export function useSwrDeals() {
  const { token, signout } = useAuth();
  const apiFetcher = useApiFetch();
  const key = 'deals';

  const createDeal = useCallback(
    (url, { arg }) =>
      apiFetcher(url, {
        method: 'POST',
        body: JSON.stringify(arg),
      }),
    [apiFetcher]
  );
  const { trigger: dealsCreate } = useSWRMutation(key, createDeal);

  const deleteDelete = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: dealsDelete } = useSWRMutation(key, deleteDelete);
  const { data, error, mutate } = useSWR(token ? 'deals' : null, apiFetcher);

  if (error && [401, 403].includes(error.status)) {
    signout();
  }

  if (!token) {
    return {
      deals: [],
      dealsIsLoading: false,
      dealsIsError: false,
      dealsMutate: mutate,
      dealsCreate,
      dealsDelete,
    };
  }

  return {
    deals: data,
    dealsIsLoading: !error && !data,
    dealsIsError: error,
    dealsMutate: mutate,
    dealsCreate,
    dealsDelete,
  } as {
    deals: MultipleDealResponse[];
    dealsIsLoading: boolean;
    dealsIsError: boolean;
    dealsMutate: any;
    dealsCreate: any;
    dealsDelete: any;
  };
}

export function useSwrDeal(activeDealId) {
  const apiFetcher = useApiFetch();
  const isValidId = uuidValidate(activeDealId);
  const key = `deals/${activeDealId}`;

  const upsertDeal = useCallback(
    (url, { arg }) =>
      apiFetcher(url, {
        method: 'PUT',
        body: JSON.stringify(arg),
      }),
    [apiFetcher]
  );

  const { trigger: dealUpsert, isMutating } = useSWRMutation(key, upsertDeal);
  const { data, error, isLoading, mutate } = useSWR(isValidId ? key : null, apiFetcher);

  if (activeDealId === 'new') {
    return {
      deal: undefined,
      dealIsLoading: false,
      dealIsError: false,
      dealMutate: mutate,
      dealUpsert,
      dealIsMutating: isMutating,
    };
  }

  if (!isValidId) {
    return {
      deal: undefined,
      dealIsLoading: true,
      dealIsError: false,
      dealMutate: mutate,
      dealUpsert,
      dealIsMutating: true,
    };
  }
  return {
    deal: data,
    dealIsLoading: isLoading,
    dealIsError: error,
    dealMutate: mutate,
    dealUpsert,
    dealIsMutating: isMutating,
  } as {
    deal: SingleDealResponse;
    dealIsLoading: boolean;
    dealIsError: boolean;
    dealMutate: any;
    dealUpsert: any;
    dealIsMutating: boolean;
  };
}

export function useMe() {
  const { token, signout } = useAuth();
  const apiFetcher = useApiFetch();
  const key = 'me';

  const { sessionCustomer, setSessionCustomer } = useSessionContext();

  const upsertMe = useCallback(
    (url, { arg }) =>
      apiFetcher(url, {
        method: 'PUT',
        body: JSON.stringify(arg),
      }),
    [apiFetcher]
  );

  const { trigger: meUpsert } = useSWRMutation(key, upsertMe);
  const { data, error } = useSWR(token ? key : null, apiFetcher);

  if (error && [401, 403].includes(error.status)) {
    signout();
  }

  if (!token) {
    return {
      me: sessionCustomer
        ? (sessionCustomer as Customer)
        : (returnCustomerDefaultValues(undefined) as Customer), // To do: fix this
      meIsLoading: false,
      meIsError: false,
      meUpsert: setSessionCustomer,
    };
  }

  return {
    me: returnCustomerDefaultValues(data),
    meIsLoading: !error && !data,
    meIsError: error,
    meUpsert,
  } as {
    me: Customer;
    meIsLoading: boolean;
    meIsError: boolean;
    meUpsert: any;
  };
}

export function useFavorites() {
  const { token } = useAuth();
  const apiFetcher = useApiFetch();
  const key = 'favorites';

  const deleteFavorite = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: favoriteDelete } = useSWRMutation(key, deleteFavorite);
  const { data, error } = useSWR(token ? key : null, apiFetcher);

  return {
    favorites: data,
    favoritesIsLoading: !error && !data,
    favoritesIsError: error,
    favoriteDelete,
  } as {
    favorites: Favorite[];
    favoritesIsLoading: boolean;
    favoritesIsError: boolean;
    favoriteDelete: any;
  };
}

// To do: test
export function useDealers() {
  const apiFetcher = useApiFetch();
  const key = 'dealers';
  // @ts-ignore
  const { data, error } = useSWR(key, apiFetcher);

  return {
    dealers: data,
    dealersIsLoading: !error && !data,
    dealersIsError: error,
  } as {
    dealers: DisplayDealer[];
    dealersIsLoading: boolean;
    dealersIsError: boolean;
  };
}

export function useTradeins() {
  const apiFetcher = useApiFetch();
  const key = `customers/tradeins`;
  const { token } = useAuth();
  const { sessionTradeins, setSessionTradeins } = useSessionContext();

  const deleteTradein = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: tradeinsDelete } = useSWRMutation(token ? key : null, deleteTradein);
  const { data, error, mutate } = useSWR(token ? key : null, apiFetcher);

  if (!token) {
    return {
      tradeins: sessionTradeins ? sessionTradeins : [],
      tradeinsIsLoading: false,
      tradeinsIsError: false,
      tradeinsMutate: setSessionTradeins,
      tradeinsDelete,
    };
  }

  return {
    tradeins: data,
    tradeinsIsLoading: !error && !data,
    tradeinsIsError: error,
    tradeinsMutate: mutate,
    tradeinsDelete,
  } as {
    tradeins: TradeinBlock[];
    tradeinsIsLoading: boolean;
    tradeinsIsError: boolean;
    tradeinsMutate: any;
    tradeinsDelete: any;
  };
}

export function useTradein(blockId) {
  const { token } = useAuth();
  const fetcher = useApiFetch();
  const { data, error, mutate } = useSWR(
    token && blockId !== 'new' ? `customers/tradeins/${blockId}` : null,
    fetcher
  );

  const { sessionTradeins, sessionCustomer, setSessionTradeins } = useSessionContext();

  if (!token) {
    return {
      tradein: sessionTradeins
        ? (returnTradeinFormDefaultValues(sessionTradeins[0]) as TradeinFormDefaultValues)
        : (returnTradeinFormDefaultValues(sessionCustomer) as TradeinFormDefaultValues), // To do: fix this
      tradeinIsLoading: false,
      tradeinIsError: false,
      tradeinMutate: setSessionTradeins,
    };
  }

  if (blockId === 'new') {
    return {
      tradein: returnTradeinFormDefaultValues(undefined),
      tradeinIsLoading: false,
      tradeinIsError: false,
      tradeinMutate: mutate,
    };
  }

  return {
    tradein: returnTradeinFormDefaultValues(data),
    tradeinIsLoading: !error && !data,
    tradeinIsError: error,
    tradeinMutate: mutate,
  } as {
    tradein: TradeinFormDefaultValues;
    tradeinIsLoading: boolean;
    tradeinIsError: boolean;
    tradeinMutate: any;
  };
}

export function useCreditApplication(blockId: any, signer: Signer) {
  const config = useConfig()!;
  const { token } = useAuth();
  const apiFetcher = useApiFetch();
  const key = `customers/creditapplications`;

  const {
    data,
    error,
    mutate: creditApplicationMutate,
  } = useSWR(token && blockId !== 'new' ? `${key}/${blockId}` : null, apiFetcher);

  const submitCreditApplication = useCallback(
    (url, { arg }) =>
      apiFetcher(blockId === 'new' ? url : `${url}/${blockId}`, {
        method: blockId === 'new' ? 'POST' : 'PUT',
        body: JSON.stringify(arg),
      }),
    [apiFetcher, blockId]
  );
  const { trigger: creditApplicationSubmit } = useSWRMutation(key, submitCreditApplication, {
    onSuccess: () => creditApplicationMutate(),
  });

  if (blockId === 'new') {
    return {
      creditApplication: returnCreditApplicationDefaultValues(signer, undefined, config),
      creditApplicationIsLoading: false,
      creditApplicationtIsError: false,
      creditApplicationSubmit,
    };
  }

  return {
    creditApplication: returnCreditApplicationDefaultValues(undefined, data),
    creditApplicationIsLoading: !error && !data,
    creditApplicationIsError: error,
    creditApplicationSubmit,
  };
}

export function useCreditApplications() {
  const apiFetcher = useApiFetch();
  const { token } = useAuth();
  const key = `customers/creditapplications`;
  const { sessionCreditApplication, setSessionCreditApplication } = useSessionContext();

  const deleteCreditApplications = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: creditApplicationsDelete } = useSWRMutation(key, deleteCreditApplications);
  const swapCreditApplications = useCallback(
    url =>
      apiFetcher(`${url}/swap`, {
        method: 'PATCH',
      }),
    [apiFetcher]
  );
  const { trigger: creditApplicationsSwap } = useSWRMutation(key, swapCreditApplications);
  const { data, error, mutate } = useSWR(token ? key : null, apiFetcher);

  if (!token) {
    return {
      creditApplications: sessionCreditApplication ? sessionCreditApplication : [],
      creditApplicationsIsLoading: false,
      creditApplicationsIsError: false,
      creditApplicationsMutate: setSessionCreditApplication,
      creditApplicationsDelete,
      creditApplicationsSwap,
    };
  }

  return {
    creditApplications: data,
    creditApplicationsIsLoading: !error && !data,
    creditApplicationsIsError: error,
    creditApplicationsMutate: mutate,
    creditApplicationsDelete,
    creditApplicationsSwap,
  } as {
    creditApplications: CreditApplicationBlock[];
    creditApplicationsIsLoading: boolean;
    creditApplicationsIsError: boolean;
    creditApplicationsMutate: any;
    creditApplicationsDelete: any;
    creditApplicationsSwap: any;
  };
}

export function useReference(blockId) {
  const { token } = useAuth();
  const apiFetcher = useApiFetch();
  const key = `customers/references`;
  const { sessionReferences } = useSessionContext();

  const {
    data,
    error,
    mutate: referenceMutate,
  } = useSWR(token && blockId !== 'new' ? `${key}/${blockId}` : null, apiFetcher);

  const submitReference = useCallback(
    (url, { arg }) =>
      apiFetcher(blockId === 'new' ? url : `${url}/${blockId}`, {
        method: blockId === 'new' ? 'POST' : 'PUT',
        body: JSON.stringify(arg),
      }),
    [apiFetcher, blockId]
  );
  const { trigger: referenceSubmit } = useSWRMutation(key, submitReference, {
    onSuccess: () => referenceMutate(),
  });

  if (!token) {
    return {
      reference:
        sessionReferences?.find(block => block.id! === blockId) ||
        returnReferenceFormDefaultValues(data),
      referenceIsLoading: false,
      referenceIsError: false,
      referenceMutate: referenceMutate,
      referenceSubmit,
    };
  }

  if (blockId === 'new') {
    return {
      reference: returnReferenceFormDefaultValues(undefined),
      referenceIsLoading: false,
      referenceIsError: false,
      referenceSubmit,
    };
  }

  return {
    reference: returnReferenceFormDefaultValues(data),
    referenceIsLoading: !error && !data,
    referenceIsError: error,
    referenceSubmit,
  };
}

export function useReferences(isPartial: boolean = false) {
  const config = useConfig()!;
  const { token } = useAuth();
  const apiFetcher = useApiFetch();
  const { sessionCustomer, sessionReferences, setSessionReferences } = useSessionContext();
  const key = `customers/references`;

  // For no auth, we don't need to look at the /me endpoint
  const referenceCount = config?.requireReferenceCount || 0;

  const submitUnauthorizedReferences = useCallback(
    (blocks: ReferenceSubmitBlock[], customer?: CustomerFormattedBlock) =>
      apiFetcher('unauthorized/reference', {
        method: 'POST',
        body: JSON.stringify({
          customer: customer ?? sessionCustomer,
          blocks,
        }),
      }).then((resp: { blocks: string[]; customerId: string; vehicle: any }) => {
        // copy session references, take the resp ids, map them to the submitted blocks, and set the session references
        const newReferences = blocks.map((block, index) => ({
          ...block,
          id: resp.blocks[index],
        }));
        setSessionReferences([...(sessionReferences || []), ...newReferences]);
      }),

    [apiFetcher, sessionCustomer, sessionReferences, setSessionReferences]
  );

  const deleteReference = useCallback(
    (url, { arg: { id } }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: referencesDelete } = useSWRMutation(key, deleteReference);
  const { data, error, mutate } = useSWR(token ? key : null, apiFetcher);

  if (!token) {
    if (isPartial) {
      return {
        // Always start from blank data
        references: Array.from({ length: referenceCount }, () =>
          returnReferenceFormDefaultValues()
        ),
        referencesIsLoading: false,
        referencesIsError: false,
        referencesSubmit: submitUnauthorizedReferences,
      };
    }
    return {
      references: sessionReferences ? (sessionReferences as Reference[]) : ([] as Reference[]),
      referencesIsLoading: false,
      referencesIsError: false,
      referencesSubmit: submitUnauthorizedReferences,
    };
  }

  return {
    references: data
      ? data.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      : [],
    referencesIsLoading: !error && !data,
    referencesIsError: error,
    referencesMutate: mutate,
    referencesDelete,
    referencesSubmit: submitUnauthorizedReferences,
  } as {
    references: Reference[];
    referencesIsLoading: boolean;
    referencesIsError: boolean;
    referencesMutate: any;
    referencesDelete: any;
    referencesSubmit: (blocks: ReferenceSubmitBlock[]) => Promise<void>;
  };
}

export function useDocuments() {
  const apiFetcher = useApiFetch();
  const config = useConfig()!;
  const { token } = useAuth();
  const key = `customers/documents`;
  const { sessionDocuments, setSessionDocuments, sessionCustomer } = useSessionContext();

  const submitUnauthorizedDocuments = useCallback(
    (blocks: DocumentBlock[], customer?: CustomerFormattedBlock) =>
      apiFetcher('unauthorized/document', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', origin: `https://${config.websiteDomain}` },
        body: JSON.stringify({
          customer: customer ?? sessionCustomer,
          blocks,
        }),
      }).then((resp: any) => {
        if (!token) {
          const docs = sessionDocuments?.length ? sessionDocuments : [];
          setSessionDocuments([...docs, ...blocks]);
        }
        return resp;
      }),

    [
      apiFetcher,
      sessionCustomer,
      sessionDocuments,
      setSessionDocuments,
      config.websiteDomain,
      token,
    ]
  );

  const deleteDocument = useCallback(
    (url, { arg: { id } }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: documentsDelete } = useSWRMutation(key, deleteDocument);
  const upsertDocument = useCallback(
    (url, { arg: { id, body } }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'PUT',
        body: JSON.stringify(body),
      }),
    [apiFetcher]
  );
  const { trigger: documentsUpsert } = useSWRMutation(key, upsertDocument);
  const createDocument = useCallback(
    (url, { arg }) =>
      apiFetcher(url, {
        method: 'POST',
        body: JSON.stringify(arg),
      }).then((resp: any) => {
        return resp;
      }),
    [apiFetcher]
  );
  const { trigger: documentsCreate } = useSWRMutation(key, createDocument);
  const { data, error, mutate } = useSWR(token ? key : null, apiFetcher);

  if (!token) {
    return {
      documents: sessionDocuments ? sessionDocuments : [],
      documentsIsLoading: false,
      documentsIsError: false,
      documentsMutate: setSessionDocuments,
      documentsDelete: setSessionDocuments,
      documentsSubmit: submitUnauthorizedDocuments,
    };
  }

  return {
    documents: data,
    documentsIsLoading: !error && !data,
    documentsIsError: error,
    documentsMutate: mutate,
    documentsDelete,
    documentsUpsert,
    documentsSubmit: documentsCreate,
  } as {
    documents: DocumentBlock[];
    documentsIsLoading: boolean;
    documentsIsError: boolean;
    documentsMutate: any;
    documentsDelete: any;
    documentsUpsert: any;
    documentsSubmit: any;
  };
}
export function useAppointments() {
  const { token } = useAuth();
  const { sessionAppointment, setSessionAppointment } = useSessionContext();
  const apiFetcher = useApiFetch();
  const key = `customers/appointments`;

  const deleteAppointment = useCallback(
    (url, { arg: id }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: appointmentsDelete } = useSWRMutation(key, deleteAppointment);
  const { data, error, mutate } = useSWR(token ? key : null, apiFetcher);

  if (!token) {
    return {
      appointments: sessionAppointment ? [sessionAppointment] : [],
      appointmentsIsLoading: false,
      appointmentsIsError: false,
      appointmentsMutate: setSessionAppointment,
      appointmentsDelete,
    };
  }

  return {
    appointments: data,
    appointmentsIsLoading: !error && !data,
    appointmentsIsError: error,
    appointmentsMutate: mutate,
    appointmentsDelete,
  } as {
    appointments: AppointmentBlock[];
    appointmentsIsLoading: boolean;
    appointmentsIsError: boolean;
    appointmentsMutate: any;
    appointmentsDelete: any;
  };
}

export function useAppointment(blockId) {
  const { token } = useAuth();
  const apiFetcher = useApiFetch();
  const key = `customers/appointments`;

  const {
    data,
    error,
    mutate: appointmentMutate,
  } = useSWR(blockId !== 'new' ? `${key}/${blockId}` : null, apiFetcher);

  const submitAppointment = useCallback(
    (url, { arg }) =>
      apiFetcher(blockId === 'new' ? url : `${url}/${blockId}`, {
        method: blockId === 'new' ? 'POST' : 'PUT',
        body: JSON.stringify(arg),
      }),
    [apiFetcher, blockId]
  );
  const { trigger: appointmentSubmit } = useSWRMutation(token ? key : null, submitAppointment, {
    onSuccess: () => appointmentMutate(),
  });

  if (blockId === 'new') {
    return {
      appointment: undefined,
      appointmentIsLoading: false,
      appointmentIsError: false,
      appointmentSubmit,
    };
  }

  return {
    appointment: data,
    appointmentIsLoading: !error && !data,
    appointmentIsError: error,
    appointmentSubmit,
  } as {
    appointment: AppointmentBlock;
    appointmentIsLoading: boolean;
    appointmentIsError: boolean;
    appointmentSubmit: any;
  };
}

export function useVehicleVdp(vin) {
  const apiFetcher = useWebsiteFetch();
  const key = `?action=vehicles&vin=${vin}`;
  const { data, isLoading, error } = useSWR(key === '' || !key ? null : key, apiFetcher, {
    shouldRetryOnError: false,
  });

  return {
    vehicleVdp: data,
    vehicleVdpIsloading: isLoading,
    vehicleVdpIsError: error,
  };
}

export function useVehicleVinSearch(path) {
  const { sessionDesk, sessionPaymentType } = useSessionContext();
  const apiFetcher = useSearchVinApiFetch();
  const key = path;
  const { data, error } = useSWR(key, apiFetcher, { shouldRetryOnError: false });
  // to do: add other deal props we need to treat this vehicle like a "deal"

  return {
    searchVehicle:
      data &&
      ({
        ...data,
        paymentType: sessionPaymentType,
        isDeskingLocked: sessionDesk && sessionDesk[path],
      } as UnauthorizedDealResponse),
    searchVehicleIsLoading: !error && !data,
    searchVehicleIsError: error,
  };
}

export function useDesk() {
  const { activeDealId, deal } = useDeals();
  const isValidId = uuidValidate(activeDealId);
  const vin = deal?.vin;
  const path = 'desk';
  const { token, signout } = useAuth();
  const apiFetcher = useApiPostFetch();
  const key = token ? `deals/${activeDealId}/${path}` : `unauthorized/${path}`;
  const { sessionCustomer, sessionDesk, unauthorizedLockedDeals } = useSessionContext();

  // Request desk if
  // 1. No token (unauthorized)..
  // 1.1. Vin is present
  // 1.2. Full customer info is present
  // 1.3. Deal for this vin is not locked
  // 2. Token is present...
  // 2.1. Vin is present
  // 2.2. Deal is valid guid
  const unauthorizedDealIsLocked =
    !token &&
    ((sessionDesk && sessionDesk[vin as string]) ||
      (unauthorizedLockedDeals && unauthorizedLockedDeals.includes(vin as string)));

  const useUnauthorizedDesk = !token && vin && sessionCustomer && !unauthorizedDealIsLocked;
  const useAuthorizedDesk = token && vin && isValidId;
  const makeDeskRequest = useAuthorizedDesk || useUnauthorizedDesk;

  const postDesk = useCallback(
    (url, { arg }) =>
      apiFetcher(url, {
        body: JSON.stringify(arg),
      }),
    [apiFetcher]
  );

  const unauthorizedPostDesk = useCallback(
    (url, { arg }) => {
      return apiFetcher(
        url,
        {
          body: JSON.stringify({
            deskingParams: arg,
            customer: sessionCustomer,
            vin: vin,
          }),
        },
        false
      );
    },
    [apiFetcher, sessionCustomer, vin]
  );

  const { trigger: deskUpsert, isMutating } = useSWRMutation(
    key,
    token ? postDesk : unauthorizedPostDesk
  );

  const { data, error, isLoading, mutate } = useSWR(
    makeDeskRequest ? key : null,
    () =>
      apiFetcher(
        key,
        token
          ? undefined
          : {
              body: JSON.stringify({
                customer: sessionCustomer,
                vin: vin,
              }),
            },
        token
      ),
    token
      ? {
          shouldRetryOnError: false,
          dedupingInterval: 100,
        }
      : {
          shouldRetryOnError: false,
        }
  );

  // To do: we don't want to mutate this path every time - only on updatedAt change

  if (error && [401, 403].includes(error.status)) {
    signout();
  }

  // Hooks are not ready
  if (!makeDeskRequest) {
    return {
      desk: undefined,
      deskIsLoading: true,
      deskIsError: false,
      deskUpsert,
      deskMutate: mutate,
      deskIsMutating: isMutating,
    };
  }

  // Deal is locked, but we have a session desk
  if (unauthorizedDealIsLocked) {
    return {
      desk: sessionDesk ? sessionDesk[vin as string] : undefined,
      deskIsLoading: false,
      deskIsError: false,
      deskUpsert,
      deskMutate: mutate,
      deskIsMutating: isMutating,
    };
  }

  // Unauthorized desk
  if (useUnauthorizedDesk) {
    return {
      desk: data,
      deskIsLoading: !error && !data,
      deskIsError: error,
      deskUpsert,
      deskMutate: mutate,
      deskIsMutating: isMutating,
    };
  }

  // Authorized desk
  return {
    desk: data,
    deskIsLoading: isLoading,
    deskIsError: error,
    deskUpsert,
    deskMutate: mutate,
    deskIsMutating: isMutating,
  } as {
    desk: DeskingReponse;
    deskIsLoading: boolean;
    deskIsError: boolean;
    deskUpsert: any;
    deskMutate: any;
    deskIsMutating: boolean;
  };
}

export function useDeskUpdatedAt(activeDealId: string) {
  const { token, signout } = useAuth();
  const apiFetcher = useApiFetch();
  const key = `deals/${activeDealId}/desk/updated-at`;
  const { data, error, isLoading, mutate } = useSWR(
    token && activeDealId !== 'new' ? key : null,
    apiFetcher,
    {
      refreshInterval: 1000,
      onErrorRetry: (error, _, __, revalidate, { retryCount }) => {
        let retryFrequency = 1000;
        // Never retry on 404.
        if (error.status === 404) return;

        // Only retry up to 10 times.
        if (retryCount >= 10) return;

        // Retry after 1000 + 100 * retryCount ms.
        setTimeout(() => revalidate({ retryCount }), retryFrequency + retryCount * 100);
      },
    }
  );

  if (error && [401, 403].includes(error.status)) {
    signout();
  }

  if (activeDealId === 'new') {
    return {
      deskUpdatedAt: undefined,
      deskUpdatedAtIsLoading: !error && !data,
      deskUpdatedAtIsError: error,
      deskUpdatedAtMutate: mutate,
    };
  }

  return {
    deskUpdatedAt: data,
    deskUpdatedAtIsLoading: isLoading,
    deskUpdatedAtIsError: error,
    deskUpdatedAtMutate: mutate,
  } as {
    deskUpdatedAt: any;
    deskUpdatedAtIsLoading: boolean;
    deskUpdatedAtIsError: boolean;
    deskUpdatedAtMutate: any;
  };
}

export function useProgramSummary(activeDealId, delay = false) {
  // We need an OK response from /desk before requesting program summary
  const { token } = useAuth();
  const { deal } = useDeals();
  const apiFetcher = useApiFetch();
  const apiPostFetcher = useApiPostFetch();
  const key = token
    ? `deals/${activeDealId}/desking/program-summary`
    : 'unauthorized/program-summary';
  const { sessionCustomer } = useSessionContext();

  const unauthorizedPostProgramSummary = useCallback(
    (url, { arg }) => {
      return apiPostFetcher(
        url,
        {
          body: JSON.stringify({
            deskingParams: arg,
            customer: sessionCustomer,
            vin: deal?.vin,
          }),
        },
        false
      );
    },
    [apiPostFetcher, sessionCustomer, deal]
  );

  const { trigger: programSummaryPost, isMutating } = useSWRMutation(
    key,
    token ? () => {} : unauthorizedPostProgramSummary,
    { revalidate: false }
  );

  const { data, error, mutate } = useSWR(
    (activeDealId !== 'new' || !token) && !delay ? key : null,
    () =>
      token
        ? apiFetcher(key, undefined)
        : apiPostFetcher(
            key,
            {
              body: JSON.stringify({
                customer: sessionCustomer,
                vin: deal?.vin,
              }),
            },
            token
          ),
    {
      shouldRetryOnError: false,
    }
  );

  if (!token) {
    if (delay) {
      return {
        programSummary: undefined,
        programSummaryIsLoading: true,
        programSummaryIsError: false,
        programSummaryMutate: mutate,
        programSummaryPost,
        programSummaryIsMutating: isMutating,
      };
    }
    return {
      programSummary: data,
      programSummaryIsLoading: false,
      programSummaryIsError: error,
      programSummaryMutate: mutate,
      programSummaryPost,
      programSummaryIsMutating: isMutating,
    };
  }

  if (activeDealId === 'new') {
    return {
      programSummary: undefined,
      programSummaryIsLoading: false,
      programSummaryIsError: false,
      programSummaryMutate: mutate,
    };
  }

  return {
    programSummary: data,
    programSummaryIsLoading: !error && !data,
    programSummaryIsError: error,
    programSummaryMutate: mutate,
    programSummaryIsMutating: isMutating,
  } as {
    programSummary: ProgramSummary;
    programSummaryIsLoading: boolean;
    programSummaryIsError: Error;
    programSummaryMutate: any;
    programSummaryIsMutating: boolean;
  };
}

export function useChat() {
  const dataIsLoading = false;
  const error = false;
  const basicData: ChatMessage[] = [
    {
      type: 'bot',
      text: 'Hello! How can we help you today?',
      task: 'learningOptions',
    },
  ];
  // temp state for mocking api
  const [serverData, setServerData] = useState<ChatMessage[]>(basicData);
  // const { token } = useAuth();
  // const apiFetcher = useApiPostFetch();
  // const key = token ? `chat` : 'unauthorized/chat';
  // const { sessionCustomer, sessionDesk, unauthorizedLockedDeals } = useSessionContext();

  // const postChat = useCallback(
  //   (url, { arg }) =>
  //     apiFetcher(url, {
  //       body: JSON.stringify(arg),
  //     }),
  //   [apiFetcher]
  // );

  // const unauthorizedPostChat = useCallback(
  //   (url, { arg }) => {
  //     return apiFetcher(
  //       url,
  //       {
  //         body: JSON.stringify({
  //           customer: sessionCustomer,
  //           message: arg,
  //         }),
  //       },
  //       false
  //     );
  //   },
  //   [apiFetcher, sessionCustomer]
  // );

  // const { trigger: updateChat, isMutating } = useSWRMutation(
  //   key,
  //   token ? postChat : unauthorizedPostChat
  // );

  // const { data, error, isLoading, mutate } = useSWR(
  //   key,
  //   () =>
  //     apiFetcher(
  //       key,
  //       token
  //         ? undefined
  //         : {
  //             body: JSON.stringify({
  //               customer: sessionCustomer,
  //               message,
  //             }),
  //           },
  //       token
  //     ),
  //   token
  //     ? {
  //         shouldRetryOnError: false,
  //         dedupingInterval: 100,
  //       }
  //     : {
  //         shouldRetryOnError: false,
  //       }
  // );

  // // To do: we don't want to mutate this path every time - only on updatedAt change

  // if (error && [401, 403].includes(error.status)) {
  //   AuthService.signOut();
  // }

  const handleMockFetch = async (data: string) => {
    const newMessages = [
      ...serverData,
      { id: serverData.length, type: 'user' as 'user', text: data },
    ];
    setServerData(newMessages);

    // Return a default message
    return [
      {
        id: serverData.length + 1,
        type: 'bot',
        text: 'Hello! How can we help you today?',
        task: 'learningOptions',
      } as ChatMessage,
    ];
  };

  async function mockFetchChat(data: string) {
    const response = await handleMockFetch(data);
    return response;
  }

  return {
    chat: serverData,
    chatIsLoading: dataIsLoading,
    chatIsError: error,
    updateChat: mockFetchChat,
  };
}
