import { useMemo } from 'react';
import { Colors } from 'src/types/colors';
import { ComponentState } from 'src/types/component';

export function useSelectorBox(listType, size, state: ComponentState[]) {
  const style = useMemo(() => {
    let styles: React.CSSProperties = {
      borderWidth: '1px',
      backgroundColor: Colors.White,
      borderColor: listType === 'card' ? Colors.Gray5 : 'transparent',
      borderStyle: 'solid',
      borderRadius: listType === 'card' ? `var(--container-border-radius)` : '0',
    };

    if (state.includes(ComponentState.Done)) {
      styles.borderColor = 'transparent';
    }

    if (state.includes(ComponentState.Active)) {
      styles.backgroundColor = Colors.Blue3;
      styles.borderColor = Colors.Blue1;
    }

    if (state.includes(ComponentState.Alert)) {
      styles.borderColor = Colors.Red1;
    }

    if (state.includes(ComponentState.OrangeAlert)) {
      styles.borderColor = Colors.Orange1;
      styles.borderStyle = 'dashed';
    }

    if (state.includes(ComponentState.Disabled)) {
      styles.borderColor = Colors.Gray6;
    }

    if (listType === 'row') {
      styles.borderBottomColor = Colors.Gray5;
    }

    if (size === 'medium') {
      styles.border = 'none';
    }

    return styles;
  }, [state, listType, size]);

  return { style };
}
