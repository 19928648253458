export enum DocumentTag {
  DriversLicenseFront = 'dlfront',
  DriversLicenseBack = 'dlback',
  Insurance = 'insurance',
  Income = 'income',
  Residence = 'residence',
  TradeIn = 'tradein',
  Reference = 'reference',
  Other = 'other',
}

export interface DocumentBlock {
  id: string;
  customerId: string;
  updatedAt: string;
  createdAt: string;
  tag: DocumentTag;
  url: string;
  name: string;
}
