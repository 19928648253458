import Success from '../../components/form/Success';

export default function ProfileSuccessPage() {
  return (
    <Success
      title="Great Job!"
      subtitle="Keep up the good work, we're almost finished with the paperwork. Just a few more forms
        to go and you're on your way to drive your new car!"
    />
  );
}
