import { Colors } from 'src/types/colors';
import './LargeLabel.css';

interface LargeLabelProps {
  text: string;
  color?: Colors;
  className?: string;
}

export function LargeLabel({ text, color, className }: LargeLabelProps) {
  function getLabelType(color: Colors | undefined) {
    switch (color) {
      case Colors.Blue1:
        return 'mod-blue';
      case Colors.Black:
        return 'mod-black';
      case Colors.Green1:
        return 'mod-green';
      case Colors.Orange1:
        return 'mod-orange';
      case Colors.Red1:
        return 'mod-red';
      default:
        return 'mod-basic';
    }
  }

  return <div className={`lg-label ${getLabelType(color)} ${className}`}>{text}</div>;
}
