import { useState, useEffect, createContext, useContext } from 'react';
import { API_URL } from '../../constants/env';
import { Dealership } from '../../types/dealership';
import {
  OnMessageFn,
  WidgetForm,
  WidgetSuccessConfirmation,
  WidgetThankYouConfig,
  WidgetThankYouCTA,
} from 'src/widgets/SpaceWidget';
import { ChatOptions } from 'src/types/chatOptions';
import { successCtaDefault } from 'src/constants/defaults';

export interface Config extends Dealership {
  widgetDomain: string;
  form?: WidgetForm;
  hostGlobal: typeof globalThis;
  routes?: WidgetThankYouConfig;
  isModal: boolean;
  hostMessage?: OnMessageFn;
  redirectUrl: string;
  location?: string; // A fixed location set by multilocation widget
  chatOptions?: ChatOptions;
  formConfirmation?: WidgetSuccessConfirmation;
}

export const ConfigContext = createContext<Config | null>(null);
const LOCALHOST_HOSTNAME = 'localhost';

function convertRoutes(routes: WidgetThankYouConfig | undefined, websiteDomain: string) {
  let modifiedRoutes = routes || {};
  let websiteOrigin = `https://${websiteDomain}`;

  for (const key in modifiedRoutes) {
    if (modifiedRoutes[key].route) {
      modifiedRoutes[key].route = websiteOrigin + modifiedRoutes[key].route;
    }
  }
  return modifiedRoutes;
}

function getRedirectOrigin(widgetDomain: string, websiteDomain: string) {
  // Widget redirects
  if (widgetDomain !== '') {
    const { hostname } = new URL(widgetDomain);
    const redirectOrigin =
      hostname === LOCALHOST_HOSTNAME ? 'http://localhost:3000' : `https://portal.${websiteDomain}`;
    return redirectOrigin;
  }

  // eslint-disable-next-line no-undef
  const hostDomain = globalThis?.location?.hostname ?? LOCALHOST_HOSTNAME;
  return hostDomain === LOCALHOST_HOSTNAME ? 'http://localhost:3000' : `https://${websiteDomain}`;
}

export function useConfigState({
  widgetDomain = '',
  form = undefined,
  hostGlobal = globalThis,
  routes = undefined,
  isModal = true,
  hostMessage = undefined,
  location = undefined,
  chatOptions = undefined,
  confirmation = undefined,
  successCta = successCtaDefault,
  hideLocations = undefined,
  container,
  display,
  setIsFormSubmitted,
  reset,
}: {
  widgetDomain?: string;
  form?: WidgetForm;
  hostGlobal?: typeof globalThis;
  routes?: WidgetThankYouConfig;
  isModal?: boolean;
  location?: string;
  hostMessage?: OnMessageFn;
  chatOptions?: ChatOptions;
  confirmation?: WidgetSuccessConfirmation;
  successCta?: WidgetThankYouCTA;
  hideLocations?: string[];
  display?: { isModal?: boolean };
  container?: HTMLElement;
  setIsFormSubmitted?: (isSubmitted: boolean) => void;
  reset?: () => void;
}): Config | null {
  const [config, setConfig] = useState<Config | null>(null);

  useEffect(() => {
    let mounted = true;

    Promise.all([fetch(`${API_URL}/config`).then(res => res.json()) as Promise<Dealership>]).then(
      ([dynamicConfig]) => {
        const config: Config = {
          hideLocations:
            dynamicConfig.hideLocations !== undefined ? dynamicConfig.hideLocations : hideLocations,
          widgetDomain,
          ...dynamicConfig,
          baseApi: API_URL,
          // If portalDomain is empty, we are in standalone retailing context
          isRetailing: widgetDomain === '',
          // locations: locations,
          // packages: {
          //   desking: false,
          //   hideprices: false,
          //   retailing: true,
          //   crm: true,
          //   ims: true,
          //   daisi: true,
          //   sms: true,
          // },
          chatOptions,
          form,
          hostGlobal,
          isModal,
          hostMessage,
          routes: convertRoutes(routes, dynamicConfig.websiteDomain),
          redirectUrl: getRedirectOrigin(widgetDomain, dynamicConfig.websiteDomain),
          location,
          formConfirmation: confirmation,
          successCta: dynamicConfig.forms?.successCta || successCta,
          display,
          container,
          reset,
          setIsFormSubmitted,
        };
        if (mounted) {
          setConfig(config);
        }
      }
    );

    return function cleanup() {
      mounted = false;
    };
  }, [
    widgetDomain,
    form,
    hostGlobal,
    routes,
    isModal,
    hostMessage,
    location,
    chatOptions,
    confirmation,
    successCta,
    hideLocations,
    display,
    container,
    setIsFormSubmitted,
    reset,
  ]);

  return config;
}

export function useConfig() {
  const config = useContext(ConfigContext);
  return config;
}
