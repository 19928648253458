import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_PLACES_KEY } from '../constants/env';

const loader = new Loader({
  apiKey: GOOGLE_PLACES_KEY,
  version: 'weekly',
  libraries: ['places'],
});

const useGooglePlaceAutoComplete = () => {
  const initAutoComplete = async (input, callback) => {
    if (window.parent.google) {
      // Widget loads script in parent window
      let autoComplete = new window.parent.google.maps.places.Autocomplete(input, {
        // limit to North America for now
        componentRestrictions: { country: ['us'] },
        fields: ['address_component'],
        types: ['address'],
      });
      autoComplete.addListener('place_changed', callback);
      return autoComplete;
    } else {
      // Load script now
      return new Promise((resolve, reject) => {
        loader
          .load()
          .then(google => {
            const autoComplete = new google.maps.places.Autocomplete(input, {
              // limit to North America for now
              componentRestrictions: { country: ['us'] },
              fields: ['address_component'],
              types: ['address'],
            });

            autoComplete.addListener('place_changed', callback);

            resolve(autoComplete);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  };

  const getFullAddress = async autoComplete => {
    const place = autoComplete.getPlace();

    let address1,
      address2,
      locality,
      adminArea1Short,
      postalCode = '';

    // Get each component of the address from the place details,
    for (const component of place.address_components) {
      const componentType = component.types[0];

      if (componentType === 'street_number') {
        address1 = component.long_name;
      }
      if (componentType === 'route') {
        address1 = `${address1 ?? ''} ${component.long_name}`;
      }
      if (componentType === 'locality') {
        locality = component.long_name;
      }
      if (componentType === 'administrative_area_level_1') {
        adminArea1Short = component.short_name;
      }
      if (componentType === 'postal_code') {
        postalCode = component.long_name;
      }
    }

    let resAddress = {
      address1: address1,
      address2: address2,
      city: locality,
      state: adminArea1Short,
      postalCode: postalCode,
    };

    return resAddress;
  };

  return {
    initAutoComplete,
    getFullAddress,
  };
};

export default useGooglePlaceAutoComplete;
