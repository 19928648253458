export function Callout({ title, desc }: { title: string; desc?: string }) {
  return (
    <div className="callout--block is-fade-in">
      <div className="callout">
        <div className="text--title-2">{title}</div>
        {desc ? <div className="text--body-2">{desc}</div> : null}
      </div>
    </div>
  );
}
