import { useDocTitle } from '../../helpers/global-document-title';
import { useMe } from '../../fetches/useSWRFetch';
import PageLoader from '../../components/PageLoader';
import PageError from '../../components/PageError';
import { BasicInfoForm } from './BasicInfoForm';

export default function BasicInfoIndexPage() {
  useDocTitle('My Account');
  const { me, meIsLoading, meIsError, meUpsert } = useMe();
  if (meIsLoading) return <PageLoader />;
  if (meIsError) return <PageError />;

  return <BasicInfoForm me={me} meUpsert={meUpsert} />;
}
