import { useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { VehicleCardLabels } from './VehicleCardLabels';
import { VehicleCardImage } from './VehicleCardImage';
import { VehicleCardPrice } from './VehicleCardPrice';
import { VehicleCardTitle } from './VehicleCardTitle';
import { VehicleCardLoading } from './VehicleCardLoading';
import { useVehicleVdp, useVehicleVinSearch } from '../../../fetches/useSWRFetch';
import './ModularVehicleCard.css';
import { useDeals } from '../../context/DealsProvider';
import { Favorite } from '../../../types/favorite';
import {
  DealStatus,
  MultipleDealResponse,
  SingleDealResponse,
  UnauthorizedDealResponse,
} from '../../../types/deal';
import { CRMVehicle, VehicleStatus, returnVehiclePhoto } from 'src/types/vehicle';
import { VehicleCardTerms } from './VehicleCardTerms';
import { VehicleCardBody } from './VehicleCardBody';
import { VehicleWebsiteLink } from './VehicleWebsiteLink';
import { useAuth } from 'src/components/context/AuthProvider';

interface ModularVehicleCardProps {
  item:
    | Favorite
    | MultipleDealResponse
    | SingleDealResponse
    | CRMVehicle
    | UnauthorizedDealResponse;
  status?: DealStatus; // Only exists on deal
  handleSelect: (
    vehicle:
      | Favorite
      | MultipleDealResponse
      | SingleDealResponse
      | UnauthorizedDealResponse
      | CRMVehicle
  ) => Promise<void>;
}

export default function ModularVehicleCard({
  item,
  status,
  handleSelect,
}: ModularVehicleCardProps) {
  const { activeDealId } = useDeals();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { searchVehicle } = useVehicleVinSearch(item?.vin);
  const { token } = useAuth();

  const vehicle = useMemo(() => ({ ...item, ...searchVehicle }), [item, searchVehicle]);
  const { vehicleVdp } = useVehicleVdp(vehicle?.vin);

  const labels = useMemo(() => {
    const arr: string[] = [];

    // Vehicle status is only available on authorized deals
    if (token) {
      if (
        vehicle.vehicleStatus === VehicleStatus.Unavailable ||
        vehicle.vehicleStatus === VehicleStatus.Hidden ||
        (status && status !== DealStatus.Open)
      ) {
        arr.push(VehicleStatus.Unavailable);
      }
    }

    if (searchVehicle?.customTags?.length) {
      arr.push(...searchVehicle.customTags);
    }

    if (searchVehicle?.manualCustomTags?.length) {
      arr.push(...searchVehicle.manualCustomTags);
    }

    return arr;
  }, [vehicle, searchVehicle, status, token]);

  const onHandleSelect = async (
    vehicle: MultipleDealResponse | UnauthorizedDealResponse | Favorite
  ) => {
    setIsSubmitting(true);

    try {
      await handleSelect(vehicle);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderClass = useMemo(() => {
    const arr = ['modular-vehicle-card'];

    if (activeDealId === vehicle.id) {
      arr.push('is-active');
    }

    if (status && status !== DealStatus.Open) {
      arr.push('is-unavailable');
    }

    return arr.join(' ');
  }, [activeDealId, vehicle, status]);

  const renderButtonClass = useMemo(() => {
    const arr = ['modular-vehicle-card--select'];

    if (activeDealId === vehicle.id) {
      arr.push('is-active');
    }

    if (status && status !== DealStatus.Open) {
      arr.push('is-unavailable');
    }

    return arr.join(' ');
  }, [activeDealId, vehicle, status]);

  if (!vehicle) {
    return (
      <div>
        <VehicleCardLoading />
      </div>
    );
  }

  return (
    <div className="modular-vehicle-card--holder">
      <button
        className={renderButtonClass}
        type="button"
        onClick={() => onHandleSelect(vehicle)}
        disabled={isSubmitting}
      ></button>
      <div className={renderClass}>
        <div className="modular-vehicle-card--top">
          <VehicleCardImage
            imageSrc={returnVehiclePhoto(vehicle)}
            className="modular-vehicle-card-img"
          />
        </div>
        <VehicleCardLabels labels={labels} />

        <div className="modular-vehicle-card--bottom">
          <div className="modular-vehicle-card--text">
            <VehicleCardTitle
              vehicle={vehicle}
              isAvailable={vehicle.vehicleStatus === VehicleStatus.Available}
            />
            <div className="modular-vehicle-card--subtitle text--body-1">
              <VehicleCardBody trim={vehicle.trim} />
            </div>
          </div>
          <div className="modular-vehicle-card--text">
            <div
              className={`modular-vehicle-card--title ${
                vehicle.sellingPrice || vehicle.price ? 'text--title-1' : 'text--body-1'
              }`}
            >
              {vehicle.price === null && vehicle.msrp ? (
                <span className="text--body-3">MSRP </span>
              ) : null}
              <VehicleCardPrice
                vin={vehicle.vin}
                price={vehicle.sellingPrice || vehicle.price}
                // Status is named differently on CRMVehicle
                isAvailable={
                  vehicle.vehicleStatus === VehicleStatus.Available ||
                  vehicle.status === VehicleStatus.Available
                }
                mileage={vehicle.mileage}
              />
            </div>
            <div className="modular-vehicle-card--subtitle text--body-1">
              <VehicleCardTerms
                price={vehicle.sellingPrice || vehicle.price}
                condition={vehicle.condition}
                mileage={vehicle.mileage}
              />
            </div>
          </div>
        </div>
      </div>
      <VehicleWebsiteLink link={vehicleVdp?.url} />
    </div>
  );
}
